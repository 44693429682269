// material
import { Box, Grid, Container, Typography, Skeleton } from '@mui/material';
// components
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';
import '../../css/pbi-embedding.css';


// ----------------------------------------------------------------------

export default function Stock() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPowerBIStock = async () => {
      fetchData(setIsLoading,setData,setError,"/powerbi/stock", "get", null);
    };
    fetchPowerBIStock();
  }, []);

  if (isLoading) {
      return (
        <Page title="Stock">
          <Container maxWidth="false">
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">Stock</Typography>
            </Box>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12} >
                <Skeleton variant="rect"  height={500}  />
              </Grid>
            </Grid>
          </Container>
        </Page>
      );
  }

  if (data) { 
    console.log("data")
    console.log(data)
    return ( 
      <Page title="Stock">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Stock</Typography>
          Show stock composition. Filter by clients/ vintage/ region etc... 
          Show best case/wine performers, overall valuation AND performance
        </Box>
        <Grid container direction="row" justifyContent="center" alignItems="center">      
        <Grid item sx={{ maxWidth: 1280, width: 1280 }} >
          <PowerBIEmbed
              embedConfig = {{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: data.reportId,
                embedUrl: data.embedUrl,
                accessToken: data.token,
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    },
                    pageNavigation: {
                        visible: false
                    }                    
                  },
                  background: models.BackgroundType.Transparent,
                  // START Report specific settings
                  layoutType: models.LayoutType.Custom,
                  customLayout: {
                      displayOption: models.DisplayOption.FitToWidth // .ActualSize // 
                  }                  
                }
              }}

              eventHandlers = { 
                new Map([
                  ['loaded', function loaded() {console.log('Report loaded');}],
                  ['rendered', function rendered() {console.log('Report rendered');}],
                  ['error', function error(event) {console.log(event.detail);}]
                ])
              }
                
              // cssClassName = { "report-style-class" }
              cssClassName = "embed-container" 

              getEmbeddedComponent = { (embeddedReport) => {
                window.report = embeddedReport;
              }}
            />    
          </Grid>
        </Grid>
      </Container>
    </Page>      

      )
  }

  if (error) {
    return (
      <Page title="Stock">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Stock</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h5">Could not load: Error {error.message} </Typography>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }

  return (
    <Page title="Stock">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Stock</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h5">Could not load: Error</Typography>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  

}
