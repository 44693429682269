import { replace } from 'lodash';
import numeral from 'numeral';

// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
      thousands: ' ',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  currency: {
      symbol: '€'
  }
});

// switch between locales
numeral.locale('fr');

// ----------------------------------------------------------------------

export function fCurrency(number) {
  if (number!== 0 && !number) {
    return "-"
  }
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}
export function fCurrencyLC(number) {
  if (number!== 0 && !number) {
    return "-"
  }
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function currencyFormat(num)  {
  return `€${parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}
export function fPercentDecimal(number) {
  return numeral(number ).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function zeroPad(num, places) { 
  return String(num).padStart(places, '0'); 
}