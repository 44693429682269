// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import {  Box, Grid, Container, Typography, Skeleton, IconButton, Chip, TextField, Snackbar, Button,  ButtonGroup, Alert  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useLoadScript, GoogleMap, Polyline, Marker } from "@react-google-maps/api";
// components
import {useNavigate,useParams} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
// 

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  map: {
    width: '100%',
    height: '100vh'
  },  
  mapContainer: {
    width: '100%',
    height: '100vh'
  },  
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function TrackerItem() {
  const classes = useStyles(); 
  const { id } = useParams();     
  const [itemId, setItemId] = useState(id);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton,setIsLoadingButton] = useState(false); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [uploadDialog, setUploadDialog] = useState(false);
  const [dataFile,setDataFile] = useState({name:"no file selected"});

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  

  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/tracker/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/tracker-param`, "get", null);
    };
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
    } 
  }, [id]);  


  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/shipping/list/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
      </strong>
    )
  },
    { field: 'name', headerName: 'name', width:200 },
    { field: 'description', headerName: 'description', width:200 },
    { field: 'dateAssigned', headerName: 'Tracker Assigned On', width:200 },
    { field: 'startShipping', headerName: 'Shipped On', width:200 },
    { field: 'endShipping', headerName: 'End Shipping', width:200 , flex:1 },
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  


function displayGoogleMaps() {

  if (!isLoaded) {
    return <div>Loading...</div>
    }
    if (data.gps.length ===0 ) {
      return "No GPS data to show"
    }
  // https://www.youtube.com/watch?v=2po9_CIRW7I
  console.log(data)

  const path = data.gps.map((gps) => gps.position );


  const onLoad = polyline => {
    console.log('polyline: ', polyline)
  };  

  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 50000,
    paths: data.gps.map((gps) => gps.position ),  
    zIndex: 1
  };  
  console.log(options);
  return (
    <div className={classes.map} >
      <GoogleMap zoom={10} center={{lat: data.gps[data.gps.length-1].position.lat, lng: data.gps[data.gps.length-1].position.lng}} mapContainerClassName={classes.mapContainer}>
            <Polyline
              onLoad={onLoad}
              path={path}            
              options={options}
            />
            {data.gps.map((info, index) => (
                <Marker
                  key={index}
                  // icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
                  position={info.position}
                  label={`${index}`}
                  title={info.title}
                />
            ))}            
      </GoogleMap> 
    </div>
  )
}


function displayDataItem() {

  if (itemId ==="new") {
    return ""
  }

  return (
    <Grid container item xs={12} spacing={3}>
    <Grid  item xs={12} md={12} >
        <Card >
              <CardContent>                
              <LoadingButton
                    color="primary" loading={isLoading}
                    onClick={() => handleDialogUploadOpen()}
                    variant="contained"
                >
                  <InsertDriveFileIcon/>Upload Tracker data
                </LoadingButton>    
      
            </CardContent>
          </Card >
          {dialogUpload()}                
        </Grid>                         
        <Grid  item xs={12} md={6} >
            <Card >
              <CardHeader title="Temperature" />
              <CardContent>
                Last Reading:  <Chip label={`${data.lastReading.temperature}C°`} color="primary" /> <small>on {data.lastReading.date}</small>
                <HighchartsReact
                  constructorType='stockChart'
                  highcharts={Highcharts}
                  options={{
                    credits: {
                      enabled: false
                    },  
                    title: {
                      text: `C°`
                    },
                    series: [{
                      name:  `Temperature`,
                      data: data.readings.temperature,
                      tooltip: {
                        valueDecimals: 2
                      }
                    }]
                  }}
                />
              </CardContent>
            </Card >
        </Grid> 
        <Grid  item xs={12} md={6} >
            <Card >
            <CardHeader title="Humidity" />
              <CardContent>
                Last Reading:  <Chip label={`${data.lastReading.humidity}%`} color="primary" /> <small>on {data.lastReading.date}</small>
                <HighchartsReact
                  constructorType='stockChart'
                  highcharts={Highcharts}
                  options={{
                    credits: {
                      enabled: false
                    },  
                    title: {
                      text: "% Humidity"
                    },
                    series: [{
                      name:  `Humidity`,
                      data: data.readings.humidity,
                      tooltip: {
                        valueDecimals: 2
                      }
                    }]
                  }}
                />
              </CardContent>
            </Card >
        </Grid> 
        <Grid  item xs={12} md={12} >
        <Card >
            <CardHeader title="GPS Tracker" />
              <CardContent>                
              {displayGoogleMaps()}
            </CardContent>
          </Card >                
        </Grid>
    
          
    <Grid item xs={12}>

    <Box sx={{ pb: 5 }}>
      <Typography variant="h6">Shipments linked to tracker {data.data.name}</Typography>
    </Box>            
    Go to shipping page to link tracker to shipping
    <DataGridPro
      autoHeight 
      pagination
      rows={data.shipping} 
      columns= {columns}
      loading={isLoading}
      components={{
        Toolbar: CustomToolbar,
      }}         
    />
    </Grid>
  </Grid>  

  )

}

  const setTrackerData = (field,value) => {
    data.data[field] = value
    setData({...data});
  }

  const handleSaveClick = () => {
    uploadItem(); 
  }  

  const handleCancelClick = () => { 
    setData({...data});
    setIsDisabled(true);  
  }  

  const uploadDataFromFile = async () => {
    setIsLoadingButton(true);
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    postData.append("refOrder", itemId)
    postData.append('file', dataFile); // The file has be the last element  

    const res = await fetchDataAwait(`/tracker/upload-file`, "post", postData, {'Content-Type': 'multipart/form-data'});
    console.log(res);
    if (res.error) {
      setErrorMessage(res.error.message);
    }
    else {
      setUploadDialog(false); 
      setIsLoading(true);
      console.log("res.data")
      console.log(res.data)
      setData(res.data);
      setConfirmationMessage("Data Updated");
      setIsLoading(false);
    }

    setIsLoadingButton(false);
    return 1;
  }
  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
  }; 
  const handleDialogUploadOpen = () => {
    setUploadDialog(true); 
  }     
  const handleDialogUploadClose = () => {
    setUploadDialog(false); 
  }      
  const dialogUpload = () =>  (
    <Dialog
    open={uploadDialog}
    onClose={() => handleDialogUploadClose()}
    aria-labelledby="alert-dialog-upload"
    maxWidth='lg'
    >
      <DialogTitle  sx={{ pb: 5 }}>Upload data for that tracker</DialogTitle> 
      <DialogContent>
      <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">        
                <Grid sx={{ pb: 2 }} item xs={12}>
                Upload data for that tracker
                </Grid>                                              
                <Grid sx={{ pb: 2 }} item xs={12}  >
                  File must be a <strong>"csv"</strong> and contain a column "date", "temperature","humidity", "latitude","longitude" <br/>
                  Any previous data will be erased - If no data is available for some columns, leave the cell empty.
                </Grid>  
                <Grid item xs={12} >
                <Button
                  variant="contained"
                  component="label"
                >
                  Select a CSV
                  <input
                    type="file"
                    hidden
                    onChange={handleCapture}
                  />
                </Button>  <br/>     
                {dataFile.name}   
                </Grid>                                                                                                                                                                                                                          
              </Grid>             
                    
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> uploadDataFromFile()} color="primary">
        Upload CSV
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogUploadClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );


  const uploadItem = async () => {
    setIsLoadingButton(true);      
    let url = '/tracker/';
    if (itemId !=="new") {
      url += itemId;
    } 
  
    let res = null;
    if (itemId ==="new") {
      res = await fetchDataAwait(url, "post", data.data);
    }
    else {
      res = await fetchDataAwait(url, "put", data.data);
    }
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      // setData(res.data);
      if (itemId ==="new") {
        setItemId(res.data.id);
      }
      setConfirmationMessage("Succesfully saved.");
      setIsDisabled(true);  
    }   
    setIsLoadingButton(false);      
  }
  

  function displayActionButtons() {
    if (isLoading || !data) {
      return ""
    }
    if (isDisabled) {
      return (
        <Button
          variant="contained"
          onClick={() => setIsDisabled(false)}
        >
          <EditIcon/>
          Edit
        </Button>
  
      )
    }
  
    return (    
    <ButtonGroup>
      <LoadingButton
        variant="contained"
        loading={isLoadingButton}
        onClick={() => handleSaveClick()}
      >
        <SaveIcon/>
        Save
      </LoadingButton>
      <Button
        variant="contained"
        onClick={() => handleCancelClick(true)}
      >
        <CancelIcon/>
        Cancel
      </Button>        
  </ButtonGroup>
  )
  }
  

  if (isLoading) {
    return (
      <Page title="Trackers">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Tracker</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Trackers">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Tracker {data.data.name}</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            <Grid container item xs={12} spacing={3}>
                                         
              <Grid item xs={12} >
                <Card >
                  <CardContent>
                    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                      <Grid item xs={12} md={3} >
                        <Typography variant="h6">Tracker Name</Typography>
                      </Grid>  
                      <Grid item xs={12} md={9} >
                        <TextField 
                            onChange={(e)=> setTrackerData("name",e.target.value)}
                            className={classes.field} 
                            id="p-name" 
                            fullWidth required
                            value={data.data.name}
                            disabled={isDisabled}
                        />                      
                      </Grid>                                                                      
                      <Grid item xs={12} md={3} >
                        <Typography variant="h6">External Ref</Typography>
                      </Grid>  
                      <Grid item xs={12} md={9} >
                        <TextField 
                            onChange={(e)=> setTrackerData("externalReference",e.target.value)}
                            className={classes.field} 
                            id="p-externalReference" 
                            fullWidth required
                            value={data.data.externalReference}
                            disabled={isDisabled}                        
                        />                         
                      </Grid>
                      <Grid item xs={12} md={3} >
                        <Typography variant="h6">Tempmate S/N</Typography>
                      </Grid>  
                      <Grid item xs={12} md={3} >
                        <TextField 
                            onChange={(e)=> setTrackerData("tempmateAPISerialNumber",e.target.value)}
                            className={classes.field} 
                            id="p-tempmateAPISerialNumber" 
                            fullWidth required
                            value={data.data.tempmateAPISerialNumber}
                            disabled={isDisabled}                        
                        />                         
                      </Grid>
                      <Grid item xs={12} md={3} >
                        <Typography variant="h6">TempmateAPI Status</Typography>
                      </Grid>  
                      <Grid item xs={12} md={3} >
                        <TextField 
                            onChange={(e)=> setTrackerData("tempmateAPIStatus",e.target.value)}
                            className={classes.field} 
                            id="p-tempmateAPIStatus" 
                            fullWidth required
                            value={data.data.tempmateAPIStatus}
                            disabled={isDisabled}                        
                        />                         
                      </Grid>                                                                  
                      <Grid item xs={12} >
                      {displayActionButtons()} <small> created at {data.data.createdAt} | updated at {data.data.updatedAt} </small>   
                      </Grid>                                                                                                                                                                                                     
                    </Grid>  
                  </CardContent>
                </Card >            
              </Grid>   

            </Grid>      
            

          {displayDataItem()}
          </Grid>
        </Container>
        {snackbarError()}
        {snackbarSuccess()}        
      </Page>
    );
  }
  return (
    <Page title="Trackers">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Tracker</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



