
import {  Button, Snackbar, Alert, IconButton, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DescriptionIcon from '@mui/icons-material/Description';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';

import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { fetchDataAwait } from '../../utils/api';


export default function ButtonDialogGenerateInvoice(props) {
  const {itemId, callbackItem, isDisabled} = props;
  ButtonDialogGenerateInvoice.propTypes = {
    itemId: PropTypes.string.isRequired,
    callbackItem: PropTypes.func.isRequired,    
    isDisabled: PropTypes.bool.isRequired,
  };
  const navigate = useNavigate();  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const [dataGrid,setDataGrid] = useState([]);

  const columnsDocuments =  [
    { field: 'id', headerName: ' ', width:50
    , renderCell: (params) => (
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/client/document/${params.value}`)}
      >
        <ZoomInIcon />
        {params.value}
      </IconButton >
    )
  },
  { field: 'url', headerName: 'Doc', width:20, renderCell: (params) => {
    if (params.value) {
      return (
        <Link
          target="_blank"
          href={params.value}
        >
          <DescriptionIcon />
        </Link>                 
      )
    }
    return "-"
  }}, 
  { field: 'documentType', headerName: 'documentType' ,width:200},   
  { field: 'document', headerName: 'document' ,width:300},
  { field: 'description', headerName: 'description',width:300},      
  { field: 'client', headerName: 'client',width:200},        
  { field: 'admin', headerName: 'admin', width:200},      
  { field: 'isClientVisible', headerName: 'is Visible by Client?', width:200 , renderCell: (params) => {
    if (params.value ===1) {
      return (
        <span>
        <VisibilityIcon/>
        <LoadingButton loading={isLoading}  variant="text" onClick={() => handleOpenDocVisibility(params.row.id,0)}>
        Hide it
      </LoadingButton>               
     </span>
      )
    }
    return (
      <span>
        <VisibilityOffIcon/>
        <LoadingButton loading={isLoading} variant="text" onClick={() => handleOpenDocVisibility(params.row.id,1)}>
        Show it
      </LoadingButton>               
     </span>
    )
}},
  { field: 'isEmailSentToClient', headerName: 'isEmailSentToClient',width:200},      
  { field: 'createdAt', headerName: 'createdAt', width:200  },
  { field: 'updatedAt', headerName: 'updatedAt', width:200  }
  ];
  

  const checkInvoice = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/document/transaction/${itemId}`, "get", null);
    console.log(res);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDataGrid(res.data.documents);
    setIsLoading(false);  
    return true    
  }


  const handleOpenDocVisibility = async (docId, visibility) => {
    setIsLoading(true);      
    const url = `/document/visibility/${docId}/${visibility}`;
    console.log(url);
    const res = await fetchDataAwait(url, "get", null);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      // Update table
      const updatedRows = dataGrid.map((row) => {
        if (row.id === docId) {
          row.isClientVisible = visibility;
          return row;
        }
        return row;
      });      
      setDataGrid([...updatedRows]);
    }   
    setIsLoading(false);      
    return 0;    
    
  } 
  

  const createNewInvoice = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/generate/invoice/${itemId}`, "get", null);
    console.log(res);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    callbackItem(res.data);    
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  

  const handleOpenDialog = () => {
    checkInvoice();    
    setDialogOpen(true);    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  let jsxWarning = "";
  if (dataGrid && dataGrid.filter((el) =>  el.documentTypeId === 2).length>0) {
    jsxWarning = (<Alert severity="warning">There is already an invoice for that transaction</Alert>
    )
  }  

  return (
    <span>
      <LoadingButton  disabled={isDisabled}  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Generate Invoice</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Generate Invoice</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
               {jsxWarning}
               <Typography variant="h6">List of linked documents</Typography>
                <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={dataGrid}
                columns= {columnsDocuments}
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <LoadingButton  disabled={isDisabled}  variant="contained"  color="primary"  onClick={() => createNewInvoice()}>Generate New Invoice</LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
          {snackbarError()}      
        {snackbarSuccess()}           
        </Dialog>        

    </span>
    )
}