// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Stack, Box, Grid, Container, Typography, Skeleton, TextField, Snackbar, Alert, Link} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';



// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

export default function View() {
  const searchRef = useRef();
  const classes = useStyles();     
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null); 


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/stock/search", "put", {});
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [   
    { field: 'id', headerName: '#', width:50   }, 
    { field: 'wmsId', headerName: 'wmsId v2', width:300 }, 
    { field: 'oldWmsId', headerName: 'OLD wmsId', width:300   }, 
    { field: 'cases_qty', headerName: 'Qty', width:100   },   
      { field: 'name', headerName: 'Case', width:300   },      
      { field: 'packSize', headerName: 'packSize', width:150   },
      { field: 'bottleSize', headerName: 'bottleSize', width:150   },
      { field: 'lwin', headerName: 'lwin', width:150   },
      { field: 'location', headerName: 'location', width:150   }, 
      { field: 'orderReference', headerName: 'orderReference', width:200   },
      { field: 'status', headerName: 'status', width:150   },  
      { field: 'user', headerName: 'Client', width:150  },
      { field: 'saleforbidden', headerName: 'saleforbidden', width:200   },
      { field: 'bookValue', headerName: 'bookValue', width:150 },      
      { field: 'currentValue', headerName: 'currentValue', width:150 },
  ];



const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

const handleSearch = async () => {
    setIsLoadingSearch(true);
    console.log(searchRef.current.value);
    const res = await fetchDataAwait("/stock/search", "put", {search: searchRef.current.value});
    console.log(res)
    if (res.error) {
      setErrorMessage(res.error.message);
    }
    else {
      setData(res.data);
      setConfirmationMessage(res.data.message);

    }
    setIsLoadingSearch(false);
  }
  

  if (isLoading) {
    return (
      <Page title="Cases WmsId">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Cases aggregated by WmsId</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Cases WmsId">
        <Container maxWidth="false">
          <Stack sx={{ pb: 5 }} direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between" >       
              <Typography variant="h4">Cases aggregated by WmsId</Typography>        
          </Stack> 
          <Grid item xs={12}>
              To update the allocation file, copy data from the csv link here: <Link href={data.url}>Link</Link> <br/><br/>
            </Grid>          
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            <Grid item xs={12} md={6}>    
                  <TextField fullWidth label="Search cases in database"
                              inputRef={searchRef} 
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  handleSearch()
                                }
                              }}                                   
                  />
              </Grid>
            <Grid item xs={12}  md={6} sx={{ pb: 5 }}>                          
                  <LoadingButton loading={isLoadingSearch} variant="contained" onClick={handleSearch}>
                  Search
                </LoadingButton>                
            </Grid>
            <Grid item xs={12}>
              <DataGridPro
                pagination
                autoHeight 
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                
                loading={isLoadingSearch}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}     
      </Page>
    );
  }
  return (
    <Page title="Cases">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Cases aggregated by WmsId</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



