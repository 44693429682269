import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import sharpPrecisionManufacturing from '@iconify/icons-ic/sharp-precision-manufacturing';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import baselineWineBar from '@iconify/icons-ic/baseline-wine-bar';
import roundLocalShipping from '@iconify/icons-ic/round-local-shipping';
import peopleFill from '@iconify/icons-eva/people-fill';
// import baselineAnalytics from '@iconify/icons-ic/baseline-analytics';
// import baselineExitToApp from '@iconify/icons-ic/baseline-exit-to-app';
import outlineChecklist from '@iconify/icons-ic/outline-checklist';
// import outlineTimelapse from '@iconify/icons-ic/outline-timelapse';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Stock Wines/Cases',
    icon: getIcon(baselineWineBar),
    children: [
      {
        title: 'Dashboard',
        path: '/stock/overview'
      },
      {
        title: 'Individual Cases',
        path: '/stock/case'
      },
      {
        title: 'Stock View',
        path: '/stock/view'
      },      
      {
        title: 'Inventory',
        path: '/stock/inventory'
      },        
      {
        title: 'Customs',
        path: '/stock/customs'
      },              
      {
        title: 'Vintages',
        path: '/stock/vintage'
      },
      {
        title: 'Wines',
        path: '/stock/wine'
      },      
      {
        title: 'Estates',
        path: '/stock/estate'
      },
      {
        title: 'Traceability',
        path: '/traceability/case'
      },      
    ]
  },  
  {
    title: 'Clients',
    icon: getIcon(peopleFill),
    children: [
      {
        title: 'Dashboard',
        path: '/client/dashboard'
      },
      {
        title: 'List Clients',
        path: '/client/list'
      },
      {
        title: 'Documents',
        path: '/client/documents'
      },
      {
        title: 'Management Fee',
        path: '/client/management-fee/list'
      },   
      {
        title: 'Generate Reporting data',
        path: '/client/reporting/data'
      },            
      {
        title: 'Reporting doc',
        path: '/client/reporting/doc'
      },                  
    ]
  },  
  {
    title: 'Allocate',
    icon: getIcon(outlineChecklist),
    children: [
      {
        title: 'Dashboard',
        path: '/allocation/dashboard'
      },      
      {
        title: 'New Transaction',
        path: '/allocation/select'
      },
      {
        title: 'List Transactions',
        path: '/client/transaction'
      },            
      {
        title: 'Allocation Round',
        path: '/allocation/allocate'
      },
      {
        title: 'Target Allocation',
        path: '/allocation/define'
      },
    ]
  },
  {
    title: 'Purchases',
    icon: getIcon(shoppingBagFill),
    children: [
      {
        title: 'Add New Cases',
        path: '/purchase/add-cases'
      },             
      {
        title: 'Dashboard',
        path: '/purchase/dashboard'
      },
      {
        title: 'Purchases',
        path: '/purchase/list'
      },
      {
        title: 'Suppliers',
        path: '/purchase/suppliers'
      },      
  
    ]
  },

  {
    title: 'Warehouse',
    icon: getIcon(sharpPrecisionManufacturing),
    children: [
      {
        title: 'Dashboard',
        path: '/warehouse/dashboard'
      },
      {
        title: 'Prepare Order',
        path: '/warehouse/prepare'
      },  
      {
        title: 'Pallets',
        path: '/warehouse/pallet'
      },            
      {
        title: 'Sensors WH',
        path: '/warehouse/sensor'
      },
      {
        title: 'Locations',
        path: '/warehouse/location'
      },
    
      // {
      //   title: 'WIP - Manage Tasks',
      //   path: '/warehouse/task'
      // },
      // {
      //   title: 'WIP - Transit Overview',
      //   path: '/warehouse/transit'
      // },      
    ]
  },

  {
    title: 'Transport',
    icon: getIcon(roundLocalShipping),
    children: [
      {
        title: 'Shipments',
        path: '/shipping/list'
      }, 
      {
        title: 'Trackers',
        path: '/tracker/list'
      },       
    ]
  },  
  // {
  //   title: 'Market Analytics',
  //   icon: getIcon(baselineAnalytics),
  //   children: [
  //     {
  //       title: 'Performance',
  //       path: '/analytics/performance'
  //     },
  //     {
  //       title: 'News/Topics Crawler',
  //       path: '/analytics/news'
  //     }
  //   ]
  // },
  // {
  //   title: '[Client VIEW]',
  //   icon: getIcon(outlineTimelapse),
  //   children: [
  //     {
  //       title: 'Wine Portfolio',
  //       path: '/client-view'
  //     },
  //     {
  //       title: 'Cash Transactions',
  //       path: '/client-view/transactions'
  //     },
  //     {
  //       title: 'Documents',
  //       path: '/client-view/documents'
  //     },
  //     {
  //       title: 'Marketplace/Trading?',
  //       path: '/client-view/marketplace'
  //     }
  //   ]
  // }
];

export default sidebarConfig;
