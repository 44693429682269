import { makeStyles } from '@mui/styles';
import { CardHeader, Card, CardContent, Skeleton,  Grid, } from '@mui/material';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PropTypes from 'prop-types';
import { useState, useCallback, useRef, useEffect } from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { useLoadScript, GoogleMap, Polyline, Marker } from "@react-google-maps/api";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  map: {
    width: '100%',
    height: '600px'
  },  
  mapContainer: {
    width: '100%',
    height: '600px'
  },      
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function CardShippingTraceability(props) {
  const classes = useStyles();   
  const { data } = props;
  CardShippingTraceability.propTypes = {
    data: PropTypes.object.isRequired,
  };  
  const [isLoading, setIsLoading] = useState(true);
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });


  const [map, setMap] = useState(null)
  
  const dataRef = useRef(data)
  useEffect(
    () => { dataRef.current = data },
    [data]
  )


  const onLoadMap = useCallback((map) => {
    let dataLatLng;
    // let myLatlng;
    let marker;
    // const bounds = new window.google.maps.LatLngBounds( {
    //   lat: 46.26158318059036, 
    //   lng: 6.146286036091205
    // });

    if  (dataRef.current.gps.length ===1) {
      return 0
    }

      // Create LatLngBounds object.
      const latlngbounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < dataRef.current.gps.length; i += 1) {
          dataLatLng = dataRef.current.gps[i]
          const myLatlng =new window.google.maps.LatLng(dataLatLng.position.lat, dataLatLng.position.lng);
          marker =new window.google.maps.Marker({
              position: myLatlng,
              map,
              title: dataLatLng.title
          });
          latlngbounds.extend(marker.position);
      }

      map.setCenter(latlngbounds.getCenter());
      map.fitBounds(latlngbounds);

    // map.fitBounds(bounds);
    setMap(map)
  }, [])


  function displayTemperatureChart() {
  if (data.temperature.length ===0) {
    return "No Temperature data"
  }
  return (
<HighchartsReact
          
          highcharts={Highcharts}
          
          options={{
            chart: {
              type: 'spline'
          },
          xAxis: {
            type: 'datetime',

          },            
            title: {
              text: "Temperature Sensor (C°)"
            },
            legend:{ enabled:false },

            credits: {
              enabled: false
            },  
            yAxis: {
              title: false
          },
            series: [{
              name:  `Temperature sensor`,
              data: data.temperature,
              tooltip: {
                valueDecimals: 2
              }
            }]
          }}
        />   

  )    
  }


  function displayHumidityChart() {
    if (data.temperature.length ===0) {
      return "No Humidity data"
    }
    return (
  <HighchartsReact
            
            highcharts={Highcharts}
            
            options={{
              chart: {
                type: 'spline'
            },
            xAxis: {
              type: 'datetime',
            },              
              title: {
                text: "Humidity Sensor (%)"
              },
              legend:{ enabled:false },
  
              credits: {
                enabled: false
              },  
              yAxis: {
                title: false
            },
              series: [{
                name:  `Humidity sensor`,
                data: data.humidity,
                tooltip: {
                  valueDecimals: 2
                }
              }]
            }}
          />   
  
    )    
    }


  function displayGoogleMaps() {
    if (!isLoaded) {
      return <div>Loading...</div>
      }
    if (data.gps.length ===0 ) {
      return "No tracking data"
    }
    // https://www.youtube.com/watch?v=2po9_CIRW7I
    const path = data.gps.map((gps) => gps.position );
  
    const onLoad = polyline => {
      console.log('polyline: ', polyline)
    };  
  
    const options = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 50000,
      paths: data.gps.map((gps) => gps.position ),  
      zIndex: 1,
      // disableDefaultUI: true,
    };  
    
    // 
    // onLoad={onLoadMap} 
    return (
      <div className={classes.map} >
        <GoogleMap onLoad={onLoadMap} options={{disableDefaultUI: true}} zoom={12} center={{lat: data.gps[data.gps.length-1].position.lat, lng: data.gps[data.gps.length-1].position.lng}} mapContainerClassName={classes.mapContainer}>
              <Polyline
                onLoad={onLoad}
                path={path}            
                options={options}
              />
              {data.gps.map((info, index) => (
                  <Marker
                    key={index}
                    // icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
                    position={info.position}
                    label={`${index}`}
                    title={info.title}
                  />
              ))}            
        </GoogleMap> 
      </div>
    )
  }


  if (data) {
    return (
    <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
      <Grid item xs={12} >
        <Card >
        <CardHeader title={`Shipping: ${data.shipping}`} />
          <CardContent >            
          {data.title} - Supplier: {data.supplier} - Transporteur: {data.transporteur} <br/>
          <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
          <Grid item xs={6} >          
              <Card >
                <CardContent >            
                  {displayTemperatureChart()}  
                </CardContent >
              </Card >
            </Grid>  
            <Grid item xs={6} >          
              <Card >
                <CardContent >            
                  {displayHumidityChart()}
                </CardContent >
              </Card >
            </Grid>              
            <Grid item xs={12} >          
              <Card >
                <CardHeader title="Tracking Location" />
                <CardContent >            
                  {displayGoogleMaps()}          
                </CardContent >
              </Card >
            </Grid>                               
          </Grid>                        
          </CardContent >
        </Card >  
      </Grid>                               
    </Grid>                
    )
  }


  return (  
    <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
      <Grid item xs={12} >
      <Skeleton variant="rectangular" height={300} />  
      </Grid>                               
    </Grid>            
                                                                                                                                                                                      
    )
  
}