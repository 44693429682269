// material
import { makeStyles } from '@mui/styles';
import { Button, Chip, Grid, Container, Typography, FormControlLabel, CardHeader, Skeleton, CardActions, IconButton, Snackbar,Select, FormControl, InputLabel, MenuItem, Alert, Stack, Card, CardContent  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

// components
import {useNavigate, useParams} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import DialogSelectClient from '../../components/allocate/DialogSelectClient';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

// ----------------------------------------------------------------------


export default function AddCashTransaction() {
  const classes = useStyles();  
  const { id } = useParams();      
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   
  // Form
  const [formError, setFormError] = useState({});
  const [isLoadingButton,setIsLoadingButton] = useState(false); 
  const [dialogOpenClient,setDialogOpenClient] = useState(false);
  const [dataFile,setDataFile] = useState({name:"no file selected"});


  
  useEffect(() => {
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/cash-transaction-param/${id}`, "get", null);
    };      
    if (id!=='new') {
      fetchDataReactScreenNew();
    } else {
      fetchDataReactScreenNew();
    }
    setFormError({
        "transactionType": false,
        "cash": false,
        "invoiceNumber": false,
        "note": false,
        "invoiceVisibility": false,
    });
    return 0    
  }, [id]);




const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

function displayLoading() {
  return (
  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
    <Grid item xs={12}>
      <Skeleton variant="rect"  height={500}  />
    </Grid>
  </Grid>
  )
}


function displayError() {
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item >
        <Alert  severity="error" sx={{ width: '100%' }}>
        {error.message}
        </Alert>          
      </Grid>
    </Grid>
  );  
}

const uploadItem = async () => {
  setIsLoadingButton(true);      

  // IF NO user invoice UPLOAD
  if (data.transaction.generateInvoice!=="upload") {
    console.log("No invoice upload")
    const res = await fetchDataAwait('/cash-transaction', "post",  data);
    console.log(res)
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage("Succesfully saved.");
      navigate(`/client/transaction/${res.data.id}`)
    }
  }
  // UPLOAD FILE - Get signed url
  else if (!dataFile.name || dataFile.name==="" || dataFile.name==="no file selected") {
      setErrorMessage("Error: No invoice file has been provided");
  } else {
    const signedUrl = await fetchDataAwait('/get-signed-url/', "put",  {file: dataFile.name, type: 'invoice'});
    console.log(signedUrl);
    if (signedUrl.error) {
      setErrorMessage("Error with S3 presigned url");
      setIsLoadingButton(false); 
      return 0;
    }     
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    Object.entries(signedUrl.data.presignedURL.fields).forEach(([k, v]) => {
      postData.append(k, v);
    });  

    postData.append('file', dataFile); // The file has be the last element  

    const response = await axios.post(signedUrl.data.presignedURL.url, postData, {
      headers: {'Content-Type': 'multipart/form-data'},
    })          
    .then(async () => {
      console.log("UPLOADING DATA");
      const res = await fetchDataAwait('/cash-transaction/', "post",  {...data, transaction: {...data.transaction ,file: signedUrl.data.fileName} });
      if (res.error) {
        setErrorMessage(res.error.message);
      } else {
        // setData(res.data);
        setConfirmationMessage("Succesfully saved."); 
        navigate(`/client/transaction/${res.data.id}`)
      }          
    })
    .catch(error => {
      setErrorMessage("Error S3 upload");
      console.log(error);                
    });
    console.log("end")    
    console.log(response)    


  }
    
  setIsLoadingButton(false);     
  return 1 
}

const handleSaveClick = () => {
  // Check
  uploadItem(); 
}


const setTransactionData = (field,value) => {
  data.transaction[field] = value
  setData({...data});
}


const handleSelectedTransactionTypeId = (event) => {
  data.transaction.transactionType = event.target.value
  setData({...data});
};


function displayInvoiceForm() {
  if (data.transaction.generateInvoice !=='upload') {
    return ""
  }
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>Provide a pdf invoice</Grid>
      <Grid item xs={12}  md={4}>
      <TextField 
        onChange={(e)=> setTransactionData("invoiceNumber",e.target.value)}
        className={classes.field} 
        id="invoiceNumber" 
        label="Invoice Number"      
        fullWidth required
        value={data.transaction.invoiceNumber}
        error={formError.note}
        disabled={data.transaction.generateInvoice!=='upload'}
        InputLabelProps={{ shrink: true }}
      />                
    </Grid>   
    <Grid item xs={12} md={8}>
          <Button
            variant="contained"
            component="label"
            disabled={data.transaction.generateInvoice!=='upload'}
          >
            Upload Invoice (pdf)
            <input
              accept="application/pdf"
              type="file"
              hidden
              onChange={handleCapture}
            />
        </Button>  <br/>     
        {dataFile.name}
    </Grid>  

  </Grid>
  )
}

function displayActionButtons() {
  return (       
    <LoadingButton
      variant="contained"
      loading={isLoadingButton || isLoading}
      onClick={() => handleSaveClick()}
    >
      <SaveIcon/>
      Save
    </LoadingButton>    
  )
}


const handleChangeRadio = (event) => {
  console.log(event.target.value)
  setTransactionData("generateInvoice",event.target.value)
};  

const updateClientData = (rowId,rowClient) => {
  data.user.id = rowClient.id;
  data.user.externalId = rowClient.externalId ;
  data.user.cashBalance = rowClient.cashBalance ;
  data.user.casesValue = rowClient.casesValue ;
  data.user.bookValue = rowClient.bookValue ;
  data.user.valueDate = rowClient.valueDate ;
  setData({...data});
};  


const handleCapture = (event) => {
  setDataFile(event.target.files[0]);
};


function displayPage(func) {
  return (
    <Page title="Cash Transaction">
      <Container maxWidth="false">
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            New Cash Transaction
          </Typography>
        </Stack>        
        {func()}
      </Container>
      {snackbarError()}   
      {snackbarSuccess()}    
    </Page>
  );
}


function displayItem() {
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
       
      <Grid item xs={12} >
        <Card >
        <CardContent>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">  
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Transaction Type*</InputLabel>
              <Select 
                label="Transaction Type" 
                labelId="select-category"
                id="select-category"
                value={data.transaction.transactionType}
                onChange={handleSelectedTransactionTypeId}
              >
                {data.transactionTypes.map((item,i) => (
                    <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                ))}
                </Select> 
              </FormControl>  
            </Grid>           
            <Grid item xs={12} md={4}>
              <TextField 
                  onChange={(e)=> setTransactionData("cash",e.target.value)}
                  className={classes.field} 
                  id="cash" 
                  label="Cash"
                  type="number"
                  fullWidth required
                  value={data.transaction.cash}
                  error={formError.cash}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
            <TextField 
                  onChange={(e)=> setTransactionData("note",e.target.value)}
                  className={classes.field} 
                  id="note" 
                  label="Note" 
                  multiline
                  rows={4}                  
                  fullWidth required
                  value={data.transaction.note}
                  error={formError.note}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid item xs={12} md={12}>
                Client: <Chip label={data.user.externalId} /><IconButton onClick={() => setDialogOpenClient(true)} variant="contained" color="primary" size="small"><EditIcon/></IconButton> 
            </Grid>                        
              <Grid  sx={{ pb: 2 }} container item xs={12} spacing={3} direction="row" justifyContent="center" alignItems="space-between" >
              <Grid item xs={12} md={3} >
                <Card >
                  <CardHeader
                    title={fCurrency(data.user.cashBalance)}
                    subheader="Cash"
                  />              
                </Card >
              </Grid>  
              <Grid item xs={12} md={3}>
                <Card >
                  <CardHeader
                    title={fCurrency(data.user.casesValue)}
                    subheader="Collection Market Value"
                  />              
                </Card >              
              </Grid> 
              <Grid item xs={12} md={3}>
                <Card >
                  <CardHeader
                    title={fCurrency(data.user.bookValue)}
                    subheader="Book Value"
                  />              
                </Card >              
              </Grid>                                             
            </Grid>  
            <Grid item xs={12} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Invoice</FormLabel>
                <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                        value={data.transaction.generateInvoice}
                        onChange={handleChangeRadio}
                      >
                  <FormControlLabel value="auto" control={<Radio />} label="Created by system" />
                  <FormControlLabel value="upload" control={<Radio />} label="Upload Invoice" />
                  <FormControlLabel value="na" control={<Radio />} label="No invoice" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {displayInvoiceForm()}
          </Grid>                        
        </CardContent>
        <CardActions>
         {displayActionButtons()}
        </CardActions>
        </Card>                    
      </Grid>
      <DialogSelectClient dialogOpen={dialogOpenClient} setDialogOpen={setDialogOpenClient} setErrorMessage={setErrorMessage} rowId={1} setClient={updateClientData}/>        
  </Grid>    
  )

}

  // PAGE LOGIC
  if (isLoading) {
    return displayPage(displayLoading);
  }
  if (!error) {   
    return displayPage(displayItem);
  }
  return displayPage(displayError);



}

