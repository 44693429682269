// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import {MenuItem, Select, Chip, Box, Grid, Container, Typography, Snackbar, Skeleton,  FormControl,  Alert, Button,  ButtonGroup, IconButton, Stack, Card, CardContent, AppBar, Link, CardHeader  } from '@mui/material';
// Checkbox,  FormControlLabel,
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DescriptionIcon from '@mui/icons-material/Description';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// components
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import DialogAddUniqueCaseFromSearch from '../../components/allocate/DialogAddUniqueCaseFromSearch';
import ButtonDialogUploadDocumentShipping from '../../components/warehouse/ButtonDialogUploadDocumentShipping';
import ButtonDialogShippingStart from '../../components/warehouse/ButtonDialogShippingStart';
import ButtonDialogShippingEnd from '../../components/warehouse/ButtonDialogShippingEnd';
import ButtonDialogPickSupplier from '../../components/stock/ButtonDialogPickSupplier';
import ButtonDialogPickTransporter from '../../components/stock/ButtonDialogPickTransporter';
import Page from '../../components/Page';
import ButtonDialogShippingTracker from '../../components/warehouse/ButtonDialogShippingTracker';
import DialogShippingTrackerUpdate from '../../components/warehouse/DialogShippingTrackerUpdate';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fDate } from '../../utils/formatTime';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}


export default function ShippingItem() {
  const classes = useStyles();   
  const { id } = useParams();    
  const [itemId, setItemId] = useState(id);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [dialogOpenShippingEnd, setDialogOpenShippingEnd] = useState(false);
  const [dialogOpenShippingStart, setDialogOpenShippingStart] = useState(false);
  const [dialogOpenDocNew, setDialogOpenDocNew] = useState(false);
  const [dialogOpenDoc, setDialogOpenDoc] = useState(false);
  const [documentIdRow, setDocumentIdRow] = useState(null);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [dataFile,setDataFile] = useState({name:"no file selected"});
  const [rowId, setRowId] = useState(null);
  const [dialogOpenCase,setDialogOpenCase] = useState(false);

  const [trackerIdRow, setTrackerIdRow] = useState(null);
  const [trackerName, setTrackerName] = useState(null);
  const [dialogOpenRow, setDialogOpenRow] = useState(null);
  const [endDate, setEndDate]= useState(null);
  const [startDate, setStartDate]= useState(null);
  const [description, setDescription]= useState(null);


  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingButton,setIsLoadingButton] = useState(false);   
  

  const handleDialogUploadOpen = () => {
    setUploadDialog(true); 
  }   

  const handleDialogUploadClose = () => {
    setUploadDialog(false); 
  }  

  // TRANSACTION DATA
  const newRow = {
    id:0,
    caseId: 0,
    name: "",
    packSize: "",
    bottleSize: "",
    packType: "",
    status: "",
    location:"",
    orderReference:"",
    shipment:""

  };

  const handleClickAddRow = () => {
    const row = {...newRow};
    row.id = Math.max(...data.cases.map(o => o.id), 0)+1;
    handleAddCasesData([...data.cases,row]);
  }; 
  const handleRemoveRow = (rowId) => {
    handleAddCasesData(data.cases.filter((el) =>  el.id !== rowId));
  };  
  const handleAddCase = (rowTableId) => {
    setRowId(rowTableId);
    setDialogOpenCase(true);
  };     
  const updateCaseData = (rowId,rowCase) => {

    if (data.cases.find(obj => obj.caseId === rowCase.id)) {
      setErrorMessage(`Case id ${rowCase.id} already added to list`);
      return 0
    }

    const updatedRows = data.cases.map((row) => {
      if (row.id === rowId) {
      
        row.caseId = rowCase.id;
        row.name = rowCase.name;
        row.status = rowCase.status;
        row.certificationReference = rowCase.certificationReference;
     
        row.packSize= rowCase.packSize;
        row.bottleSize= rowCase.bottleSize;
        row.packType= rowCase.packType;
        row.location= rowCase.location;

        return row;
      }
      return row;
    });
    handleAddCasesData([...updatedRows]);
    return 1
  };     

  const uploadListFromFile = async () => {
    setIsLoadingButton(true);
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    postData.append("refOrder", id)
    postData.append('file', dataFile); // The file has be the last element  


    const res = await fetchDataAwait(`/shipping/upload-list`, "post", postData, {'Content-Type': 'multipart/form-data'});
    if (res.error) {
      setErrorMessage(res.error.message);
    }
    else {
      setUploadDialog(false); 
      setIsLoading(true);
      console.log("res.data")
      console.log(res.data)
      handleAddCasesData(res.data);
      setConfirmationMessage("List updated. Do not forget to save.");
      setIsLoading(false);
    }

    setIsLoadingButton(false);
    return 1;
  }
  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
  };   
  const dialogUpload = () =>  (
    <Dialog
    open={uploadDialog}
    onClose={() => handleDialogUploadClose()}
    aria-labelledby="alert-dialog-upload"
    maxWidth='lg'
    >
      <DialogTitle  sx={{ pb: 5 }}>Upload a list of cases</DialogTitle> 
      <DialogContent>
      <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">        
                <Grid sx={{ pb: 2 }} item xs={12}>
                  Upload of list of cases to replace the existing list-
                </Grid>                                              
                <Grid sx={{ pb: 2 }} item xs={12}  >
                  File must be a <strong>"csv"</strong> and contain a column "caseId" with the list of ids
                </Grid>  
                <Grid item xs={12} >
                <Button
                  variant="contained"
                  component="label"
                >
                  Upload a CSV
                  <input
                    type="file"
                    hidden
                    onChange={handleCapture}
                  />
                </Button>  <br/>     
                {dataFile.name}   
                </Grid>                                                                                                                                                                                                                          
              </Grid>             
                    
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> uploadListFromFile()} color="primary">
        Upload CSV
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogUploadClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const handleTypeSelected = (event) => {
    setShippingData("destinationType",event.target.value);
  };  

  const handleSelectedPreconfigured = (event) => {
    const preconfigured = data.preconfigured[event.target.value];
    setShippingData("destinationPosition",preconfigured.position);
    setShippingData("destinationLabel",preconfigured.label);
    setShippingData("destinationType",preconfigured.type);
    setShippingData("destinationAddress",preconfigured.address);
    setShippingData("destinationLatitude",preconfigured.latitude);
    setShippingData("destinationLongitude",preconfigured.longitude);
  };  

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }  

  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/shipping/list/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/shipping-param`, "get", null);
    };
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
    } 
  }, [id]);


  const columnsCases =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.row.id);}}
        >
          <ClearIcon />
        </IconButton >        
      </span>
    )
  },
  { field: 'caseId', headerName: 'caseId', width:30       , renderCell: (params) => (
    <IconButton 
      variant="contained"
      color="primary"
      size="small"
      onClick={() => navigate(`/stock/case/${params.value}`)}
    >
      <ZoomInIcon />
      {params.value}
    </IconButton >
    )},  
    { field: 'name', headerName: 'Case', width: 300  , renderCell: (params) => (
      <span>
          
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleAddCase(params.row.id);}}
        >
          <AddCircleOutlineOutlinedIcon />
          
        </IconButton >    
        {params.value}    
      </span>
      )
    },      
    { field: 'packSize', headerName: 'packSize', width:150  },
    { field: 'packType', headerName: 'packType', width:150  },
    { field: 'bottleSize', headerName: 'bottleSize', width:150  }, 
    { field: 'orderReference', headerName: 'orderReference', width:200  },
    { field: 'shippingId', headerName: 'shippingId', width:150  },
    { field: 'purchaseId', headerName: 'PO No', width:150   , renderCell: (params) => (
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/purchase/list/${params.value}`)}
      >
        <ZoomInIcon />
        {params.value}
      </IconButton >
      )},  
  ];


  const columnsTrackers =  [
    { field: 'id', headerName: ' ', width:100
    , renderCell: (params) => (
      <span>
        <IconButton disabled={isDisabled}
        variant="contained"
        color="primary"
        size="small"
        onClick={()=> { updateShippingTracker(params.row);}}
      >
        <EditIcon />
      </IconButton >      
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/tracker/list/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >
        </span>
    )
  },
    { field: 'name', headerName: 'Tracker', width: 200 },
    { field: 'externalReference', headerName: 'externalReference', width:150  },
    { field: 'description', headerName: 'Description', width:300  },
    { field: 'dateAssigned', headerName: 'Placed On', width:150  }, 
    { field: 'dateRemoved', headerName: 'Removed On', width:200  },
    { field: 'Action', headerName: 'Delete Link', width:200, renderCell: (params) => (
      <strong>
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { deleteShippingTracker(itemId,params.row.id);}}
        >
          <DeleteIcon />
        </IconButton >
      </strong>
      )
    },   
  ];




const columnsDocuments =  [
  { field: 'id', headerName: ' ', width:75
  , renderCell: (params) => (
    <LoadingButton    color="primary"  onClick={() => handleOpenDialogDoc(params.value)}><ZoomInIcon />{params.value}</LoadingButton>
  )
},
{ field: 'url', headerName: 'Doc', width:30, renderCell: (params) => {
  if (params.value) {
    return (
      <Link
        target="_blank"
        href={params.value}
      >
        <DescriptionIcon />
      </Link>                 
    )
  }
  return "-"
}}, 
{ field: 'name', headerName: 'document' ,width:300},
{ field: 'description', headerName: 'description',width:300},       
{ field: 'admin', headerName: 'admin', width:200},      
{ field: 'fileDate', headerName: 'Date File', width:200  },
{ field: 'createdAt', headerName: 'createdAt', width:200  },
{ field: 'updatedAt', headerName: 'updatedAt', width:200  }
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  


const uploadItem = async () => {
  setIsLoadingButton(true);      
  let url = '/shipping/list/';
  if (itemId !=="new") {
    url += itemId;
  } 

  let res = null;
  if (itemId ==="new") {
    res = await fetchDataAwait(url, "post", data.shipping);
  }
  else {
    res = await fetchDataAwait(url, "put", data.shipping);
  }
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    setData(res.data);
    if (itemId ==="new") {
      setItemId(res.data.shipping.id);
    }
    setConfirmationMessage("Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}


const refreshShippingItem = async (param) => {
  setIsLoadingButton(true);      
  let url = '/shipping/list/';
  if (itemId !=="new") {
    url += itemId;
  } 

  const res = await fetchDataAwait(url, "get", null);

  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    data[param] = res.data[param]
    setData({...data});
  }   
  setIsLoadingButton(false);      

}


const handleSaveClick = () => {
  uploadItem(); 
}



const handleSaveCases = async () => {
  setIsLoadingButton(true);      
  const url = `/shipping/cases/${itemId}`;

  const res = await fetchDataAwait(url, "post", data.cases);
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    setConfirmationMessage("Shipped Cases Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}



const setShippingData = (field,value) => {
  data.shipping[field] = value
  setData({...data});
}


const setShippingDate = (field,value) => {
  data.shipping[field] = fDate(value);
  setData({...data});
}



const setTrackersData = (value) => {
  data.trackers = value
  setData({...data});
}


const handleAddCasesData = (value) => {
  data.cases = value
  setData({...data});
}


const handleCancelClick = () => { 
  setData({...data});
  setIsDisabled(true);  
}




const handleOpenDialogDocNew = () => {
  setDialogOpenDocNew(true);  
} 

const handleOpenDialogDoc = (param) => {
  setDocumentIdRow(param); 
  setDialogOpenDoc(true);  
}


const updateShippingTracker = async (row) => {
  setDialogOpenRow(true);

  setTrackerIdRow(row.id);
  setTrackerName(row.name);
  setEndDate(row.dateRemoved);
  setStartDate(row.dateAssigned);
  setDescription(row.description);  
   
}
const deleteShippingTracker = async (shippingId, trackerId) => {
  setIsLoading(true);
  const res = await fetchDataAwait(`/delete-shipping-tracker/${shippingId}/${trackerId}`, "get", null );
  if (res.error) {
      setErrorMessage(res.error.message);
      setIsLoading(false);  
      return false
  }
  setTrackersData(res.data.trackers)  
  setConfirmationMessage(res.data.message);
  setIsLoading(false);  
  return true    
}  



function displayPanels() {
  if (itemId!=="new") {
    return (
      <Grid item xs={12}>
        <Card >
          <CardContentNoPadding >            
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Documents" {...a11yProps(0)} />
          <Tab label="Shipped Cases" {...a11yProps(1)} />          
          <Tab label="Trackers" {...a11yProps(2)} />  

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
          <ButtonDialogUploadDocumentShipping 
                documentId={null} 
                shippingId={itemId} 
                callBackFunction={() => refreshShippingItem("documents")} 
                setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage} 
                dialogOpen={dialogOpenDocNew} 
                setDialogOpen={setDialogOpenDocNew} 
                />    
          <LoadingButton  variant="contained"  color="primary"  onClick={() => handleOpenDialogDocNew()}><InsertDriveFileIcon/>Upload Doc</LoadingButton>

          <ButtonDialogUploadDocumentShipping 
                documentId={documentIdRow} 
                shippingId={itemId} 
                callBackFunction={() => refreshShippingItem("documents")} 
                setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage} 
                dialogOpen={dialogOpenDoc} 
                setDialogOpen={setDialogOpenDoc} 
                /> 

          <DataGridPro
            autoHeight 
            pagination
            rows={data.documents} 
            columns= {columnsDocuments}
            
            loading={isLoading}
            components={{
              Toolbar: CustomToolbar,
            }}         
          />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Grid xs={12} sx={{ pb: 2 }} item justifyContent="left" alignItems="center" > 
            <LoadingButton
                color="primary" loading={isLoading}
                onClick={() => handleDialogUploadOpen()}
                variant="contained"
            >
              <InsertDriveFileIcon/>Upload from list
            </LoadingButton>    
            {dialogUpload()}
            </Grid>
            <Grid xs={12} item justifyContent="left" alignItems="center" >
              <Stack sx={{ pb:3 }} direction="row" spacing={2} justifyContent="left">   
                  <LoadingButton
                      color="primary" loading={isLoading}
                      onClick={handleClickAddRow}
                      variant="contained"
                  >
                    <AddCircleOutlineOutlinedIcon/>Add Row
                  </LoadingButton>
                  <LoadingButton
                      color="primary" loading={isLoading}
                      onClick={handleSaveCases}
                      variant="contained"
                  >
                    <SaveIcon/>Save List
                  </LoadingButton>     
                          
              </Stack>   
            </Grid>                        
            <Grid xs={12} item container spacing={3} direction="row" justifyContent="left" alignItems="center"  className={classes.root}>  
                <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={data.cases} 
                columns= {columnsCases} 
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}                  
              />      
            </Grid>
      </TabPanel> 
      <TabPanel value={value} index={2}>
      <ButtonDialogShippingTracker shippingId={itemId} shippingName={data.shipping.name} callBackFunction={setTrackersData}/>       
      <DialogShippingTrackerUpdate shippingId={itemId}  shippingName={data.shipping.name} callBackFunction={setTrackersData}
      trackerId={trackerIdRow}
      trackerName={trackerName}
      dialogOpen={dialogOpenRow}
      setDialogOpen={setDialogOpenRow}
      endDate={endDate}
      setEndDate={setEndDate}
      startDate={startDate}
      setStartDate={setStartDate}
      description={description}
      setDescription={setDescription}                  
      />    




        <DataGridPro
          autoHeight 
          pagination
          rows={data.trackers} 
          columns= {columnsTrackers}
          
          loading={isLoading}
          components={{
            Toolbar: CustomToolbar,
          }}         
        />            
      </TabPanel>          
      </CardContentNoPadding >
        </Card >                  
      </Grid>
    )
  }
  return ""
}


function displayStatusDateShipping() {
  if (itemId!=="new") {

    let labelJsx  = (
      <Chip label="Not Started" color="primary" variant="outlined" />
    )
    if (data.shipping.isDeleted ===1) {
      labelJsx = (
        <Chip label="DELETED" color="error" />
      )
    } 
    if (data.shipping.endShipping) {
      labelJsx = (
        <Chip label="COMPLETED" color="warning" />
      )
    } 
    else if  (data.shipping.startShipping)  {
      labelJsx = (
        <Chip label="STARTED" color="success" />
      )
    }
    return (
      <Grid item xs={12}>
      <Card >
          <CardContent>
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
                <Typography variant="h5">Status - {labelJsx}</Typography>
              </Grid>                            
              <Grid item xs={6} md={3} >
                <Typography variant="h6">Shipping START</Typography>
              </Grid>  
              <Grid item xs={6} md={3} >

                <ButtonDialogShippingStart 
                shippingId={itemId} 
                shippingName={data.shipping.name} 
                startDate={data.shipping.startShipping}
                callBackFunction={(i) => setShippingData("startShipping",i)} 
                setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage} 
                dialogOpen={dialogOpenShippingStart} 
                setDialogOpen={setDialogOpenShippingStart} 
                /> 


                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Date"
                  inputFormat="dd/MM/yyyy HH:mm"
                  value={data.shipping.startShipping}
                  renderInput={(params) => <TextField {...params} />}
                  disabled
                />        
                </LocalizationProvider>  <IconButton  variant="contained"  color="primary"  onClick={() => setDialogOpenShippingStart(true)}><EditIcon/></IconButton>                                 
    
              </Grid>                          
              <Grid item xs={6} md={3} >
                <Typography variant="h6">Shipping END</Typography>
              </Grid>  
              <Grid item xs={6} md={3} >

              <ButtonDialogShippingEnd 
                shippingId={itemId} 
                shippingName={data.shipping.name} 
                endDate={data.shipping.endShipping}
                callBackFunction={(i) => setShippingData("endShipping",i)} 
                setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage} 
                dialogOpen={dialogOpenShippingEnd} 
                setDialogOpen={setDialogOpenShippingEnd} 
                /> 

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Date"
                  inputFormat="dd/MM/yyyy HH:mm"
                  value={data.shipping.endShipping}
                  renderInput={(params) => <TextField {...params} />}
                  disabled
                />        
                </LocalizationProvider>  <IconButton  variant="contained"  color="primary"  onClick={() => setDialogOpenShippingEnd(true)}><EditIcon/></IconButton>                               
    
              </Grid>                                                                                                                                                                                                                                                                                                                                                        
            </Grid>  
          </CardContent>          
        </Card >                        
        
      </Grid> 
    )
  } 
  return ""
}

function displayActionButtons() {
  if (isLoading || !data) {
    return ""
  }
  if (isDisabled) {
    const buttonJsx ="";
    // if ( data.shipping.id && data.shipping.id !=="new") {
    //   buttonJsx = (
    //     <span >     
    //       <LoadingButton
    //         variant="contained"  color="primary" loading={isLoading}
    //         id="basic-button"
    //         aria-controls="basic-menu"
    //         aria-haspopup="true"
    //         aria-expanded={openMenu ? 'true' : undefined}
    //         onClick={handleClickMenu}
    //       >
    //         <ListIcon/>Actions
    //       </LoadingButton>          
    //     </span> )  
    // }
    return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between" >       
      <Button
        variant="contained"
        onClick={() => setIsDisabled(false)}
      >
        <EditIcon/>
        Edit
      </Button>
      {buttonJsx}
    </Stack>        
    )
  }

  return (    
  <ButtonGroup>
    <LoadingButton
      variant="contained"
      loading={isLoadingButton}
      onClick={() => handleSaveClick()}
    >
      <SaveIcon/>
      Save
    </LoadingButton>
    <Button
      variant="contained"
      onClick={() => handleCancelClick(true)}
    >
      <CancelIcon/>
      Cancel
    </Button>        
</ButtonGroup>
)
}



  if (isLoading) {
    return (
      <Page title="Shipping">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Shipping</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error && data) {   
    return (
      <Page title="Shipping">
        <Container maxWidth="false">

          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Shipping #{itemId} - {data.shipping.name}</Typography>                              
                {displayActionButtons()}                                       
          </Stack>                          
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                
            {displayStatusDateShipping()}      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Shipping Name</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setShippingData("name",e.target.value)}
                          className={classes.field} 
                          id="p-name" 
                          fullWidth required
                          value={data.shipping.name}
                          disabled={isDisabled}
                      />                      
                    </Grid>  
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Supplier</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          className={classes.field} 
                          id="Supplier" 
                          label="Supplier" 
                          fullWidth required
                          value={data.shipping.supplier}
                          disabled
                          InputLabelProps={{ shrink: true }}
                      />
                      <ButtonDialogPickSupplier itemName={data.shipping.name}  supplierName={data.shipping.supplier} callbackItem={setShippingData} isDisabled={isDisabled} />              
                    </Grid>   
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Transporter</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          className={classes.field} 
                          id="Transporter" 
                          label="Transporter" 
                          fullWidth required
                          value={data.shipping.transporter}
                          disabled
                          InputLabelProps={{ shrink: true }}
                      />
                      <ButtonDialogPickTransporter itemName={data.shipping.name}  supplierName={data.shipping.transporter} callbackItem={setShippingData} isDisabled={isDisabled} />              
                    </Grid> 
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Cost (€)</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setShippingData("cost",e.target.value)}
                          className={classes.field} 
                          id="cost" 
                          label="Cost" 
                          fullWidth required
                          value={data.shipping.cost}
                          disabled={isDisabled}
                          InputLabelProps={{ shrink: true }}
                      />
                    </Grid>                                                                                                                  
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Description</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setShippingData("description",e.target.value)}
                          className={classes.field} 
                          id="p-description" 
                          fullWidth required
                          value={data.shipping.description}
                          disabled={isDisabled}
                          multiline
                          rows={4}                          
                      />                         
                    </Grid>                                                                                                    
 
                    <Grid item xs={12}>
                    <Card >
                        <CardContent>
                          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                          <Grid item xs={12} >
                              <Typography variant="h5">Destination*   </Typography>
                              All the cases shipped will be recorded with this position at the end of the shipment.<br/>
                              Use a predefined destination:                             <FormControl> 
                                <Select style={{width: 300}}
                                  autoWidth	
                                  size="small"
                                  labelId="select-destination"
                                  id="select-destination"
                                  onChange={handleSelectedPreconfigured}
                                  disabled={isDisabled}
                                >
                                  {Object.keys(data.preconfigured).map((item,i) => (
                                      <MenuItem value={item} key={i}>{item}</MenuItem>
                                  ))}
                                </Select> 
                              </FormControl> 
                            </Grid>                            
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Position Name</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9} >
                              <TextField 
                                  onChange={(e)=> setShippingData("destinationPosition",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationPosition" 
                                  fullWidth required
                                  value={data.shipping.destinationPosition}
                                  disabled={isDisabled}
                              />                      
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Label (short name)</Typography>
                            </Grid>  
                            <Grid item xs={12} md={3}>
                              <TextField 
                                  onChange={(e)=> setShippingData("destinationLabel",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationLabel" 
                                  fullWidth required
                                  value={data.shipping.destinationLabel}
                                  disabled={isDisabled}
                              />                      
                            </Grid>  
                            <Grid item xs={12} md={2} >
                              <Typography variant="h6">Type</Typography>
                            </Grid>  
                            <Grid item xs={12} md={4} >
                              <FormControl> 
                                <Select style={{width: 300}}
                                  autoWidth	
                                  labelId="select-destinationType"
                                  id="select-destinationType"
                                  value={data.shipping.destinationType}
                                  onChange={handleTypeSelected}
                                  disabled={isDisabled}
                                >
                                  {data.type.map((item,i) => (
                                      <MenuItem value={item} key={i}>{item}</MenuItem>
                                  ))}
                                </Select> 
                              </FormControl>                                                 
                            </Grid>                              
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Address</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9} >
                              <TextField 
                                  onChange={(e)=> setShippingData("destinationAddress",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationAddress" 
                                  fullWidth required
                                  value={data.shipping.destinationAddress}
                                  disabled={isDisabled}
                                  multiline
                                  rows={4}                          
                              />                         
                            </Grid> 
                            <Grid item xs={6} md={2} >
                              <Typography variant="h6">Latitude</Typography>
                            </Grid>  
                            <Grid item xs={6} md={2} >
                              <TextField 
                                  onChange={(e)=> setShippingData("destinationLatitude",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationLatitude" 
                                  fullWidth required
                                  value={data.shipping.destinationLatitude}
                                  disabled={isDisabled}
                              />                      
                            </Grid>    
                            <Grid item xs={6} md={2} >
                              <Typography variant="h6">Longitude</Typography>
                            </Grid>  
                            <Grid item xs={6} md={2} >
                              <TextField 
                                  onChange={(e)=> setShippingData("destinationLongitude",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationLongitude" 
                                  fullWidth required
                                  value={data.shipping.destinationLongitude}
                                  disabled={isDisabled}
                              />                      
                            </Grid>                                                                                                                                                                                                                                                                                                                                                                                   
                          </Grid>  
                        </CardContent>
                      </Card >                        
                    </Grid> 
                    
                    <Grid item xs={12}>
                    <Card >
                        <CardContent>
                          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                          <Grid item xs={12} >
                              <Typography variant="h5">Customs Data</Typography>
                            </Grid>                            
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Customs Document</Typography>
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <TextField 
                                  onChange={(e)=> setShippingData("customsDocument",e.target.value)}
                                  className={classes.field} 
                                  id="p-customsDocument" 
                                  fullWidth required
                                  value={data.shipping.customsDocument}
                                  disabled={isDisabled}
                              />                      
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Document Issue Date</Typography>
                            </Grid>  
                            <Grid item xs={12} md={3} >
                             <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                label="Date"
                                inputFormat="dd/MM/yyyy"
                                value={data.shipping.customsDocumentIssueDate}
                                onChange={(e)=> setShippingDate("customsDocumentIssueDate",e)}
                                renderInput={(params) => <TextField {...params} />}
                                disabled={isDisabled}
                              />        
                              </LocalizationProvider>                                 
                                                               
                            </Grid> 
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Issuing Customs Office</Typography>
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <TextField 
                                  onChange={(e)=> setShippingData("issuingCustomsOffice",e.target.value)}
                                  className={classes.field} 
                                  id="p-issuingCustomsOffice" 
                                  fullWidth required
                                  value={data.shipping.issuingCustomsOffice}
                                  disabled={isDisabled}
                              />                      
                            </Grid> 
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Entry Date</Typography>
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                label="Date"
                                inputFormat="dd/MM/yyyy"
                                value={data.shipping.customsEntryDate}
                                onChange={(e)=> setShippingDate("customsEntryDate",e)}
                                renderInput={(params) => <TextField {...params} />}
                                disabled={isDisabled}
                              />
                              </LocalizationProvider>                                 
                  
                            </Grid> 
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">11-95</Typography>
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <TextField 
                                  onChange={(e)=> setShippingData("number1195",e.target.value)}
                                  className={classes.field} 
                                  id="p-number1195" 
                                  fullWidth required
                                  value={data.shipping.number1195}
                                  disabled={isDisabled}
                              />                      
                            </Grid>                                                                                                                                                                                                                                                                                                                                                         
                          </Grid>  
                        </CardContent>
                      </Card >                        
                    </Grid>   



                    <Grid item xs={12} >
                      <small> created at {data.shipping.createdAt} | updated at {data.shipping.updatedAt} </small> 
                    </Grid>                                                                                                                                                                                                                      
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>  
            {displayPanels()}                                      

          </Grid>
        </Container>
        {snackbarError()}
        {snackbarSuccess()}
        <DialogAddUniqueCaseFromSearch dialogOpen={dialogOpenCase} setDialogOpen={setDialogOpenCase} setErrorMessage={setErrorMessage} rowId={rowId} setCase={updateCaseData}/>
      </Page>
    );
  }
  return (
    <Page title="Shipping">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Shipping Item</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

