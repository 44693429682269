// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Select, InputLabel, MenuItem, FormControl, Box, Grid, Container, Typography, Snackbar, Skeleton,  Checkbox,  FormControlLabel, Alert, Button,  ButtonGroup, IconButton, Stack, Card,CardHeader, CardContent, AppBar, Link  } from '@mui/material';
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu from '@mui/material/Menu';
import ListIcon from '@mui/icons-material/List';


// import {useDropzone} from 'react-dropzone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

// components
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import ButtonDialogPickRole from '../../components/clients/ButtonDialogPickRole';
import ButtonDialogUploadDocument from '../../components/clients/ButtonDialogUploadDocument';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency,fPercent } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}


export default function ClientItem() {
  const classes = useStyles();   
  const { id } = useParams();    
  const [itemId, setItemId] = useState(id);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false); 
  const [openSummary, setOpenSummary] = useState(false); 
  const [openValuation, setOpenValuation] = useState(false); 
  const [openMultipleInvoices, setOpenMultipleInvoices] = useState(false); 
  const [open1275Invoicing, setOpen1275Invoicing] = useState(false); 

  const [endDate, setEndDate] = useState(''); 
  const [startDate, setStartDate] = useState(''); 

  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [formError, setFormError] = useState({}); 
  const [isLoadingButton,setIsLoadingButton] = useState(false);   

  // Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const columnsFeeRate = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton disabled={isDisabled}
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.value);}}
        >
          <DeleteIcon />
        </IconButton >
      </strong>
      )
    },      
  { field: 'percentage', headerName: 'Fee Rate (%)', width:150, cellClassName: 'editable-theme--cell', type: 'number' , editable: !isDisabled  },  
  { field: 'rateStart', headerName: 'Start (€)', cellClassName: 'editable-theme--cell', width:150, type: 'number', editable: !isDisabled  },
  { field: 'rateEnd', headerName: 'End (€)', cellClassName: 'editable-theme--cell', width:150,  editable: !isDisabled  },
  ];

  const columnsRoles = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton disabled={isDisabled}
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRole(params.value);}}
        >
          <DeleteIcon />
        </IconButton >        
      </strong>
      )
    },      
  { field: 'name', headerName: 'Role', width:150}
  ];



  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function CustomAddToolbar() {
    return (
      <GridToolbarContainer>            
              <Button disabled={isDisabled} variant="text" onClick={() => handleAddFeeRateRow()} color="primary">
                <AddIcon/>Add Row 
              </Button>
      </GridToolbarContainer>
    );
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }  

  function CustomAddRoleToolbar() {
    return (
      <GridToolbarContainer>            
        <ButtonDialogPickRole dataGrid={data.roles}  callbackItem={setNewRole} isDisabled={isDisabled} />
      </GridToolbarContainer>
    );
  }
  

  const handleCellEditCommit = ({ id, field, value }) => {
    const updatedRows = (data.userStorageFeeRate).map((row) => {
      if (row.id === id) {
        row[field] = value;
        return row;
      }
      return row;
    });
    setData({...data, userStorageFeeRate: updatedRows});
  };
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/user/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/user-param`, "get", null);
    };
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
    }
    setFormError({
      "name": false,
      "email": false,
      "phone": false,
      "address": false,
      "status": false,
      "clientInstructions": false,       
      "externalId": false,
      "storageFeeCollectionBasis": false,      
      "VATonFee": false,  
    });    
  }, [id]);



  const columnsTransactions =  [
    { field: 'id', headerName: 'id', width:20
      , renderCell: (params) => (
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/client/transaction/${params.value}`)}
          >
            <ZoomInIcon />
            {params.value}
          </IconButton >        
      )
    }, 
    { field: 'updatedAt', headerName: 'updatedAt', width:200  },
    { field: 'methodLabel', headerName: 'Type' ,width:200},
    { field: 'note', headerName: 'Note',width:300},      
    { field: 'invoice', headerName: 'Invoice#',width:200},        
    { field: 'groupName', headerName: 'Transac Group', width:200},      
    { field: 'cashTransfer', headerName: 'cashTransfer', width:200 },
    { field: 'admin', headerName: 'Admin',width:200},      
    { field: 'createdAt', headerName: 'createdAt', width:200  }
];


  const columnsCases =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/case/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >
    )
  },
    { field: 'name', headerName: 'Case', width:300 },
    { field: 'packSize', headerName: 'packSize', width:150  },
    { field: 'packType', headerName: 'packType', width:150  },
    { field: 'bottleSize', headerName: 'bottleSize', width:150  },
    { field: 'bookValue', headerName: 'BookValue', width:150 , renderCell: (params) =>  (
      <span>
        {fCurrency(params.value)}
     </span>
    )
},
    { field: 'currentValue', headerName: 'MarketValue', width:150  , renderCell: (params) =>  (
        <span>
          {fCurrency(params.value)}
       </span>
      )
  },
  { field: 'colour', headerName: 'colour', width:150  },
  { field: 'region', headerName: 'region', width:150  },
  { field: 'country', headerName: 'country', width:150  },  
    { field: 'lwin', headerName: 'lwin', width:200  },      
    { field: 'location', headerName: 'location', width:200  },      
    { field: 'orderReference', headerName: 'orderReference', width:200  },
    { field: 'status', headerName: 'status', width:200  }
  ];


const columnsDocuments =  [
  { field: 'id', headerName: ' ', width:50
  , renderCell: (params) => (
    <IconButton 
      variant="contained"
      color="primary"
      size="small"
      onClick={() => navigate(`/client/document/${params.value}`)}
    >
      <ZoomInIcon />
      {params.value}
    </IconButton >
  )
},
{ field: 'url', headerName: 'Doc', width:20, renderCell: (params) => {
  if (params.value) {
    return (
      <Link
        target="_blank"
        href={params.value}
      >
        <DescriptionIcon />
      </Link>                 
    )
  }
  return "-"
}}, 
{ field: 'documentType', headerName: 'documentType' ,width:200},   
{ field: 'name', headerName: 'document' ,width:300},
{ field: 'description', headerName: 'description',width:300},       
{ field: 'creator', headerName: 'admin', width:200},      
{ field: 'isClientVisible', headerName: 'is Visible by Client?', width:200 , renderCell: (params) => {
    if (params.value ===1) {
      return (
        <span>
        <VisibilityIcon/>
        <LoadingButton loading={isLoading}  variant="text" onClick={() => handleOpenDocVisibility(params.row.id,0)}>
        Hide it
      </LoadingButton>               
     </span>
      )
    }
    return (
      <span>
        <VisibilityOffIcon/>
        <LoadingButton loading={isLoading} variant="text" onClick={() => handleOpenDocVisibility(params.row.id,1)}>
        Show it
      </LoadingButton>
     </span>
    )
}},
{ field: 'isEmailSentToClient', headerName: 'isEmailSentToClient',width:200},      
{ field: 'createdAt', headerName: 'createdAt', width:200  },
{ field: 'updatedAt', headerName: 'updatedAt', width:200  }
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  


const uploadItem = async () => {
  setIsLoadingButton(true);      
  let url = '/user/';
  if (itemId !=="new") {
    url += itemId;
  } 


  console.log({user: data.user, roles: data.roles, userStorageFeeRate: data.userStorageFeeRate });
  let res = null;
  if (itemId ==="new") {
    res = await fetchDataAwait(url, "post",  {user: data.user, userRoles: data.userRoles, userStorageFeeRate: data.userStorageFeeRate });
  }
  else {
    res = await fetchDataAwait(url, "put", {user: data.user, userRoles: data.userRoles, userStorageFeeRate: data.userStorageFeeRate });
  }
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    setData(res.data);
    if (itemId ==="new") {
      setItemId(res.data.user.id);
    }
    setConfirmationMessage("Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}


const handleSaveClick = () => {
  uploadItem(); 
}

const setClientData = (field,value) => {
  data.user[field] = value
  setData({...data});
}


const handleCancelClick = () => { 
  setData({...data});
  setIsDisabled(true);  
}

const handleChangeCheckBox = (event) => {
  setClientData("VATonFee",event.target.checked)
};  


const handleSelectedStatus = (event) => {
  setClientData("status",event.target.value);
};

const handleSelectedFeeBasis = (event) => {
  setClientData("storageFeeCollectionBasis",event.target.value);
};

const handleAddFeeRateRow= () => {
  const newUserStorageFeeRate = JSON.parse(JSON.stringify(data.userStorageFeeRate));
  const NewId = Math.min(...newUserStorageFeeRate.map(o => o.id), 0)-1;
  newUserStorageFeeRate.push({
    id: NewId,
    percentage:null,
    rateStart: null,
    rateEnd: null
  });
  setData({...data, userStorageFeeRate: newUserStorageFeeRate});  
};
const handleRemoveRow = (rowId) => {
  const filteredData =  data.userStorageFeeRate.filter((el) =>  el.id !== rowId); 
  setData({...data, userStorageFeeRate: filteredData}); 
}

const handleRemoveRole = (rowId) => {
  const filteredData =  data.userRoles.filter((el) =>  el.id !== rowId); 
  setData({...data, userRoles: filteredData}); 
}
const setNewRole= (newRow) => {
  const newUserRoles = JSON.parse(JSON.stringify(data.userRoles));
  if (data.userRoles.find(x => x.id === newRow.id)){
    setErrorMessage(`Role "${newRow.name}" already in list`);
  }
  newUserRoles.push(newRow);
  setData({...data, userRoles: newUserRoles});  
};



const dialogPasswordReset = () =>  (
    <Dialog
    open={dialogOpen}
    onClose={()=> setDialogOpen(false)}
    aria-labelledby="alert-dialog-reset"
    aria-describedby="alert-dialog-reset-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title">Password Reset</DialogTitle> 
      <DialogContent>
        Are you sure you want to reset the password?
      </DialogContent>
      <DialogActions>
      <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleResetPassword()} color="primary">
          Yes
        </LoadingButton>        
        <Button variant="text" onClick={()=> setDialogOpen(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleResetPassword = async () => {
    setIsLoadingButton(true);      
    const url = `/user-reset-password/${itemId}`;
    if (itemId ==="new") {
      setErrorMessage("User/Client is not defined yet");
      return 0;
    } 
    const res = await fetchDataAwait(url, "get", null);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      setDialogOpen(false);
    }   
    setIsLoadingButton(false);      
    return 0;
  }  




  const dialogValuation = () =>  (
    <Dialog
    open={openValuation}
    onClose={() => handleDialogOpenValuationClose()}
    aria-labelledby="alert-dialog-Valuation"
    aria-describedby="alert-dialog-Valuation-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate Portfolio Valuation</DialogTitle> 
      <DialogContent sx={{ pb: 5 }}>
        Please select the time period for the Valuation.<br/><br/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start date"
                  inputFormat="dd/MM/yyyy"
                  value={startDate}
                  onChange={(e)=> setStartDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>    
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="End date"
                  inputFormat="dd/MM/yyyy"
                  value={endDate}
                  onChange={(e)=> setEndDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>  
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateValuation()} color="primary">
          Yes
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenValuationClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenValuationClose = () => {
    setOpenValuation(false); 
  }  
  const handleGenerateValuation = async () => {
    setIsLoadingButton(true);      
    const url = `/portfolio/valuation/${itemId}`;
    if (itemId ==="new") {
      setErrorMessage("User/Client is not defined yet");
      return 0;
    } 
    if (Object.prototype.toString.call(startDate) !== "[object Date]" || Object.prototype.toString.call(endDate) !== "[object Date]") {
      setErrorMessage("Dates are incorrect");
      setIsLoadingButton(false);      
      return 0;      
    }
    const res = await fetchDataAwait(url, "put", {dateStart:startDate.toISOString(),  dateEnd:endDate.toISOString()});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      setOpenValuation(false);
      reloadDocuments();
    }   
    setIsLoadingButton(false);      
    return 0;
  }  




  const dialogSummary = () =>  (
    <Dialog
    open={openSummary}
    onClose={() => handleDialogOpenSummaryClose()}
    aria-labelledby="alert-dialog-summary"
    aria-describedby="alert-dialog-summary-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate transactions summary</DialogTitle> 
      <DialogContent sx={{ pb: 5 }}>
        Please select the time period for the summary.<br/><br/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start date"
                  inputFormat="dd/MM/yyyy"
                  value={startDate}
                  onChange={(e)=> setStartDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>    
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="End date"
                  inputFormat="dd/MM/yyyy"
                  value={endDate}
                  onChange={(e)=> setEndDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>  
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateSummary()} color="primary">
          Yes
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenSummaryClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenSummaryClose = () => {
    setOpenSummary(false); 
  }  
  const handleGenerateSummary = async () => {
    setIsLoadingButton(true);      
    const url = `/generate/transactions/${itemId}`;
    if (itemId ==="new") {
      setErrorMessage("User/Client is not defined yet");
      return 0;
    } 
    if (Object.prototype.toString.call(startDate) !== "[object Date]" || Object.prototype.toString.call(endDate) !== "[object Date]") {
      setErrorMessage("Dates are incorrect");
      setIsLoadingButton(false);      
      return 0;      
    }
    const res = await fetchDataAwait(url, "put", {dateStart:startDate.toISOString(),  dateEnd:endDate.toISOString()});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      setOpenSummary(false);
      reloadDocuments();
    }   
    setIsLoadingButton(false);      
    return 0;
  }  


  const dialogGenerateMultipleInvoices = () =>  (
    <Dialog
    open={openMultipleInvoices}
    onClose={() => handleDialogOpenMultipleInvoicesClose()}
    aria-labelledby="alert-dialog-multiple"
    aria-describedby="alert-dialog-multiple-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate multiple invoices</DialogTitle> 
      <DialogContent sx={{ pb: 5 }}>
        Please select the time period on which to generate the invoices - Invoices already created will be replaced and the invoice number will be incremented.<br/><br/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start date"
                  inputFormat="dd/MM/yyyy"
                  value={startDate}
                  onChange={(e)=> setStartDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>    
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="End date"
                  inputFormat="dd/MM/yyyy"
                  value={endDate}
                  onChange={(e)=> setEndDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>  
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateMultipleInvoices()} color="primary">
          Yes
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenMultipleInvoicesClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenMultipleInvoicesClose = () => {
    setOpenMultipleInvoices(false); 
  }  
  const handleGenerateMultipleInvoices = async () => {
    setIsLoadingButton(true);      
    const url = `/generate/invoices`;
    if (itemId ==="new") {
      setErrorMessage("User/Client is not defined yet");
      return 0;
    } 
    if (Object.prototype.toString.call(startDate) !== "[object Date]" || Object.prototype.toString.call(endDate) !== "[object Date]") {
      setErrorMessage("Dates are incorrect");
      setIsLoadingButton(false);      
      return 0;      
    }
    const res = await fetchDataAwait(url, "post", {userId:[itemId], startDate:startDate.toISOString(),  endDate:endDate.toISOString()});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      setOpenMultipleInvoices(false);
      reloadDocuments();
    }   
    setIsLoadingButton(false);      
    return 0;
  }  


  const handleOpenDocVisibility = async (docId, visibility) => {
    setIsLoadingButton(true);      
    const url = `/document/visibility/${docId}/${visibility}`;
    const res = await fetchDataAwait(url, "get", null);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      // Update table
      const updatedRows = data.documents.map((row) => {
        if (row.id === docId) {
          row.isClientVisible = visibility;
          return row;
        }
        return row;
      });      
      setData({...data, documents: updatedRows});
    }   
    setIsLoadingButton(false);      
    return 0;    
    
  } 
  


  const dialog1275Invoicing = () =>  (
    <Dialog
    open={open1275Invoicing}
    onClose={() => handleDialogOpen1275InvoicingClose()}
    aria-labelledby="alert-dialog-1275"
    aria-describedby="alert-dialog-1275-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate 1275 / 1275 Collections Invoicing</DialogTitle> 
      <DialogContent sx={{ pb: 5 }}>
        Please select the time period to produce the 1275 / 1275 Collections Invoicing for that client<br/><br/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start date"
                  inputFormat="dd/MM/yyyy"
                  value={startDate}
                  onChange={(e)=> setStartDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>    
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="End date"
                  inputFormat="dd/MM/yyyy"
                  value={endDate}
                  onChange={(e)=> setEndDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>  
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerate1275Invoicing()} color="primary">
          Generate
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpen1275InvoicingClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpen1275InvoicingClose = () => {
    setOpen1275Invoicing(false); 
  }
  const handleGenerate1275Invoicing = async () => {
    setIsLoadingButton(true);      
    const url = `/generate/invoice1275`;
    if (itemId ==="new") {
      setErrorMessage("User/Client is not defined yet");
      return 0;
    } 
    const res = await fetchDataAwait(url, "put", {client_id:itemId, dateStart:startDate.toISOString(),  dateEnd:endDate.toISOString()});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      setOpen1275Invoicing(false);
      reloadDocuments();
    }   
    setIsLoadingButton(false);
    return 0;
  }


  const reloadDocuments = async () => {
    setIsLoadingButton(true);      
    if (itemId ==="new") {
      setErrorMessage("User/Client is not defined yet");
      return 0;
    } 
    const url = `/user-docs/${itemId}`;    
    const res = await fetchDataAwait(url, "get", null);
    console.log(res);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      data.documents = res.data.documents
      setData({...data});      
    }   
    setIsLoadingButton(false);
    return 0;
  }  


function displayActionButtons() {
  if (isLoading || !data) {
    return ""
  }
  if (isDisabled) {
    let buttonJsx ="";
    if ( data.user.id && data.user.id !=="new") {
      buttonJsx = (
        <span >     
          <LoadingButton
            variant="contained"  color="primary" loading={isLoading}
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClickMenu}
          >
            <ListIcon/>Actions
          </LoadingButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
              <Stack direction="column" spacing={2}>
              <LoadingButton  variant="contained" onClick={() => setDialogOpen(true)}>
                Reset Password
              </LoadingButton>
              <LoadingButton  variant="contained" onClick={() => setOpenMultipleInvoices(true)}>
                Generate Multiple Invoices
              </LoadingButton>
              <LoadingButton  variant="contained" onClick={() => setOpenSummary(true)}>
                Generate Transaction Summary
              </LoadingButton>
              <Button  variant="contained" onClick={() => setOpen1275Invoicing(true)}>
                Generate 1275-1275 Collections Invoices
              </Button> 
              <LoadingButton  variant="contained" onClick={() => setOpenValuation(true)}>
                Recompute Portfolio Valuation
              </LoadingButton>              
              <ButtonDialogUploadDocument userId={id} callBackFunction={() => reloadDocuments()} />    
              </Stack>
          </Menu>           
          {dialogGenerateMultipleInvoices()}
          {dialogSummary()}
          {dialogValuation()}
          {dialogPasswordReset()}
          {dialog1275Invoicing()}
        </span> )  
    }
    return (
    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between" >       
      <Button
        variant="contained"
        onClick={() => setIsDisabled(false)}
      >
        <EditIcon/>
        Edit
      </Button>
      {buttonJsx}
    </Stack>        
    )
  }

  return (    
  <ButtonGroup>
    <LoadingButton
      variant="contained"
      loading={isLoadingButton}
      onClick={() => handleSaveClick()}
    >
      <SaveIcon/>
      Save
    </LoadingButton>
    <Button
      variant="contained"
      onClick={() => handleCancelClick(true)}
    >
      <CancelIcon/>
      Cancel
    </Button>        
</ButtonGroup>
)
}



  if (isLoading) {
    return (
      <Page title="Client">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Client</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error && data) {   
    return (
      <Page title="Clients">
        <Container maxWidth="false">

          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Client {data.user.externalId}</Typography>                              
                {displayActionButtons()}                                       
          </Stack>          
          <Grid  sx={{ pb: 2 }} container item xs={12} spacing={3} direction="row" justifyContent="center" alignItems="space-between" >
          <Grid item xs={6} md={2}>
              <Card >
                <CardHeader
                  title={`${fCurrency(data.userFlows.inflow)}/ ${fCurrency(data.userFlows.outflow)}`}
                  subheader="Inflows/Outflows"
                />              
              </Card >              
            </Grid>  
            <Grid item xs={6} md={2} >
              <Card >
                <CardHeader
                  title={fCurrency(data.user.cashBalance)}
                  subheader="Cash"
                />              
              </Card >
            </Grid>  
            <Grid item xs={6} md={2}>
              <Card >
                <CardHeader
                  title={`${fCurrency(data.userFees.fees)}`}
                  subheader="Fees Paid"
                />              
              </Card >              
            </Grid>              
            <Grid item xs={6} md={2}>
              <Card >
                <CardHeader
                  title={`${fCurrency(Math.round(data.user.casesValue))}`}
                  subheader={`Collection (${fPercent((100*data.user.casesValue/data.user.bookValue-100))})`} 
                />              
              </Card >              
            </Grid> 
            <Grid item xs={6} md={2}>
              <Card >
                <CardHeader
                  title={fCurrency(Math.round(data.user.bookValue))}
                  subheader="Book Value"
                />              
              </Card>
            </Grid>
            <Grid item xs={6} md={2}>
              <Card >
                <CardHeader
                  title={`${fCurrency(Math.round(data.caseMargin.WinePnl))}/ ${fPercent(data.caseMargin.margin)}`}
                  subheader="WinePnL/ Margin"
                />              
              </Card >              
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Name*</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setClientData("name",e.target.value)}
                          className={classes.field} 
                          id="client-name" 
                          fullWidth required
                          value={data.user.name}
                          error={formError.name}
                          disabled={isDisabled}
                      />                      
                    </Grid>  
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">External Id*</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setClientData("externalId",e.target.value)}
                          className={classes.field} 
                          id="client-externalId" 
                          fullWidth required
                          value={data.user.externalId}
                          error={formError.externalId}
                          disabled={isDisabled}
                      />                       
                    </Grid>       
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Status *</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <FormControl fullWidth>
                        <InputLabel>Client Status</InputLabel>                      
                        <Select style={{width: 300}}
                              autoWidth	
                              label="Client Status" 
                              disabled={isDisabled}
                              labelId="select-clientStatus"
                              id="select-clientStatus"
                              value={data.user.status}
                              onChange={handleSelectedStatus}
                            >
                              {data.status.map((item,i) => (
                                  <MenuItem value={item} key={i}>{item}</MenuItem>
                              ))}
                          </Select>  
                      </FormControl> 
                    </Grid>                                   
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Email*</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setClientData("email",e.target.value)}
                          className={classes.field} 
                          id="client-email" 
                          fullWidth required
                          value={data.user.email}
                          error={formError.email}
                          disabled={isDisabled}
                      />                       
                    </Grid>        
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Phone</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setClientData("phone",e.target.value)}
                          className={classes.field} 
                          id="client-phone" 
                          fullWidth
                          value={data.user.phone}
                          error={formError.phone}
                          disabled={isDisabled}
                      />                       
                    </Grid>            
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Address</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setClientData("address",e.target.value)}
                          className={classes.field} 
                          id="client-address" 
                          fullWidth required
                          value={data.user.address}
                          error={formError.address}
                          disabled={isDisabled}
                          multiline
                          rows={4}                          
                      />                         
                    </Grid> 
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Apply VAT*</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                        <FormControlLabel
                        control={<Checkbox disabled={isDisabled} checked={data.user.VATonFee} onChange={handleChangeCheckBox} />}
                        label="VAT on services"
                        />                         
                    </Grid>                     
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Client Instructions</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setClientData("clientInstructions",e.target.value)}
                          className={classes.field} 
                          id="client-clientInstructions" 
                          fullWidth required
                          value={data.user.clientInstructions}
                          error={formError.clientInstructions}
                          disabled={isDisabled}
                          multiline
                          rows={2}                          
                      />                       
                    </Grid>   
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Management Fee Basis</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <FormControl fullWidth>
                        <InputLabel>Management Fee Basis</InputLabel>                      
                        <Select style={{width: 300}}
                              autoWidth	
                              label="Management Fee Basis" 
                              disabled={isDisabled}
                              labelId="select-storageFeeCollectionBasis"
                              id="select-storageFeeCollectionBasis"
                              value={data.user.storageFeeCollectionBasis}
                              onChange={handleSelectedFeeBasis}
                            >
                              {data.listStorageFeeCollectionBasis.map((item,i) => (
                                  <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                              ))}
                          </Select>  
                      </FormControl>                    
                    </Grid>
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Fee Rates</Typography>
                    </Grid>  
                    <Grid item xs={12} md={12} direction="row" justifyContent="left" alignItems="left"  className={classes.root}  >
                          <DataGridPro 
                          overflow="auto"
                          disableExtendRowFullWidth
                          autoHeight 
                          rows={data.userStorageFeeRate} 
                          columns= {columnsFeeRate} 
                          onCellEditCommit={handleCellEditCommit}
                          components={{
                            Toolbar: CustomAddToolbar,
                          }}                          
                          />    
                    </Grid>
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Roles</Typography>
                    </Grid>                       
                    <Grid item xs={12} md={12} direction="row" justifyContent="left" alignItems="left"  className={classes.root}  >
                          <DataGridPro 
                          overflow="auto"
                          disableExtendRowFullWidth
                          autoHeight 
                          rows={data.userRoles}
                          columns= {columnsRoles}
                          components={{
                            Toolbar: CustomAddRoleToolbar,
                          }}                          
                          />    
                    </Grid>                                                             
                    <Grid item xs={12} >
                      <small> created at {data.user.createdAt} | updated at {data.user.updatedAt} </small> 
                    </Grid>                                                                                                                                                                                                     
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>                                        
            <Grid item xs={12}>
            <Card >
                <CardContentNoPadding >            
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Cases" {...a11yProps(0)} />
                <Tab label="Valuation" {...a11yProps(1)} />
                <Tab label="Transactions" {...a11yProps(2)} />
                <Tab label="Documents" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <DataGridPro
                autoHeight 
                pagination
                rows={data.cases} 
                columns= {columnsCases}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HighchartsReact
                constructorType='stockChart'
                highcharts={Highcharts}
                options={{
                  credits: {
                    enabled: false
                  },  
                  title: {
                    text: `${data.user.externalId}`
                  },
                  series: [{
                    name: `Cases Value`,
                    data: data.performance,
                    tooltip: {
                      valueDecimals: 0
                    }                    
                  },
                  {
                    name: `Cash`,
                    data: data.accountBalance,
                    tooltip: {
                      valueDecimals: 0
                    }
                  }]
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DataGridPro
                autoHeight 
                pagination
                rows={data.transactions} 
                columns= {columnsTransactions}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>

                <ButtonDialogUploadDocument userId={id} callBackFunction={() => reloadDocuments()} />

                <DataGridPro
                  autoHeight 
                  pagination
                  rows={data.documents} 
                  columns= {columnsDocuments}
                  loading={isLoading}
                  components={{
                    Toolbar: CustomToolbar,
                  }}         
                />
                
            </TabPanel>   
            </CardContentNoPadding >
              </Card >                  
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}  
      </Page>
    );
  }
  return (
    <Page title="Clients">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Client</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

