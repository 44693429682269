// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Alert, Snackbar, TextField, Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Card, CardContent, Button, AppBar, Link, ButtonGroup  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// components
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';
import ButtonDialogGenerateInvoice from '../../components/clients/ButtonDialogGenerateInvoice';
import ButtonDialogCancelTransaction from '../../components/clients/ButtonDialogCancelTransaction';
import ButtonDialogUploadTransactionDocument from '../../components/allocate/ButtonDialogUploadTransactionDocument';

// ----------------------------------------------------------------------

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}



export default function TransactionItem() {
  const { id } = useParams();    
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [note, setNote] = useState("");

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const [isLoadingButton,setIsLoadingButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   


  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/transaction/${id}`, "get", null);
    };
    fetchDataReactScreen();
  }, [id]);  
  
  useEffect(() => {
    if (data) {
      setNote(data.data.note);
    }
  }, [data]);  


  const columnsCases =  [
    { field: 'id', headerName: ' ', width:80
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/case/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >
    )
  },
    { field: 'name', headerName: 'Case', width:200 },
    { field: 'packSize', headerName: 'packSize', width:200  },
    { field: 'packType', headerName: 'packType', width:200  },
    { field: 'bottleSize', headerName: 'bottleSize', width:200  },
    { field: 'lwin', headerName: 'lwin', width:200  },      
    { field: 'location', headerName: 'location', width:200  },      
    { field: 'orderReference', headerName: 'orderReference', width:200  },
    { field: 'status', headerName: 'status', width:200  }
  ];


  const columnsTransactions =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/client/transaction/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
  { field: 'client', headerName: 'Client' ,width:200},   
  { field: 'methodLabel', headerName: 'Type' ,width:200},
  { field: 'note', headerName: 'Note',width:300},      
  { field: 'invoice', headerName: 'Invoice#',width:200},        
  { field: 'groupName', headerName: 'Transac Group', width:200},      
  { field: 'cashTransfer', headerName: 'cashTransfer', width:200 },
  { field: 'admin', headerName: 'Admin',width:200},      
  { field: 'createdAt', headerName: 'createdAt', width:200  },
  { field: 'updatedAt', headerName: 'updatedAt', width:200  }
];


const columnsDocuments =  [
  { field: 'id', headerName: ' ', width:50
  , renderCell: (params) => (
    <IconButton 
      variant="contained"
      color="primary"
      size="small"
      onClick={() => navigate(`/client/document/${params.value}`)}
    >
      <ZoomInIcon />
      {params.value}
    </IconButton >
  )
},
{ field: 'url', headerName: 'Doc', width:20, renderCell: (params) => {
  if (params.value) {
    return (
      <Link
        target="_blank"
        href={params.value}
      >
        <DescriptionIcon />
      </Link>                 
    )
  }
  return "-"
}}, 
{ field: 'documentType', headerName: 'documentType' ,width:200},   
{ field: 'document', headerName: 'document' ,width:300},
{ field: 'description', headerName: 'description',width:300},      
{ field: 'client', headerName: 'client',width:200},        
{ field: 'admin', headerName: 'admin', width:200},      
{ field: 'isClientVisible', headerName: 'is Visible by Client?', width:200 , renderCell: (params) => {
    if (params.value ===1) {
      return (
        <span>
        <VisibilityIcon/>
        <LoadingButton loading={isLoading}  variant="text" onClick={() => handleOpenDocVisibility(params.row.id,0)}>
        Hide it
      </LoadingButton>               
     </span>
      )
    }
    return (
      <span>
        <VisibilityOffIcon/>
        <LoadingButton loading={isLoading} variant="text" onClick={() => handleOpenDocVisibility(params.row.id,1)}>
        Show it
      </LoadingButton>               
     </span>
    )
}},
{ field: 'isEmailSentToClient', headerName: 'isEmailSentToClient',width:200},      
{ field: 'createdAt', headerName: 'createdAt', width:200  },
{ field: 'updatedAt', headerName: 'updatedAt', width:200  }
];



  const columnsCash =  [
    { field: 'methodLabel', headerName: 'Method', width:200 },
    { field: 'cashTransferValue', headerName: 'Value', width:200 , renderCell: (params) =>  fCurrency(params.value)  },    
    { field: 'cashTransferCategory', headerName: 'Category', flex:1  },

  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  


  const handleOpenDocVisibility = async (docId, visibility) => {
    setIsLoadingButton(true);      
    const url = `/document/visibility/${docId}/${visibility}`;
    console.log(url);
    const res = await fetchDataAwait(url, "get", null);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      // Update table
      const updatedRows = data.documents.map((row) => {
        if (row.id === docId) {
          row.isClientVisible = visibility;
          return row;
        }
        return row;
      });      
      setData({...data, documents: updatedRows});
    }   
    setIsLoadingButton(false);      
    return 0;    
    
  } 
  

  const handleCancelClick = () => { 
    setNote(data.data.note);
    setIsDisabled(true);  
  }
  const setNoteData = (field,value) => {
    data.data[field] = value
    setData({...data});
  }
  const uploadItem = async () => {
    setIsLoadingButton(true);   
    let url = '/transactions/edit/';
    url += id;
    const res = await fetchDataAwait(url, "put", {note});
    
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      // setData(res);
      setConfirmationMessage("Succesfully saved.");
      setNoteData("note", note);
      setIsDisabled(true);  
    }   
    setIsLoadingButton(false);      
  }
  
  const updateInvoiceData = (newInvoiceData) => {
    console.log(newInvoiceData);
    data.data.invoice = newInvoiceData.invoice;
    data.documents = newInvoiceData.documents;// [newInvoiceData.document,...data.documents];
    setData({...data});
  }
  
  const handleSaveClick = () => {
    uploadItem(); 
  }
    
  const reloadDocuments = async () => {
    setIsLoadingButton(true);      
    const url = `/transaction-docs/${id}`;    
    const res = await fetchDataAwait(url, "get", null);
    console.log(res);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      data.documents = res.data.documents
      setData({...data});      
    }   
    setIsLoadingButton(false);
    return 0;
  }  



  function displayNoteActionButtons() {
    if (isDisabled) {
      return (
        <Grid item xs={12} md={9} >
        <ButtonGroup>
          <IconButton
            variant="contained"
            onClick={() => setIsDisabled(false)}
          >
            <EditIcon/>
            
          </IconButton>
        </ButtonGroup>                       
        {data.data.note}  
      </Grid> 
      )
    }

    return ( 
    <Grid item xs={12} md={9} >
    
      <ButtonGroup>
        <LoadingButton
          variant="contained"
          loading={isLoadingButton}
          onClick={() => handleSaveClick()}
        >
          <SaveIcon/>
          Save
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => handleCancelClick(true)}
        >
          <CancelIcon/>
          Cancel
        </Button>        
      </ButtonGroup>
      <TextField 
            onChange={(e)=> setNote(e.target.value)}
            id="note" 
            fullWidth required
            value={note}
            disabled={isDisabled}
        />         
    </Grid> 
  )
}



  if (isLoading) {
    return (
      <Page title="Transaction">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Transaction - {id}</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Transactions">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Transaction - {id}</Typography>                              
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" mb={5}>  
                  <ButtonDialogGenerateInvoice itemId={id}  callbackItem={updateInvoiceData} isDisabled={!isDisabled} />      
                  <ButtonDialogCancelTransaction itemId={data.data.transactionGroupId}  dataGrid={data.transactionGroup} isDisabled={!isDisabled} />          
                </Stack>                                      
          </Stack>          
            
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                  <Grid item xs={12} md={3} >
                      <Typography variant="h6">Note</Typography>
                    </Grid>  
                    {displayNoteActionButtons()} 
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Transaction Method</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      {data.data.methodLabel}
                    </Grid>        
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Invoice #</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      {data.data.invoice}
                    </Grid>            
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Client</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <IconButton 
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => navigate(`/client/list/${data.data.clientId}`)}
                      >
                        <ZoomInIcon /> 
                      </IconButton >                       
                      {data.data.client}
                    </Grid> 
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Linked Transactions</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      {data.data.groupName}
                    </Grid>  
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Cash Transfer</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} >
                      {fCurrency(data.data.cashTransfer)}
                    </Grid>
                    <Grid item xs={12} >
                      <small> created at {data.data.createdAt} | updated at {data.data.updatedAt} | Admin {data.data.admin} </small> 
                    </Grid>
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>                                        
            <Grid item xs={12}>
            <Card >
                <CardContentNoPadding >            
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Cash Movements" {...a11yProps(0)} />
                <Tab label="Documents/Invoice" {...a11yProps(1)} />
                <Tab label="Linked Transactions" {...a11yProps(2)} />
                <Tab label="Impacted Cases" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <DataGridPro
                pagination
                autoHeight 
                rows={data.cash} 
                columns= {columnsCash}
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
            <ButtonDialogUploadTransactionDocument setErrorMessage={setErrorMessage} setConfirmationMessage={setConfirmationMessage} transactionId={id} callBackFunction={() => reloadDocuments()} />

              <DataGridPro
                pagination
                autoHeight 
                rows={data.documents} 
                columns= {columnsDocuments}
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DataGridPro
                pagination
                autoHeight 
                rows={data.transactionGroup} 
                columns= {columnsTransactions}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
               <DataGridPro
                pagination
                autoHeight 
                rows={data.cases} 
                columns= {columnsCases}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </TabPanel>   
            </CardContentNoPadding >
              </Card >                  
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}  
      </Page>
    );
  }
  return (
    <Page title="Transactions">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Transaction</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

