import { makeStyles } from '@mui/styles';
import { Skeleton, MenuItem, Select, RadioGroup, Radio, Button,  FormControl, Grid, Typography, TextField, FormControlLabel } from '@mui/material';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from "axios";
import { fetchDataAwait } from '../../utils/api';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function ButtonUploadPaymentTerms(props) {
  const classes = useStyles();   
  const { paymentId, purchaseId, callBackFunction, setErrorMessage, setConfirmationMessage, dialogOpen, setDialogOpen } = props;
  ButtonUploadPaymentTerms.propTypes = {
    paymentId: PropTypes.number,
    purchaseId: PropTypes.string,
    callBackFunction: PropTypes.func,
    setErrorMessage: PropTypes.func,
    setConfirmationMessage: PropTypes.func,
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
  };  
  const [isLoading, setIsLoading] = useState(true);
  
    
  const [paymentData,setPaymentData] = useState(null);
  const [listCurrencies,setListCurrencies] = useState([]);


  useEffect(() => {
    refreshPaymentData(); 
  }, [paymentId]);


  const handleCurrencySelected = (event) => {
    handleAddPaymentData("amountCurrency",event.target.value);
  };  
  

  const handleAddPaymentData = (field,value) => {
    paymentData[field] = value
    setPaymentData({...paymentData});
  }



  const uploadPayment = async () => {
    setIsLoading(true);
    console.log( {purchaseId, ...paymentData});
    let res;
    if (paymentId) {
      res = await fetchDataAwait(`/purchase/payment/${paymentId}`, "put",  {purchaseId, ...paymentData});
    } else {
      res = await fetchDataAwait(`/purchase/payment`, "post",  {purchaseId, ...paymentData});
    }
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage("Succesfully saved.");
      setDialogOpen(false);
      callBackFunction(); 
    } 

    
    setIsLoading(false);
    return 1;
  }
  

  const refreshPaymentData = async() => {
    setIsLoading(true);
    let res;
    if (paymentId) {
      res = await fetchDataAwait(`/purchase/payment/${paymentId}`, "get", null);
    } else {
      res = await fetchDataAwait(`/purchase/payment`, "get", null);
    }    
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setListCurrencies(res.data.currencies);      
    setPaymentData(res.data.payment);  
    setIsLoading(false);  
    return true       
  }        


  // const handleOpenDialog = () => {
  //   setDialogOpen(true);  
  //   refreshPaymentData();
  // }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }


  const handleChangeRadio = (event) => {
    handleAddPaymentData("isInvoice",event.target.value)
  };  


  function displayContent() {
    if (paymentData) {
      return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                                           

        <Grid item xs={12} md={3} >
          <Typography variant="h6">Payment Details</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <TextField 
              onChange={(e)=> handleAddPaymentData("paymentDetails",e.target.value)}
              className={classes.field} 
              multiline
              rows={2}   
              id="client-name" 
              fullWidth required
              value={paymentData.paymentDetails}
          />                      
        </Grid>  

        <Grid item xs={12} md={3} >
          <Typography variant="h6">Payment Deadline</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Deadline"
                    inputFormat="dd/MM/yyyy"
                    value={paymentData.paymentDeadline}
                    onChange={(e)=> handleAddPaymentData("paymentDeadline",e)}
                    renderInput={(params) => <TextField {...params} />}
                  />        
            </LocalizationProvider>                 
        </Grid>  
        <Grid item xs={12} md={3} >
          <Typography variant="h6">Payment Amount</Typography>
        </Grid>  
        <Grid item xs={7} md={3} >
          <TextField 
              onChange={(e)=> handleAddPaymentData("amount",e.target.value)}
              className={classes.field} 
              label="Amount Local Currency"
              id="p-amount" 
              fullWidth required
              value={paymentData.amount}
              number
          />                      
        </Grid>   
        <Grid item xs={5} md={3} >
          <FormControl> 
            <Select style={{width: 150}}
              autoWidth	
              label="Currency" 
              labelId="select-currency"
              id="select-currency"
              value={paymentData.amountCurrency}
              onChange={handleCurrencySelected}
            >
              {listCurrencies.map((item,i) => (
                  <MenuItem value={item} key={i}>{item}</MenuItem>
              ))}
            </Select> 
          </FormControl>                   
        </Grid>
        <Grid item xs={5} md={3} >
          <TextField 
              onChange={(e)=> handleAddPaymentData("amountEuro",e.target.value)}
              className={classes.field} 
              label="Amount EUR"
              number
              fullWidth 
              value={paymentData.amountEuro}
          />                      
        </Grid>                       
        <Grid item xs={12} md={3} >
          <Typography variant="h6">Percentage of full purchase</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <TextField 
              onChange={(e)=> handleAddPaymentData("percentageTotal",e.target.value)}
              className={classes.field} 
              label="Percentage Total"
              number
              fullWidth 
              value={paymentData.percentageTotal}

          />                      
        </Grid> 

        <Grid item xs={12} md={3} >
          <Typography variant="h6">Paid On</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Paid On"
              inputFormat="dd/MM/yyyy"
              value={paymentData.paidOn}
              onChange={(e)=> handleAddPaymentData("paidOn",e)}
              renderInput={(params) => <TextField {...params} />}
            />        
            </LocalizationProvider>                        
        </Grid>                                                                                                                                                         
        </Grid>        
      )
    }

    return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
         <Skeleton variant="rectangular" height={300} />                                                                                                                                                                                   
      </Grid>        
      )
    
  }

  return (
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">Update Payment terms</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {displayContent()}                    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" onClick={() => uploadPayment()} color="primary">
              Update Payment Terms
            </LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
    )
}