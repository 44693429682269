
import {  Button, Snackbar, Alert  } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';


export default function ButtonDialogPalletSensor(props) {
  const { palletId, code } = props;
  ButtonDialogPalletSensor.propTypes = {
    palletId: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  };  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataGrid, setDataGrid] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const columns = [
    { field: 'id', headerName: 'id', width:200
      , renderCell: (params) =>  (
      <Button  variant="contained" onClick={() => updatePalletSensor(params.value)} color="primary" autoFocus>
        Add
      </Button>
      )
    },      
    { field: 'name', headerName: 'name', width:200  },
    { field: 'externalReference', headerName: 'externalReference', width:200  }
  ];



  const updateSensorList = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait("/sensors", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDataGrid(res.data.data)  
    setIsLoading(false);  
    return true    
  }
  

  const updatePalletSensor = async (sensorGroupId) => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/pallet-sensor/${palletId}/${sensorGroupId}`, "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }  
    setConfirmationMessage(res.data.message);
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  

  const handleOpenDialog = () => {
    setDialogOpen(true);
    updateSensorList();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  return (
    <span>
      <LoadingButton  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}><SpeedIcon/>Assign Sensor</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Assign Sensor to Pallet {code}</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={dataGrid} 
                columns= {columns} 
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
        {snackbarError()}      
        {snackbarSuccess()}
    </span>
    )
}