import { Button, IconButton, Grid, TextField  } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ImageIcon from  '@mui/icons-material/Image';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'

import { fetchDataAwait } from '../../utils/api';



export default function DialogAddUniqueCaseFromSearch(props) {
  const searchRef = useRef();
  const {dialogOpen, setDialogOpen, setErrorMessage, rowId, setCase} = props;
  DialogAddUniqueCaseFromSearch.propTypes = {
    rowId: PropTypes.number,
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    setCase: PropTypes.func.isRequired,
  }; 

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]); 
  
  const [dialogPicture, setDialogPicture] = useState(""); 
  const [dialogOpenPicture, setDialogOpenPicture] = useState(false);  
  const [dialogCaseName, setDialogCaseName] = useState("n/a");  


  // const columnsGrid = [
  //   { field: 'id', headerName: ' ', width:20
  //   , renderCell: (params) => (
  //     <strong>
  //       <IconButton 
  //         variant="contained"
  //         color="primary"
  //         size="small"
  //         onClick={()=> { handleAddCases(params.row);}}
  //       >
  //         <AddCircleOutlineOutlinedIcon />
  //       </IconButton >        
  //     </strong>
  //     )
  //   },      
  //   { field: 'caseName', headerName: 'Case Type', width:300 },  // Name size-qty-owner
  //   { field: 'caseOwner', headerName: 'Case Owner', width:200 },
  //   // { field: 'caseOwnerId', headerName: 'Case Owner', width:200 },
  //   { field: 'availableQuantity', headerName: 'Quantity', width:200 },
  //   { field: 'category', headerName: 'Category', width:200 },
  //   { field: 'region', headerName: 'Region', width:200 },
  //   { field: 'bookValue', headerName: 'bookValue', width:200 },
  //   { field: 'currentValue', headerName: 'currentValue', width:200 },

  //   { field: 'currentValueUpdatedAt', headerName: 'last update', width:200 },
  //   { field: 'location', headerName: 'location', width:200 },
  //   { field: 'orderReference', headerName: 'orderReference', width:200 },
  //   { field: 'packType', headerName: 'packType', width:200 },

  //   { field: 'qualityStandard', headerName: 'qualityStandard', width:200 },
  //   { field: 'status', headerName: 'status', width:200 }
  //   ]


    const columnsGrid =  [
      { field: 'id', headerName: ' ', width:80
      , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleAddCases(params.row);}}
        >
          <AddCircleOutlineOutlinedIcon />
          {params.value}
        </IconButton >        
      </strong>
      )
    },
    { field: 'user', headerName: 'Client', width:200   },    
    { field: 'url', headerName: 'Pic', width:20, renderCell: (params) => {
      if (params.value) {
        return (
        <strong>
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClickPicture(params.value, params.row)}
          >
            <ImageIcon />
          </IconButton >            
        </strong>
        )
      }
      return "-"
    }},    
      { field: 'name', headerName: 'Case', width:300   },      
      { field: 'packSize', headerName: 'packSize', width:150   },
      { field: 'bottleSize', headerName: 'bottleSize', width:200   },
      { field: 'lwin', headerName: 'lwin', width:200   },
      { field: 'location', headerName: 'location', width:200   },
      { field: 'orderReference', headerName: 'orderReference', width:200   },
      { field: 'status', headerName: 'status', width:200   },
      { field: 'bookValue', headerName: 'bookValue', width:150   },
      { field: 'saleforbidden', headerName: 'saleforbidden', width:200   },
      { field: 'certificationReference', headerName: '9digit', width:200   },
      { field: 'currentValue', headerName: 'currentValue', width:200   },

  ];
  

  const handleClickPicture = (img, row) => {
    setDialogPicture(img); 
    setDialogCaseName(`${row.name} - ${row.certificationReference}` ); 
    setDialogOpenPicture(true);  
  }  


  const handleAddCases = (row) => {
    setCase(rowId,row)
    setDialogOpen(false);
  }


  const handleCloseDialog = () => {
    console.log("close")
      setDialogOpen(false);    
  } 
    
  const refreshData = async() => {
    setIsLoading(true);
    const dataPost = {
      search: searchRef.current.value,
      searchAll: false
    }
    const res = await fetchDataAwait("/cases", "put", dataPost);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
  
    console.log(res.data)
    setData(res.data.data);
    setIsLoading(false);  
    setDialogOpen(true); 
    return true       
  }      

  return (
    <span>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Search Unique Case</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
              <Grid item xs={12} md={6}>    
                <TextField fullWidth id="fullWidth"
                            inputRef={searchRef} 
                            onKeyPress={(event) => {
                              if (event.key === 'Enter')
                              refreshData()
                            }}                               
                />
              </Grid>
              <Grid item xs={12}  md={6} sx={{ pb: 5 }}>                   
                  <LoadingButton loading={isLoading} variant="contained" onClick={refreshData}>
                  Search
                </LoadingButton>                
              </Grid>
              <Grid item xs={12}>
                <DataGridPro overflow="auto"
                  pagination
                  autoHeight 
                  rows={data} 
                  columns= {columnsGrid} 
                  pageSize={25}
                  loading={isLoading}
                  />
              </Grid>
            </Grid>


                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>              
      </span>
    )
}