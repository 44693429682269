// material
import { makeStyles } from '@mui/styles';
import { Box, Grid, Container, Typography, Skeleton, Button, IconButton,  Card, CardContent, CardActions, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
// components
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));

// ----------------------------------------------------------------------

export default function PrepareOrder() {
  const navigate = useNavigate();
  const classes = useStyles();
  
  // Create new
  const [openDialogForm, setopenDialogForm] = useState(false);
  const [targetName, setTargetName] = useState('');
  const [targetDesc, setTargetDesc] = useState('');
  const [expectedDate, setExpectedDate] = useState('');
  const [targetNameError, setTargetNameError] = useState(false);
  const [targetDescError, setTargetDescError] = useState(false);

  const columnsOrder = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { navigate(`/warehouse/prepare/${params.row.id}`) }}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
      </span>
      )
    },      
    { field: 'name', headerName: 'Title', width:200 },        
    { field: 'expectedDate', headerName: 'Expected Date', width:100 },      
    { field: 'status', headerName: 'Status', width:100 },   
    { field: 'description', headerName: 'Description', width:300 },    
    { field: 'createdAt', headerName: 'createdAt', width:300 },    
    { field: 'updatedAt', headerName: 'updatedAt', width:300 },    
    ];


  // API NEW
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  // SNACKBAR
  const [errorMessage, setErrorMessage] = useState(null);  
  const [confirmationMessage, setConfirmationMessage] = useState(null);   


  // API LIST
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [errorList, setErrorList] = useState(null);
  const fetchDataList = async () => {
    fetchData(setIsLoadingList,setDataList,setErrorList,"/warehouse/get-order-list", "get", null);
  };
  useEffect(() => {
    fetchDataList();
  }, []);  
  useEffect(() => {
    if (errorList) {
      setErrorMessage(errorList.message);
    }
  }, [errorList]);


  const handleClickOpen = () => {
    setopenDialogForm(true);
  };
  const handleClose = () => {
    setopenDialogForm(false);
  }; 


  const handleDialogSubmit = async () => {
    setTargetNameError(false);
    setTargetDescError(false);
    
    if ( targetName === '') {
      setTargetNameError(true);
    }
    if ( targetDesc === '') {
      setTargetDescError(true);
    }   
    if ( targetName && targetDesc) {
      setIsLoadingNew(true);
      const res = await fetchDataAwait("/warehouse/create-order", "post", {name: targetName, description: targetDesc, expectedDate});
      if (res.error) {
        setErrorMessage(res.error.message);
      }
      else {
        // UPDATE ID        
        handleClose();
        navigate(`/warehouse/prepare/${res.data.id}`);
      }
      setIsLoadingNew(false);

    }
  }
 

  function dialogFormNew() {
    return (
      <Dialog open={openDialogForm} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>New Order</DialogTitle>
        <DialogContent>          
          <TextField 
            onChange={(e)=> setTargetName(e.target.value)}
            className={classes.field} 
            id="target-name" 
            label="Order Title" 
            fullWidth required
            value={targetName}
            error={targetNameError}
          />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Expected Order Date"
                  inputFormat="dd/MM/yyyy"
                  value={expectedDate}
                  onChange={(e)=> setExpectedDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>               
          <TextField
            onChange={(e)=> setTargetDesc(e.target.value)}
            className={classes.field}
            id="target-description"
            label="Description"
            value={targetDesc}
            multiline
            rows={4}
            fullWidth
            error={targetDescError}
          />              
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton loading={isLoadingNew} variant="contained" onClick={handleDialogSubmit} color="primary">
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }


  function loadingSelect() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>      
      </Grid>
    )
  }

  function selectTarget() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12}  component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Create a new Order
              </Typography>
            </CardContent>
            <CardActions>
              <Button  onClick={handleClickOpen} variant="contained" color="primary">CREATE</Button>
              {dialogFormNew()}
            </CardActions>
        </Grid>
        <Grid item xs={12} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Existing Orders
              </Typography>              
              <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={dataList.data}
                columns= {columnsOrder} 
                loading={isLoadingList}
                />              
            </CardContent>

        </Grid>           
      </Grid>
    )
  }



  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };  
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  


  function displayPage(func) {
    return (
      <Page title="Order Prep">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Prepare Order</Typography>
          </Box>
          {func()}
        </Container>
        {snackbarError()}       
        {snackbarSuccess()} 
      </Page>
    );
  }

  // PAGE LOGIC
  if (!isLoadingList) {
    return displayPage(selectTarget);
  }

    return displayPage(loadingSelect);
}
