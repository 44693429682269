
import {  Stack, CardContent,Typography, Box, Button, Snackbar, Alert, IconButton, Grid, TextField, AppBar } from '@mui/material';
import ImageIcon from  '@mui/icons-material/Image';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';
import AddIcon from  '@mui/icons-material/Add';
import { styled } from "@mui/material/styles";
import axios from "axios";


import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}



export default function ButtonDialogModifyVintagePicture(props) {
  const {vintageId, vintageName, callbackCase, url} = props;
  ButtonDialogModifyVintagePicture.propTypes = {
    vintageId: PropTypes.number.isRequired,
    url: PropTypes.string,
    vintageName: PropTypes.string.isRequired,
    callbackCase: PropTypes.func.isRequired,    
  };  
  const [searchField, setSearchField] = useState(vintageName);  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({vintagePictures: []});
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    

  const [dataFile200,setDataFile200] = useState({name:"no file selected"});
  const [dataFile600,setDataFile600] = useState({name:"no file selected"});


  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const [value, setValue] = useState(0);  

  const updatePictureList = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/vintage-picture`, "put", {search: searchField});

    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setData(res.data)  
    setIsLoading(false);  
    return true    
  }
  

  const updateCasePicture = async (picId) => {
    setIsLoading(true);
    if (! vintageId) {
      setErrorMessage("Error: Please first create the vintage before adding a picture");
      setIsLoading(false);  
      return false
    }
    const res = await fetchDataAwait(`/vintage-picture/${vintageId}/${picId}`, "put", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }  
    setConfirmationMessage(res.data.message);
    callbackCase("url",  data.vintagePictures.find(obj => obj.id === picId).url);
    callbackCase("urlThumbnail",  data.vintagePictures.find(obj => obj.id === picId).urlThumbnail);    
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  

  const uploadCasePicture = async () => {
    console.log("uploadCasePicture")
    setIsLoading(true);
    if (! vintageId) {
      setErrorMessage("Error: Please first create the vintage before adding a picture");
      setIsLoading(false);  
      return false
    }
    const tag = (Math.random() + 1).toString(36).substring(7);
    const file600 = `${tag}_${vintageId}_600.png`;
    const file200 = `${tag}_${vintageId}_200.png`;

    const signedUrl = await fetchDataAwait(`/vintage-signed-url/${vintageId}`, "put",  {file: file600});
    console.log(signedUrl);
    if (signedUrl.error) {
      setErrorMessage("Error with presigned url 600");
      setIsLoading(false); 
      return 0;
    }     
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    Object.entries(signedUrl.data.presignedURL.fields).forEach(([k, v]) => {
      postData.append(k, v);
    });  

    postData.append('file', dataFile600); // The file has be the last element  
    const response = await axios.post(signedUrl.data.presignedURL.url, postData, {
      headers: {'Content-Type': 'multipart/form-data'},
    })          
    .then(async () => {

      const signedUrl200 = await fetchDataAwait(`/vintage-signed-url/${vintageId}`, "put",  {file: file200});
      console.log(signedUrl200);
      if (signedUrl200.error) {
        setErrorMessage("Error with S3 presigned url 200");
        setIsLoading(false); 
        return 0;
      }     
      // UPLOAD FILE - Upload file
      const postData = new FormData();
      Object.entries(signedUrl200.data.presignedURL.fields).forEach(([k, v]) => {
        postData.append(k, v);
      });  
  
      postData.append('file', dataFile200); // The file has be the last element  
      const response = await axios.post(signedUrl200.data.presignedURL.url, postData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })          
      .then(async () => {
        console.log("UPLOADING DATA");
        const res = await fetchDataAwait(`/upload-vintage-picture/${vintageId}`, "post", {"pic200":signedUrl200.data.fileName, "pic600":signedUrl.data.fileName });
        if (res.error) {
            setErrorMessage(res.error.message);
            setIsLoading(false);  
            return false
        }  
        setConfirmationMessage(res.data.message);
        callbackCase("url", res.data.url);
        callbackCase("urlThumbnail", res.data.urlThumbnail);    
        setIsLoading(false);  
        setDialogOpen(false);          
      })
      .catch(error => {
        setErrorMessage("Error with file upload");
        console.log(error);                
      });
    })
    .catch(error => {
      setErrorMessage("Error with file upload");
      console.log(error);                
    });


    return true    
  }


  const handleOpenDialog = () => {
    setDialogOpen(true);
    updatePictureList();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  let jsxWarning = "";
  if (url) {
    jsxWarning = (<Alert severity="warning">Vintage already assigned to a Picture</Alert>
    )
  }  

  const MAX_IMAGE_SIZE = 300000

  const handleCapture600 = (e) => {
    if (e.target.files.length > 0) {
      setErrorMessage(null)
      if (!e.target.files[0].type.includes('image/png')) {
        setErrorMessage( 'Wrong file type - PNG only.')
        return 0
      }
      if (e.target.files[0].size > MAX_IMAGE_SIZE) {
        setErrorMessage('Image is loo large - 300k maximum')
        return 0
      }        
      setDataFile600(e.target.files[0]);
    }
  };  

  const handleCapture200 = (e) => {
    if (e.target.files.length > 0) {
      setErrorMessage(null)
      if (!e.target.files[0].type.includes('image/png')) {
        setErrorMessage( 'Wrong file type - PNG only.')
        return 0
      }
      if (e.target.files[0].size > MAX_IMAGE_SIZE) {
        setErrorMessage('Image is loo large - 300k maximum')
        return 0
      }        
      setDataFile200(e.target.files[0]);
    }
  };    

  return (
    <span>
      <LoadingButton  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}><ImageIcon/>Modify Bottle Picture</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Assign a bottle picture to vintage {vintageName}</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
               {jsxWarning}
            
            
               <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Pick Image" {...a11yProps(0)} />
                <Tab label="Or UPLOAD Images" {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
               <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
                <Grid item xs={12} md={6}>    
                      <TextField fullWidth label="Search cases in database" id="fullWidth"
                                  onChange={(e)=> setSearchField(e.target.value)}
                                  value={searchField}
                                  onKeyPress={(event) => {
                                    if (event.key === 'Enter')
                                    updatePictureList()
                                  }}                                 
                      />
                  </Grid>
                  <Grid item xs={12}  md={6} sx={{ pb: 5 }}>                             
                      <LoadingButton loading={isLoading} variant="contained" onClick={updatePictureList}>
                      Search
                    </LoadingButton>                
                  </Grid>              
              </Grid>
               <ImageList sx={{ width: 500, height: 450 }}>
                <ImageListItem key="Subheader" cols={2}>
                  <ListSubheader component="div">List of Bottle Pictures</ListSubheader>
                </ImageListItem>
                {data.vintagePictures.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                      src={`${item.url}`}
                      srcSet={`${item.url}`}
                      alt={item.name}
                      loading="lazy"
                    />
                    <ImageListItemBar

                      subtitle={item.name}
                      actionIcon={
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          aria-label={`Add picture of ${item.name}`}
                          onClick={()=>updateCasePicture(item.id)}
                        >
                          <AddIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>    
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
                  <Grid item xs={12} >    
                    Bottle image must be with the following format:
                      <br/>- 600px by 600px, and 200px by 200px
                      <br/>- Only transparent background, no shadow.
                      <br/>- Bottle should stand up
                      <br/>- Lighting on the bottle should reflect as line. Do not collect picture with bottle reflecting its surroundings.
                      <br/>- Format png
                      <br/>- No company watermark (such as 'vivino')
                      <br/>- No used bottle, only perfect bottle (no scratched or dirty bottles).
                      <br/>- Always go with a 750ml format

                    </Grid>
                    <Grid item xs={12}  >                             
                    <br/>
                       <Button
                        variant="contained"
                        component="label"
                      >
                        Select a 600x600 png bottle picture
                        <input
                          type="file"
                          hidden
                          onChange={handleCapture600}
                        />
                      </Button>  <br/>  {dataFile600.name}                   
                      <br/><br/> <Button
                        variant="contained"
                        component="label"
                      >
                        Select a 200x200 png bottle picture
                        <input
                          type="file"
                          hidden
                          onChange={handleCapture200}
                        />
                      </Button>  <br/>   {dataFile200.name}    
                      </Grid>  
                                            
                </Grid>
                <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          spacing={2}
                        >
                        <LoadingButton variant="contained"  color="primary"  loading={isLoading}
                            aria-label='Add picture'
                            onClick={()=>uploadCasePicture()}
                          >
                            Upload vintage pictures
                          </LoadingButton>   
                        </Stack>                   
              </TabPanel>              
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
          {snackbarError()}      
        {snackbarSuccess()}
        </Dialog>        
    </span>
    )
}