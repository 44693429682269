
import {  Button  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

import { useState } from 'react';
import { fCurrency } from '../../utils/formatNumber';



export default function ButtonDialogDeleteClient(props) {

  const {updateClientSelection,  setErrorMessage, client, setClient, clientSelection } = props;
  ButtonDialogDeleteClient.propTypes = {
    updateClientSelection: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    clientSelection:  PropTypes.object.isRequired, 
    client: PropTypes.object.isRequired
  };   
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientFiltered, setClientFiltered] = useState([]);
  let clientDeletion =[];

  const columnsClient = [
    { field: 'name', headerName: 'Name', width:200
      , renderCell: (params) =>  (
      <div>
        <strong>{params.value}</strong> - <small>{params.row.externalId}</small>
      </div>
      )
    },      
    { field: 'cashBalance', headerName: 'Cash', width:200  , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'casesValue', headerName: 'Wine Value', width:200 , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'bookValue', headerName: 'Book Value', width:200 , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'valueDate', headerName: 'Value Date', width:200  },  
  ];

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

  const handleCloseAddDialogConfirmedClient = () => {
    if (clientDeletion.length >0) {
      // Add new column to allocation table
      updateClientSelection(clientDeletion);
    }
      setDialogOpen(false);
    return true;
  }      

  const handleButtonClick = () => {
    setClientFiltered(client.filter((el) =>  clientSelection.includes(el.id) )); 
    setDialogOpen(true);  

  }      
      

  return (
    <span>
      <LoadingButton  variant="contained"  color="primary" loading={isLoading}  onClick={handleButtonClick}><DeleteIcon/>Remove Client(s)</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Select Client to remove:</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={clientFiltered} 
                columns= {columnsClient} 
                pageSize={25}
                loading={isLoading}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  clientDeletion=newSelection;
                }}
                
                />
                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseAddDialogConfirmedClient} color="primary" autoFocus>
              DELETE
            </Button>
          </DialogActions>
        </Dialog>              
    </span>
    )
}