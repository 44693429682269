// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Button,  ButtonGroup, Snackbar, Alert, Stack, Card, CardContent  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
// components
import {useNavigate, useParams} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));

// ----------------------------------------------------------------------


export default function EstateItem() {
  const classes = useStyles();  
  const { id } = useParams();      
  const [itemId, setItemId] = useState(id);
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   
  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);  
  const [region, setRegion] = useState('');
  const [regionError, setRegionError] = useState(false);  
  const [subRegion, setSubRegion] = useState('');
  const [subRegionError, setSubRegionError] = useState(false);  
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState(false);  
  const [latitude, setLatitude] = useState('');
  const [latitudeError, setLatitudeError] = useState(false);  
  const [longitude, setLongitude] = useState('');
  const [longitudeError, setLongitudeError] = useState(false);   
  const [isLoadingButton,setIsLoadingButton] = useState(false); 

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/estate/${id}`, "get", null);
    };
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      setIsDisabled(false);
      setIsLoading(false);
      setData({
        "data": {
          "name": null,
          "country": null,
          "region": null,
          "subRegion": null,
          "latitude": null,
          "longitude": null
        },
        "wines":[]
      });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setName(data.data.name == null ? '': data.data.name);
      setCountry(data.data.country == null ? '': data.data.country);
      setRegion(data.data.region == null ? '': data.data.region);
      setSubRegion(data.data.subRegion == null ? '': data.data.subRegion);
      setLatitude(data.data.latitude == null ? '': data.data.latitude);
      setLongitude(data.data.longitude == null ? '': data.data.longitude);
    }
  }, [data]);

  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/wine/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
      </strong>
    )
  },
  { field: 'name', headerName: 'Wine', width:200 },
  { field: 'lwin', headerName: 'lwin', width:200   },
  { field: 'country', headerName: 'Country', width:200   },
  { field: 'region', headerName: 'Region', width:200   },
  { field: 'subRegion', headerName: 'subRegion', width:200   },
  { field: 'createdAt', headerName: 'created', width:200   },
  { field: 'updatedAt', headerName: 'updated', width:200   }
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

function displayLoading() {
  return (
  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
    <Grid item xs={12}>
      <Skeleton variant="rect"  height={500}  />
    </Grid>
  </Grid>
  )
}


function displayError() {
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item >
        <Alert  severity="error" sx={{ width: '100%' }}>
        {error.message}
        </Alert>          
      </Grid>
    </Grid>
  );  
}


const validateNumberField = myNumber => {
  const numberRegEx =/^[+-]?\d*(?:[.,]\d*)?$/;
  return numberRegEx.test(String(myNumber).toLowerCase());
};
const checkBeforeUpload = () => {
  setNameError(false);
  setRegionError(false);
  setCountryError(false);
  setSubRegionError(false);
  setLatitudeError(false);
  setLongitudeError(false);
  let errorDetected = false;
  
  if ( Name === '') {
    setNameError(true);
    errorDetected = true;
  }
  if ( region === '') {
    setRegionError(true);
    errorDetected = true;
  }
  if ( country === '') {
    setCountryError(true);
    errorDetected = true;
  }
  if ( !validateNumberField(latitude) || latitude ==='') {
    setLatitudeError(true);
    errorDetected = true;
  }
  if ( !validateNumberField(longitude)  || longitude ==='') {
    setLongitudeError(true);
    errorDetected = true;
  }        

  if (errorDetected) {
    setErrorMessage("Incorrect fields");
    return false
  }

  return true
}


const uploadItem = async () => {
  setIsLoadingButton(true);      
  let url = '/estate/';
  if (itemId !=="new") {
    url += itemId;
  } 
  const upsertData = {
    name:Name,
    country,
    region,
    subRegion,
    latitude,
    longitude
  }
  let res = null;
  if (itemId ==="new") {
    res = await fetchDataAwait(url, "post", upsertData);
  }
  else {
    res = await fetchDataAwait(url, "put", upsertData);
  }
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    console.log(res)
    setData(res.data);
    if (itemId ==="new") {
      setItemId(res.data.id);
    }
    setConfirmationMessage("Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}


const handleSaveClick = () => {
  if (checkBeforeUpload()) {
    uploadItem();
  } 
}


const handleCancelClick = () => { 
  setData({...data});
  setNameError(false);
  setRegionError(false);
  setCountryError(false);
  setSubRegionError(false);
  setLatitudeError(false);
  setLongitudeError(false);  
  setIsDisabled(true);  
}

function displayActionButtons() {

    if (isDisabled) {
      return (
      <Button
        variant="contained"
        onClick={() => setIsDisabled(false)}
      >
        <EditIcon/>
        Edit
      </Button>
      )
    }

    return (    
    <ButtonGroup>

    <LoadingButton
      variant="contained"
      loading={isLoadingButton}
      onClick={() => handleSaveClick()}
    >
      <SaveIcon/>
      Save
    </LoadingButton>
    <Button
      variant="contained"
      onClick={() => handleCancelClick(true)}
    >
      <CancelIcon/>
      Cancel
    </Button>        
  </ButtonGroup>
  )
}


function displayPage(func) {
  return (
    <Page title="Estate">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Estate
          </Typography>
          {displayActionButtons()}
        </Stack>        
        {func()}
      </Container>
      {snackbarError()}   
      {snackbarSuccess()}    
    </Page>
  );
}

function displayItem() {
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item xs={12} >
        <Card >
          <CardContent>
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">       
              <Grid item xs={12}>
                <TextField 
                    onChange={(e)=> setName(e.target.value)}
                    className={classes.field} 
                    id="estate-name" 
                    label="Estate Name" 
                    fullWidth required
                    value={Name}
                    error={NameError}
                    disabled={isDisabled}
                    InputLabelProps={{ shrink: true }}
                />
              </Grid>    
              <Grid item xs={12} md={4}>
              <TextField 
                    onChange={(e)=> setCountry(e.target.value)}
                    className={classes.field} 
                    id="country-name" 
                    label="Country" 
                    fullWidth required
                    value={country}
                    error={countryError}
                    disabled={isDisabled}
                    InputLabelProps={{ shrink: true }}
                />
              </Grid> 
              <Grid item xs={12} md={4}>
                <TextField 
                    onChange={(e)=> setRegion(e.target.value)}
                    className={classes.field} 
                    id="region-name" 
                    label="Region" 
                    fullWidth required
                    value={region}
                    error={regionError}
                    disabled={isDisabled}
                    InputLabelProps={{ shrink: true }}
                />
              </Grid> 
              <Grid item xs={12} md={4}>
                <TextField 
                    onChange={(e)=> setSubRegion(e.target.value)}
                    className={classes.field} 
                    id="subregion-name" 
                    label="SubRegion" 
                    fullWidth
                    value={subRegion}
                    error={subRegionError}
                    disabled={isDisabled}
                    InputLabelProps={{ shrink: true }}
                />
              </Grid>    
              <Grid item xs={6}>
              <TextField 
                    onChange={(e)=> setLatitude(e.target.value)}
                    className={classes.field} 
                    type="number"
                    id="lat-name" 
                    label="Latitude" 
                    fullWidth required
                    value={latitude}
                    error={latitudeError}
                    disabled={isDisabled}
                    InputLabelProps={{ shrink: true }}
                />
              </Grid>    
              <Grid item xs={6}>          
                <TextField 
                    onChange={(e)=> setLongitude(e.target.value)}
                    className={classes.field} 
                    type="number"
                    id="lon-name" 
                    label="Longitude" 
                    fullWidth required
                    value={longitude}
                    error={longitudeError}
                    disabled={isDisabled}
                    InputLabelProps={{ shrink: true }}
                /> 
              </Grid>                                                                                                                                                                                                    
            </Grid>  
          </CardContent>
        </Card >       
        </Grid>                                                  
        <Grid item xs={12}>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h6">Wines from this estate </Typography>
        </Box>        
        
        <DataGridPro
          pagination
          autoHeight 
          rows={data.wines} 
          columns= {columns} 
          
          loading={isLoading}
          components={{
            Toolbar: CustomToolbar,
          }}         
        />
      </Grid>
    </Grid>    
  )

}

  // PAGE LOGIC
  if (isLoading) {
    return displayPage(displayLoading);
  }
  if (!error) {   
    return displayPage(displayItem);
  }
  return displayPage(displayError);



}

