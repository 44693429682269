
import {  Button ,IconButton } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';


import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';



export default function DialogSelectClient(props) {
  const {dialogOpen, setDialogOpen, setErrorMessage, rowId, setClient} = props;
  DialogSelectClient.propTypes = {
    rowId: PropTypes.number,
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    setClient: PropTypes.func.isRequired,
  }; 

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const columnsClient = [
    { field: 'name', headerName: 'Name', width:200
      , renderCell: (params) =>  (
      <div>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleAddClient(params.row);}}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton >          
        <strong>{params.value}</strong> - <small>{params.row.externalId}</small>
      </div>
      )
    },      
    { field: 'cashBalance', headerName: 'Cash', width:200  , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'casesValue', headerName: 'Wine Value', width:200 , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'bookValue', headerName: 'Book Value', width:200 , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'valueDate', headerName: 'Value Date', width:200  },  
  ];


  const handleAddClient = (row) => {
    setClient(rowId,row)
    setDialogOpen(false);
  }


  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

  // const handleCloseAddDialogConfirmedClient = () => {
  //   if (props.clientSelection.length >0) {
  //     // Add new column to allocation table
  //     props.addClientColumns();
  //   }
  //     setDialogOpen(false);
  //   return true;
  // }       
    
  const refreshClients = async() => {
    setIsLoading(true);
    const res = await fetchDataAwait("/allocation/get-clients", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    
    setData(res.data.client);  
    setIsLoading(false);  
    return true       
  }      

  return (
    <span>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Select Client<LoadingButton loading={isLoading} size="small" onClick={() => refreshClients()} >< RefreshIcon/></LoadingButton></DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={data} 
                columns= {columnsClient} 
                pageSize={25}
                loading={isLoading}
                />
                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>              
    </span>
    )
}