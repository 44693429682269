import { makeStyles } from '@mui/styles';
import { Box, Grid, Container, Typography, Skeleton, Button, IconButton, Snackbar, Alert, Stack, Chip  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro,   GridToolbarContainer,GridToolbarExport, GridToolbarDensitySelector} from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
// import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';


// components
import {  useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fCurrency } from '../../utils/formatNumber';
import ButtonDialogAddClient from '../../components/allocate/ButtonDialogAddClient';
import ButtonDialogSelectClientForTransactions from '../../components/allocate/ButtonDialogSelectClientForTransactions';
import ButtonDialogDeleteClient from '../../components/allocate/ButtonDialogDeleteClient';
import DialogAddRowsFromTarget from '../../components/allocate/DialogAddRowsFromTarget';
import DialogRowPickPrices from '../../components/allocate/DialogRowPickPrices';
import DialogAddRowsFromCategory from '../../components/allocate/DialogAddRowsFromCategory';
import DialogAddRowsFromSearch from '../../components/allocate/DialogAddRowsFromSearch';
import DialogAddRowsFromPreviousRound from '../../components/allocate/DialogAddRowsFromPreviousRound';
import DialogClientHeaderBreakdown from '../../components/allocate/DialogClientHeaderBreakdown';


import { fetchData, fetchDataAwait } from '../../utils/api';

// ----------------------------------------------------------------------

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarExport   csvOptions={{utf8WithBom: true,allColumns:true}} />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },  
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));


export default function AllocateRound() {
  const navigate = useNavigate();  
  const { id } = useParams();  
  const classes = useStyles();
  // Page
  const [roundId, setRoundId] = useState(null);
  // Confirmation
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogFunction, setConfirmationDialogFunction] = useState(()=>true);
  const [confirmationDialogParam, setConfirmationDialogParam] = useState([]);
  // Create new
  const [openDialogForm, setopenDialogForm] = useState(false); 

  const [dataRoundInProgress, setDataRoundInProgress] = useState([]); 
  const [client, setClient] = useState([]);


  const [clientSelection, setClientSelection] = useState([]);
  // PRICES DATA
  const [dialogPickValueOpen, setDialogPickValueOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  // CLIENT BREAKDOWN DATA
  const [dialogClientBreakdownOpen, setDialogClientBreakdownOpen] = useState(false);
  const [clientId, setClientId] = useState(null);

  
   const [isLoading, setIsLoading] = useState(true);
   // SNACKBAR
   const [errorMessage, setErrorMessage] = useState(null); 
   const [confirmationMessage, setConfirmationMessage] = useState(null); 
   // API LIST
   const [isLoadingPage, setIsLoadingPage] = useState(true);
   const [dataRound, setDataRound] = useState(null);
   const [errorRound, setErrorRound] = useState(null);


  const [isDisabled, setIsDisabled] = useState(true);
  // Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchDataRound = async () => {
      setRoundId(id);
      fetchData(setIsLoadingPage,setDataRound,setErrorRound,`/allocation/get-round-composition/${id}`, "get", null);
    }; 
    fetchDataRound();
  }, [id]); 
  useEffect(() => {
    setIsLoading(false);
    console.log("useEffectdataRound")
    // Load data
    if (!isLoadingPage) {
      console.log("Loading")
      setDataRoundInProgress(dataRound.roundComposition);
      setClient(dataRound.client);
      setClientSelection(dataRound.clientSelection);
    }

  }, [dataRound, isLoadingPage]); 

  
  let columnsRoundCompositionWithClients = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { removeRow(params.value);}}
        >
          <DeleteIcon />
        </IconButton >        
      </strong>
      )
    },      
    { field: 'caseName', headerName: 'Case', width:300 },  // Name size-qty-owner
    { field: 'caseOwner', headerName: 'Owner', width:100 },
    { field: 'availableQuantity', headerName: 'Qty', width:80 },
    { field: 'category', headerName: 'Category', width:200 },
    { field: 'region', headerName: 'Region', width:200 },
    { field: 'bookValue', headerName: 'bookValue', width:150 , renderCell: (params) =>  fCurrency(params.value) }, 
    { field: 'currentValue', headerName: 'currentValue', width:150, renderCell: (params) =>  fCurrency(params.value) }, 
    { field: 'sellingPrice', headerName: 'sellingPrice', width:150 ,
    cellClassName: 'editable-theme--cell', type: 'number' , 
    editable: true, 
    renderCell: (params) =>  (<span>
      <IconButton 
    variant="contained"
    color="primary"
    size="small"
    onClick={()=> { handlePickValue(params.row.id, params.value)}}
    >
    <InfoIcon />
    </IconButton > {fCurrency(params.value) }</span>)
  
  },
    { field: 'error', headerName: 'error', width:300  , 
      renderCell: (params) => {
        if (params.value!=='' && params.value) {
          return (<Alert severity="error">{params.value}</Alert>)
        } 
        return ''
      }
    }
    ]; 


  const handleClose = () => {
    setopenDialogForm(false);
  }; 


  function pushToTransactions() {
    navigate('/allocation/transaction', { state: {roundId: id, clientId: id} }); 
  }    


  function loadingRound() {
    return (
    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} >
        <Skeleton variant="rect"  height={500}  />
      </Grid>
    </Grid>
    )
  }

  function handleCloseConfirmationDialog() {
    setConfirmationDialogOpen(false);
  }
  function handleCloseConfirmationDialogConfirmed() {
    setConfirmationDialogOpen(false);
    confirmationDialogFunction(...confirmationDialogParam);
    
  }


  
  const handleSaveClick = () => { 

    if (dataRoundInProgress.length>0 && clientSelection.length>0) {
      saveNewRoundAllocation();
    } 
    else {
      setErrorMessage("Missing rows or client selection")
    }
  }



  const removeRow = (rowId) => {
    const filteredRoundComposition = dataRoundInProgress.filter((el) =>  el.id !== rowId); 
    console.log(filteredRoundComposition.length)
    setDataRoundInProgress([...filteredRoundComposition])
  }


  const deleteClientColumns = (clientId) => {
    const newColumns = columnsRoundCompositionWithClients.filter(e => (e.field !==`${clientId}_ownedQuantity` && e.field !==`${clientId}_allocatedQuantity` && e.field !==`${clientId}_allocatedCaseValue`) );  
    // setColumnsRoundCompositionWithClients(newColumns);
    console.log(newColumns);
    columnsRoundCompositionWithClients = newColumns;
    setDataRoundInProgress([...dataRoundInProgress]);
  }


  const addRows = async (selection, cases) => {
    setIsLoading(true);
    // Update existing rows
    const updatedRows = dataRoundInProgress.map((row) => {
      if (selection.includes(row.id)) {
        // Update existing row
        const rowCase = cases.find(obj => obj.id === row.id)
        row.id = rowCase.id;
        row.caseName = rowCase.caseName;
        row.availableQuantity = rowCase.availableQuantity;
        row.caseOwnerId = rowCase.caseOwnerId;
        row.caseOwner = rowCase.caseOwner;
        row.bookValue = rowCase.bookValue;
        row.region = rowCase.region;
        row.category = rowCase.category;
        row.category2 = rowCase.category2;
        
        return row;
      }
      return row;
    });
    // Get new rows
    const existingId = updatedRows.map((row) => (row.id) )
    let selectedRows = cases.filter((el) =>  selection.includes(el.id))
    selectedRows = selectedRows.filter((el) =>  !(existingId.includes(el.id)))
    const rows = await updateClientSelection(clientSelection, [...updatedRows,...selectedRows]);
    setDataRoundInProgress(rows); // [...updatedRows,...selectedRows]
    setIsLoading(false);
  }    

  const updateClientAdded = async (selectedClients) => {
    const rows = await updateClientSelection(selectedClients, dataRoundInProgress);
    setDataRoundInProgress(rows);
  };


  const updateRowsWithClientOwnership = async (rows,ownership, listClients) => {
    rows = rows.map((row) => {
      if (row.id in ownership) {
        const dictOwnershipRow =  ownership[row.id];
        listClients.forEach( (client) => {
          if (client in dictOwnershipRow) {
            row[`${client}_ownedQuantity`] = dictOwnershipRow[client]
          } else {
            row[`${client}_ownedQuantity`] = 0
          }
          
          if (!(`${client}_allocatedQuantity` in row)) {
            row[`${client}_allocatedQuantity`]= 0
          }
          if (!(`${client}_allocatedCaseValue` in row)) {
            row[`${client}_allocatedCaseValue`]= null
          }
        });         
        // Object.entries(dictOwnershipRow).forEach(([key, value]) => {
        //   row[`${key}_ownedQuantity`] = value
        //   if (!(`${key}_allocatedQuantity` in row)) {
        //     row[`${key}_allocatedQuantity`]= 0
        //   }
        //   if (!(`${key}_allocatedCaseValue` in row)) {
        //     row[`${key}_allocatedCaseValue`]= null
        //   }
        // }); 
      } else {
        listClients.forEach( (client) => {
          row[`${client}_ownedQuantity`] = 0
          if (!(`${client}_allocatedQuantity` in row)) {
            row[`${client}_allocatedQuantity`]= 0
          }
          if (!(`${client}_allocatedCaseValue` in row)) {
            row[`${client}_allocatedCaseValue`]= null
          }
        }); 
      }
      return row;
    });
    console.log(rows);
    return rows;
  }


  const removeClientSelected = async (selectedClientsToRemove) => {
    setIsLoading(true);
    if (selectedClientsToRemove.length>0) {
      const newClientSelection =  clientSelection.filter((el) => ! selectedClientsToRemove.includes(el) ); 
      setClientSelection([...new Set([...newClientSelection])]);
    }
    setIsLoading(false);
    return 1;
};

  const updateClientSelection = async (selectedClients,rows) => {
      setIsLoading(true);
      // console.log(updateClientSelection);
      // console.log(selectedClients)
      // console.log(rows)

      if (dataRoundInProgress.length>0 && [...clientSelection,...selectedClients].length>0) {
        const res = await fetchDataAwait(`/allocation/update-client-ownership`, "put", {clients: [...clientSelection,...selectedClients], roundComposition: dataRoundInProgress.map(el => el.id)});
        if (res.error) {
          setErrorMessage(res.error.message);
        } else {
          setConfirmationMessage("Clients ownership data updated");
          rows = updateRowsWithClientOwnership(rows, res.data.ownership,[...new Set([...clientSelection,...selectedClients])]); 
        }
      }
      setIsLoading(false);
      // Update client selection
      setClientSelection([...new Set([...clientSelection,...selectedClients])]);

      return rows;
  };


  const updateSellingPrices = async () => {
    setIsLoading(true);

    const res = await fetchDataAwait(`/allocation/update-prices`, "put", {list: dataRoundInProgress.map(el => el.id)});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage("Prices updated");
      const updatedRows = dataRoundInProgress.map((row) => {
        const foundRow = res.data.prices.find(obj => obj.id === row.id) 
        if (foundRow) {
          row.sellingPrice = foundRow.price;
          row.error = foundRow.error;
        }
        return row;
      });
      setDataRoundInProgress(updatedRows);       
      console.log(res.data)
    }
    
    setIsLoading(false);

};


const updateSellingPrice = async (wmsId,price) => {
  setIsLoading(true);
  setDialogPickValueOpen(false);
  const updatedRows = dataRoundInProgress.map((row) => {
    if (wmsId === row.id) {
      row.sellingPrice = price;
    }
    return row;
  });
  setDataRoundInProgress(updatedRows);       

  setIsLoading(false);

};


  const deleteClientSelection= (removedClients) => {
    console.log(removedClients)
    console.log(clientSelection.filter((el) =>  !(removedClients.includes(el))))
    setClientSelection(clientSelection.filter((el) =>  !(removedClients.includes(el))));
  };  

  const addClientColumns = () => {
    console.log("addClientColumns")
    // Add new clients columns if necessary
    // 1-find if clientSelection is already in dataRoundClients - -ownedQuantity","allocatedQuantity","allocatedCaseValue
    // const clientIdToAdd =  clientSelection.filter(e => dataRoundClients.map(e => e.id).indexOf(e) === -1);
    const clientColumnsToAdd = client.filter(e => clientSelection.includes(e.id)).map( x => [
      { 
        field: `${x.id}_ownedQuantity`, 
        headerName: `owned-${x.externalId}`, 
        width:130,
        type: 'number', 
        renderHeader: (params) => (
          <strong>
            {x.externalId} owned 
          </strong>
      )},
      { 
        field:`${x.id}_allocatedQuantity`, 
        headerName: `allocated-${x.externalId}`, 
        width:130, 
        cellClassName: 'editable-theme--cell', 
        type: 'number' , 
        editable: true,
        renderHeader: (params) => (
          <strong>
           <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={()=> { handleClientBreakdown(params)}}
            >
            <InfoIcon /> 
            </IconButton >        
            {x.externalId} allocated
          </strong>
        )},
        { 
          field: `${x.id}_allocatedCaseValue`, 
          headerName: `price-${x.externalId}`, 
          width:130, 
          cellClassName: 'editable-theme--cell', 
          type: 'number' , 
          editable: true,
          renderHeader: (params) => (
            <strong>
              {x.externalId} price
            </strong>
          )}
    ]).flat();
    console.log(clientColumnsToAdd)
    // 2-Add columns
    // setColumnsRoundCompositionWithClients([...columnsRoundCompositionWithClients.filter(e => e.field!=='error'), ...clientColumnsToAdd, ...columnsRoundCompositionWithClients.filter(e => e.field==='error') ])
    columnsRoundCompositionWithClients = [...columnsRoundCompositionWithClients.filter(e => e.field!=='error'), ...clientColumnsToAdd, ...columnsRoundCompositionWithClients.filter(e => e.field==='error') ];
    console.log(columnsRoundCompositionWithClients);
    // setDataRoundInProgress([...dataRoundInProgress]);

  }    

  const saveNewRoundAllocation = async () => {
    setIsLoading(true);      
    const res = await fetchDataAwait(`/allocation/update-round-composition/${roundId}`, "put",{roundComposition: dataRoundInProgress, clientSelection  }) // , client
    if (res.error) {
      setErrorMessage(res.error.message);
      console.log(res.error)
      const updatedRows = dataRoundInProgress.map((row) => {
        const foundError = res.error.listErrors.find(obj => obj.id === row.id) 
        if (foundError) {
          row.error = foundError.error;
          return row;
        }
        row.error = ""
        return row;
      });
      setDataRoundInProgress(updatedRows);          
    } else {
      const updatedRows = dataRoundInProgress.map((row) => {
        row.error = ""
        return row;
      });
      setDataRoundInProgress(updatedRows);      
      setConfirmationMessage("Successfully saved the allocation!");
    }
    setIsLoading(false);      
  }



  const handleSaveDescriptionClick = async () => { 
    setIsLoading(true);  

    const res = await fetchDataAwait(`/allocation/update-round/${roundId}`, "put", {name: dataRound.description.name, description: dataRound.description.description});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage("Details updated");
      setIsDisabled(true); 
      setopenDialogForm(false);
    }
    setIsLoading(false);
  }  


  const handleEditDescriptionClick = () => {
    if (isDisabled) {
      setIsDisabled(false);  
    } else {
      setIsDisabled(true);        
    }
  }    


  
  function defineRound() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
        <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">   
          <Grid container item xs={12} >
            {dialogFormEdit()}
            <Typography variant="h6">Round - <Chip color="primary" label={dataRound.description.name} variant="outlined" /><IconButton onClick={() => setopenDialogForm(true)} variant="contained" color="primary" size="small"><EditIcon/></IconButton></Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">
          <Grid item xs={6} md={12} >
            
            <Stack sx={{ pb:3 }} direction="row" spacing={2} justifyContent="flex-end">   
                    
                  <ButtonDialogSelectClientForTransactions roundId={id} clientSelection={clientSelection} client={client} />
              </Stack> 
            </Grid>          
          <Grid item xs={6} md={12} >
          <Stack sx={{ pb:3 }} direction={{ xs: 'column', sm: 'row' }}  spacing={2} justifyContent="left">  
       
                <LoadingButton  variant="contained"  color="primary" loading={isLoading}  onClick={handleSaveClick}><SaveIcon/>Save</LoadingButton>              
                <LoadingButton
                  variant="contained"  color="primary" loading={isLoading}
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClickMenu}
                >
                  <MenuIcon/>Add Cases
                </LoadingButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {/* <DialogAddCasesFromSearch setErrorMessage={setErrorMessage} addRows={addRows} /> dialogOpen={dialogOpenCase} setDialogOpen={setDialogOpenCase} setErrorMessage={setErrorMessage}  */}
                  <DialogAddRowsFromSearch setErrorMessage={setErrorMessage} addRows={addRows}/>
                  {/* <DialogAddRowsFromTarget setErrorMessage={setErrorMessage} addRows={addRows}/>
                  <DialogAddRowsFromPreviousRound setErrorMessage={setErrorMessage} addRows={addRows}/>
                  <DialogAddRowsFromCategory setErrorMessage={setErrorMessage} addRows={addRows}/> */}
                </Menu>     
                 <ButtonDialogAddClient   updateClientSelection={updateClientAdded} setErrorMessage={setErrorMessage} client={client} setClient={setClient}/>
                 <ButtonDialogDeleteClient  clientSelection={clientSelection} updateClientSelection={removeClientSelected} setErrorMessage={setErrorMessage} client={client} />
                 
                 <LoadingButton  variant="contained"  color="primary" loading={isLoading}  onClick={() => updateSellingPrices()} >Feed prices</LoadingButton>
                 {/* <LoadingButton  variant="contained"  color="primary" loading={isLoading} >Feed Allocations</LoadingButton>[generate alloc (based on algo, choice possible,)] */}

            </Stack>  
          </Grid>

        </Grid>
          <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="center"  className={classes.root}>
            <DataGridPro 
            getRowHeight={() => 'auto'} 
            autoHeight
            rowsPerPageOptions={[25,50,100]}
            pagination             
            rows={dataRoundInProgress} 
            columns= {columnsRoundCompositionWithClients} 
            disableSelectionOnClick
            loading={isLoading}
            onCellEditCommit={handleCellEditCommit}
            components={{
              Toolbar: CustomToolbar,
            }}            
          />           
        </Grid>

        {dialogConfirmation()}           
      </Grid>

    )
  }
  //             pageSize={100}
  //          pagination
 // https://mui.com/x/react-data-grid/rows/
 // https://mui.com/x/react-data-grid/column-pinning/


  function dialogConfirmation() {
    return (            
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseConfirmationDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseConfirmationDialogConfirmed} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>  
      )
  }

  const updateRoundDescription = (field,value) => {
    dataRound.description[field] = value
    setDataRound({...dataRound});
  }
  

  function dialogFormEdit() {
    return (
     
      <Dialog open={openDialogForm} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            Edit Round Details  <IconButton onClick={() => {setIsDisabled(true); setopenDialogForm(false);}} >< CancelIcon/></IconButton> 
          </Stack>              
   
          </DialogTitle>
        <DialogContent>          
          <TextField 
            onChange={(e)=> updateRoundDescription("name",e.target.value)}
            className={classes.field} 
            id="round-name" 
            label="Round Name" 
            fullWidth required
            disabled={isDisabled}
            value={dataRound.description.name}
          />
          <TextField
            onChange={(e)=> updateRoundDescription("description",e.target.value)}
            className={classes.field}
            id="round-description"
            label="Description"
            value={dataRound.description.description}
            disabled={isDisabled}
            multiline
            rows={4}
            fullWidth required
          />  
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Allocation Round date"
                  inputFormat="dd/MM/yyyy"
                  value={dataRound.description.roundDate}
                  disabled={isDisabled}
                  onChange={(e)=> updateRoundDescription("roundDate",e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>    
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Expiry date"
                  inputFormat="dd/MM/yyyy"
                  value={dataRound.description.roundDateExpiry}
                  disabled={isDisabled}
                  onChange={(e)=> updateRoundDescription("roundDateExpiry",e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>                    
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12} > 
            <Button disabled={!isDisabled} onClick={handleEditDescriptionClick}><EditIcon/>Edit</Button> 
            <LoadingButton loading={isLoading} disabled={isDisabled} onClick={handleSaveDescriptionClick}><SaveIcon/>Save</LoadingButton> 
          </Grid>           
        </DialogActions>
      </Dialog>

    );
  }  


  const handleCellEditCommit = ({ id, field, value }) => {
    const updatedRows = dataRoundInProgress.map((row) => {
      if (row.id === id) {
        row[field] = value;
        return row;
      }
      return row;
    });
    setDataRoundInProgress([...updatedRows]);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };  
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  

  const handlePickValue = (idRow, value) => {
    setRowId(idRow);
    setDialogPickValueOpen(true);
  }; 

  const handleClientBreakdown =  (header) => {
    const array = header.field.split("-");
    setClientId(parseInt(array[0],10));
    console.log(parseInt(array[0],10));
    setDialogClientBreakdownOpen(true);
  }; 

  function displayPage(func) {
    return (
      <Page title="Allocation Tool">
        <Container maxWidth="false">
          <Box sx={{ pb: 1 }}>
            <Typography variant="h4">Allocation Tool</Typography>
          </Box>
          {func()}
        </Container>
        <DialogRowPickPrices  dialogOpen={dialogPickValueOpen} setDialogOpen={setDialogPickValueOpen} setErrorMessage={setErrorMessage} rowId={rowId} callBackFunction={updateSellingPrice} />
        <DialogClientHeaderBreakdown  dialogOpen={dialogClientBreakdownOpen} setDialogOpen={setDialogClientBreakdownOpen} setErrorMessage={setErrorMessage} roundComposition={dataRoundInProgress} clientSelection={clientId} />
        {snackbarError()}       
        {snackbarSuccess()}    
      </Page>
    );
  }


  // PAGE LOGIC
  if (!isLoadingPage) {
    addClientColumns();
    return displayPage(defineRound);
  }

    return displayPage(loadingRound);
}

