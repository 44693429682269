// material
// import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
// import { Box, Grid, Container, Typography, Skeleton, IconButton  } from '@mui/material';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PropTypes from 'prop-types';
import { IconButton, Box, Grid, Container, Typography, Skeleton, Button, Snackbar, Alert  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  useGridApiRef,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';


const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) => ({
    actions: {
      color: theme.palette.text.secondary,
    },
    textPrimary: {
      color: theme.palette.text.primary,
    },
  }),
  { defaultTheme },
);


// ----------------------------------------------------------------------


export default function Supplier() {
  const navigate = useNavigate();  
  const classes = useStyles();
  const apiRef = useGridApiRef();

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGrid, setIsLoadingGrid] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null); 
  const [confirmationMessage, setConfirmationMessage] = useState(null);   

  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/suppliers", "get", null);
    };
    fetchDataReactScreen();
  }, []); 


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };


  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };


  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open   onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }


  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open   onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }


  function EditToolbar(props) {
    const { apiRef } = props;
  
    const getNewId = (rows) => (
      Math.max(...rows.map(o => o.id), 0) +1
    );
  
    const handleClick = () => {
      const id = getNewId(data.data);
      apiRef.current.updateRows([{ id, isNew: true }]);
      apiRef.current.setRowMode(id, 'edit');
      // Wait for the grid to render with the new row
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: apiRef.current.getRowsCount() - 1,
        });
  
        apiRef.current.setCellFocus(id, 'name');
      });
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Supplier
        </Button>
      </GridToolbarContainer>
    );
  }
  
  EditToolbar.propTypes = {
    apiRef: PropTypes.shape({
      current: PropTypes.object.isRequired,
    }).isRequired,
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, 'edit');
  };

  const handleSaveClick = (id) => async (event) => {
    setIsLoadingGrid(true);
    event.stopPropagation();
    apiRef.current.commitRowChange(id);    
    const row = apiRef.current.getRow(id);
    // console.log(row);
    let newData = null;
    // NEW ID
    if (row.isNew) {
      // console.log("NEW ID");
      newData = await fetchDataAwait(`/supplier`, "post",row);
      // console.log(newData)
    }
    else {
      // EXISTING SUPPLIER
      // console.log("EXISTING ID");
      newData = await fetchDataAwait(`/supplier/${id}`, "put", row);
    }
    setIsLoadingGrid(false);    
    if (newData.error) {
      setErrorMessage(newData.error.message);
    } else {
      setConfirmationMessage("Supplier added/updated");
      setData(newData.data);
      apiRef.current.setRowMode(id, 'view');      
    }
    // apiRef.current.updateRows([{ ...row, isNew: false }]);
  };

  // const handleDeleteClick = (id) => (event) => {
  //   event.stopPropagation();
  //   apiRef.current.updateRows([{ id, _action: 'delete' }]);
  // };

  const handleCancelClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, 'view');

    const row = apiRef.current.getRow(id);
    if (row.isNew) {
      apiRef.current.updateRows([{ id, _action: 'delete' }]);
    }
  };
   


  const columns =  [
    { field: 'id', headerName: 'id',  width:80
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/purchase/suppliers/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
    { field: 'name', headerName: 'Name', width:200 ,editable: true},
    { field: 'region', headerName: 'Region', width:150 ,editable: true},
    { field: 'country', headerName: 'Country', width:150 ,editable: true},
    { field: 'contactInfo', headerName: 'Contact Info', width:300 ,editable: true},

    { field: 'position', headerName: 'position name', width:300 ,editable: true},
    { field: 'positionLabel', headerName: 'Label', width:100 ,editable: true},
    { field: 'positionType', headerName: 'Type', width:100 ,editable: true},
    { field: 'positionAddress', headerName: 'Address', width:200 ,editable: true},
    { field: 'positionLatitude', headerName: 'Latitude', width:80, type: 'number'  ,editable: true},
    { field: 'positionLongitude', headerName: 'Longitude', width:80, type: 'number'  ,editable: true},

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: classes.actions,
      getActions: ({ id }) => {
        const isInEditMode = apiRef.current.getRowMode(id) === 'edit';

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={1}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              key={2}
              icon={<CancelIcon />}
              label="Cancel"
              className={classes.textPrimary}
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={1}
            icon={<EditIcon />}
            label="Edit"
            className={classes.textPrimary}
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={1}
            icon={<ZoomInIcon />}
            label="View"
            className={classes.textPrimary}
            onClick={() => navigate(`/purchase/suppliers/${id}`)}
            color="inherit"
          />,          
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },    
];


  if (isLoading) {
    return (
      <Page title="Suppliers">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Suppliers</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    // console.log("data")
    // console.log(data)
    return (
      <Page title="Suppliers">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Suppliers</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <DataGridPro
                autoHeight 
                pagination
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                apiRef={apiRef}
                editMode="row"    
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}                            
                
                loading={isLoadingGrid}
                components={{
                  Toolbar: EditToolbar,
                }} 
                componentsProps={{
                  toolbar: { apiRef },
                }}                        
              />
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}
      {snackbarSuccess()}              
      </Page>
    );
  }
  return (
    <Page title="Suppliers">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Suppliers</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



