
import {  Button, Snackbar, Alert, IconButton  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import {useNavigate} from 'react-router-dom';
import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';


export default function ButtonDialogPalletLocation(props) {
  const navigate = useNavigate();
  const {locationId, palletCode, palletId, locationName, callbackLocation, isDisabled} = props;
  ButtonDialogPalletLocation.propTypes = {
    locationId: PropTypes.number,
    palletCode: PropTypes.string.isRequired,
    palletId: PropTypes.number.isRequired,
    locationName: PropTypes.string.isRequired,
    callbackLocation: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  };  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataGrid, setDataGrid] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const columns =  [
    { field: 'id', headerName: ' ', width:200
    , renderCell: (params) =>  (
      <LoadingButton loading={isLoading} size="small"  variant="contained" onClick={() => updateLocationPallet(params.value)} color="primary" autoFocus>
        Add
      </LoadingButton>
      )
    },  
    { field: 'code', headerName: 'Code', width:200 },
    { field: 'level', headerName: 'Level', width:200 },
    { field: 'area', headerName: 'Area', width:300 },
    { field: 'palletId', headerName: 'Pallet', minWidth: 150
    , renderCell: (params) => {
      if (params.row.palletCode) {
        return (
        <strong>
          {params.row.palletCode}
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/warehouse/pallet/${params.value}`)}
          >
            <ZoomInIcon />
          </IconButton >        
        </strong>
        )
      }
        return (
        <span>
          No pallet       
        </span>
        ) 
    }
  },    
    { field: 'status', headerName: 'status', width:200 },
    { field: 'observation', headerName: 'Observation', minWidth: 150, flex:1  },
    { field: 'countCase', headerName: '# Cases', width:200  },
    { field: 'weight', headerName: 'Weight', width:200  },  
    { field: 'lastAddition', headerName: 'lastAddition', width:150  },
    { field: 'dateRemoval', headerName: 'dateRemoval', width:150  }
];


  const updateLocationList = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait("/locations", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDataGrid(res.data.data)  
    setIsLoading(false);  
    return true    
  }
  

  const updateLocationPallet = async (locationId) => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/location-pallet/${locationId}/${palletId}`, "put", {});
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }  
    setConfirmationMessage(res.data.message);
    callbackLocation("locationId",  locationId);
    callbackLocation("locationName",  dataGrid.find(obj => obj.id === locationId).code);
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  

  const handleOpenDialog = () => {  
    setDialogOpen(true);    
    updateLocationList();      
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      console.log("XXX")
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  let jsxWarning = "";
  if (locationId) {
    jsxWarning = (<Alert severity="warning">Pallet is already assigned to location {locationName}</Alert>
    )
  }  
  return (
    <span>
      <LoadingButton  disabled={isDisabled} loading={isLoading} size="small"  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Modify Location</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Add Pallet {palletCode} to Location:</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {jsxWarning}
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={dataGrid}
                columns= {columns}
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
          {snackbarError()}      
        {snackbarSuccess()}          
        </Dialog>        

    </span>
    )
}