// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Button  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';
import { fCurrency,fPercentDecimal, fCurrencyLC } from '../../utils/formatNumber';

// ----------------------------------------------------------------------


export default function Vintage() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/vintages", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
      { field: 'id', headerName: 'id', width:20
      , renderCell: (params) => (
        <strong>
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/stock/vintage/${params.value}`)}
          >
            <ZoomInIcon />
          </IconButton >  
          {params.value}      
        </strong>
      )
    },
      { field: 'name', headerName: 'Vintage', width:300  },
      { field: 'year', headerName: 'Year', width:200  },
      { field: 'estate', headerName: 'Estate', width:200  },
      { field: 'lwin', headerName: 'lwin', width:200  },
      { field: 'country', headerName: 'Country', width:200  },
      { field: 'WineRegion', headerName: 'Region', width:200  },
      { field: 'subRegion', headerName: 'subRegion',width:200  },

      { field: 's3Key', headerName: 'bottle pic?', minWidth: 80,  renderCell: (params) => {
        if (params.value) {
          return 1
        } 
        return 0
        }
      },
      { field: 'region', headerName: 'Region Category', width:200  },
      { field: 'category', headerName: 'category',width:200  },
      { field: 'category2', headerName: 'category2',width:200  },

      { field: 'date0', headerName: 'date0', width:200  },
      { field: 'avgListedValue0', headerName: 'avgListedValue0', width:200  },
      { field: 'mktPrice0', headerName: 'mktPrice0',width:200  },

      { field: 'date1W', headerName: 'date1W', width:200  },
      { field: 'avgListedValue1W', headerName: 'avgListedValue1W', width:200  },
      { field: 'mktPrice1W', headerName: 'mktPrice1W', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'ReturnAvgListedValue1W', headerName: 'ReturnAvgListedValue1W', width:200  },
      { field: 'ReturnMktPrice1W', headerName: 'ReturnMktPrice1W', width:200  },

      { field: 'date1M', headerName: 'date1M', width:200  },
      { field: 'avgListedValue1M', headerName: 'avgListedValue1M', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'mktPrice1M', headerName: 'mktPrice1M', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'ReturnAvgListedValue1M', headerName: 'ReturnAvgListedValue1M', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },
      { field: 'ReturnMktPrice1M', headerName: 'ReturnMktPrice1M', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },
      
      { field: 'date3M', headerName: 'date3M', width:200  },
      { field: 'avgListedValue3M', headerName: 'avgListedValue3M', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'mktPrice3M', headerName: 'mktPrice3M', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'ReturnAvgListedValue3M', headerName: 'ReturnAvgListedValue3M', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },
      { field: 'ReturnMktPrice3M', headerName: 'ReturnMktPrice3M', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },
      
      { field: 'date6M', headerName: 'date6M', width:200  },
      { field: 'avgListedValue6M', headerName: 'avgListedValue6M', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'mktPrice6M', headerName: 'mktPrice6M', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'ReturnAvgListedValue6M', headerName: 'ReturnAvgListedValue6M', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },
      { field: 'ReturnMktPrice6M', headerName: 'ReturnMktPrice6M', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },

      { field: 'date1Y', headerName: 'date1Y', width:200  },
      { field: 'avgListedValue1Y', headerName: 'avgListedValue1Y', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'mktPrice1Y', headerName: 'mktPrice1Y', minWidth: 150 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'ReturnAvgListedValue1Y', headerName: 'ReturnAvgListedValue1Y', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },
      { field: 'ReturnMktPrice1Y', headerName: 'ReturnMktPrice1Y', minWidth: 150, renderCell: (params) => fPercentDecimal(params.value)  },  

      { field: 'createdAt', headerName: 'created', width:200  },
      { field: 'updatedAt', headerName: 'updated', width:200  }
  ];


  if (isLoading) {
    return (
      <Page title="Vintages">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Vintages</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    console.log("data")
    console.log(data)
    return (
      <Page title="Vintages">
        <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Vintages
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => navigate(`/stock/vintage/new`)}
          >
            New
          </Button>
        </Stack> 
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item xs={12}>
       
       <DataGridPro
         pagination
         autoHeight 
         rows={data.data} // {rows} //
         columns= {columns} //  {data.columns} //
         
         loading={isLoading}
         components={{
          Toolbar: CustomToolbar,
        }}         
       />
     </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Vintages">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Vintages</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



