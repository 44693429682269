import { Button, Grid, Skeleton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import { fetchData } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },  
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));





export default function DialogRowPickPrices(props) {
  const classes = useStyles();

  const {dialogOpen, setDialogOpen, setErrorMessage, rowId, callBackFunction} = props;
  DialogRowPickPrices.propTypes = {
    rowId: PropTypes.number.isRequired,
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    callBackFunction: PropTypes.func.isRequired,
    listValues: PropTypes.array.isRequired,
  }; 

  const [prices, setPrices] = useState(null);
  // let prices = null;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const pricesData = {
    prices: 
      {lwin: rowId,
        livex: {market:"n/a",
                averagePrice:"n/a",
                quantity:"n/a",
                date:"-"
                }
    }
  }

  useEffect(() => {

    setPrices({...pricesData , lwin: rowId});
    const fetchDataPrices = async () => {
      if (rowId) {
        fetchData(setIsLoading,setPrices,setError,`/allocation/get-prices`, "put", {id: rowId});
      }
    }; 
    setIsLoading(true);
    fetchDataPrices();
  }, [rowId]); 

  // useEffect(() => {
  //   setIsLoading(true);
  //   prices = null
  //   const fetchDataInUseEffect = async () => {
  //     const res = fetchDataAwait(`/allocation/get-prices`, "put", {id: rowId});
  //     if (res.error) {
  //       setErrorMessage(res.error.message);
  //       setIsLoading(false);
  //     } else {
  //       console.log("res.data")
  //       console.log(res)
  //       // prices = res.data.prices
  //     }
  //   }
  //   fetchDataInUseEffect();

  // }, [rowId]);   


  // const handleSaveDialog = () => {
  //   callBackFunction(rowId,value)
  //   setDialogOpen(false);
  // }

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  } 

  const displayPrices = () => {
    if (isLoading) {
      return (
      <Grid item xs={12} >
        <Skeleton variant="rect"  height={200}  />
      </Grid>        
      )
    }
    if (prices) {
      return (
        <Grid item xs={12} md={12}>    
        LWIN: {prices.prices.lwin}<br/><br/>
        Livex Date data: {prices.prices.livex.date}
        Livex Market: <Button variant="text" onClick={() => callBackFunction(rowId,prices.prices.livex.market)} color="primary">{fCurrency(prices.prices.livex.market)}</Button><br/>
        Livex averagePrice: <Button variant="text" onClick={() => callBackFunction(rowId,prices.prices.livex.averagePrice)} color="primary">{fCurrency(prices.prices.livex.averagePrice)}</Button><br/>
        Livex quantity: {prices.prices.livex.quantity}<br/>

        {/* {listValues.map((item,i) => (
                      <MenuItem value={item} key={i}>{item}</MenuItem>
                  ))}         */}
      </Grid>
      )
    }
    return "No data found"
  }

  return (
      <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">Check and pick available prices for this case</DialogTitle> 
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            
              {displayPrices()}
              {/* <FormControl> 
                <Select style={{width: 300}}
                  autoWidth	
                  label="status Size" 
                  labelId="select-status"
                  id="select-status"
                  value={value}
                  onChange={handleSelected}
                >
                  {listValues.map((item,i) => (
                      <MenuItem value={item} key={i}>{item}</MenuItem>
                  ))}
                </Select> 
              </FormControl> */}
          </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>           
    )
}