import {  Button, Snackbar, Alert  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';


const useStyles = makeStyles(() => ({
  field: {
    marginTop:10,
    marginBottom: 0,
    display: 'block'
  }
}));

export default function ButtonAddNewVintage(props) {
  const navigate = useNavigate();  
  const classes = useStyles();   
  const {wineId } = props;
  ButtonAddNewVintage.propTypes = {
    wineId: PropTypes.number.isRequired,
  };
  const [vintageYear, setVintageYear] = useState("");  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    

  const handleOpenDialog = () => {
    setDialogOpen(true);
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);
  }
    
  const handleCloseAdd = async () => {
    if (Number.isNaN(vintageYear)) {
      setErrorMessage("Vintage year is not a number");
      return false
    }
    if (vintageYear!== 1000 && (vintageYear<1500 || vintageYear>2050)) {
      setErrorMessage("Vintage year does not seem to be correct");
      return false
    }
    setIsLoading(true);
    const res = await fetchDataAwait(`/new-vintage-year/${wineId}/${vintageYear}`, "post", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    } 
    setIsLoading(false); 
    handleCloseDialog(false);
    navigate(`/stock/vintage/${res.data.vintageId}`)
    // REDIRECT
    return true    
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar>
      )
    }
    return ""
  }     

  return (
    <span>
      <LoadingButton loading={isLoading}  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Add New Vintage</LoadingButton>
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth='sm'
        >
          <DialogTitle id="alert-dialog-title">Create New Vintage</DialogTitle> 
          <DialogContent >
            <DialogContentText id="alert-dialog-description">
              <TextField 
                    onChange={(e)=> setVintageYear(e.target.value)}
                    className={classes.field} 
                    type="number"
                    id="vintageYear" 
                    label="New Vintage Year" 
                    fullWidth
                    value={vintageYear}
                    InputLabelProps={{ shrink: true }}
                />            
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <LoadingButton loading={isLoading} variant="contained" onClick={handleCloseAdd} color="primary" autoFocus>
              ADD
            </LoadingButton>
        </DialogActions>
        {snackbarError()}      
        {snackbarSuccess()}        
        </Dialog>        
    </span>
    )
}