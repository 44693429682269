// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../components/Page';
import QrScanner from '../components/QrScanner';

// ----------------------------------------------------------------------

export default function QRCam() {
  return (
    <Page title="QR Cam">
      <Container maxWidth="false">
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
          <QrScanner/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
