
import {  Button, Snackbar, Alert, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PropTypes from 'prop-types';

import { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { fetchDataAwait } from '../../utils/api';


export default function ButtonDialogCancelTransaction(props) {
  const {itemId, isDisabled, dataGrid} = props;
  ButtonDialogCancelTransaction.propTypes = {
    itemId: PropTypes.string.isRequired,    
    isDisabled: PropTypes.bool.isRequired,
    dataGrid:PropTypes.array.isRequired,
  };
  const navigate = useNavigate();  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    


  const columnsTransactions =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/client/transaction/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
  { field: 'client', headerName: 'Client' ,width:200},   
  { field: 'methodLabel', headerName: 'Type' ,width:200},
  { field: 'note', headerName: 'Note',width:300},      
  { field: 'invoice', headerName: 'Invoice#',width:200},        
  { field: 'groupName', headerName: 'Transac Group', width:200},      
  { field: 'cashTransfer', headerName: 'cashTransfer', width:200 },
  { field: 'admin', headerName: 'Admin',width:200},      
  { field: 'createdAt', headerName: 'createdAt', width:200  },
  { field: 'updatedAt', headerName: 'updatedAt', width:200  }
];
  

  const deleteTransaction = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/transactions/cancel/${itemId}`, "get", null);
    console.log(res);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    } 
    setIsLoading(false);  
    setDialogOpen(false);
    navigate(`/client/transaction`);
    return true    
  }
  

  const handleOpenDialog = () => { 
    setDialogOpen(true);    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  // let jsxWarning = "";
  // if (dataGrid && dataGrid.filter((el) =>  el.documentTypeId === 2).length>0) {
  //   jsxWarning = (<Alert severity="warning">There is already an invoice for that transaction</Alert>
  //   )
  // }  

  return (
    <span>
      <LoadingButton loading={isLoading} disabled={isDisabled}  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Delete Transaction</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Delete Transaction</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Alert severity="warning">Deleting this transaction will also cancel these linked transactions:</Alert>
            After deletion this transaction and all linked ones will be removed from the database. Only previously produced invoices will still exist but will be marked as "CANCELED".
            <br/>
            <Typography variant="h6">List of linked transactions</Typography>
                <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={dataGrid}
                columns= {columnsTransactions}
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} disabled={isDisabled}  variant="contained"  color="primary"  onClick={() => deleteTransaction()}>Confirm deletion</LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
          {snackbarError()}      
        {snackbarSuccess()}           
        </Dialog>        

    </span>
    )
}