import { makeStyles } from '@mui/styles';
import { Checkbox, Chip, TextField, FormControl, FormLabel, RadioGroup, Radio,FormControlLabel, Grid, Container, Typography, Button, IconButton, Snackbar, Alert, Stack  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import axios from "axios";

// components
import { useState, useEffect } from 'react';
import {
  useLocation
} from "react-router-dom";
import Page from '../../components/Page';
import DialogSelectClient from '../../components/allocate/DialogSelectClient';
import DialogAddUniqueCaseFromSearch from '../../components/allocate/DialogAddUniqueCaseFromSearch';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';

// ----------------------------------------------------------------------


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },  
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));


export default function AddTransactionCase(props) {
  const classes = useStyles();
  const location = useLocation();

  // Confirmation
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogFunction, setConfirmationDialogFunction] = useState(()=>true);
  const [confirmationDialogParam, setConfirmationDialogParam] = useState([]);
  const [dialogOpenClient,setDialogOpenClient] = useState(false);
  const [dialogOpenCase,setDialogOpenCase] = useState(false);
 
  // Transactions data
  const [rowId, setRowId] = useState(null);

  const newTransaction = {
    id:1,
    caseName: "",
    caseId: null,    
    caseOwnerId:"",
    caseOwner:"",
    bookValue:null,
    currentValue:null,
    status:"",
    sellerPrice:null,
    buyerId:null,
    buyerName:"",
    buyerPrice:null,
    error:""
  };
  const [dataTransactions, setDataTransactions] = useState([newTransaction,]);  
  const [transactionsCases, setTransactionsCases] = useState(0);  
  const [transactionsAmount, setTransactionsAmount] = useState(0);  
  useEffect(() => {
    const totalCases = dataTransactions.filter((el) =>  el.caseId >0).length; 
    const totalAmount = dataTransactions.map(item => item.buyerQuantity * item.buyerPrice).reduce((prev, curr) => prev + curr, 0);
    setTransactionsCases(totalCases)
    setTransactionsAmount(totalAmount)
    return 0    
  }, [dataTransactions]);  

  const [transactionInvoice, setTransactionInvoice] = useState({
    generateInvoice:"na",
    invoiceNumber:null,
    invoiceName:null,
    invoiceDescription:null,
  });
  const updateDataTransactions = (field,value) => {
    transactionInvoice[field] = value
    setTransactionInvoice({...transactionInvoice});
  }
  const [dataFileInvoice,setDataFileInvoice] = useState({name:"no file selected"});
  const handleCaptureInvoice = (event) => {
    setDataFileInvoice(event.target.files[0]);
  };

   const [isLoading, setIsLoading] = useState(false);
   // SNACKBAR
   const [errorMessage, setErrorMessage] = useState(null); 
   const [confirmationMessage, setConfirmationMessage] = useState(null); 
   const [isChecked, setIsChecked] = useState(true); 

   const handleChangeCheckBox = (event) => {
    setIsChecked(event.target.checked);
  };   
  
   useEffect(() => {
     const fetchDataReactScreen = async () => {
       fetchData(setIsLoading,setDataTransactions,setErrorMessage,"/transactions/load-generate", "put", {"list":location.state});
     };
     if (location.state) {
       setIsLoading(true);
       fetchDataReactScreen();
     }
   }, []); 


  // CREATE TRANSACTIONS
  const handleCreateTransactions = async () => {
    setIsLoading(true);

    // IF NO user invoice UPLOAD
    if (transactionInvoice.generateInvoice!=="upload") {
      console.log("No invoice upload")      
      const res = await fetchDataAwait('/transactions/generate', "post", {transactions : dataTransactions, generateCashTransactions: isChecked, caseId: true,  ...transactionInvoice });
      
      if (res.error) {
        setErrorMessage(res.error.message);
        if (res.error.errorRows) {
          console.log(res.error.message)
          const updatedRows = dataTransactions.map((row) => {
            const foundError = res.error.errorRows.find(obj => obj.id === row.id) 
            if (foundError) {
              row.error = foundError.error;
              return row;
            }
            row.error = ""
            return row;
          });
          setDataTransactions(updatedRows);      
        }
      } else {
        setConfirmationMessage("Transactions succesfully created.");
      }   
    } else {
      const signedUrl = await fetchDataAwait('/get-signed-url/', "put",  {file: dataFileInvoice.name, type: 'invoice'});
      console.log(signedUrl);
      if (signedUrl.error) {
        setErrorMessage("Error with S3 presigned url");
        setIsLoading(false); 
        return 0;
      }     
      // UPLOAD FILE - Upload file
      const postData = new FormData();
      Object.entries(signedUrl.data.presignedURL.fields).forEach(([k, v]) => {
        postData.append(k, v);
      });  
  
      postData.append('file', dataFileInvoice); // The file has be the last element  
  
      const response = await axios.post(signedUrl.data.presignedURL.url, postData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })          
      .then(async () => {
        console.log("UPLOADING DATA");
        const res = await fetchDataAwait('/transactions/generate', "post", {transactions : dataTransactions, caseId: true,  ...transactionInvoice , generateCashTransactions: isChecked  ,file: signedUrl.data.fileName});
        if (res.error) {
          setErrorMessage(res.error.message);
          if (res.error.errorRows) {
            console.log(res.error.message)
            const updatedRows = dataTransactions.map((row) => {
              const foundError = res.error.errorRows.find(obj => obj.id === row.id) 
              if (foundError) {
                row.error = foundError.error;
                return row;
              }
              row.error = ""
              return row;
            });
            setDataTransactions(updatedRows);      
          }
        } else {
          setConfirmationMessage("Transactions succesfully created.");
        }          
      })
      .catch(error => {
        setErrorMessage("Error with file upload");
        console.log(error);                
      });
  
    }
    setIsLoading(false);     

  };

  const handleClickAddRow = () => {
    const row = {...newTransaction};
    row.id = Math.max(...dataTransactions.map(o => o.id), 0)+1;
    setDataTransactions([...dataTransactions,row]);
  };


  const handleRemoveRow = (rowId) => {
    // const newCases = dataTransactions.filter((el) =>  el.id !== rowId); 
    setDataTransactions(dataTransactions.filter((el) =>  el.id !== rowId));
  };  
  const handleAddCase = (rowTableId) => {
    setRowId(rowTableId);
    setDialogOpenCase(true);
  };    
  const handleAddUser = (rowTableId) => {
    setRowId(rowTableId);
    setDialogOpenClient(true);
  };    


  const handleChangeRadio = (event) => {
    console.log(event.target.value)
    updateDataTransactions("generateInvoice",event.target.value)
  };    

  const updateClientData = (rowId,rowCase) => {
    const updatedRows = dataTransactions.map((row) => {
      if (row.id === rowId) {
        row.buyerId = rowCase.id;
        row.buyerName = rowCase.name;
        return row;
      }
      return row;
    });
    setDataTransactions(updatedRows);
  };  

  const updateCaseData = (rowId,rowClient) => {
    console.log(rowClient)
    console.log(rowId)
    const updatedRows = dataTransactions.map((row) => {
      if (row.id === rowId) {
        row.caseId = rowClient.id;
        row.caseName = rowClient.name;
        // row.availableQuantity = rowClient.availableQuantity;
        row.caseOwnerId = rowClient.userId;
        row.caseOwner = rowClient.user;
        row.bookValue = rowClient.bookValue;
        row.currentValue = rowClient.currentValue;
        row.status = rowClient.status;
        row.bottleSize = rowClient.bottleSize;
        row.packSize = rowClient.packSize;
        return row;
      }
      return row;
    });
    console.log(updatedRows)
    setDataTransactions([...updatedRows]);
  };   

  
  const handleCellEditCommit = ({ id, field, value }) => {
    const updatedRows = dataTransactions.map((row) => {
      if (row.id === id) {
        row[field] = value;
        return row;
      }
      return row;
    });
    setDataTransactions(updatedRows);
  };  

  const columnsTransactions = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.row.id);}}
        >
          <ClearIcon />
        </IconButton >        
      </span>
      )
    },      
    { field: 'caseId', headerName: 'caseId', width:100 },        
    { field: 'caseOwner', headerName: 'Client', width:100 },    
    { field: 'status', headerName: 'Status', width:100 },  
    { field: 'caseName', headerName: 'Case', width:300     , renderCell: (params) => (
      <span>
         
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleAddCase(params.row.id);}}
        >
          <AddCircleOutlineOutlinedIcon />
         
        </IconButton >    
        {params.value}    
      </span>
      )
    }, 
    { field: 'sellerPrice', headerName: 'sellerPrice', width:200 ,cellClassName: 'editable-theme--cell', type: 'number' , editable: true,},
    { field: 'buyerId', headerName: 'Buyer', width:200   , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleAddUser(params.row.id);}}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton >        
        {params.row.buyerName}        
      </span>
      )
    },   
    { field: 'buyerPrice', headerName: 'BuyerPrice', width:200 ,cellClassName: 'editable-theme--cell', type: 'number' , editable: true,},    
    { field: 'bookValue', headerName: 'book', width:70 , renderCell: (params) =>  fCurrency(params.value) }, 
    { field: 'currentValue', headerName: 'current price', width:70 , renderCell: (params) =>  fCurrency(params.value) }, 
    { field: 'error', headerName: 'error', width:300  , 
      renderCell: (params) => {
        if (params.value!=='' && params.value) {
          return (<Alert severity="error">{params.value}</Alert>)
        }
        return ''
      }
    }
    ];



  function handleCloseConfirmationDialog() {
    setConfirmationDialogOpen(false);
  }
  function handleCloseConfirmationDialogConfirmed() {
    setConfirmationDialogOpen(false);
    confirmationDialogFunction(...confirmationDialogParam);
    
  }

  const [uploadDialog, setUploadDialog] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [dataFile,setDataFile] = useState({name:"no file selected"});
  const handleDialogUploadClose = () => {
    setUploadDialog(false); 
  }  
  const handleDialogUploadOpen = () => {
    setUploadDialog(true); 
  }    
  const uploadDocument = async () => {
    setIsLoadingButton(true);
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    postData.append("refOrder", 1)
    postData.append('file', dataFile); // The file has be the last element  

    console.log(postData)

    const res = await fetchDataAwait(`/transactions/load-file-generate`, "post", postData, {'Content-Type': 'multipart/form-data'});
    if (res.error) {
      setErrorMessage(res.error.message);
    }   
    else {
      setUploadDialog(false); 
      setIsLoading(true);
      setDataTransactions(res.data);
      setConfirmationMessage("List updated.");
      setIsLoading(false);
    }

    setIsLoadingButton(false);
    return 1;
  }
  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
  };   
  const dialogUpload = () =>  (
    <Dialog
    open={uploadDialog}
    onClose={() => handleDialogUploadClose()}
    aria-labelledby="alert-dialog-upload"
    maxWidth='lg'
    >
      <DialogTitle  sx={{ pb: 5 }}>Upload a list of cases</DialogTitle> 
      <DialogContent>
      <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">        
                <Grid sx={{ pb: 2 }} item xs={12}>
                  Upload of list of cases to replace the existing list-
                </Grid>                                              
                <Grid sx={{ pb: 2 }} item xs={12}  >
                  File must be a <strong>"csv"</strong> and contain a column "caseId" with the list of ids, and (optional) a column "priceBuyer", "priceSeller" and "buyerId" (numerical value only). BuyerId must be a client id.
                </Grid>  
                <Grid item xs={12} >
                <Button
                  variant="contained"
                  component="label"
                >
                  Select a CSV
                  <input
                    type="file"
                    hidden
                    onChange={handleCapture}
                  />
                </Button>  <br/>     
                {dataFile.name}   
                </Grid>                                                                                                                                                                                                                          
              </Grid>             
                    
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> uploadDocument()} color="primary">
          Upload CSV
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogUploadClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );


  function displayInvoiceForm() {
    if (transactionInvoice.generateInvoice !=='upload') {
      return ""
    }
    return (
      <Grid container item xs={12}>
        <Grid item xs={12} md={3}>Provide a pdf invoice</Grid> 
      <Grid item xs={12} md={8}>
            <Button
              variant="contained"
              component="label"
              disabled={transactionInvoice.generateInvoice!=='upload'}
            >
              Upload Invoice (pdf)
              <input
                accept="application/pdf"
                type="file"
                hidden
                onChange={handleCaptureInvoice}
              />
          </Button>  <br/>     
          {dataFileInvoice.name}
      </Grid>  
      <Grid item xs={12}  md={3}>Invoice Number</Grid>
        <Grid item xs={12}  md={9}>
        <TextField 
          onChange={(e)=> updateDataTransactions("invoiceNumber",e.target.value)}
          className={classes.field} 
          id="invoiceNumber" 
          label="Invoice Number"      
          fullWidth required
          value={transactionInvoice.invoiceNumber}
          disabled={transactionInvoice.generateInvoice!=='upload'}
          InputLabelProps={{ shrink: true }}
        />             
      </Grid>   
      <Grid item xs={12}  md={3}>File name</Grid>
        <Grid item xs={12}  md={9}>
        <TextField 
          onChange={(e)=> updateDataTransactions("invoiceName",e.target.value)}
          className={classes.field} 
          id="Name" 
          label="Invoice Name"      
          fullWidth required
          value={transactionInvoice.invoiceName}
          disabled={transactionInvoice.generateInvoice!=='upload'}
          InputLabelProps={{ shrink: true }}
        />                
      </Grid> 
      <Grid item xs={12}  md={3}>Description</Grid>
        <Grid item xs={12}  md={9}>
        <TextField 
          onChange={(e)=> updateDataTransactions("invoiceDescription",e.target.value)}
          className={classes.field} 
          id="Description" 
          label="Description"      
          fullWidth required
          multiline
          rows={2}            
          value={transactionInvoice.invoiceDescription}
          disabled={transactionInvoice.generateInvoice!=='upload'}
          InputLabelProps={{ shrink: true }}
        />                
      </Grid> 


    </Grid>
    )
  }
  
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true,allColumns:true}} />
        <Chip label={`#Cases: ${transactionsCases}`} variant="outlined" />
        <Chip label={`Total Buyer: ${fCurrency(transactionsAmount)}`} variant="outlined" />
      </GridToolbarContainer>
    );
  }  
 
  
  function defineTransactions() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item  xs="12" justifyContent="left" alignItems="center" >  
            <Stack sx={{ pb:3 }} direction="row" spacing={2} justifyContent="space-between">   
                <LoadingButton
                    color="primary" loading={isLoading}
                    onClick={handleClickAddRow}
                    variant="contained"
                >
                  <AddCircleOutlineOutlinedIcon/>Add Row
                </LoadingButton>
                <LoadingButton
                    color="primary" loading={isLoading}
                    onClick={() => handleDialogUploadOpen()}
                    variant="contained"
                >
                  <InsertDriveFileIcon/>Upload from list
                </LoadingButton>    
                               
            </Stack>  
          </Grid>

          <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="center"  className={classes.root}>  
              <DataGridPro 
              pagination
              overflow="auto"
              autoHeight 
              rows={dataTransactions} 
              columns= {columnsTransactions} 
              pageSize={100}
              loading={isLoading}
              onCellEditCommit={handleCellEditCommit}
              components={{
                Toolbar: CustomToolbar,
              }}               
            />      
        </Grid>   
        {dialogConfirmation()}   
        {dialogUpload()}      
        <DialogAddUniqueCaseFromSearch dialogOpen={dialogOpenCase} setDialogOpen={setDialogOpenCase} setErrorMessage={setErrorMessage} rowId={rowId} setCase={updateCaseData}/>
        <DialogSelectClient dialogOpen={dialogOpenClient} setDialogOpen={setDialogOpenClient} setErrorMessage={setErrorMessage} rowId={rowId} setClient={updateClientData}/>          
      </Grid>
    ) // [dialogOpenClient,setDialogOpenClient]
  }


  function dialogConfirmation() {
    return (            
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseConfirmationDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseConfirmationDialogConfirmed} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>  
      )
  }



  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };  
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }

  function displayPage(func) {
    return (
      <Page title="New Transaction of specific Cases">
        <Container maxWidth="false">
            <Stack  sx={{ pb: 5 }} direction="row" spacing={2} justifyContent="space-between">   
                <Typography variant="h4">New Transaction of specific Cases</Typography> Use this form to buy or sell cases based on their ids.            
              
                <LoadingButton
                  variant="contained"  color="primary" loading={isLoading}
                  onClick={handleCreateTransactions}
                >
                  Create Transactions
                </LoadingButton>

            </Stack>   
            <Grid item xs={12}  md={3} >
                    <FormControlLabel
                    control={<Checkbox  checked={isChecked} onChange={handleChangeCheckBox} />}
                    label="Impact Cash balance"
                    />     
            </Grid>                                    
            <Grid item xs={12} md={12}>
              <FormControl component="fieldset">
              <FormLabel component="legend">Invoice to link to these transactions</FormLabel>
                <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                        value={transactionInvoice.generateInvoice}
                        onChange={handleChangeRadio}
                      >
                  <FormControlLabel value="na" control={<Radio />} label="No invoice" />
                  <FormControlLabel value="upload" control={<Radio />} label="Upload Invoice" />
                </RadioGroup>
              </FormControl>
            </Grid>            
          {displayInvoiceForm()}                    
          {func()}
        </Container>
        {snackbarError()}       
        {snackbarSuccess()}    
      </Page>
    );
  }


  // PAGE LOGIC
  return displayPage(defineTransactions);

}

