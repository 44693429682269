// material
import { makeStyles } from '@mui/styles';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Button, Snackbar, Alert, Stack,CardHeader, Card, CardContent,  Chip  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import StoreIcon from '@mui/icons-material/Store';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PinDropIcon from '@mui/icons-material/PinDrop';
import FortIcon from '@mui/icons-material/Fort';

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import { useLoadScript, GoogleMap, Polyline, Marker } from "@react-google-maps/api";
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect, useCallback, useRef } from 'react';
// import ButtonDialogCaseSensor from '../../components/stock/ButtonDialogCaseSensor';
// import ButtonDialogCasePallet from '../../components/stock/ButtonDialogCasePallet';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';

import CardShippingTraceability from '../../components/traceability/CardShippingTraceability';
import CardWarehouseTraceability from '../../components/traceability/CardWarehouseTraceability';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  map: {
    width: '100%',
    height: '600px'
  },  
  mapContainer: {
    width: '100%',
    height: '600px'
  },    
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}



export default function CaseTraceabilityItem() {
  const classes = useStyles();   
  const { id } = useParams();    
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);  
  const [dialogOpenPicture, setDialogOpenPicture] = useState(false);  

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = useState(null)
  
  const dataRef = useRef(data)
  useEffect(
    () => { dataRef.current = data },
    [data]
  )

  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/traceability/${id}`, "get", null);
    }; 
    fetchDataReactScreen();
    return 0    
  }, [id]);


  const onLoadMap = useCallback((map) => {
    let dataLatLng;
    // let myLatlng;
    let marker;
    // const bounds = new window.google.maps.LatLngBounds( {
    //   lat: 46.26158318059036, 
    //   lng: 6.146286036091205
    // });

    if  (dataRef.current.mapData.length ===1) {
      return 0
    }

      // Create LatLngBounds object.
      const latlngbounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < dataRef.current.mapData.length; i += 1) {
          dataLatLng = dataRef.current.mapData[i]
          const myLatlng =new window.google.maps.LatLng(dataLatLng.position.lat, dataLatLng.position.lng);
          marker =new window.google.maps.Marker({
              position: myLatlng,
              map,
              title: dataLatLng.title
          });
          latlngbounds.extend(marker.position);
      }

      map.setCenter(latlngbounds.getCenter());
      map.fitBounds(latlngbounds);

    // map.fitBounds(bounds);
    setMap(map)
  }, [])

  function displayGoogleMaps() {

    if (!isLoaded) {
      return <div>Loading...</div>
      }
      if (data.mapData.length ===0 ) {
        return "No data to show"
      }
    // https://www.youtube.com/watch?v=2po9_CIRW7I
    const path = data.mapData.map((gps) => gps.position );
  
    const onLoad = polyline => {
      console.log('polyline: ', polyline)
    };  
  
    const options = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 50000,
      paths: data.mapData.map((gps) => gps.position ),  
      zIndex: 1,
      // disableDefaultUI: true,
    };  
    
    // 
    // onLoad={onLoadMap} 
    return (
      <div className={classes.map} >
        <GoogleMap onLoad={onLoadMap} options={{disableDefaultUI: true}} zoom={12} center={{lat: data.mapData[data.mapData.length-1].position.lat, lng: data.mapData[data.mapData.length-1].position.lng}} mapContainerClassName={classes.mapContainer}>
              <Polyline
                onLoad={onLoad}
                path={path}            
                options={options}
              />
              {data.mapData.map((info, index) => (
                  <Marker
                    key={index}
                    // icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
                    position={info.position}
                    label={`${index}`}
                    title={info.title}
                  />
              ))}            
        </GoogleMap> 
      </div>
    )
  }

  function getIcon(type) {
    // 1275_warehouse
    // in_transit
    // ESTATE
    // WAREHOUSE
    // negociant
    // merchant    
    if (type.toUpperCase()==="PRODUCED") {
      return (<AgricultureIcon />)
    }
    if (type.toUpperCase()==="ESTATE") {
      return (<FortIcon />)
    }    
    if (type.toUpperCase()==="IN_TRANSIT") {
      return (<LocalShippingIcon />)
    }
    if (type.toUpperCase()==="1275_WAREHOUSE") {
      return (<WarehouseIcon />)
    }
    if (type.toUpperCase()==="PURCHASED") {
      return (<SummarizeIcon />)
    }            
    if (type.toUpperCase()==="MERCHANT") {
      return (<StoreIcon />)
    }         
    if (type.toUpperCase()==="NEGOCIANT") {
      return (<StoreIcon />)
    }                   
    return (
      <PinDropIcon />
    )
  }
  
  function CustomizedTimeline() {
    return (
      <Timeline align="left">
              {data.timeline.map((info, index) => (
              <TimelineItem key={index}>
              {/* <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {info.date}
              </TimelineOppositeContent> */}
              <TimelineOppositeContent style={{ maxWidth: "1px", paddingLeft: '0px', paddingRight: '0px' }} />
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  {getIcon(info.type)}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Box
                 align="left"
                variant="body2"
                color="text.secondary"
              >
                {info.date}
              </Box>                
                <Typography variant="h6" component="span">
                {info.title}
                </Typography>
                <Typography>{info.description}</Typography>
              </TimelineContent>
            </TimelineItem>                
              ))}            

      </Timeline>
    );
  }


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  
  
  function displayLoading() {
    return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item xs={12}>
        <Skeleton variant="rect"  height={500}  />
      </Grid>
    </Grid>
    )
  }
  
  
  function displayError() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
        <Grid item >
          <Alert  severity="error" sx={{ width: '100%' }}>
          {error.message}
          </Alert>          
        </Grid>
      </Grid>
    );  
  }
  


  function displayDialogPicture() {
    if (data.case.urlThumbnail) {
      return (
        <div>
        <Button onClick={() => setDialogOpenPicture(true)}>
        <img alt="Case Thumbnail" src={data.case.urlThumbnail} />
        <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setDialogOpenPicture(true)}
            >
              <ZoomInIcon />
            </IconButton > 
            {data.case.certificationReference}     
        </Button>

        <Dialog
          open={dialogOpenPicture}
          onClose={() => setDialogOpenPicture(false)}
          fullWidth
          maxWidth='xl'
          >
            <DialogTitle >Case Picture</DialogTitle> 
            <DialogContent>
              <DialogContentText >
                {data.case.name}
              </DialogContentText>
                <InnerImageZoom src={data.case.url}   zoomSrc={data.case.url} />
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={() => setDialogOpenPicture(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>               
      </div>
      )
    }
    return ( <div> {data.case.certificationReference} </div>)      
  }   


  function displayItem() {

    return (
      <span>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          #{id} - {data.case.name}
          </Typography>
        </Stack>              
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">  
                  <Grid item xs={12}  md={4}>
                    {displayDialogPicture()}
                  </Grid>
                  <Grid item xs={12} md={4} >
                  Format Pack <strong>{data.case.packFormat}</strong>
                  </Grid> 
                  <Grid item xs={12} md={4} >
                  bottleSize <strong>{data.case.bottleSize} </strong>                                                        
                  </Grid>                     
                  <Grid item xs={12} md={4} >
                    Packtype <strong>{data.case.packType} </strong>                                                          
                  </Grid>                               
                  <Grid item xs={12} md={4} >
                    # Bottles <strong>{data.case.qtyBottlesInCase}</strong>
                  </Grid>                     
                  <Grid item xs={12} md={4} >
                    Year <strong>{data.case.year}</strong>
                  </Grid>                                                                                                              
                </Grid>              
                </CardContent>
              </Card >            
              
            </Grid>                                        
            <Grid item xs={4}>
              <Card >
              <CardHeader title="Case Timeline" />
                <CardContent >
                  {CustomizedTimeline()}                       
                </CardContent >
              </Card >                  
            </Grid>  
            <Grid item xs={8}>
              <Card >
                <CardHeader title="Case Journey" />
                <CardContent>                
                {displayGoogleMaps()}
                </CardContent>
              </Card >              
            </Grid>
             
            {data.listSensors.map((sensor, index) => (
                  <CardWarehouseTraceability
                    key={index}
                    // icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
                    data={sensor}
                    label={`${index}`}
                  />
              ))}                    
            {data.listTrackers.map((tracker, index) => (
                  <CardShippingTraceability
                    key={index}
                    // icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
                    data={tracker}
                    label={`${index}`}
                  />
              ))}                 
            <Grid item xs={12}>
            <Card >
                <CardHeader title="Disclaimer" />
                <CardContent>                
                <small>
                Case temperature and humidity is taken at the pallet level. True temperature and humidity of the wine is more stable due to the case isolation and liquid inertia<br/>
                  GPS data is showing an indication of case locations due to limitation of trackers technology<br/>
                  Supporting documents available on demand<br/>
                  Etc...<br/>
                </small>
                </CardContent>
              </Card >              
            </Grid>                               
          </Grid>
    </span>
    );
  }





  function displayPage(func) {
    return (
      <Page title="Report">
        <Container maxWidth="false">
          {func()}
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}    
      </Page>
    );
  }
  
  // PAGE LOGIC
  if (isLoading) {
    return displayPage(displayLoading);
  }
  if (!error) {   
    return displayPage(displayItem);
  }
  return displayPage(displayError);

}

