// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Chip, MenuItem, Select, Box, Grid, Container, Typography, Snackbar, Skeleton,  FormControl,  Alert, Button,  ButtonGroup, IconButton, Stack, Card, CardContent, AppBar, Link  } from '@mui/material';
// Checkbox,  FormControlLabel,
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DescriptionIcon from '@mui/icons-material/Description';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

// components
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import ButtonDialogUploadDocument from '../../components/stock/ButtonDialogUploadDocument';
import ButtonUploadPaymentTerms from '../../components/stock/ButtonUploadPaymentTerms';
import ButtonBackUpdatePOCase from '../../components/stock/ButtonBackUpdatePOCase';
import ButtonDialogPickSupplier from '../../components/stock/ButtonDialogPickSupplier';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}


export default function PurchaseItem() {
  const classes = useStyles();   
  const { id } = useParams();    
  const [itemId, setItemId] = useState(id);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [dialogOpenDocNew, setDialogOpenDocNew] = useState(false);
  const [dialogOpenDoc, setDialogOpenDoc] = useState(false);
  const [documentIdRow, setDocumentIdRow] = useState(null);
  
  const [dialogOpenBatchUpdate, setDialogOpenBatchUpdate] = useState(false);  
  const [dialogOpenPaymentNew, setDialogOpenPaymentNew] = useState(false);
  const [dialogOpenPayment, setDialogOpenPayment] = useState(false);
  const [paymentIdRow, setPaymentIdRow] = useState(null);

  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingButton,setIsLoadingButton] = useState(false);   
  

  function handleChange(event, newValue) {
    setValue(newValue);
  }


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }  
  const [purchaseAmount, setPurchaseAmoumt] = useState(0);  
  const [purchaseCases, setPurchaseCases] = useState(0); 
  const [purchaseBottles, setPurchaseBottles] = useState(0); 
  const [purchaseBottlesInCase, setPurchaseBottlesInCase] = useState(0); 
  function CustomToolbarCases() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
        <Chip label={`Amount: ${fCurrency(purchaseAmount)}`} variant="outlined" />
        <Chip label={`#Cases: ${purchaseCases}`} variant="outlined" />
        <Chip label={`#Bottles: ${purchaseBottlesInCase} - PackSizeTotal: ${purchaseBottles}`} variant="outlined" />
      </GridToolbarContainer>
    );
  }  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/purchase/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/purchase-param`, "get", null);
    };
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
    } 
  }, [id]);
  useEffect(() => {
    if (data) {
      const totalCases = data.cases.length;
      const totalAmount = data.cases.map(item => item.bookValue ).reduce((prev, curr) => prev + curr, 0);
      const totalBottles = data.cases.map(item => Number(item.packSize)).reduce((prev, curr) => prev + curr, 0);
      const totalBottlesInCase = data.cases.map(item => Number(item.qtyBottlesInCase)).reduce((prev, curr) => prev + curr, 0);
      setPurchaseAmoumt(totalAmount)
      setPurchaseCases(totalCases)
      setPurchaseBottles(totalBottles)
      setPurchaseBottlesInCase(totalBottlesInCase)
    }
    return 0    
  }, [data]);



  const columnsPayments =  [
    { field: 'id', headerName: ' ', width:75
    , renderCell: (params) => (
      <LoadingButton    color="primary"  onClick={() => handleOpenDialogPayment(params.value)}><ZoomInIcon />{params.value}</LoadingButton>
    )
  },
  { field: 'paymentDetails', headerName: 'paymentDetails', width:200  },  
    { field: 'paymentDeadline', headerName: 'paymentDeadline', width:200 },
    { field: 'percentageTotal', headerName: 'percentageTotal', width:200  },
    { field: 'amount', headerName: 'amount', width:200  },
    { field: 'amountCurrency', headerName: 'amountCurrency', width:200  },
    { field: 'amountEuro', headerName: 'amountEuro', width:200  },      
    { field: 'paidOn', headerName: 'paidOn', width:200  },      
  ];



  const columnsCases =  [
    { field: 'id', headerName: 'caseId', width:80
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/case/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >
    )
  },
    { field: 'name', headerName: 'Case', width: 300 },
    { field: 'packSize', headerName: 'packSize', width:150  },
    { field: 'qtyBottlesInCase', headerName: '#Bottles', width:150  },
  
    { field: 'packType', headerName: 'packType', width:150  },
    { field: 'bottleSize', headerName: 'bottleSize', width:150  },
    { field: 'location', headerName: 'location', width:200  },      
    { field: 'orderReference', headerName: 'orderReference', width:200  },
    { field: 'status', headerName: 'status', width:200  },

    { field: 'externalId', headerName: 'Purchase allocated to', width:200  },
    { field: 'ownershipStatus', headerName: 'ownership', width:200  },
    { field: 'dateAllocation', headerName: 'dateAllocation', width:200  },
    { field: 'bookValue', headerName: 'bookValue', width:200  , renderCell: (params) =>  fCurrency(params.value)  },
    { field: 'exitValue', headerName: 'exitValue', width:200  , renderCell: (params) =>  fCurrency(params.value)  },
    { field: 'dateExit', headerName: 'dateExit', width:200  },
  ];


const columnsDocuments =  [
  { field: 'id', headerName: ' ', width:75
  , renderCell: (params) => (
    <LoadingButton    color="primary"  onClick={() => handleOpenDialogDoc(params.value)}><ZoomInIcon />{params.value}</LoadingButton>
  )
},
{ field: 'url', headerName: 'Doc', width:30, renderCell: (params) => {
  if (params.value) {
    return (
      <Link
        target="_blank"
        href={params.value}
      >
        <DescriptionIcon />
      </Link>                 
    )
  }
  return "-"
}}, 
{ field: 'isInvoice', headerName: 'Invoice' ,width:100},   
{ field: 'name', headerName: 'document' ,width:300},
{ field: 'description', headerName: 'description',width:300},       
{ field: 'admin', headerName: 'admin', width:200},      
{ field: 'fileDate', headerName: 'Date File', width:200  },

{ field: 'invoicePaymentDeadline', headerName: 'invoicePaymentDeadline', width:200  },
{ field: 'invoiceAmount', headerName: 'invoiceAmount', width:200  },
{ field: 'invoiceAmountCurrency', headerName: 'invoiceAmountCurrency', width:200  },
{ field: 'invoiceAmountEuro', headerName: 'invoiceAmountEuro', width:200  },

{ field: 'createdAt', headerName: 'createdAt', width:200  },
{ field: 'updatedAt', headerName: 'updatedAt', width:200  }
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  


const uploadItem = async () => {
  setIsLoadingButton(true);      
  let url = '/purchase/';
  if (itemId !=="new") {
    url += itemId;
  } 

  let res = null;
  if (itemId ==="new") {
    res = await fetchDataAwait(url, "post", data.purchase);
  }
  else {
    res = await fetchDataAwait(url, "put", data.purchase);
  }
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    setData(res.data);
    if (itemId ==="new") {
      setItemId(res.data.purchase.id);
    }
    setConfirmationMessage("Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}

const handleSaveShippingClick  = async () => {
  setIsLoadingButton(true);      
  const url = `/purchase/shipping/${itemId}`;
  const res = await fetchDataAwait(url, "post", {...data.shipping});
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    data.shipping = res.data.shipping
    setData({...data});
    setConfirmationMessage("Succesfully saved shipping terms.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}


const refreshPurchaseItem = async (param) => {
  setIsLoadingButton(true);      
  let url = '/purchase/';
  if (itemId !=="new") {
    url += itemId;
  } 

  const res = await fetchDataAwait(url, "get", null);

  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    data[param] = res.data[param]
    setData({...data});
  }   
  setIsLoadingButton(false);      

}


const handleSaveClick = () => {
  uploadItem(); 
}



const setPurchaseData = (field,value) => {
  data.purchase[field] = value
  setData({...data});
}


const handleAddShippingData = (field,value) => {
  data.shipping[field] = value
  setData({...data});
}


const handleCancelClick = () => { 
  setData({...data});
  setIsDisabled(true);  
}




const handleCurrencySelected = (event) => {
  setPurchaseData("orderValuePreliminaryCurrency",event.target.value);
};  


const handleOpenDialogDocNew = () => {
  setDialogOpenDocNew(true);  
} 

const handleOpenDialogDoc = (param) => {
  setDocumentIdRow(param); 
  setDialogOpenDoc(true);  
}  

const handleOpenDialogPaymentNew = () => {
  setDialogOpenPaymentNew(true);  
} 

const handleOpenDialogPayment = (param) => {
  setPaymentIdRow(param); 
  setDialogOpenPayment(true);  
}  

function displayPanels() {
  if (itemId!=="new") {
    return (
      <Grid item xs={12}>
        <Card >
          <CardContentNoPadding >            
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Documents" {...a11yProps(0)} />
          <Tab label="Payments" {...a11yProps(1)} />
          <Tab label="Shipping" {...a11yProps(2)} />
          <Tab label="Cases" {...a11yProps(3)} />          

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
          <ButtonDialogUploadDocument 
                documentId={null} 
                purchaseId={itemId} 
                callBackFunction={() => refreshPurchaseItem("documents")} 
                setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage} 
                dialogOpen={dialogOpenDocNew} 
                setDialogOpen={setDialogOpenDocNew} 
                />    
          <LoadingButton  variant="contained"  color="primary"  onClick={() => handleOpenDialogDocNew()}><InsertDriveFileIcon/>Upload Doc</LoadingButton>

          <ButtonDialogUploadDocument 
                documentId={documentIdRow} 
                purchaseId={itemId} 
                callBackFunction={() => refreshPurchaseItem("documents")} 
                setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage} 
                dialogOpen={dialogOpenDoc} 
                setDialogOpen={setDialogOpenDoc} 
                /> 

          <DataGridPro
            autoHeight 
            pagination
            rows={data.documents} 
            columns= {columnsDocuments}
            
            loading={isLoading}
            components={{
              Toolbar: CustomToolbar,
            }}         
          />
      </TabPanel>
      <TabPanel value={value} index={1}>

          <ButtonUploadPaymentTerms 
                paymentId={null} 
                purchaseId={itemId} 
                callBackFunction={() => refreshPurchaseItem("payments")} 
                setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage} 
                dialogOpen={dialogOpenPaymentNew} 
                setDialogOpen={setDialogOpenPaymentNew} 
                />    
          <LoadingButton  variant="contained"  color="primary"  onClick={() => handleOpenDialogPaymentNew()}><InsertDriveFileIcon/>Add Payment scheduled/confirmed</LoadingButton>



          <DataGridPro
            autoHeight 
            pagination
            rows={data.payments} 
            columns= {columnsPayments}
            
            loading={isLoading}
            components={{
              Toolbar: CustomToolbar,
            }}         
          />
      </TabPanel>
      <TabPanel value={value} index={2}>
          Details of expected Shipping
          <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <TextField 
                        onChange={(e)=> handleAddShippingData("supplierTerms",e.target.value)}
                        className={classes.field} 
                        label="Supplier Terms"
                        fullWidth multiline rows={4}    
                        value={data.shipping.supplierTerms}

                    />                      
                  </Grid>   

                  <Grid item xs={12} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Supplier Deadline"
                        inputFormat="dd/MM/yyyy"
                        value={data.shipping.supplierShippingDeadline}
                        onChange={(e)=> handleAddShippingData("supplierShippingDeadline",e)}
                        renderInput={(params) => <TextField {...params} />}
                      />        
                      </LocalizationProvider>                        
                  </Grid>    

                  <Grid item xs={12}  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="1275 Expected Shipping"
                        inputFormat="dd/MM/yyyy"
                        value={data.shipping.expectedShippingDate1275}
                        onChange={(e)=> handleAddShippingData("expectedShippingDate1275",e)}
                        renderInput={(params) => <TextField {...params} />}
                      />        
                      </LocalizationProvider>                        
                  </Grid> 
                  <Grid item xs={12} >
                    <LoadingButton
                      variant="contained"
                      loading={isLoadingButton}
                      onClick={() => handleSaveShippingClick()}
                    >
                      <SaveIcon/>
                      Save Shipping Terms
                    </LoadingButton>
                  </Grid>
            </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        To create new cases based on this purchase, go to the <Button onClick={() => navigate(`/purchase/add-cases/purchase/${itemId}`)} >Add New Cases</Button>
        <DataGridPro
          autoHeight 
          pagination
          rows={data.cases} 
          columns= {columnsCases}
          
          loading={isLoading}
          components={{
            Toolbar: CustomToolbarCases,
          }}         
        />                
      </TabPanel>   
      </CardContentNoPadding >
        </Card >                  
      </Grid>
    )
  }
  return ""
}


function displayActionButtons() {
  if (isLoading || !data) {
    return ""
  }
  if (isDisabled) {
    const buttonJsx ="";
    // if ( data.purchase.id && data.purchase.id !=="new") {
    //   buttonJsx = (
    //     <span >     
    //       <LoadingButton
    //         variant="contained"  color="primary" loading={isLoading}
    //         id="basic-button"
    //         aria-controls="basic-menu"
    //         aria-haspopup="true"
    //         aria-expanded={openMenu ? 'true' : undefined}
    //         onClick={handleClickMenu}
    //       >
    //         <ListIcon/>Actions
    //       </LoadingButton>          
    //     </span> )  
    // }
    return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between" >       
      <Button
        variant="contained"
        onClick={() => setIsDisabled(false)}
      >
        <EditIcon/>
        Edit
      </Button>
 
      {buttonJsx}
    </Stack>        
    )
  }

  return (    
  <ButtonGroup>
    <LoadingButton
      variant="contained"
      loading={isLoadingButton}
      onClick={() => handleSaveClick()}
    >
      <SaveIcon/>
      Save
    </LoadingButton>
    <Button
      variant="contained"
      onClick={() => handleCancelClick(true)}
    >
      <CancelIcon/>
      Cancel
    </Button>        
</ButtonGroup>
)
}



  if (isLoading) {
    return (
      <Page title="Purchase">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Purchase</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error && data) {   
    return (
      <Page title="Purchase">
        <Container maxWidth="false">

          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Purchase</Typography>                              
                {displayActionButtons()}                                       
          </Stack>                          
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Purchase Name</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setPurchaseData("name",e.target.value)}
                          className={classes.field} 
                          id="p-name" 
                          fullWidth required
                          value={data.purchase.name}
                          disabled={isDisabled}
                      />                      
                    </Grid>  
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Supplier</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          className={classes.field} 
                          id="Supplier" 
                          label="Supplier" 
                          fullWidth required
                          value={data.purchase.supplier}
                          disabled
                          InputLabelProps={{ shrink: true }}
                      />
                      <ButtonDialogPickSupplier itemName={data.purchase.name}  supplierName={data.purchase.supplier} callbackItem={setPurchaseData} isDisabled={isDisabled} />              
                    </Grid>                                                                             
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Description</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setPurchaseData("description",e.target.value)}
                          className={classes.field} 
                          id="p-description" 
                          fullWidth required
                          value={data.purchase.description}
                          disabled={isDisabled}
                          multiline
                          rows={4}                          
                      />                         
                    </Grid> 
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Expected Amount</Typography>
                      <small>Initial amount recorded </small>
                    </Grid>  
                    <Grid item xs={7} md={3} >
                      <TextField 
                          onChange={(e)=> setPurchaseData("orderValuePreliminary",e.target.value)}
                          className={classes.field} 
                          label="Amount Local Currency"
                          id="p-amount" 
                          fullWidth required
                          value={data.purchase.orderValuePreliminary}
                          disabled={isDisabled}
                      />                      
                    </Grid>   
                    <Grid item xs={5} md={3} >
                      <FormControl> 
                        <Select style={{width: 150}}
                          autoWidth	
                          label="Currency" 
                          labelId="select-currency"
                          id="select-currency"
                          value={data.purchase.orderValuePreliminaryCurrency}
                          onChange={handleCurrencySelected}
                          disabled={isDisabled}
                        >
                          {data.currencies.map((item,i) => (
                              <MenuItem value={item} key={i}>{item}</MenuItem>
                          ))}
                        </Select> 
                      </FormControl>                   
                    </Grid>
                    <Grid item xs={5} md={3} >
                      <TextField 
                          onChange={(e)=> setPurchaseData("orderValueBookedInDatabaseEUR",e.target.value)}
                          className={classes.field} 
                          label="Amount EUR"
                          id="p-amount" 
                          fullWidth required
                          value={data.purchase.orderValueBookedInDatabaseEUR}
                          disabled={isDisabled}
                      />                      
                    </Grid>                       
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Batch update field</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                    <ButtonBackUpdatePOCase 
                      purchaseId={itemId} 
                      setConfirmationMessage={setConfirmationMessage} 
                      setErrorMessage={setErrorMessage} 
                      dialogOpen={dialogOpenBatchUpdate} 
                      setDialogOpen={setDialogOpenBatchUpdate} 
                      />           
                    <LoadingButton  variant="contained"  color="primary"  onClick={() => setDialogOpenBatchUpdate(true)}>Update cases/bottles fields</LoadingButton>

                         
                    </Grid>
                    {/* <Grid item xs={12} md={3} >
                      <Typography variant="h6">Upload Document</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} >
                      <ButtonDialogUploadDocument userId={id} callBackFunction={() => console.log()} />                         
                    </Grid>                                                                                                         */}
                    <Grid item xs={12} >
                      <small> created at {data.purchase.createdAt} | updated at {data.purchase.updatedAt} </small> 
                    </Grid>                                                                                                                                                                                                     
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>  
            {displayPanels()}                                      

          </Grid>
        </Container>
        {snackbarError()}
        {snackbarSuccess()}
      </Page>
    );
  }
  return (
    <Page title="Purchase">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Purchase</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

