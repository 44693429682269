
import {  Button, Snackbar, Alert  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';


export default function ButtonDialogPickTransporter(props) {
  const {itemName, callbackItem, supplierName, isDisabled} = props;
  ButtonDialogPickTransporter.propTypes = {
    itemName: PropTypes.string.isRequired,
    supplierName: PropTypes.string,
    callbackItem: PropTypes.func.isRequired,    
    isDisabled: PropTypes.bool.isRequired,
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataGrid, setDataGrid] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const columns = [
    { field: 'id', headerName: 'id', width:200
      , renderCell: (params) =>  (
      <Button  variant="contained" onClick={() => updateSupplier(params.value)} color="primary" autoFocus>
        Select
      </Button>
      )
    },      
    { field: 'name', headerName: 'Supplier', width:200 },
    { field: 'type', headerName: 'Type', width:200 },
    { field: 'country', headerName: 'Country', width:200  },
    { field: 'region', headerName: 'Region', width:200  },
    { field: 'contactInfo', headerName: 'contactInfo', width:200  },
  ];

  const updateSupplierList = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait("/suppliers", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDataGrid(res.data.data);
    setIsLoading(false);  
    return true    
  }

  const updateSupplier = async (supplierId) => {
    callbackItem("transporterId",  supplierId);
    callbackItem("transporter",  dataGrid.find(obj => obj.id === supplierId).name);    
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  

  const handleOpenDialog = () => {
    setDialogOpen(true);
    updateSupplierList();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  let jsxWarning = "";
  if (supplierName) {
    jsxWarning = (<Alert severity="warning">Transporter: "{supplierName}" already selected</Alert>
    )
  }  

  return (
    <span>
      <LoadingButton  disabled={isDisabled}  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Update Transporter</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Select Transporter</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <LoadingButton  disabled={isDisabled}  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Refresh List</LoadingButton>
               {jsxWarning}
                <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={dataGrid}
                columns= {columns}
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
          {snackbarError()}      
        {snackbarSuccess()}           
        </Dialog>        

    </span>
    )
}