
import {  Button  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

import { useState } from 'react';
import {   useNavigate } from 'react-router-dom';
import { fCurrency } from '../../utils/formatNumber';



export default function ButtonDialogSelectClientForTransactions(props) {
  const navigate = useNavigate();  
  const {roundId, client, clientSelection } = props;
  ButtonDialogSelectClientForTransactions.propTypes = {
    clientSelection:  PropTypes.array.isRequired, 
    roundId:  PropTypes.number.isRequired, 
    client: PropTypes.object.isRequired
  };   
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientFiltered, setClientFiltered] = useState([]);


  const columnsClient = [
    { field: 'id', headerName: 'id', width:150
    , renderCell: (params) =>  (
    <div>
      <Button  variant="contained" onClick={() => pushToTransactions(params.value)}>
                CREATE
      </Button>
    </div>
    )
  }, 
    { field: 'name', headerName: 'Name', width:300
      , renderCell: (params) =>  (
      <div>
        <strong>{params.row.id}</strong> - <small>{params.row.externalId}</small>
      </div>
      )
    } 
  ];



  const pushToTransactions= (id) => {
    navigate('/allocation/transaction', { state: {roundId, clientId: id} }); 
  }    


  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }


  const handleButtonClick = () => {
    setClientFiltered(client.filter((el) =>  clientSelection.includes(el.id) )); 
    setDialogOpen(true);  

  }      
      
  return (
    <span>
      <LoadingButton  variant="contained"  color="primary" loading={isLoading}  onClick={handleButtonClick}><DeleteIcon/>Create Client Transactions</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Select Client to create Transactions</DialogTitle> 
          WARNING: Make sure you have saved the allocation first <br/>
          Any quantity at 0 will be ignored<br/>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={clientFiltered} 
                columns= {columnsClient} 
                pageSize={25}
                loading={isLoading}

                />
                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>              
    </span>
    )
}