// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Stack,   Button  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';


// ----------------------------------------------------------------------


export default function Estate() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/estates", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (

        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/estate/${params.value}`)}
        >
          <ZoomInIcon />
        </IconButton >        
 
    )
  },
    { field: 'name', headerName: 'Estate', width:200 },
    { field: 'country', headerName: 'Country', width:200  },
    { field: 'region', headerName: 'Region', width:200  },
    { field: 'subRegion', headerName: 'subRegion', width:200  },
    { field: 'latitude', headerName: 'latitude', width:200  },      
    { field: 'longitude', headerName: 'longitude', width:200  },      
    { field: 'createdAt', headerName: 'created', width:200  },
    { field: 'updatedAt', headerName: 'updated', width:200  }
];


  if (isLoading) {
    return (
      <Page title="Estates">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Estates</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    console.log("data")
    console.log(data)
    return (
      <Page title="Estates">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Estates
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => navigate(`/stock/estate/new`)}
          >
            New
          </Button>
        </Stack>          
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item xs={12}>
       
       <DataGridPro
         pagination
         autoHeight 
         rows={data.data} // {rows} //
         columns= {columns} //  {data.columns} //
         
         loading={isLoading}
         components={{
          Toolbar: CustomToolbar,
        }}         
       />
     </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Estates">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Estates</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



