// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Chip  } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// components
import {useNavigate,useParams} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';

// ----------------------------------------------------------------------
// const options = {
//   credits: {
//     enabled: false
//   },  
//   title: {
//     text: 'Sensor XX'
//   },
//   series: [{
//     name: 'AAPL',
//     data: [],
//     tooltip: {
//       valueDecimals: 2
//     }
//   }]
// }



export default function SensorItem() {
  const { id } = useParams();     
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/sensor/${id}`, "get", null);
    };
    fetchDataReactScreen();
  }, [id]);  


  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (

        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/case/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        

    )
  },
    { field: 'name', headerName: 'Case', width:200 },
    { field: 'packSize', headerName: 'packSize', width:200  },
    { field: 'packType', headerName: 'packType', width:200  },
    { field: 'bottleSize', headerName: 'bottleSize', width:200  },
    { field: 'lwin', headerName: 'lwin', width:200  },      
    { field: 'location', headerName: 'location', width:200  },      
    { field: 'orderReference', headerName: 'orderReference', width:200  },
    { field: 'status', headerName: 'status', width:200  }
];


  if (isLoading) {
    return (
      <Page title="Sensors">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Sensor</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Sensors">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Sensor {data.data.code}</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            <Grid container item xs={12} spacing={3}>                           
              <Grid  item xs={12} >
                <Typography variant="h5">{data.data.name} - ref: {data.data.externalReference}</Typography>
              </Grid>
              {data.sensors.map((item,i) => (
                <Grid key={i} item xs={12} md={6} >
                  <Card >
                    <CardHeader title={`${item.sensor.name} - ref: ${item.sensor.externalReference}`}
                    subheader={item.sensor.type} />
                    <CardContent>
                      Last Reading:  <Chip label={`${item.lastReading.value}${item.sensor.unit}`} color="primary" /> <small>on {item.lastReading.date}</small>
                      <HighchartsReact
                        constructorType='stockChart'
                        highcharts={Highcharts}
                        options={{
                          credits: {
                            enabled: false
                          },  
                          title: {
                            text: `${item.sensor.type} (${item.sensor.unit})`
                          },
                          series: [{
                            name:  `${item.sensor.type} sensor`,
                            data: item.readings,
                            tooltip: {
                              valueDecimals: 2
                            }
                          }]
                        }}
                      />
                    </CardContent>
                  </Card >
              </Grid>  
                ))}                                        
            </Grid>            
            <Grid item xs={12}>
            <Box sx={{ pb: 5 }}>
              <Typography variant="h6">Cases linked to sensor {data.data.code}</Typography>
            </Box>            
            <DataGridPro
              autoHeight 
              pagination
              rows={data.cases} 
              columns= {columns}
              
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
              }}         
            />
            </Grid>
          </Grid>   
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Sensors">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Sensor</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



