// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Button } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from '@mui/icons-material/Add';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';


// ----------------------------------------------------------------------


export default function ShippingList() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/shippings", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
    { field: 'id', headerName: ' ', width:80
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/shipping/list/${params.value}`)}
        >         
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
    { field: 'name', headerName: 'name', width:200 },
    { field: 'description', headerName: 'description', width:200 },
    { field: 'supplier', headerName: 'Supplier', width:300 },
    { field: 'transporter', headerName: 'Transporter', width:300 },
    { field: 'startShipping', headerName: 'Shipped On', width:200 },
    { field: 'endShipping', headerName: 'End Shipping', width:200 },
    { field: 'destinationPosition', headerName: 'destination', width:150 },
    { field: 'number1195', headerName: '11-95', width:150 },
    { field: 'customsDocument', headerName: 'customsDoc', width:150 },
    { field: 'createdAt', headerName: 'createdAt', width:150 },
    { field: 'updatedAt', headerName: 'updatedAt', minWidth: 150, flex:1  },    
];


  if (isLoading) {
    return (
      <Page title="Shipping">
        <Container maxWidth="false">         
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Shipping</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    console.log("data")
    console.log(data)
    return (
      <Page title="Shipping">
        <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Shipping
              </Typography>
              <Button
                variant="contained"
                onClick={() => navigate(`/shipping/list/new`)}
              >
                <AddIcon/>
                New
              </Button>
          </Stack>            
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <DataGridPro
                autoHeight 
                pagination
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Shipping List">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Shipping List</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



