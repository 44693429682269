// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Alert, Snackbar, FormControlLabel, FormControl, TextField, Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Card, CardContent, Button, AppBar, Link, ButtonGroup  } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// components
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';

// ----------------------------------------------------------------------



function CustomToolbar() {
  return (
    <GridToolbarContainer>            
      <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
    </GridToolbarContainer>
  );
}  


export default function ManagementFeeItem() {
  const { id } = useParams();    
  const navigate = useNavigate();

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [checkboxSelection, setCheckboxSelection] = useState(true);
  const [selection, setSelection] = useState([]);

  const [isLoadingButton,setIsLoadingButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   
  const [dialogOpen, setDialogOpen] = useState(false); 
  const [generateInvoice, setGenerateInvoice] = useState("yes"); 

  
  const handleChangeRadio = (event) => {
    setGenerateInvoice(event.target.value)
  };  

  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/get-management-fee-calculation/${id}`, "get", null);
    };
    fetchDataReactScreen();
  }, [id]);  
  

  const columnsMangementFees =  [
    { field: 'id', headerName: 'Id', width:20
    , renderCell: (params) => (
      <strong>
        {params.value}        
      </strong>
    )
  },
    { field: 'externalId', headerName: 'Admin', width:200 , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/client/list/${params.row.userId}`)}
        >
          <ZoomInIcon />
        </IconButton >  
        {params.value}       
      </strong>
    ) },    
    { field: 'VATonFee', headerName: 'VATonFee', width:200 },
    
    { field: 'VATonFee', headerName: 'VATonFee', width:200 , renderCell: (params) =>  {
      if (params.value===1) {
        return "YES"
      }
      return "-"
  }  },
    { field: 'averagePortfolioValue', headerName: 'averagePortfolioValue', width:200  , renderCell: (params) =>  fCurrency(params.value) },
    { field: 'calculationData', headerName: 'calculationData', width:300 },
    { field: 'status', headerName: 'status', width:200 },
    { field: 'storageFee', headerName: 'Mgnt Fee', width:200 , renderCell: (params) =>  fCurrency(params.value) },
    { field: 'storageFeeCollectionBasis', headerName: 'Based on', width:200 },
    { field: 'transactionId', headerName: 'transactionId', width:200, renderCell: (params) => {
      if (params.value) {
        return (
          <span>
            <Button variant="text" onClick={() => navigate(`/client/transaction/${params.value}`)}>
            id {params.value}
            </Button>               
          </span>
        )
      }
      return (
        <span>
          -
       </span>
      )
  }}, 
  ];





  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  


  const dialogGenerateTransactions = () =>  (
    <Dialog
    open={dialogOpen}
    onClose={()=> setDialogOpen(false)}
    aria-labelledby="alert-dialog-reset"
    aria-describedby="alert-dialog-reset-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title">Generate Transactions</DialogTitle> 
      <DialogContent>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">  
          <Grid item xs={12} md={12}>
            Are you sure you want to generate the selected transactions?
          </Grid> 
          <Grid item xs={12} md={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Generate Invoices?</FormLabel>
              <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                      value={generateInvoice}
                      onChange={handleChangeRadio}
                    >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>  
        </Grid>       
      </DialogContent>
      <DialogActions>
      <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleTransactionsGeneration()} color="primary">
          Yes
        </LoadingButton>        
        <Button variant="text" onClick={()=> setDialogOpen(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleTransactionsGeneration = async () => {
    setIsLoadingButton(true);
    const url = `/create-mgnt-fee-transactions`;
    // console.log(selection);
    const res = await fetchDataAwait(url, "post", {transactions:selection, generateInvoice} );
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      setDialogOpen(false);
    }
    setIsLoadingButton(false);
    return 0;
  }  


  if (isLoading) {
    return (
      <Page title="Management Fee">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Management Fee - {id}</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Management Fees">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Management Fee - {id}</Typography>                                                                   
          </Stack>          
            
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Generated At</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      {data.calculation.createdAt}
                    </Grid>        
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Start Period (excluding)</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      {data.calculation.startDate}
                    </Grid>            
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">End Period</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                        {data.calculation.endDate}
                    </Grid> 
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">CSV File</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                    <Link href={data.calculation.url}>Calculation File</Link> 
                    </Grid>  
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Status</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} >
                      {data.calculation.status}
                    </Grid>
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>       

            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">   
                    <Grid item xs={12}  >
                      <LoadingButton  variant="contained" onClick={() => setDialogOpen(true)}>
                      Generate Invoices for selected Clients  
                      </LoadingButton>                      
                    </Grid>      
                    <Grid item xs={12}  >
                      <DataGridPro
                        checkboxSelection={checkboxSelection} 
                        autoHeight 
                        pagination
                        rows={data.clients} 
                        columns= {columnsMangementFees}
                        onSelectionModelChange={(newSelection) => {
                          setSelection(newSelection);
                        }}
                        loading={isLoading}
                        components={{
                          Toolbar: CustomToolbar,
                        }}         
                      />
                    </Grid>
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>             

          </Grid>
        </Container>
        {dialogGenerateTransactions()}
        {snackbarError()}   
        {snackbarSuccess()}  
      </Page>
    );
  }
  return (
    <Page title="Management Fees">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Management Fee</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

