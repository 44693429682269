// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Button,  ButtonGroup, Snackbar,Select, FormControl, InputLabel, MenuItem, Alert, Stack, Card, CardContent  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

// components
import {useNavigate, useParams} from 'react-router-dom';
import { useState, useEffect } from 'react';
import ButtonAddNewVintage from '../../components/stock/ButtonAddNewVintage';
import ButtonDialogPickEstate from '../../components/stock/ButtonDialogPickEstate';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

// ----------------------------------------------------------------------


export default function WineItem() {
  const classes = useStyles();  
  const { id } = useParams();      
  const [itemId, setItemId] = useState(id);
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   
  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [formError, setFormError] = useState({});
  const [isLoadingButton,setIsLoadingButton] = useState(false); 
  // Grapes
  const [addDialogOpen,setAddDialogOpen] = useState(false);
  const [selectedGrapeId,setSelectedGrapeId] = useState(null);
  const columnsWineGrapes = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.value);}}
        >
          <DeleteIcon />
        </IconButton >        
      </strong>
    )
  },    
  { field: 'name', headerName: 'Name', width:200   },  
  { field: 'blendPercent', headerName: 'Blend Percent (%)', cellClassName: 'editable-theme--cell', width:200, type: 'number' , editable: true  },
  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/wine/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/wine-param`, "get", null);
    };      
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
      // const params = fetchParams()
      // if (params.error) {
      //   console.log("ERROR")
      //   // setErrorMessage(params.error.message);
      //   return 0
      // }
      // console.log(params)     
      // setData({
      //   "wine": {
      //     "name": null,
      //     "lwin": null,
      //     "country": null,
      //     "region": null,
      //     "subRegion": null,
      //     "wineColourId": null,
      //     "wineColour": null,
      //     "estate": null,
      //     "estateId": null,
      //     "displayLocation": null,
      //     "displayWineLine1": null,
      //     "displayWineLine2": null,
      //     "preDrinkingYears": null,
      //     "matureDrinkingYears": null,       
      //     "organicStatus": null,
      //     "productionBottlesAnnual": null,                       
      //   },
      //   "vintages":[],
      //   "wineGrapes":[],
      //   "grapes":params.grapes,
      //   "wineColours":params.wineColours,        
      // });
      // setIsDisabled(false);
      // setIsLoading(false);   
      // console.log("DONE")   
    }
    setFormError({
        "name": false,
        "lwin": false,
        "country": false,
        "region": false,
        "subRegion": false,
        "wineColourId": false,
        "wineColour": false,
        "estate": false,
        "estateId": false,
        "displayLocation": false,
        "displayWineLine1": false,
        "displayWineLine2": false,
        "preDrinkingYears": false,
        "matureDrinkingYears": false,       
        "organicStatus": false,
        "productionBottlesAnnual": false      
    });
    return 0    
  }, [id]);


  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/vintage/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
  { field: 'name', headerName: 'Vintage', width:300 },
  { field: 'year', headerName: 'Year', width:200   },
  { field: 'lwin', headerName: 'lwin', width:200   }
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

function displayLoading() {
  return (
  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
    <Grid item xs={12}>
      <Skeleton variant="rect"  height={500}  />
    </Grid>
  </Grid>
  )
}


function displayError() {
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item >
        <Alert  severity="error" sx={{ width: '100%' }}>
        {error.message}
        </Alert>          
      </Grid>
    </Grid>
  );  
}

const uploadItem = async () => {
  setIsLoadingButton(true);      
  let url = '/wine/';
  if (itemId !=="new") {
    url += itemId;
  } 

  let res = null;
  if (itemId ==="new") {
    res = await fetchDataAwait(url, "post",  {wine: data.wine, wineGrapes: data.wineGrapes });
  }
  else {
    res = await fetchDataAwait(url, "put", {wine: data.wine, wineGrapes: data.wineGrapes });
  }
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    setData(res.data);
    if (itemId ==="new") {
      setItemId(res.data.wine.id);
    }
    setConfirmationMessage("Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}


const handleSaveClick = () => {
  uploadItem(); 
}


const setWineData = (field,value) => {
  data.wine[field] = value
  setData({...data});
}


const handleSelectedColourId = (event) => {
  data.wine.wineColourId = event.target.value
  data.wine.wineColour = data.wineColours.find(obj => obj.id === event.target.value).name 
  setData({...data});
};



const handleCancelClick = () => { 
  setData({...data});

  setIsDisabled(true);  
}

function displayActionButtons() {

  if (isLoading || !data) {
    return ""
  }

    if (isDisabled) {
      let buttonJsx ="";
      if ( data.wine.id) {
        buttonJsx = <ButtonAddNewVintage wineId={itemId} />  
      }
      return (
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" >
        <Button
          variant="contained"
          onClick={() => setIsDisabled(false)}
        >
          <EditIcon/>
          Edit
        </Button>
        {buttonJsx}
      </Stack>        
      )
    }

    return (    
    <ButtonGroup>
      <LoadingButton
        variant="contained"
        loading={isLoadingButton}
        onClick={() => handleSaveClick()}
      >
        <SaveIcon/>
        Save
      </LoadingButton>
      <Button
        variant="contained"
        onClick={() => handleCancelClick(true)}
      >
        <CancelIcon/>
        Cancel
      </Button>        
  </ButtonGroup>
  )
}


function displayPage(func) {
  return (
    <Page title="Wine">
      <Container maxWidth="false">
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Wine
          </Typography>
          {displayActionButtons()}
        </Stack>        
        {func()}
      </Container>
      {snackbarError()}   
      {snackbarSuccess()}    
    </Page>
  );
}



const handleRemoveRow = (rowId) => {
  const filteredWineGrapes = data.wineGrapes.filter((el) =>  el.id !== rowId); 
  setData({...data, wineGrapes: filteredWineGrapes});
}
const handleCellEditCommit = ({ id, field, value }) => {
  const updatedRows = data.wineGrapes.map((row) => {
    if (row.id === id) {
      row[field] = value;
      return row;
    }
    return row;
  });
  setData({...data, wineGrapes: updatedRows});
};

const handleSelectedGrapeId = (event) => {
  setSelectedGrapeId(event.target.value);
};
const handleCloseAddDialog = () => {
  setAddDialogOpen(false);
  }
const handleCloseAddDialogConfirmed = () => {
  if ( selectedGrapeId === '' ) {
    setErrorMessage("Select a grape");
    return false;
  }  
  // Check if row already exists
 if (data.wineGrapes.some((el) => 
    el.id === selectedGrapeId
  )) {
    setErrorMessage("This grape is already defined.");
    return false;
  }
  // Add to existing Composition
  const newRow = {
    id: selectedGrapeId,
    name: data.grapes.find(obj => obj.id === selectedGrapeId).name,
    blendPercent:0,
  }
  setData({...data, wineGrapes: [...data.wineGrapes,newRow]});
  setAddDialogOpen(false);

  return true;
}

function defineGrapeList() {
  return (

    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="left">
      <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">
        <Grid container item xs={12} >
            <ButtonGroup variant="contained"  color="primary" aria-label="contained primary button group">
              <Button  onClick={()=> setAddDialogOpen(true)} disabled={isDisabled}><AddIcon/>Add Grape</Button>
              <Dialog
                open={addDialogOpen}
                onClose={handleCloseAddDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Add a Grape</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Grid  item xs={12} sm={6} md={3}>
                          Grape
                        <Select style={{width: 300}}
                            autoWidth	
                            labelId="select-grape"
                            id="select-grape"
                            onChange={handleSelectedGrapeId}
                          >
                            {data.grapes.map((item,i) => (
                                <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                            ))}
                        </Select>  
                      </Grid>                      
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="text" onClick={handleCloseAddDialog} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleCloseAddDialogConfirmed} color="primary" autoFocus>
                      ADD
                    </Button>
                  </DialogActions>
                </Dialog>              
          </ButtonGroup>         
        </Grid>               
      </Grid>
        <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left"  className={classes.root} md={6}>  
          <DataGridPro 
          overflow="auto"
          disableExtendRowFullWidth
          autoHeight 
          rows={data.wineGrapes} 
          columns= {columnsWineGrapes} 
          onCellEditCommit={handleCellEditCommit}
        />           
            
      </Grid>              
    </Grid>

  )
}


function displayItem() {
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item xs={12} >
        <Card >
        <CardContent>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">  
            <Grid item xs={12}>
              <TextField 
                  onChange={(e)=> setWineData("name",e.target.value)}
                  className={classes.field} 
                  id="wine-name" 
                  label="Wine Name" 
                  fullWidth required
                  value={data.wine.name}
                  error={formError.name}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
            <TextField 
                  onChange={(e)=> setWineData("lwin",e.target.value)}
                  className={classes.field} 
                  id="lwin" 
                  label="lwin 7" 
                  fullWidth required
                  value={data.wine.lwin}
                  error={formError.lwin}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Wine Colour</InputLabel>
              <Select 
                disabled={isDisabled}
                label="Wine Colour" 
                labelId="select-category"
                id="select-category"
                value={data.wine.wineColourId}
                onChange={handleSelectedColourId}
              >
                {data.wineColours.map((item,i) => (
                    <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                ))}
                </Select> 
              </FormControl>  
            </Grid>    
            <Grid item xs={12} md={4}>
            <TextField 
                  onChange={(e)=> setWineData("estate",e.target.value)}
                  className={classes.field} 
                  id="estate" 
                  label="estate" 
                  fullWidth required
                  value={data.wine.estate}
                  error={formError.estate}
                  disabled
                  InputLabelProps={{ shrink: true }}
              />
              <ButtonDialogPickEstate itemName={data.wine.name}  estateName={data.wine.estate} callbackItem={setWineData} isDisabled={isDisabled} />
            </Grid>
            <Grid item xs={12} md={4}>
            <TextField 
                  onChange={(e)=> setWineData("country",e.target.value)}
                  className={classes.field} 
                  id="country-name" 
                  label="Country" 
                  fullWidth required
                  value={data.wine.country}
                  error={formError.country}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid item xs={12} md={4}>
              <TextField 
                  onChange={(e)=> setWineData("region",e.target.value)}
                  className={classes.field} 
                  id="region-name" 
                  label="Region" 
                  fullWidth required
                  value={data.wine.region}
                  error={formError.region}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid item xs={12} md={4}>
              <TextField 
                  onChange={(e)=> setWineData("subregion",e.target.value)}
                  className={classes.field} 
                  id="subregion-name" 
                  label="SubRegion" 
                  fullWidth
                  value={data.wine.subRegion}
                  error={formError.subRegion}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>     
            <Grid item xs={12}>
              <TextField 
                  onChange={(e)=> setWineData("displayLocation",e.target.value)}
                  className={classes.field} 
                  id="displayLocation" 
                  label="Display Location" 
                  fullWidth required
                  value={data.wine.displayLocation}
                  error={formError.displayLocation}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>   
            <Grid item xs={12}>
              <TextField 
                  onChange={(e)=> setWineData("displayWineLine1",e.target.value)}
                  className={classes.field} 
                  id="displayWineLine1" 
                  label="display Wine Line 1" 
                  fullWidth required
                  value={data.wine.displayWineLine1}
                  error={formError.displayWineLine1}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>   
            <Grid item xs={12}>
              <TextField 
                  onChange={(e)=> setWineData("displayWineLine2",e.target.value)}
                  className={classes.field} 
                  id="displayWineLine2" 
                  label="display Wine Line 2" 
                  fullWidth required
                  value={data.wine.displayWineLine2}
                  error={formError.displayWineLine2}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>      

            <Grid item xs={12}>
              <TextField 
                  onChange={(e)=> setWineData("preDrinkingYears",e.target.value)}
                  className={classes.field} 
                  type="number"
                  id="preDrinkingYears" 
                  label="pre Drinking Years" 
                  fullWidth
                  value={data.wine.preDrinkingYears}
                  error={formError.preDrinkingYears}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid item xs={12}>
              <TextField 
                  onChange={(e)=> setWineData("matureDrinkingYears",e.target.value)}
                  className={classes.field} 
                  type="number"
                  id="matureDrinkingYears" 
                  label="mature Drinking Years" 
                  fullWidth
                  value={data.wine.matureDrinkingYears}
                  error={formError.matureDrinkingYears}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>   
            <Grid item xs={12}>
              <TextField 
                  onChange={(e)=> setWineData("organicStatus",e.target.value)}
                  className={classes.field} 
                  id="organicStatus" 
                  label="organic Status" 
                  fullWidth
                  value={data.wine.organicStatus}
                  error={formError.organicStatus}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>    
            <Grid item xs={12} >
              <TextField 
                  onChange={(e)=> setWineData("productionBottlesAnnual",e.target.value)}
                  className={classes.field} 
                  id="productionBottlesAnnual" 
                  label="production Bottles Annual" 
                  fullWidth
                  value={data.wine.productionBottlesAnnual}
                  error={formError.productionBottlesAnnual}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid container item xs={12}>
              List Grapes 
              {defineGrapeList()}
            </Grid>                 
            <Grid item xs={12} >
              <small> created at {data.wine.createdAt} | updated at {data.wine.updatedAt} </small> 
            </Grid>
          </Grid>              
        </CardContent>
        </Card>                    
      </Grid>
      <Grid item xs={12}>
      <Box sx={{ pb: 5 }}>
        <Typography variant="h6">Vintages from this Wine</Typography>
      </Box>        
      
      <DataGridPro
        pagination
        autoHeight 
        rows={data.vintages} 
        columns= {columns} 
        
        loading={isLoading}
        components={{
          Toolbar: CustomToolbar,
        }}         
      />
    </Grid>
  </Grid>    
  )

}

  // PAGE LOGIC
  if (isLoading) {
    return displayPage(displayLoading);
  }
  if (!error) {   
    return displayPage(displayItem);
  }
  return displayPage(displayError);



}

