// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';


// ----------------------------------------------------------------------


export default function Location() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/locations", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
    { field: 'id', headerName: ' ', width:20 },
    { field: 'code', headerName: 'Code', width:200 },
    { field: 'level', headerName: 'Level', width:200 },
    { field: 'area', headerName: 'Area', width:300 },
    { field: 'status', headerName: 'status', width:200 },
    { field: 'observation', headerName: 'Observation', minWidth: 150, flex:1  },
    { field: 'palletId', headerName: 'Pallet', minWidth: 150
    , renderCell: (params) => {
      if (params.row.palletCode) {
        return (
        <strong>
          {params.row.palletCode}
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/warehouse/pallet/${params.value}`)}
          >
            <ZoomInIcon />
          </IconButton >        
        </strong>
        )
      }
        return (
        <span>
          No pallet       
        </span>
        ) 
    }
  },
    { field: 'countCase', headerName: '# Cases', width:200  },
    { field: 'weight', headerName: 'Weight', width:200  },  
    { field: 'lastAddition', headerName: 'lastAddition', width:150  },
    { field: 'dateRemoval', headerName: 'dateRemoval', width:150  }
];


  if (isLoading) {
    return (
      <Page title="Locations">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Warehouse Locations</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    console.log("data")
    console.log(data)
    return (
      <Page title="Locations">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Warehouse Locations</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <DataGridPro
                autoHeight 
                pagination
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Locations">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Warehouse Locations</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



