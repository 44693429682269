import { makeStyles } from '@mui/styles';
import { MenuItem,FormControl, Select, Link, Card, CardContent, Chip, TextField, Checkbox,  FormControlLabel, Box, Grid, Container, Typography, Skeleton, Button, IconButton, Snackbar, Alert, Stack  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

// components
import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink} from 'react-router-dom';
import Page from '../../components/Page';
import DialogSelectClient from '../../components/allocate/DialogSelectClient';
import DialogSelectPurchase from '../../components/purchase/DialogSelectPurchase';
import DialogBottleToNewCase from '../../components/allocate/DialogBottleToNewCase';
import DialogRowPickValue from '../../components/allocate/DialogRowPickValue';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { zeroPad,fCurrency } from '../../utils/formatNumber';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },  
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));


export default function AddNewCases() {
  const classes = useStyles();
  const { id } = useParams();   
  // Confirmation
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogFunction, setConfirmationDialogFunction] = useState(()=>true);
  const [confirmationDialogParam, setConfirmationDialogParam] = useState([]);
  const [dialogOpenClient,setDialogOpenClient] = useState(false);
  const [dialogOpenPurchase,setDialogOpenPurchase] = useState(false);
  const [dataBottles, setDataBottles] = useState([]);
  const [dialoPickValueOpen, setDialoPickValueOpen] = useState(false);
  const [dataListValues, setDataListValues] = useState([]);
  const [fieldValue, setFieldValue] = useState("");
  const [fieldName, setFieldName] = useState("");
 
  // Transactions data
  const [rowId, setRowId] = useState(null);

  // Param new cases
  const newCase = {
    id:1,
    name: "",
    qty: 0,
    lwin: "",
    packSize: 6,
    bottleSize: "00750",
    packType: "OWC",
    status: "in_storage",
    caseBookValue: null,
    location: "ESTATE",
    orderRef: "",
    qualityStandard: "",
    bottleList: [],
    error:""
  };
  const [dataTransactions, setDataTransactions] = useState([newCase,]);  
  const [transactionsCases, setTransactionsCases] = useState(0);  
  const [transactionsAmount, setTransactionsAmount] = useState(0);  
  const [dateAllocation, setDateAllocation] = useState((new Date()).toISOString());  
  const [data, setData] = useState(null);
  const [locationData, setLocationData] = useState({
    position: null,
    label: null,
    type: "estate",
    address: null,
    latitude: null,
    longitude: null
  });  
  

   const [isLoading, setIsLoading] = useState(true);
   const [isLoadingButton, setIsLoadingButton] = useState(false);   
   // SNACKBAR
   const [errorMessage, setErrorMessage] = useState(null); 
   const [error, setError] = useState(null);    
   const [confirmationMessage, setConfirmationMessage] = useState(null); 
   const [isChecked, setIsChecked] = useState(true); 

   const [dialogBottleOpen, setDialogBottleOpen] = useState(false);       

   useEffect(() => {
    const fetchDataReactScreen = async () => {
      if (id) {
        console.log(`/new-case-param/${id}`);
        fetchData(setIsLoading,setData,setError,`/new-case-param/${id}`, "get", null);
      }
      else {
        fetchData(setIsLoading,setData,setError,`/new-case-param`, "get", null);
      }
    };
    fetchDataReactScreen();
    return 0    
  }, []);

  useEffect(() => {
    const totalCases = dataTransactions.map(item => item.qty).reduce((prev, curr) => prev + curr, 0);
    const totalAmount = dataTransactions.map(item => item.qty * item.caseBookValue).reduce((prev, curr) => prev + curr, 0);
    setTransactionsCases(totalCases)
    setTransactionsAmount(totalAmount)
    return 0    
  }, [dataTransactions]);
  

  const handleTypeSelected = (event) => {
    setLocationDataKey("type",event.target.value);
  };  

  // const handleSelectedPreconfigured = (event) => {
  //   const preconfigured = data.preconfigured[event.target.value];
  //   setLocationDataKey("position",preconfigured.position);
  //   setLocationDataKey("label",preconfigured.label);
  //   setLocationDataKey("type",preconfigured.type);
  //   setLocationDataKey("address",preconfigured.address);
  //   setLocationDataKey("latitude",preconfigured.latitude);
  //   setLocationDataKey("longitude",preconfigured.longitude);
  // };  

  const setLocationDataKey = (field,value) => {
    locationData[field] = value
    setLocationData({...locationData});
  }

  const [dataFile,setDataFile] = useState({name:"no file selected"});
  const handleDialogUploadClose = () => {
    setUploadDialog(false); 
  }  
  const handleDialogUploadOpen = () => {
    setUploadDialog(true); 
  }    
  const uploadDocument = async () => {
    setIsLoadingButton(true);
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    postData.append("refOrder", 1)
    postData.append('file', dataFile); // The file has be the last element  


    const res = await fetchDataAwait(`/transactions/load-file-new-cases`, "post", postData, {'Content-Type': 'multipart/form-data'});
    console.log(res)
    if (res.error) {
      console.log("eeror")
      setErrorMessage(res.error.message);
    }   
    else {
     console.log("ok")
      setUploadDialog(false); 
      setIsLoading(true);
      setDataTransactions(res.data);
      setConfirmationMessage("List updated.");
      setIsLoading(false);
    }

    setIsLoadingButton(false);
    return 1;
  }
  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
  };   
  const [uploadDialog, setUploadDialog] = useState(false);  
  const dialogUpload = () =>  (
    <Dialog
    open={uploadDialog}
    onClose={() => handleDialogUploadClose()}
    aria-labelledby="alert-dialog-upload"
    maxWidth='lg'
    >
      <DialogTitle  sx={{ pb: 5 }}>Upload a file with new cases</DialogTitle> 
      <DialogContent>
      <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">        
                <Grid sx={{ pb: 2 }} item xs={12}>
                  Upload of list of cases to replace the existing list
                </Grid>                                              
                <Grid sx={{ pb: 2 }} item xs={12}  >
                  File must be a <strong>"csv"</strong> with the following columns:<br/>
                  <ul>
                    <li><strong>caseName</strong>:</li>
                    <li><strong>qty</strong>: Number of cases</li>
                    <li><strong>lwin</strong></li>
                    <li><strong>packSize</strong>: 1 to 24</li>
                    <li><strong>bottleSize</strong> 00750 or similar</li>
                    <li><strong>packType</strong></li>
                    <li><strong>caseBookValue</strong> : BV of individual cases</li>
                    <li><strong>location</strong>: "ESTATE", "GVA_PF" or other</li>
                    <li><strong>status</strong></li>
                    <li><strong>orderRef</strong></li>
                    <li><strong>qualityStandard</strong> P3 or "-"</li>
                  </ul>
                  <ul>
                    <strong>bottleList</strong>: Add as many columns as below (replacing the # by the bottle number) to specifiy the different vintages in a single case<br/>
                    <li><strong>vintage_bottle#</strong>: vintage id as provided in page <Link to="/stock/vintage" component={RouterLink}/></li>
                    <li><strong>qty_bottle#</strong>: quantity of this type of bottle in a single case</li>
                    <li><strong>bookValue_bottle#</strong>: BV of individual bottles (optional, designed for multi case)</li>
                  </ul>
                </Grid>  
                <Grid item xs={12} >
                <Button
                  variant="contained"
                  component="label"
                >
                  Select a CSV
                  <input
                    type="file"
                    hidden
                    onChange={handleCapture}
                  />
                </Button>  <br/>     
                {dataFile.name}   
                </Grid>                                                                                                                                                                                                                          
              </Grid>             
                    
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> uploadDocument()} color="primary">
          Upload CSV
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogUploadClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );



  // CREATE TRANSACTIONS
  const handleCreateTransactions = async () => {
    setIsLoadingButton(true);
    const res = await fetchDataAwait('/cases/new', "post", {cases : dataTransactions, purchase: data.purchase.id, collector: data.user.id, generateCashTransactions: isChecked, date_allocation: dateAllocation, position: locationData});
    console.log({cases : dataTransactions, collector: data.user.id, generateCashTransactions: isChecked, date_allocation: dateAllocation});
    console.log(res)
    if (res.error) {
      setErrorMessage(res.error.message);
      if (res.error.errorRows) {
        const updatedRows = dataTransactions.map((row) => {
          const foundError = res.error.errorRows.find(obj => obj.id === row.id) 
          if (foundError) {
            row.error = foundError.error;
            return row;
          }
          row.error = ""
          return row;
        });
        setDataTransactions(updatedRows);      
      }
    } else {
      setConfirmationMessage("New cases succesfully created. Please check transactions for more details");
    }
    setIsLoadingButton(false);     
  };


  const handleClickAddRow = () => {
    const row = {...newCase};
    row.id = Math.max(...dataTransactions.map(o => o.id), 0)+1;
    setDataTransactions([...dataTransactions,row]);
    console.log(dataTransactions)
  };


  const handleRemoveRow = (rowId) => {
    setDataTransactions(dataTransactions.filter((el) =>  el.id !== rowId));
  };     
  const handleAddUser = () => {
    setRowId(1);
    setDialogOpenClient(true);
  };    
 

  const updateClientData = (rowId,rowClient) => {
    data.user.id = rowClient.id;
    data.user.externalId = rowClient.externalId ;
    setData({...data});
  };  

  const handleAddPurchase = () => {
    setRowId(1);
    setDialogOpenPurchase(true);
  };   
  const updatePurchaseData = (rowId,rowPurchase) => {
    data.purchase.id = rowPurchase.id;
    data.purchase.name = rowPurchase.name ;
    setData({...data});
  };  



  const columnsTransactions = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.row.id);}}
        >
          <ClearIcon />
        </IconButton >        
      </span>
      )
    },      
    { field: 'bottleList', headerName: 'List Bottles', width:300 ,cellClassName: 'editable-theme--cell', 
    renderCell: (params) => {
        if (params.value.length===0) {
          return (
            <span>
              <IconButton 
                variant="contained"
                color="primary"
                size="small"
                onClick={()=> { handleAddBottles(params.row);}}
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton > 
              No bottles        
            </span>
            )
        }
        return (
          <span>
            <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={()=> { handleAddBottles(params.row);}}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton >         
            {params.value.map((item,i) => (
                      <span>
                      {item.qty} bottles:  <Chip label={item.bottleName} /> 
                      </span>
                            ))}
          </span>
          )
      }
    },       
    { field: 'name', headerName: 'Case', width:300   ,cellClassName: 'editable-theme--cell' , editable: true,},
    { field: 'qty', headerName: '#Cases', width:120, type: 'number'  ,cellClassName: 'editable-theme--cell' , editable: true,},    
    { field: 'lwin', headerName: 'lwin', width:200 ,cellClassName: 'editable-theme--cell' , editable: true,},  
    { field: 'packSize', headerName: 'packSize', width:100 , renderCell: (params) =>  (<span>{zeroPad(params.value,2)}</span>) , cellClassName: 'editable-theme--cell' , editable: true,  type:'number'},
    { field: 'bottleSize', headerName: 'bottleSize', width:100 , 
          renderCell: (params) =>  (<span>
            <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={()=> { handlePickValue(data.bottleSize,params.value,"bottleSize",params.row.id)}}
              >
              <AddCircleOutlineOutlinedIcon />
              </IconButton > {params.value}</span>)  ,cellClassName: 'editable-theme--cell' },
    { field: 'packType', headerName: 'packType', width:100 , 
    renderCell: (params) =>  (<span>
                <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={()=> { handlePickValue(data.packType,params.value,"packType",params.row.id)}}
              >
              <AddCircleOutlineOutlinedIcon />
              </IconButton > {params.value}</span>)
              ,cellClassName: 'editable-theme--cell'},
    { field: 'status', headerName: 'status', width:130 , 
    renderCell: (params) =>  (<span>
                <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={()=> { handlePickValue(data.status,params.value,"status",params.row.id)}}
              >
              <AddCircleOutlineOutlinedIcon />
              </IconButton > {params.value}</span>)
              ,cellClassName: 'editable-theme--cell'},  
    { field: 'caseBookValue', headerName: 'BookValue', width:100 ,cellClassName: 'editable-theme--cell', type: 'number' , editable: true,},
    { field: 'location', headerName: 'location', width:100 ,cellClassName: 'editable-theme--cell' , editable: true,},
    { field: 'orderRef', headerName: 'orderRef', width:100 ,cellClassName: 'editable-theme--cell' , editable: true,},
    { field: 'qualityStandard', headerName: 'qualityStandard', width:100 , 
    renderCell: (params) =>  (<span>
                <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={()=> { handlePickValue(data.qualityStandard,params.value,"qualityStandard",params.row.id)}}
              >
              <AddCircleOutlineOutlinedIcon />
              </IconButton > {params.value}</span>)
              ,cellClassName: 'editable-theme--cell'},
    { field: 'error', headerName: 'error', width:300}
    ];


    function CustomAddToolbar() {
      return (
        <GridToolbarContainer>
                <Stack sx={{ pb:3 }} direction="row" spacing={2} justifyContent="space-between">   
                <span>
                <LoadingButton
                    color="primary" loading={isLoading}
                    onClick={handleClickAddRow}
                    variant="contained"
                >
                  <AddCircleOutlineOutlinedIcon/>Add Row
                </LoadingButton>

                <Chip label={`#Cases: ${transactionsCases}`} variant="outlined" />
                <Chip label={`Total: ${fCurrency(transactionsAmount)}`} variant="outlined" />
                </span>
                <LoadingButton
                    color="primary" loading={isLoading}
                    onClick={() => handleDialogUploadOpen()}
                    variant="contained"
                >
                  <InsertDriveFileIcon/>Upload from list
                </LoadingButton>    
                </Stack>
        </GridToolbarContainer>
      );
    }


  function handleCloseConfirmationDialog() {
    setConfirmationDialogOpen(false);
  }
  function handleCloseConfirmationDialogConfirmed() {
    setConfirmationDialogOpen(false);
    confirmationDialogFunction(...confirmationDialogParam);
    
  }

  const handleChangeCheckBox = (event) => {
    setIsChecked(event.target.checked);
  };   



  
  const handleCellEditCommit = ({ id, field, value }) => {
    updateFieldData(id,field,value);
  };  


  const handlePickValue = (valueList,value, field, idRow) => {
    setDataListValues(valueList);
    setRowId(idRow);
    setFieldValue(value);
    setFieldName(field);
    setDialoPickValueOpen(true);
  };  
  const updateFieldData= (rowCaseId,fieldname,value) => {
    const updatedRows = dataTransactions.map((row) => {
      if (row.id === rowCaseId) {
        row[fieldname] = value;
        // UPDATE LWIN
        if (["bottleSize","packSize"].includes(fieldname)) {
          if (row.bottleList.length>0) {
            row.lwin = `${row.bottleList[0].lwin}${zeroPad(row.packSize,2)}${row.bottleSize}`;
          }
        }
        return row;
      }
      return row;
    });
    setDataTransactions([...updatedRows]);
  };   

  const handleAddBottles = (rowTable) => {
    setRowId(rowTable.id);
    setDataBottles(rowTable.bottleList)
    setDialogBottleOpen(true);
  };   
  const updateBottleData= (rowCaseId,bottlesData) => {
      const updatedRows = dataTransactions.map((row) => {
        if (row.id === rowCaseId) {
          // UPDATE LWIN
          row.bottleList = bottlesData;
          if (bottlesData.length>0) {
            row.lwin = `${bottlesData[0].lwin}${zeroPad(row.packSize,2)}${row.bottleSize}`;
          }
          // UPDATE NAME
          if (row.name==="" || !row.lwin) {
            if (bottlesData.length>0) {
              row.name = `${bottlesData[0].bottleName}`;
            }
          }
          return row;
        }
        return row;
      });
      setDataTransactions([...updatedRows]);
    };


  
  function defineTransactions() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item  direction="row" justifyContent="left" alignItems="center" xs={12} sx={{pb:5}}>  
          <Card >
                <CardContent>
                <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                    
                  <Grid item xs={12} md={3} >
                    Allocate to :         
                      <IconButton 
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={()=> { handleAddUser();}}
                      >
                        <AddCircleOutlineOutlinedIcon />
                      </IconButton >  
                    <b>{data.user.externalId}</b>
                  </Grid> 
                  <Grid item xs={12} md={3} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Allocation date"
                      inputFormat="dd/MM/yyyy"
                      value={dateAllocation}
                      onChange={(e)=> setDateAllocation(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />        
                    </LocalizationProvider>                                    
                  </Grid>                                                        
                  <Grid item xs={12}  md={3} >
                    <FormControlLabel
                    control={<Checkbox  checked={isChecked} onChange={handleChangeCheckBox} />}
                    label="Impact Cash balance"
                    /> 
                  </Grid>               
                  <Grid item xs={12}  md={3} >
                  <LoadingButton
                    variant="contained"  color="primary" loading={isLoadingButton}
                    onClick={handleCreateTransactions}
                  >
                    CREATE CASES
                  </LoadingButton>   
                  </Grid> 
                  <Grid item xs={12}  >
                  <small>MANDATORY</small> - Link to purchase:         
                      <IconButton 
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={()=> { handleAddPurchase();}}
                      >
                        <ShoppingBasketIcon />
                      </IconButton >  
                    <b>{data.purchase.name}</b>
                  </Grid>
                  <Grid item xs={12}>
                    <Card >
                        <CardContent>
                          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                          <Grid item xs={12} >
                              <Typography variant="h5">Cases Location*   </Typography>
                              For traceability, we need to record cases' location at the time of purchase<br/>
                              {/* Use a predefined destination:                             
                              <FormControl> 
                                <Select style={{width: 300}}
                                  autoWidth	
                                  size="small"
                                  labelId="select-destination"
                                  id="select-destination"
                                  onChange={handleSelectedPreconfigured}

                                >
                                  {Object.keys(data.preconfigured).map((item,i) => (
                                      <MenuItem value={item} key={i}>{item}</MenuItem>
                                  ))}
                                </Select> 
                              </FormControl>  */}
                            </Grid>                            
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Position Name</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9} >
                              <TextField 
                                  onChange={(e)=> setLocationDataKey("position",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationPosition" 
                                  fullWidth required
                                  value={locationData.position}
                              />                      
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Label (short name)</Typography>
                            </Grid>  
                            <Grid item xs={12} md={3}>
                              <TextField 
                                  onChange={(e)=> setLocationDataKey("label",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationLabel" 
                                  fullWidth required
                                  value={locationData.label}
                              />                      
                            </Grid>  
                            <Grid item xs={12} md={2} >
                              <Typography variant="h6">Type</Typography>
                            </Grid>  
                            <Grid item xs={12} md={4} >
                              <FormControl> 
                                <Select style={{width: 300}}
                                  autoWidth	
                                  labelId="select-destinationType"
                                  id="select-destinationType"
                                  value={locationData.type}
                                  onChange={handleTypeSelected}
                                >
                                  {data.type.map((item,i) => (
                                      <MenuItem value={item} key={i}>{item}</MenuItem>
                                  ))}
                                </Select> 
                              </FormControl>                                                 
                            </Grid>                              
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Address</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9} >
                              <TextField 
                                  onChange={(e)=> setLocationDataKey("address",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationAddress" 
                                  fullWidth required
                                  value={locationData.address}
                                  multiline
                                  rows={4}                          
                              />                         
                            </Grid> 
                            <Grid item xs={6} md={2} >
                              <Typography variant="h6">Latitude</Typography>
                            </Grid>  
                            <Grid item xs={6} md={2} >
                              <TextField 
                                  onChange={(e)=> setLocationDataKey("latitude",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationLatitude" 
                                  fullWidth required
                                  value={locationData.latitude}

                              />                      
                            </Grid>    
                            <Grid item xs={6} md={2} >
                              <Typography variant="h6">Longitude</Typography>
                            </Grid>  
                            <Grid item xs={6} md={2} >
                              <TextField 
                                  onChange={(e)=> setLocationDataKey("longitude",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationLongitude" 
                                  fullWidth required
                                  value={locationData.longitude}
                              />                      
                            </Grid>                                                                                                                                                                                                                                                                                                                                                                                   
                          </Grid>  
                        </CardContent>
                      </Card >                        
                    </Grid>                                                                                                                         
                </Grid>              
                </CardContent>
              </Card >            
              
          </Grid>
          <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="center"  className={classes.root}>  
              <DataGridPro 
              pagination
              overflow="auto"
              autoHeight 
              rows={dataTransactions} 
              columns= {columnsTransactions}                
              pageSize={100}
              loading={isLoading}
              onCellEditCommit={handleCellEditCommit}
              components={{
                Toolbar: CustomAddToolbar,
              }}                    
             
            />      
        </Grid>   
        {dialogConfirmation()}          
        {dialogUpload()}   
        <DialogSelectClient dialogOpen={dialogOpenClient} setDialogOpen={setDialogOpenClient} setErrorMessage={setErrorMessage} rowId={rowId} setClient={updateClientData}/>        
        <DialogSelectPurchase dialogOpen={dialogOpenPurchase} setDialogOpen={setDialogOpenPurchase} setErrorMessage={setErrorMessage} rowId={rowId} setPurchase={updatePurchaseData}/>
      </Grid>
    ) // [dialogOpenClient,setDialogOpenClient]
  }


  function dialogConfirmation() {
    return (            
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseConfirmationDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseConfirmationDialogConfirmed} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>  
      )
  }



  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };  
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  

  function displayPage(func) {
    return (
      <Page title="Add New Cases">
        <Container maxWidth="false">
            <Stack  sx={{ pb: 5 }} direction="row" spacing={2} justifyContent="space-between">   
                <Typography variant="h4">New Cases</Typography>            
            </Stack>            
          {func()}
        </Container>
        <DialogBottleToNewCase dialogOpen={dialogBottleOpen} setDialogOpen={setDialogBottleOpen} setErrorMessage={setErrorMessage} rowId={rowId} callBackFunction={updateBottleData} dataBottles={dataBottles} setDataBottles={setDataBottles} />
        <DialogRowPickValue fieldName={fieldName}  fieldValue={fieldValue} dialogOpen={dialoPickValueOpen} setDialogOpen={setDialoPickValueOpen} setErrorMessage={setErrorMessage} rowId={rowId} callBackFunction={updateFieldData} listValues={dataListValues}  />
        {snackbarError()}       
        {snackbarSuccess()}    
      </Page>
    );
  }


  if (isLoading) {
    return (
      <Page title="Client">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">New Cases</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  }   

  if (!error && data) {   
    return displayPage(defineTransactions);
  }

    // PAGE LOGIC
  return (
    <Page title="Clients">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">New Cases</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

