import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { IconButton, Box, Grid, Container, Typography, Skeleton, Button, Card, CardContent, CardActions, Snackbar, Alert,   } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// components
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';

// ----------------------------------------------------------------------

function CustomToolbar() {
  return (
    <GridToolbarContainer>            
      <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
    </GridToolbarContainer>
  );
}  


const useStyles = makeStyles((theme) => ({
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));

export default function Allocate() {
  const classes = useStyles();
  const navigate = useNavigate();
  // Select Existing
  const [selectedRoundId, setSelectedRoundId] = useState("");
  // Create new
  const [openDialogForm, setopenDialogForm] = useState(false); 
  const [roundName, setRoundName] = useState('');
  const [roundDesc, setRoundDesc] = useState('');
  const [roundExpiry, setRoundExpiry] = useState( new Date( (new Date()).getFullYear(),(new Date()).getMonth(),(new Date()).getDate()+15));
  const [roundNameError, setRoundNameError] = useState(false);
  const [roundDescError, setRoundDescError] = useState(false);

   // API NEW
   const [isLoadingNew, setIsLoadingNew] = useState(false);
   // SNACKBAR
   const [errorMessage, setErrorMessage] = useState(null); 
   // API LIST
   const [isLoadingList, setIsLoadingList] = useState(true);
   const [dataList, setDataList] = useState(null);
   const [errorList, setErrorList] = useState(null);


   const columns =  [
    { field: 'id', headerName: 'Id', width:90
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleViewModify(params.value);}}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
      </span>
    )
  },
  { field: 'name', headerName: 'name', width:250  },       
    { field: 'roundDate', headerName: 'roundDate', width:150  },
    { field: 'roundDateExpiry', headerName: 'roundDateExpiry', width:150  },
    { field: 'description', headerName: 'description', width:150  }, 
    { field: 'isImplemented', headerName: 'isImplemented', width:200  },
    { field: 'createdAt', headerName: 'createdAt', width:200  },
    { field: 'updatedAt', headerName: 'updatedAt', width:200  },        

  ];



  const fetchDataList = async () => {
    fetchData(setIsLoadingList,setDataList,setErrorList,"/allocation/get-round-list", "get", null);
  };
  useEffect(() => {
    fetchDataList();
  }, []);  
  useEffect(() => {
    if (errorList) {
      setErrorMessage(errorList.message);
    }
  }, [errorList]);  


  const handleClickOpen = () => {
    setopenDialogForm(true);
  };
  const handleClose = () => {
    setopenDialogForm(false);
  }; 

  const handleSelectedRoundId = (event) => {
    setSelectedRoundId(event.target.value);
  };

  const handleViewModify = (roundId) => {
      navigate(`/allocation/allocate/${roundId}`);

  };

  const handleDialogSubmit = async () => {
    setRoundNameError(false);
    setRoundDescError(false);
    
    if ( roundName === '') {
      setRoundNameError(true);
    }
    if ( roundDesc === '') {
      setRoundDescError(true);
    }   
    if ( roundName && roundDesc) {
      setIsLoadingNew(true);
      const res = await fetchDataAwait("/allocation/create-round", "post", {name: roundName, description: roundDesc});
      if (res.error) {
        setErrorMessage(res.error.message);
      }
      else {
        navigate(`/allocation/allocate/${res.data.id}`);

      }
      setIsLoadingNew(false);

    }
  }


  function dialogFormNew() {
    return (
     
      <Dialog open={openDialogForm} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>New Round</DialogTitle>
        <DialogContent>          
          <TextField 
            onChange={(e)=> setRoundName(e.target.value)}
            className={classes.field} 
            id="round-name" 
            label="Round Name" 
            fullWidth required
            value={roundName}
            error={roundNameError}
          />
          <TextField
            onChange={(e)=> setRoundDesc(e.target.value)}
            className={classes.field}
            id="round-description"
            label="Description"
            value={roundDesc}
            multiline
            rows={4}
            fullWidth required
            error={roundDescError}
          />  
        <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Expiry date"
                inputFormat="dd/MM/yyyy"
                value={roundExpiry}
                onChange={(e)=> setRoundExpiry(e)}
                renderInput={(params) => <TextField {...params} />}
              />        
        </LocalizationProvider>             
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton loading={isLoadingNew} variant="contained" onClick={handleDialogSubmit} color="primary">
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>

    );
  }  


  function loadingSelect() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12}  className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
      </Grid>
    )
  }

  function selectRound() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12}  component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Create a new Round of allocation
              </Typography>
            </CardContent>
            <CardActions>
              <Button  onClick={handleClickOpen} variant="contained" color="primary">CREATE</Button>
              {dialogFormNew()}
            </CardActions>
        </Grid>
        <Typography component="h5" variant="h5" >
                Modify an existing round
              </Typography>              
                   
        <Grid item xs={12} component={Card} className={classes.card}>
            <CardContent>
            <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={dataList.data} 
                columns= {columns} 
                components={{
                  Toolbar: CustomToolbar,
                }}                  
              />                 

            </CardContent>  
        </Grid>     
      </Grid>
    )
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }


  function displayPage(func) {
    return (
      <Page title="Allocation Selection">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Allocation Selection</Typography>
          </Box>
          {func()}
        </Container>
        {snackbarError()}       
      </Page>
    );
  }


  // PAGE LOGIC
  if (!isLoadingList) {
    return displayPage(selectRound);
  }

    return displayPage(loadingSelect);
}

