import { MenuItem, Button, IconButton, Grid, TextField  } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { fCurrency } from '../../utils/formatNumber';
import { fetchDataAwait } from '../../utils/api';


export default function DialogAddRowsFromSearch(props) {

  const searchRef = useRef();  
  const {addRows, setErrorMessage, clientSelection} = props;
  DialogAddRowsFromSearch.propTypes = {
    setErrorMessage: PropTypes.func.isRequired,
    addRows: PropTypes.func.isRequired,
    // clientSelection: PropTypes.object.isRequired,
  }; 

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cases, setCases] = useState([]);
  const [selection, setSelection] = useState([]);


  const columnsGrid = [
    // { field: 'id', headerName: ' ', width:20
    // , renderCell: (params) => (
    //   <strong>
    //     <IconButton 
    //       variant="contained"
    //       color="primary"
    //       size="small"
    //       onClick={()=> { handleRemoveRow(params.value);}}
    //     >
    //       <DeleteIcon />
    //     </IconButton >        
    //   </strong>
    //   )
    // },      
    { field: 'caseName', headerName: 'Case Type', width:300 },  // Name size-qty-owner
    { field: 'caseOwner', headerName: 'Case Owner', width:200 },
    { field: 'availableQuantity', headerName: 'Quantity', width:200 },
    { field: 'category', headerName: 'Category', width:200 },
    { field: 'region', headerName: 'Region', width:200 },
    { field: 'bookValue', headerName: 'bookValue', width:200 , renderCell: (params) =>  fCurrency(params.value) }, 
    { field: 'currentValue', headerName: 'currentValue', width:200 },

    { field: 'currentValueUpdatedAt', headerName: 'last update', width:200 },
    { field: 'location', headerName: 'location', width:200 },
    { field: 'orderReference', headerName: 'orderReference', width:200 },
    { field: 'packType', headerName: 'packType', width:200 },

    { field: 'qualityStandard', headerName: 'qualityStandard', width:200 },
    { field: 'status', headerName: 'status', width:200 }
    ]
  

  const handleRemoveRow = (rowId) => {
    const newCases = cases.filter((el) =>  el.id !== rowId); 
    setCases(newCases);
  }

  const handleCloseDialog = () => {
    console.log("close")
    setDialogOpen(false);    
  }

  const handleCloseAddDialogConfirmedClient = () => {
      if (selection.length >0) {
        // Add new column to allocation table
        addRows(selection, cases);
      }
      setDialogOpen(false);
      return true;
    }      

    const handleButtonClick = () => {
      setDialogOpen(true);  
    }      
    
  const refreshData = async() => {
    setIsLoading(true);
    const dataPost = {
      // allocatedUserId: clientSelection,
      search: searchRef.current.value,
      userId: []
    }
    console.log(dataPost)
    const res = await fetchDataAwait("/allocation/get-case", "post", dataPost);
    console.log(res);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    
    setCases(res.data.cases);  
    setIsLoading(false);  
    setDialogOpen(true); 
    return true       
  }      

  return (
    <span>
      <MenuItem onClick={handleButtonClick}>Add Cases from Search</MenuItem>        
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Search Cases to Add</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
              <Grid item xs={12} md={6}>    
                <TextField fullWidth id="fullWidth"
                            inputRef={searchRef} 
                            onKeyPress={(event) => {
                              if (event.key === 'Enter')
                              refreshData()
                            }}                            
                />
              </Grid>
              <Grid item xs={12}  md={6} sx={{ pb: 5 }}>                   
                  <LoadingButton loading={isLoading} variant="contained" onClick={refreshData}>
                  Search
                </LoadingButton>                
              </Grid>
              <Grid item xs={12}>
                <DataGridPro overflow="auto"
                 
                pagination
                autoHeight 
                rows={cases} 
                columns= {columnsGrid} 
                pageSize={25}
                loading={isLoading}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  setSelection(newSelection);
                }}
                />
              </Grid>
            </Grid>                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleCloseAddDialogConfirmedClient} color="primary" autoFocus>
              ADD
            </Button>
          </DialogActions>
        </Dialog>              
      </span>
    )
}