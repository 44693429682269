// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Button  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';


// ----------------------------------------------------------------------


export default function Pallet() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/pallets", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/warehouse/pallet/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
      </strong>
    )
  },
    { field: 'code', headerName: 'Code', width:120 },
    { field: 'countCase', headerName: '# Cases', width:120  },
    { field: 'weight', headerName: 'Weight', width:120  },
    { field: 'observation', headerName: 'Observation', width:200  },    
    { field: 'locationName', headerName: 'Location', width:200  },       
    { field: 'lastAddition', headerName: 'lastAddition', width:150  }
];


  if (isLoading) {
    return (
      <Page title="Pallets">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Pallets</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    console.log("data")
    console.log(data)
    return (
      <Page title="Pallets">
        <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Pallets
          </Typography>

        </Stack>           
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item xs={12}>
       
       <DataGridPro
         autoHeight
         pagination 
         rows={data.data} // {rows} //
         columns= {columns} //  {data.columns} //
         
         loading={isLoading}
         components={{
          Toolbar: CustomToolbar,
        }}         
       />
     </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Pallets">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Pallets</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



