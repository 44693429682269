import { makeStyles } from '@mui/styles';
import { Skeleton, MenuItem, Select, RadioGroup, Radio, Button,  FormControl, Grid, Typography, TextField, FormControlLabel } from '@mui/material';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from "axios";
import { fetchDataAwait } from '../../utils/api';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function ButtonDialogUploadDocument(props) {
  const classes = useStyles();   
  const { documentId, purchaseId, callBackFunction, setErrorMessage, setConfirmationMessage, dialogOpen, setDialogOpen } = props;
  ButtonDialogUploadDocument.propTypes = {
    documentId: PropTypes.number,
    purchaseId: PropTypes.string,
    callBackFunction: PropTypes.func,
    setErrorMessage: PropTypes.func,
    setConfirmationMessage: PropTypes.func,
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
  };  
  const [isLoading, setIsLoading] = useState(true);
  
    
  const [documentData,setDocumentData] = useState(null);
  const [listCurrencies,setListCurrencies] = useState([]);
  const [dataFile,setDataFile] = useState({name:"no file selected"});


  useEffect(() => {
    refreshDocumentData(); 
  }, [documentId]);


  const handleCurrencySelected = (event) => {
    handleAddDocumentData("invoiceAmountCurrency",event.target.value);
  };  
  

  const handleAddDocumentData = (field,value) => {
    documentData[field] = value
    setDocumentData({...documentData});
  }


  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
    console.log(event.target.files[0])
  };



  const uploadDocument = async () => {
    setIsLoading(true);
    if (dataFile.type) {
      // NEW FILE TO UPLOAD
      const signedUrl = await fetchDataAwait('/get-signed-url/', "put",  {file: dataFile.name, type: "purchase"});
      console.log(signedUrl);
      if (signedUrl.error) {
        setErrorMessage("Error with S3 presigned url");
        setIsLoading(false); 
        return 0;
      }     
      // UPLOAD FILE - Upload file
      const postData = new FormData();
      Object.entries(signedUrl.data.presignedURL.fields).forEach(([k, v]) => {
        postData.append(k, v);
      });

      postData.append('file', dataFile); // The file has be the last element  

      await axios.post(signedUrl.data.presignedURL.url, postData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })          
      .then(async () => {
        console.log("UPLOADING DATA");
        let res;
        if (documentId) {
          res = await fetchDataAwait(`/purchase/document/${documentId}`, "put",  {purchaseId,file: signedUrl.data.fileName, type: dataFile.type, ...documentData});
        } else {
          res = await fetchDataAwait(`/purchase/document`, "post",  {purchaseId,file: signedUrl.data.fileName, type: dataFile.type, ...documentData});
        }
        if (res.error) {
          setErrorMessage(res.error.message);
        } else {
          // setData(res.data);
          setConfirmationMessage("Succesfully saved.");
          setDialogOpen(false);
          callBackFunction(); 
          // navigate(`/client/transaction/${res.data.id}`)
        }          
      })
      .catch(error => {
        setErrorMessage("Error S3 upload");
        console.log(error);                
      });
    } else {
      console.log("UPLOADING DATA");
      let res;
      if (documentId) {
        res = await fetchDataAwait(`/purchase/document/${documentId}`, "put",  {purchaseId, ...documentData});
      } else {
        res = await fetchDataAwait(`/purchase/document`, "post",  {purchaseId, ...documentData});
      }
      if (res.error) {
        setErrorMessage(res.error.message);
      } else {
        // setData(res.data);
        setConfirmationMessage("Succesfully saved.");
        setDialogOpen(false);
        callBackFunction(); 
        // navigate(`/client/transaction/${res.data.id}`)
      } 

    }
    setIsLoading(false);
    return 1;
  }
  

  const refreshDocumentData = async() => {
    setIsLoading(true);
    let res;
    if (documentId) {
      res = await fetchDataAwait(`/purchase/document/${documentId}`, "get", null);
    } else {
      res = await fetchDataAwait(`/purchase/document`, "get", null);
    }    
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setListCurrencies(res.data.currencies);      
    setDocumentData(res.data.document);  
    setIsLoading(false);  
    return true       
  }        


  // const handleOpenDialog = () => {
  //   setDialogOpen(true);  
  //   refreshDocumentData();
  // }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      


  const handleChangeRadio = (event) => {
    handleAddDocumentData("isInvoice",event.target.value)
  };  

  function  invoiceDisplay() {
    if (String(documentData.isInvoice) ==="1"){
       return (           
        <Grid item container spacing={3}>    
        <Grid item xs={12} md={3} >
                    <Typography variant="h6">Payment Amount</Typography>
                  </Grid>  
                  <Grid item xs={7} md={3} >
                    <TextField 
                        onChange={(e)=> handleAddDocumentData("invoiceAmount",e.target.value)}
                        className={classes.field} 
                        label="Amount Local Currency"
                        id="p-amount" 
                        fullWidth required
                        value={documentData.invoiceAmount}

                    />                      
                  </Grid>   
                  <Grid item xs={5} md={3} >
                    <FormControl> 
                      <Select style={{width: 150}}
                        autoWidth	
                        label="Currency" 
                        labelId="select-currency"
                        id="select-currency"
                        value={documentData.invoiceAmountCurrency}
                        onChange={handleCurrencySelected}
                      >
                        {listCurrencies.map((item,i) => (
                            <MenuItem value={item} key={i}>{item}</MenuItem>
                        ))}
                      </Select> 
                    </FormControl>                   
                  </Grid>
                  <Grid item xs={5} md={3} >
                    <TextField 
                        onChange={(e)=> handleAddDocumentData("invoiceAmountEuro",e.target.value)}
                        className={classes.field} 
                        label="Amount EUR"
                        id="p-amount" 
                        fullWidth required
                        value={documentData.invoiceAmountEuro}
                    />                      
                  </Grid>                       


                  <Grid item xs={12} md={3} >
                    <Typography variant="h6">Payment Deadline</Typography>
                  </Grid>  
                  <Grid item xs={12} md={9} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Payment Deadline"
                        inputFormat="dd/MM/yyyy"
                        value={documentData.invoicePaymentDeadline}
                        onChange={(e)=> handleAddDocumentData("invoicePaymentDeadline",e)}
                        renderInput={(params) => <TextField {...params} />}
                      />        
                      </LocalizationProvider>                        
                  </Grid>       
                </Grid>                   
       )}
    return "" 

  }

  function displayContent() {
    if (documentData) {
      return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
        <Grid item xs={12} md={3} >
          <Typography variant="h6">Document Type</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >                    
          <FormControl component="fieldset">
            <RadioGroup row aria-label="type" name="row-radio-buttons-group"
                    value={documentData.isInvoice}
                    onChange={handleChangeRadio}
                  >
              <FormControlLabel value="1" control={<Radio />} label="Invoice" />
              <FormControlLabel value="0" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>                
        </Grid>   
        <Grid item xs={12} md={3} >
          <Typography variant="h6">File Upload</Typography>
        </Grid> 
        <Grid item xs={12} md={8}>
              <Button
                variant="contained"
                component="label"
              >
                Upload a document
                <input
                  type="file"
                  hidden
                  onChange={handleCapture}
                />
            </Button>  <br/>     
            {dataFile.name}
        </Grid>                  
        <Grid item xs={12} md={3} >
          <Typography variant="h6">File Name</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <TextField 
              onChange={(e)=> handleAddDocumentData("name",e.target.value)}
              className={classes.field} 
              id="file-name" 
              fullWidth required
              value={documentData.name}
          />                      
        </Grid>  

        <Grid item xs={12} md={3} >
          <Typography variant="h6">Description</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <TextField 
              onChange={(e)=> handleAddDocumentData("description",e.target.value)}
              className={classes.field} 
              multiline
              rows={2}   
              id="client-name" 
              fullWidth required
              value={documentData.description}
          />                      
        </Grid>  

        <Grid item xs={12} md={3} >
          <Typography variant="h6">Date File</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Date File"
                    inputFormat="dd/MM/yyyy"
                    value={documentData.fileDate}
                    onChange={(e)=> handleAddDocumentData("fileDate",e)}
                    renderInput={(params) => <TextField {...params} />}
                  />        
            </LocalizationProvider>                 
        </Grid>  

        {invoiceDisplay()}
                                                                                                                                                                            
        </Grid>        
      )
    }

    return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
         <Skeleton variant="rectangular" height={300} />                                                                                                                                                                                   
      </Grid>        
      )
    
  }

  return (
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">Upload a document</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {displayContent()}                    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" onClick={() => uploadDocument()} color="primary">
              Update document
            </LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
    )
}