import { Button, Grid, FormControl, Select, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },  
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));





export default function DialogRowPickValue(props) {
  const classes = useStyles();

  const {listValues, fieldName, fieldValue, dialogOpen, setDialogOpen, setErrorMessage, rowId, callBackFunction} = props;
  DialogRowPickValue.propTypes = {
    rowId: PropTypes.number,
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    callBackFunction: PropTypes.func.isRequired,
    listValues: PropTypes.array.isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.string.isRequired,
  }; 

  const [value, setValue] = useState(fieldValue);  
  useEffect(() => {
    setValue(fieldValue); 
 }, [fieldValue]);


  const handleSaveDialog = () => {
    callBackFunction(rowId,fieldName, value)
    setDialogOpen(false);
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  } 

  const handleSelected = (event) => {
    setValue(event.target.value);
  };  


  return (
      <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">Select a value for field {fieldName} </DialogTitle> 
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            <Grid item xs={12} md={12}>    
              Pick a value:
            </Grid>              
            <Grid item xs={12}  className={classes.root}>
              <FormControl> 
                <Select style={{width: 300}}
                  autoWidth	
                  label="status Size" 
                  labelId="select-status"
                  id="select-status"
                  value={value}
                  onChange={handleSelected}
                >
                  {listValues.map((item,i) => (
                      <MenuItem value={item} key={i}>{item}</MenuItem>
                  ))}
                </Select> 
              </FormControl>
            </Grid>
          </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" onClick={handleSaveDialog} color="primary">
            Select
          </Button>
          <Button variant="text" onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>           
    )
}