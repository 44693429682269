// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function ClientTransactions() {
  return (
    <Page title="Cash Transactions">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Cash Transactions</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h5">React Screen</Typography>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
