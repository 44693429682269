
import {  Button, Snackbar, Alert, IconButton  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';


export default function ButtonDialogCasePallet(props) {
  const {caseId, palletCode, caseName, callbackCase, isDisabled} = props;
  ButtonDialogCasePallet.propTypes = {
    caseId: PropTypes.number.isRequired,
    palletCode: PropTypes.string,
    caseName: PropTypes.string.isRequired,
    callbackCase: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  };  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataGrid, setDataGrid] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const columns =  [
    { field: 'id', headerName: ' ', width:200
    , renderCell: (params) =>  (
      <Button  variant="contained" onClick={() => updateCasePallet(params.value)} color="primary" autoFocus>
        Add
      </Button>
      )
    },  
    { field: 'code', headerName: 'Code', width:200 },
    { field: 'observation', headerName: 'Observation', width:300  },
    { field: 'countCase', headerName: '# Cases', width:200  },
    { field: 'weight', headerName: 'Weight', width:200  },
    { field: 'lastAddition', headerName: 'lastAddition', width:200  }
];


  const updatePalletList = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait("/pallets", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDataGrid(res.data.data)  
    setIsLoading(false);  
    return true    
  }
  

  const handleDeletePalletCase = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/pallet-case/0/${caseId}`, "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }  
    setConfirmationMessage(res.data.message);
    callbackCase("palletId",  null);
    callbackCase("palletCode",  "n/a");
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  


  const updateCasePallet = async (palletId) => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/pallet-case/${palletId}/${caseId}`, "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }  
    setConfirmationMessage(res.data.message);
    callbackCase("palletId",  palletId);
    callbackCase("palletCode",  dataGrid.find(obj => obj.id === palletId).code);
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  

  const handleOpenDialog = () => {
    setDialogOpen(true);
    updatePalletList();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  let jsxWarning = "";
  if (palletCode) {
    jsxWarning = (
    <span>

          <Alert severity="warning">Case is already assigned to pallet {palletCode}
      
            <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={()=> { handleDeletePalletCase();}}
            >
              <DeleteIcon /> Remove from pallet
            </IconButton > 
            </Alert>
    </span>

    )
  }  
  return (
    <span>
      <LoadingButton  disabled={isDisabled}  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Add to Pallet</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Pick Pallet for Case {caseName}  </DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {jsxWarning}
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={dataGrid}
                columns= {columns}
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
        {snackbarError()}      
        {snackbarSuccess()}
    </span>
    )
}