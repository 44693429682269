// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Chip, MenuItem, Select, Box, Grid, Container, Typography, Snackbar, Skeleton,  FormControl,  Alert, Button,  ButtonGroup, IconButton, Stack, Card, CardContent, AppBar, Link  } from '@mui/material';
// Checkbox,  FormControlLabel,
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import DescriptionIcon from '@mui/icons-material/Description';


// components
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency,fNumber } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}


export default function SupplierItem() {
  const classes = useStyles();   
  const { id } = useParams();    
  const [itemId, setItemId] = useState(id);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

 
  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingButton,setIsLoadingButton] = useState(false);   
  

  function handleChange(event, newValue) {
    setValue(newValue);
  }


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }  

  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/supplier/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/supplier-param`, "get", null);
    };
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
    } 
  }, [id]);


  const columnsShipping =   [
    { field: 'id', headerName: ' ', width:80
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/shipping/list/${params.value}`)}
        >         
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
    { field: 'name', headerName: 'name', width:200 },
    { field: 'description', headerName: 'description', width:200 },
    { field: 'supplier', headerName: 'Supplier', width:300 },
    { field: 'transporter', headerName: 'Transporter', width:300 },
    { field: 'startShipping', headerName: 'Shipped On', width:200 },
    { field: 'endShipping', headerName: 'End Shipping', width:200 },
    { field: 'destinationPosition', headerName: 'destination', width:150 },
    { field: 'number1195', headerName: '11-95', width:150 },
    { field: 'customsDocument', headerName: 'customsDoc', width:150 },
    { field: 'createdAt', headerName: 'createdAt', width:150 },
    { field: 'updatedAt', headerName: 'updatedAt', minWidth: 150, flex:1  },    
];


const columnsPurchase =  [
  { field: 'id', headerName: ' ', width:80
  , renderCell: (params) => (
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/purchase/list/${params.value}`)}
      >
        <ZoomInIcon />
        {params.value}
      </IconButton >
  )
},
{ field: 'isPaid', headerName: '% Paid', width:150 },
{ field: 'invoice', headerName: 'Invoice', width:150 },
  { field: 'datePurchase', headerName: 'Date Purchase', width:150 },
  { field: 'name', headerName: 'name', width:250 },
  { field: 'description', headerName: 'description', width:300 },
  { field: 'supplier', headerName: 'Supplier', width:300  },   
  { field: 'orderValuePreliminary', headerName: 'Purchase Value', width:200  , renderCell: (params) =>  fNumber(params.value) }, 
  { field: 'orderValuePreliminaryCurrency', headerName: 'Currency', minWidth: 150  },
  { field: 'orderValueBookedInDatabaseEUR', headerName: 'Purchase Value EUR', minWidth: 150,  flex:1 , renderCell: (params) =>  fCurrency(params.value) }, 
];


const columnsCases =  [
  { field: 'quantity', headerName: 'Quantity', width:100 },
{ field: 'caseName', headerName: 'Case', width:300 },
{ field: 'bottleSize', headerName: 'BottleSize', width:100 },
  { field: 'packSize', headerName: 'PackSize', width:100 },
  { field: 'packType', headerName: 'PackType', width:100 },
  { field: 'status', headerName: 'Status', width:100  },
  { field: 'purchaseId', headerName: 'Purchase', width:250
  , renderCell: (params) => {
      if (params.value) {
        return (
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/purchase/list/${params.value}`)}
      >
        <ZoomInIcon />
        {params.row.purchaseName}
      </IconButton >        
    )}
    return "No Purchase"
    }
  },
  { field: 'shippingId', headerName: 'Shipping', width:250
  , renderCell: (params) => {
    if (params.value) {
      return (
    <IconButton 
      variant="contained"
      color="primary"
      size="small"
      onClick={() => navigate(`/shipping/list/${params.value}`)}
    >
      <ZoomInIcon />
      {params.row.shippingName}
    </IconButton >        
  )}
  return "No Shipping"
  }
},
  { field: 'datePosition', headerName: 'Last Position Date', width:150 },
  { field: 'position', headerName: 'position', minWidth: 200  },
  { field: 'label', headerName: 'category', minWidth: 150,  flex:1}
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  


const uploadItem = async () => {
  setIsLoadingButton(true);      
  let url = '/supplier/';
  if (itemId !=="new") {
    url += itemId;
  } 

  let res = null;
  if (itemId ==="new") {
    res = await fetchDataAwait(url, "post", data.supplier);
  }
  else {
    res = await fetchDataAwait(url, "put", data.supplier);
  }
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    if (itemId ==="new") {
      setItemId(res.data.supplier.id);
    }
    setConfirmationMessage("Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}


const handleSaveClick = () => {
  uploadItem(); 
}


const setSupplierData = (field,value) => {
  data.supplier[field] = value
  setData({...data});
}



const handleCancelClick = () => { 
  setData({...data});
  setIsDisabled(true);  
}



 

function displayPanels() {
  if (itemId!=="new") {
    return (
      <Grid item xs={12}>
        <Card >
          <CardContentNoPadding >            
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Purchases" {...a11yProps(0)} />
          <Tab label="Shippings" {...a11yProps(1)} />          
          <Tab label="Cases" {...a11yProps(2)} />  

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>

          <DataGridPro
            autoHeight 
            pagination
            rows={data.purchase} 
            columns= {columnsPurchase}
            
            loading={isLoading}
            components={{
              Toolbar: CustomToolbar,
            }}         
          />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DataGridPro
          autoHeight 
          pagination
          rows={data.shipping} 
          columns= {columnsShipping}
          
          loading={isLoading}
          components={{
            Toolbar: CustomToolbar,
          }}         
        />                
      </TabPanel>  
      <TabPanel value={value} index={2}>

          <DataGridPro
            autoHeight 
            pagination
            rows={data.cases} 
            columns= {columnsCases}
            
            loading={isLoading}
            components={{
              Toolbar: CustomToolbar,
            }}         
          />
      </TabPanel>       
      </CardContentNoPadding >
        </Card >                  
      </Grid>
    )
  }
  return ""
}


function displayActionButtons() {
  if (isLoading || !data) {
    return ""
  }
  if (isDisabled) {
    const buttonJsx ="";

    return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between" >       
      <Button
        variant="contained"
        onClick={() => setIsDisabled(false)}
      >
        <EditIcon/>
        Edit
      </Button>
      {buttonJsx}
    </Stack>        
    )
  }

  return (    
  <ButtonGroup>
    <LoadingButton
      variant="contained"
      loading={isLoadingButton}
      onClick={() => handleSaveClick()}
    >
      <SaveIcon/>
      Save
    </LoadingButton>
    <Button
      variant="contained"
      onClick={() => handleCancelClick(true)}
    >
      <CancelIcon/>
      Cancel
    </Button>        
</ButtonGroup>
)
}



  if (isLoading) {
    return (
      <Page title="Supplier">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Supplier</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error && data) {   
    return (
      <Page title="Supplier">
        <Container maxWidth="false">

          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Supplier</Typography>                              
                {displayActionButtons()}                                       
          </Stack>                          
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Name</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> setSupplierData("name",e.target.value)}
                          className={classes.field} 
                          id="p-name" 
                          fullWidth required
                          value={data.supplier.name}
                          disabled={isDisabled}
                      />                      
                    </Grid>  
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Country</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          className={classes.field} 
                          id="country" 
                          label="Country" 
                          fullWidth required
                          value={data.supplier.country}
                          disabled={isDisabled}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e)=> setSupplierData("country",e.target.value)}
                      />
                    </Grid>                
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Region</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          className={classes.field} 
                          id="region" 
                          label="Region" 
                          fullWidth required
                          value={data.supplier.region}
                          disabled={isDisabled}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e)=> setSupplierData("region",e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Type</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          className={classes.field} 
                          id="type" 
                          label="type" 
                          fullWidth required
                          value={data.supplier.positionType}
                          disabled
                          InputLabelProps={{ shrink: true }}
                          onChange={(e)=> setSupplierData("positionType",e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} >
                              <Typography variant="h6">Contact Information</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9} >
                              <TextField 
                                  onChange={(e)=> setSupplierData("contactInfo",e.target.value)}
                                  className={classes.field} 
                                  id="p-contactInfo" 
                                  label="Contact Info" 
                                  fullWidth required
                                  value={data.supplier.contactInfo}
                                  disabled={isDisabled}
                                  multiline
                                  rows={4}                          
                              />                         
                            </Grid>                     


                    <Grid item xs={12}>
                    <Card >
                        <CardContent>
                          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                                                
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Position Name</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9} >
                              <TextField 
                                  onChange={(e)=> setSupplierData("position",e.target.value)}
                                  className={classes.field} 
                                  id="p-destinationPosition" 
                                  fullWidth required
                                  value={data.supplier.position}
                                  disabled={isDisabled}
                              />                      
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Label (short name)</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9}>
                              <TextField 
                                  onChange={(e)=> setSupplierData("positionLabel",e.target.value)}
                                  className={classes.field} 
                                  id="p-positionLabel" 
                                  fullWidth required
                                  value={data.supplier.positionLabel}
                                  disabled={isDisabled}
                              />                      
                            </Grid>  
                            <Grid item xs={12} md={3} >
                              <Typography variant="h6">Address</Typography>
                            </Grid>  
                            <Grid item xs={12} md={9} >
                              <TextField 
                                  onChange={(e)=> setSupplierData("positionAddress",e.target.value)}
                                  className={classes.field} 
                                  id="p-positionAddress" 
                                  fullWidth required
                                  value={data.shipping.positionAddress}
                                  disabled={isDisabled}
                                  multiline
                                  rows={4}                          
                              />                         
                            </Grid>                             
                            <Grid item xs={6} md={2} >
                              <Typography variant="h6">Latitude</Typography>
                            </Grid>  
                            <Grid item xs={6} md={2} >
                              <TextField 
                                  onChange={(e)=> setSupplierData("positionLatitude",e.target.value)}
                                  className={classes.field} 
                                  id="p-positionLatitude" 
                                  fullWidth required
                                  value={data.supplier.positionLatitude}
                                  disabled={isDisabled}
                              />                      
                            </Grid>    
                            <Grid item xs={6} md={2} >
                              <Typography variant="h6">Longitude</Typography>
                            </Grid>  
                            <Grid item xs={6} md={2} >
                              <TextField 
                                  onChange={(e)=> setSupplierData("positionLongitude",e.target.value)}
                                  className={classes.field} 
                                  id="p-positionLongitude" 
                                  fullWidth required
                                  value={data.supplier.positionLongitude}
                                  disabled={isDisabled}
                              />                      
                            </Grid>                                                                                                                                                                                                                                                                                                                                                                                   
                          </Grid>  
                        </CardContent>
                      </Card >                        
                    </Grid> 

                    <Grid item xs={12} >
                      <small> created at {data.supplier.createdAt} | updated at {data.supplier.updatedAt} </small> 
                    </Grid>                                                                                                                                                                                                     
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>  
            {displayPanels()}                                      

          </Grid>
        </Container>
        {snackbarError()}
        {snackbarSuccess()}
      </Page>
    );
  }
  return (
    <Page title="Supplier">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Supplier</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

