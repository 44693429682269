// material
import { makeStyles } from '@mui/styles';
import { Box, Grid, Container, Typography, Skeleton, Button, IconButton, ButtonGroup, Card, CardContent, CardActions, Select,  MenuItem,Snackbar, Alert,   } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
// components
// import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));

// ----------------------------------------------------------------------

export default function TargetAllocation() {
  const classes = useStyles();
  // const navigate = useNavigate();
  // Page
  const [targetId, setTargetId] = useState(null);
  // Select Existing
  const [selectedTargetId, setSelectedTargetId] = useState("");
  // Create new
  const [openDialogForm, setopenDialogForm] = useState(false);
  // Link to client
  const [openDialogFormTargetUser, setopenDialogFormTargetUser] = useState(false);
 
  const [targetName, setTargetName] = useState('');
  const [targetDesc, setTargetDesc] = useState('');
  const [targetNameError, setTargetNameError] = useState(false);
  const [targetDescError, setTargetDescError] = useState(false);
  const [targetComposition, setTargetComposition] = useState([]);
  const columnsTargetComposition = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.value);}}
        >
          <DeleteIcon />
        </IconButton >        
      </strong>
    )
  },      
  { field: 'category', headerName: 'Category', width:200   },  
  { field: 'region', headerName: 'Region', width:200 },
  { field: 'lowerRange', headerName: 'Lower Range (%)', cellClassName: 'editable-theme--cell', width:200, type: 'number' , editable: true  },
  { field: 'upperRange', headerName: 'Upper Range (%)', cellClassName: 'editable-theme--cell', width:200, type: 'number' , editable: true  },
  { field: 'error', headerName: 'error', flex:1   , 
    renderCell: (params) => {
      if (params.value!=='' && params.value) {
        return (<Alert severity="error">{params.value}</Alert>)
      } 
      return ''
    }
  }
  ];
  // const [targetCompositionDates, setTargetCompositionDates] = useState([]);
  // Modify 
  const [exitDialogOpen, setExitDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [region, setRegion] = useState([]);
  const [category, setCategory] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedRegionId, setSelectedRegionId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  // API NEW
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  // SNACKBAR
  const [errorMessage, setErrorMessage] = useState(null);  
  const [confirmationMessage, setConfirmationMessage] = useState(null);   


  // API LIST
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [dataList, setDataList] = useState(null);
  const [errorList, setErrorList] = useState(null);
  const fetchDataList = async () => {
    fetchData(setIsLoadingList,setDataList,setErrorList,"/allocation/get-target-list", "get", null);
  };
  useEffect(() => {
    fetchDataList();
  }, []);  
  useEffect(() => {
    if (errorList) {
      setErrorMessage(errorList.message);
    }
  }, [errorList]);


  const handleSelectedTargetId = (event) => {
    setSelectedTargetId(event.target.value);
  };
  const handleSelectedUserId = (event) => {
    setSelectedUserId(event.target.value);
  };  
  const handleSelectedCategoryId = (event) => {
    setSelectedCategoryId(event.target.value);
  };
  const handleSelectedRegionId = (event) => {
    setSelectedRegionId(event.target.value);
  };

  const handleViewModify = () => {
    getTargetAllocationData(selectedTargetId);
  };


  const handleClickOpenTargetUser = async () => {
    setIsLoadingNew(true);
    if (users.length===0) {
      const res = await fetchDataAwait("/allocation/get-user", "get", null);
      if (res.error) {
        setErrorMessage(res.error.message);
      }
      else {      
        setopenDialogFormTargetUser(true);
        setUsers(res.data.users); 
      }
    } else {
      setopenDialogFormTargetUser(true);
    }
    setIsLoadingNew(false);    
  };

  const handleCloseTargetUser = () => {
    setopenDialogFormTargetUser(false);
  }; 


  const handleClickOpen = () => {
    setopenDialogForm(true);
  };
  const handleClose = () => {
    setopenDialogForm(false);
  }; 


  const handleDialogSubmit = async () => {
    setTargetNameError(false);
    setTargetDescError(false);
    
    if ( targetName === '') {
      setTargetNameError(true);
    }
    if ( targetDesc === '') {
      setTargetDescError(true);
    }   
    if ( targetName && targetDesc) {
      setIsLoadingNew(true);
      const res = await fetchDataAwait("/allocation/create-target", "post", {name: targetName, description: targetDesc});
      if (res.error) {
        setErrorMessage(res.error.message);
      }
      else {
        // UPDATE ID        
        handleClose();
        setTargetId(res.data.id);
      }
      setIsLoadingNew(false);

    }
  }


  const handleDialogSubmitTargetUser = async () => {
    if ( selectedUserId === '' || selectedTargetId ==='') {
      setErrorMessage("Select a client and a target.");
      return false;
    }

    if ( selectedUserId && selectedTargetId) {
      setIsLoadingNew(true);
      const res = await fetchDataAwait("/allocation/update-target-user", "put", {userId: selectedUserId, targetId: selectedTargetId});
      if (res.error) {
        setErrorMessage(res.error.message);
      }
      else {      
        handleCloseTargetUser();
        setConfirmationMessage("Succesfully linked target and client");
      }
      setIsLoadingNew(false);
    }
    return true;
  }


  const getTargetAllocationData = async (targetId) => {
      setIsLoadingNew(true);
      const res = await fetchDataAwait(`/allocation/get-target-composition/${targetId}`, "get",null);
      if (res.error) {
        setErrorMessage(res.error.message);
      }
      else {
        setTargetName(res.data.description.name);
        setTargetDesc(res.data.description.description);

        setTargetComposition(res.data.composition);
        // setTargetCompositionDates(res.data.compo_dates);
        setTargetId(targetId);
      }
      setIsLoadingNew(false);
  }


  function dialogFormNew() {
    return (
      <Dialog open={openDialogForm} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>New Target</DialogTitle>
        <DialogContent>          
          <TextField 
            onChange={(e)=> setTargetName(e.target.value)}
            className={classes.field} 
            id="target-name" 
            label="Target Name" 
            fullWidth required
            value={targetName}
            error={targetNameError}
          />
          <TextField
            onChange={(e)=> setTargetDesc(e.target.value)}
            className={classes.field}
            id="target-description"
            label="Description"
            value={targetDesc}
            multiline
            rows={4}
            fullWidth required
            error={targetDescError}
          />        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton loading={isLoadingNew} variant="contained" onClick={handleDialogSubmit} color="primary">
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }



  function dialogFormTargetUser() {
    return (
     
      <Dialog open={openDialogFormTargetUser} onClose={handleCloseTargetUser} fullWidth maxWidth='sm'>
        <DialogTitle>Link Target</DialogTitle>
        <DialogContent>          
          <DialogContentText id="alert-dialog-description">
            <Grid  item xs={12} sm={6} md={3}>
              Target
              <Select style={{width: 200}}
                autoWidth	
                labelId="select-target"
                id="select-target"
                value={selectedTargetId}
                onChange={handleSelectedTargetId}
              >
                {dataList.data.map((item,i) => (
                    <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                ))}
              </Select>  
            </Grid>
            <Grid  item xs={12} sm={6} md={3}>
                Client
              <Select style={{width: 200}}
                  autoWidth	
                  labelId="select-client"
                  id="select-client"
                  value={selectedUserId}
                  onChange={handleSelectedUserId}
                >
                  {users.map((item,i) => (
                      <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                  ))}
              </Select>  
            </Grid>                      
          </DialogContentText>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTargetUser} color="primary">
            Cancel
          </Button>
          <LoadingButton loading={isLoadingNew} variant="contained" onClick={handleDialogSubmitTargetUser} color="primary">
            Link
          </LoadingButton>
        </DialogActions>
      </Dialog>

    );
  }


  function loadingSelect() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={6} md={3} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>        
      </Grid>
    )
  }

  function selectTarget() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={6} md={3} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Create a new Target
              </Typography>
            </CardContent>
            <CardActions>
              <Button  onClick={handleClickOpen} variant="contained" color="primary">CREATE</Button>
              {dialogFormNew()}
            </CardActions>
        </Grid>
        <Grid item xs={12} sm={6} md={3} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Modify an existing Target
              </Typography>              
              <Select style={{width: 200}}
                autoWidth	
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTargetId}
                onChange={handleSelectedTargetId}
              >
                {dataList.data.map((item,i) => (
                    <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                ))}
              </Select>              
            </CardContent>
            <CardActions>
              <LoadingButton loading={isLoadingNew} variant="contained" disabled={dataList.data.length===0} onClick={handleViewModify} color="primary">VIEW/MODIFY</LoadingButton>
            </CardActions>
  
        </Grid>
        <Grid item xs={12} sm={6} md={3} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Link a Target to a client
              </Typography>
            </CardContent>
            <CardActions>
              <LoadingButton  loading={isLoadingNew}  onClick={handleClickOpenTargetUser} variant="contained" color="primary">LINK</LoadingButton>
              {dialogFormTargetUser()}
            </CardActions>
        </Grid>             
      </Grid>
    )
  }


  const handleExitClick = () => {setExitDialogOpen(true);}
  const handleCloseExitDialog = () => {setExitDialogOpen(false);}
  const handleCloseExitDialogConfirmed = () => {setExitDialogOpen(false);setTargetId(null);}

  const handleRemoveRow = (rowId) => {
    const filteredTargetComposition = targetComposition.filter((el) =>  el.id !== rowId); 
    setTargetComposition(filteredTargetComposition);
  }
  const handleAddClick = async () => {
    setIsLoadingNew(true);
    if (region.length===0) {
      const res = await fetchDataAwait("/allocation/get-region-category", "get", null);
      if (res.error) {
        setErrorMessage(res.error.message);
      }
      else {      
        setAddDialogOpen(true);
        setRegion(res.data.region);
        setCategory(res.data.category);      
      }
    } else {
      setAddDialogOpen(true);
    }
    setIsLoadingNew(false);

  }
  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);    
    setSelectedRegionId("");
    setSelectedCategoryId("");
    }
  const handleCloseAddDialogConfirmed = () => {
    if ( selectedRegionId === '' || selectedCategoryId ==='') {
      setErrorMessage("Select a region and a category (use n/a for one of them if necessary)");
      return false;
    }
    if ( selectedRegionId === 0 && selectedCategoryId ===0) {
      setErrorMessage("Both region cannot be set at n/a.");
      return false;
    }   
    if ( selectedRegionId !== 0 && selectedCategoryId ===-1) {
      setErrorMessage("Cash has no region. Set it to n/a.");
      return false;
    }   


    // Check if row already exists
   if (targetComposition.some((el) => 
      el.regionId === selectedRegionId && el.categoryId === selectedCategoryId
    )) {
      setErrorMessage("This combination is already added to the target.");
      return false;
    }

    // Add to existing Composition
    const newRow = {
      id: `${selectedCategoryId}|${selectedRegionId}`,
      allocationTargetId:null,
      regionId: selectedRegionId,
      region: region.find(obj => obj.id === selectedRegionId).name,
      categoryId: selectedCategoryId,
      category: category.find(obj => obj.id === selectedCategoryId).name,
      lowerRange:0,
      upperRange:0,
      error:""
    }
    setTargetComposition([...targetComposition, newRow]);
    setAddDialogOpen(false);
    setSelectedRegionId("");
    setSelectedCategoryId("");

    return true;
  }


  const checkTargetBeforeUpload = () => {
    let errorSummary = "";
    // CHECK INDIVIDUAL ROWS
    const errorList = targetComposition.map( x => {
      if (x.lowerRange <0 || x.lowerRange >100) {
        x.error =  "Range is incorrect (0-100 only)"
        return x.error
      }
      if (x.upperRange <0 || x.upperRange >100) {
        x.error = "Range is incorrect (0-100 only)"
        return  x.error
      }      
      if (x.upperRange < x.lowerRange ) {
        x.error =  "Lower range is greater than upper range."
        return  x.error
      }
      x.error ="";
      return x.error
    })
    setTargetComposition(targetComposition);
    // CHECK OVERALL CONSTRAINTS
    const sumUpper = targetComposition.reduce( (a, b) => a + b.upperRange, 0)
    const sumLower = targetComposition.reduce( (a, b) => a + b.lowerRange , 0)    
    if (sumLower>100) {
      errorSummary += `Sum of lower bounds is greater than 100% (${sumLower}%). `
    }
    if (sumUpper<100) {
      errorSummary += `Sum of upper bounds is lower than 100% (${sumUpper}%), please add a line of cash between 0 and ${100-sumUpper}%.   `
    }    
    // DISPLAY ERROR MESSAGE
    if (errorList.filter( x => x!=="").length>0 || errorSummary !=="") {
      setErrorMessage(`Error(s) detected. ${errorSummary}`);
      return false
    }
    return true
  }


  const saveNewTargetAllocation = async () => {
    setIsLoadingNew(true);      
    const res = await fetchDataAwait(`/allocation/update-target-composition/${targetId}`, "put", targetComposition);
    if (res.error) {
      setErrorMessage(res.error.message);
    }   
    setIsLoadingNew(false);      
  }


  const handleSaveClick = () => { 
    if (checkTargetBeforeUpload()) {
      saveNewTargetAllocation();
    } 
  }

  const handleSaveDescriptionClick = async () => { 
    setIsLoadingNew(true);  
    setTargetNameError(false);
    setTargetDescError(false);
    
    if ( targetName === '') {
      setTargetNameError(true);
    }
    if ( targetDesc === '') {
      setTargetDescError(true);
    }   
    if ( targetName && targetDesc) {
      setIsLoadingNew(true);
      const res = await fetchDataAwait(`/allocation/update-target/${targetId}`, "put", {name: targetName, description: targetDesc});
      if (res.error) {
        setErrorMessage(res.error.message);
      } else {
        setIsDisabled(true); 
        fetchDataList();
      }
      setIsLoadingNew(false);
    } 
    setIsLoadingNew(false);  
  }  

  const handleEditDescriptionClick = () => {
    if (isDisabled) {
      setIsDisabled(false);  
    } else {
      setIsDisabled(true);        
    }
  }  

  function defineTarget() {
    return (

      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
        <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">
          <Grid container item xs={12} > 
            <Button disabled={!isDisabled} onClick={handleEditDescriptionClick}><EditIcon/>Edit</Button> 
            <LoadingButton loading={isLoadingNew} disabled={isDisabled} onClick={handleSaveDescriptionClick}><SaveIcon/>Save</LoadingButton> 
            <Button disabled={isDisabled} onClick={handleEditDescriptionClick}><CancelIcon/></Button> 
          </Grid>      
          <Grid container item xs={12} sm={6} >
            <TextField 
              onChange={(e)=> setTargetName(e.target.value)}
              className={classes.field} 
              id="target-name" 
              label="Target Name" 
              fullWidth required
              value={targetName}
              disabled={isDisabled}
              error={targetNameError}
            />
          </Grid>
          <Grid container item xs={12} sm={6}>            
            <TextField
              onChange={(e)=> setTargetDesc(e.target.value)}
              className={classes.field}
              id="target-description"
              label="Description"
              value={targetDesc}
              multiline
              rows={4}
              fullWidth required
              disabled={isDisabled}
              error={targetDescError}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">
          <Grid container item xs={12} >
              <ButtonGroup variant="contained"  color="primary" aria-label="contained primary button group">
                <LoadingButton loading={isLoadingNew} onClick={handleAddClick}><AddIcon/>Add Row</LoadingButton>
                <Dialog
                  open={addDialogOpen}
                  onClose={handleCloseAddDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Add a target row</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Grid  item xs={12} sm={6} md={3}>
                          Category
                          <Select style={{width: 300}}
                            autoWidth	
                            labelId="select-category"
                            id="select-category"
                            value={selectedCategoryId}
                            onChange={handleSelectedCategoryId}
                          >
                            {category.map((item,i) => (
                                <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                            ))}
                          </Select>  
                        </Grid>
                        <Grid  item xs={12} sm={6} md={3}>
                            Region
                          <Select style={{width: 300}}
                              autoWidth	
                              labelId="select-region"
                              id="select-region"
                              value={selectedRegionId}
                              onChange={handleSelectedRegionId}
                            >
                              {region.map((item,i) => (
                                  <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                              ))}
                          </Select>  
                        </Grid>                      
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button variant="text" onClick={handleCloseAddDialog} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleCloseAddDialogConfirmed} color="primary" autoFocus>
                        ADD
                      </Button>
                    </DialogActions>
                  </Dialog>              
                <LoadingButton loading={isLoadingNew}  onClick={handleSaveClick}><SaveIcon/>Save</LoadingButton>
            </ButtonGroup>
            <Button onClick={handleExitClick}><KeyboardReturnIcon/>Return to Target Selection</Button>
            <Dialog
            open={exitDialogOpen}
            onClose={handleCloseExitDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Are you sure you want to leave this page?</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  If you leave this page, all unsaved changes will be lost.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={handleCloseExitDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleCloseExitDialogConfirmed} color="primary" autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>            
          </Grid>               
        </Grid>
          <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="center"  className={classes.root}>  
            <DataGridPro 
            pagination
            overflow="auto"
            autoHeight 
            rows={targetComposition} // {rows} //
            columns= {columnsTargetComposition} //  {data.columns} //
            pageSize={100}
            loading={isLoadingNew}
            onCellEditCommit={handleCellEditCommit}
          />           
              
        </Grid>              
      </Grid>

    )
  }

  const handleCellEditCommit = ({ id, field, value }) => {
        const updatedRows = targetComposition.map((row) => {
          if (row.id === id) {
            row[field] = value;
            return row;
          }
          return row;
        });
        setTargetComposition(updatedRows);
    };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };  
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  


  function displayPage(func) {
    return (
      <Page title="Target Allocation">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Target Allocation</Typography>
          </Box>
          {func()}
        </Container>
        {snackbarError()}       
        {snackbarSuccess()} 
      </Page>
    );
  }

  // PAGE LOGIC
  if (targetId) {
    return displayPage(defineTarget);
  } 
  if (!isLoadingList) {
    return displayPage(selectTarget);
  }

    return displayPage(loadingSelect);
}
