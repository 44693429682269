// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Button ,Link, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import DescriptionIcon from '@mui/icons-material/Description';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';



// ----------------------------------------------------------------------


export default function ManagementFee() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton,setIsLoadingButton] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [endDate, setEndDate] = useState(''); 
  const [startDate, setStartDate] = useState(''); 
  const [openRange, setOpenRange] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/get-management-fee-calculations", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
    { field: 'id', headerName: 'Id', width:20
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/client/management-fee/list/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
    { field: 'createdAt', headerName: 'Generated At', width:200 },    
    { field: 'startDate', headerName: 'Start Date', width:200 },
    { field: 'endDate', headerName: 'End Date', width:200   },
    { field: 'status', headerName: 'Status', width:200  },
    { field: 'url', headerName: 'CSV', width:100, renderCell: (params) => {
      if (params.value) {
        return (
          <Link
            target="_blank"
            href={params.value}
          >
            <DescriptionIcon />
          </Link>                 
        )
      }
      return "-"
    }},    
  ];



  const dialogRange = () =>  (
    <Dialog
    open={openRange}
    onClose={() => handleDialogOpenRangeClose()}
    aria-labelledby="alert-dialog-Range"
    aria-describedby="alert-dialog-Range-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate Management Fees between:</DialogTitle> 
      <DialogContent sx={{ pb: 5 }}>
        Please select the time period - from close to close.<br/> <small>For example "2021-12-31" - "2022-03-31" for Q1 2022</small><br/><br/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start date"
                  inputFormat="dd/MM/yyyy"
                  value={startDate}
                  onChange={(e)=> setStartDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>    
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="End date"
                  inputFormat="dd/MM/yyyy"
                  value={endDate}
                  onChange={(e)=> setEndDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider>  
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateRange()} color="primary">
          Create
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenRangeClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenRangeClose = () => {
    setOpenRange(false); 
  }  
  const handleGenerateRange = async () => {
    setIsLoadingButton(true);      
    const url = `/generate-management-fee`;
    if (Object.prototype.toString.call(startDate) !== "[object Date]" || Object.prototype.toString.call(endDate) !== "[object Date]") {
      setErrorMessage("Dates are incorrect");
      setIsLoadingButton(false);      
      return 0;      
    }
    const res = await fetchDataAwait(url, "post", {dateStart:startDate.toISOString(),  dateEnd:endDate.toISOString()});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      navigate(`/client/management-fee/list/${res.data.id}`)
    }   
    setIsLoadingButton(false);      
    return 0;
  }  



  if (isLoading) {
    return (
      <Page title="Management Fee Calculator">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Management Fee Calculator</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Management Fee Calculator">
        <Container maxWidth="false">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
              Management Fee Calculator
              </Typography>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => setOpenRange(true)}
              >
                New
              </Button>
            </Stack> 
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
       
              <DataGridPro overflow="auto"
                autoHeight 
                pagination
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
          {dialogRange()}
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Management Fee Calculator">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Management Fee Calculator</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}


