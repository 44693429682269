// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Button  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';


// ----------------------------------------------------------------------


export default function Wine() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  // Rows filtering
  // const [searched, setSearched] = useState("");
  // const [rows, setRows] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/wines", "get", null);
    };
    fetchDataReactScreen();
  }, []);  

  const columns =  [
      { field: 'id', headerName: ' ', width:20
      , renderCell: (params) => (
        <strong>
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/stock/wine/${params.value}`)}
          >
            <ZoomInIcon />
          </IconButton >        
        </strong>
      )
    },
    { field: 'name', headerName: 'Wine', width:200 },
    { field: 'estate', headerName: 'Estate', width:200   },
    { field: 'lwin', headerName: 'lwin', width:200   },
    { field: 'country', headerName: 'Country', width:200   },
    { field: 'region', headerName: 'Region', width:200   },
    { field: 'subRegion', headerName: 'subRegion', width:200   },
    { field: 'grapes', headerName: 'Grapes', width:200   },
    { field: 'wineColour', headerName: 'Colour', width:200   }
  ];



  if (isLoading) {
    return (
      <Page title="Wines">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Wines</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Wines">
        <Container maxWidth="false">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Wines
              </Typography>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => navigate(`/stock/wine/new`)}
              >
                New
              </Button>
            </Stack> 
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
       
              <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Wines">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Wines</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

