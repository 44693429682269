// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Stack, Box, Grid, Container, Typography, Skeleton, IconButton, Button, Checkbox,  FormControlLabel, TextField, Snackbar, Alert, Link} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ImageIcon from  '@mui/icons-material/Image';
import DeleteIcon from  '@mui/icons-material/Delete';
import AddIcon from  '@mui/icons-material/Add';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';



// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

export default function Case() {
  const searchRef = useRef();
  const classes = useStyles();     
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null); 
  const [dialogPicture, setDialogPicture] = useState(""); 
  const [dialogOpenPicture, setDialogOpenPicture] = useState(false);  
  const [dialogCaseName, setDialogCaseName] = useState("n/a");  
  const [openCaseSplit, setOpenCaseSplit] = useState(false);  
  const [newPackSize, setNewPackSize] = useState(0);  
  const [newPackFormat, setNewPackFormat] = useState(false);  
  const [isLoadingButton,setIsLoadingButton] = useState(false); 
  const [casesAggregation,setCasesAggregation] = useState([]); 

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/cases", "put", {});
    };
    fetchDataReactScreen();
  }, []);  


  const columnsCaseAggregateSelected =  [
    { field: 'id', headerName: ' ', width:80
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleDeleteCase(params.value)}
        >
          <DeleteIcon />
        </IconButton >
        {params.value}
      </strong>
    )
  },
  { field: 'url', headerName: 'Pic', width:20, renderCell: (params) => {
    if (params.value) {
      return (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleClickPicture(params.value, params.row)}
        >
          <ImageIcon />
        </IconButton >            
      </strong>
      )
    }
    return "-"
  }},    
  { field: 'user', headerName: 'Client', width:200   },
    { field: 'name', headerName: 'Case', width:300   },      
    { field: 'packSize', headerName: 'packSize', width:150   },
    { field: 'bottleSize', headerName: 'bottleSize', width:200   },
    { field: 'lwin', headerName: 'lwin', width:200   },
    { field: 'location', headerName: 'location', width:200   },
    { field: 'palletCode', headerName: 'Pallet', width:200   },
    { field: 'orderReference', headerName: 'orderReference', width:200   },
    { field: 'status', headerName: 'status', width:200   },
    { field: 'saleforbidden', headerName: 'saleforbidden', width:200   },
    { field: 'certificationReference', headerName: '9digit', width:200   },
    { field: 'bookValue', headerName: 'bookValue', width:150   },    
    { field: 'currentValue', headerName: 'currentValue', width:150   },
];

  const columnsCaseAggregate =  [
    { field: 'id', headerName: ' ', width:80
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleAddCase(params.value)}
        >
          <AddIcon />
        </IconButton >
        {params.value}
      </strong>
    )
  },
  { field: 'url', headerName: 'Pic', width:20, renderCell: (params) => {
    if (params.value) {
      return (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleClickPicture(params.value, params.row)}
        >
          <ImageIcon />
        </IconButton >            
      </strong>
      )
    }
    return "-"
  }},  
  { field: 'user', headerName: 'Client', width:200   },    
    { field: 'name', headerName: 'Case', width:300   },      
    { field: 'packSize', headerName: 'packSize', width:150   },
    { field: 'bottleSize', headerName: 'bottleSize', width:200   },
    { field: 'lwin', headerName: 'lwin', width:200   },
    { field: 'location', headerName: 'location', width:200   },
    { field: 'palletCode', headerName: 'Pallet', width:200   },
    { field: 'orderReference', headerName: 'orderReference', width:200   },
    { field: 'status', headerName: 'status', width:200   },
    { field: 'saleforbidden', headerName: 'saleforbidden', width:200   },
    { field: 'certificationReference', headerName: '9digit', width:200   },
    { field: 'bookValue', headerName: 'bookValue', width:150   },
    { field: 'currentValue', headerName: 'currentValue', width:150  },
];

  const columns =  [
      { field: 'id', headerName: ' ', width:80
      , renderCell: (params) => (
        <strong>
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/stock/case/${params.value}`)}
          >
            <ZoomInIcon />
          </IconButton >
          {params.value}
        </strong>
      )
    },
    { field: 'url', headerName: 'Pic', width:20, renderCell: (params) => {
      if (params.value) {
        return (
        <strong>
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClickPicture(params.value, params.row)}
          >
            <ImageIcon />
          </IconButton >            
        </strong>
        )
      }
      return "-"
    }},    
      { field: 'name', headerName: 'Case', width:300   },      
      { field: 'packSize', headerName: 'packSize', width:150   },
      { field: 'bottleSize', headerName: 'bottleSize', width:150   },
      { field: 'lwin', headerName: 'lwin', width:150   },
      { field: 'location', headerName: 'location', width:150   },
      { field: 'palletCode', headerName: 'Pallet', width:150  , renderCell: (params) => {
        if (params.value) {
          return (
          <strong>
            {params.value} 
          </strong>
          )
        }
        return "-"
      }},      
      { field: 'orderReference', headerName: 'orderReference', width:200   },
      { field: 'status', headerName: 'status', width:150   },
      { field: 'certificationReference', headerName: '9digit', width:200   },      
      { field: 'user', headerName: 'Client', width:150  },
      { field: 'saleforbidden', headerName: 'saleforbidden', width:200   },
      { field: 'bookValue', headerName: 'bookValue', width:150 },      
      { field: 'currentValue', headerName: 'currentValue', width:150 },

  ];



  const dialogCaseSplit = () =>  (
    <Dialog
    open={openCaseSplit}
    onClose={() => handleDialogOpenCaseSplitClose()}
    aria-labelledby="alert-dialog-CaseSplit"
    aria-describedby="alert-dialog-CaseSplit-description"
    maxWidth='xl'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Aggregate Cases</DialogTitle> 
      <DialogContent sx={{ pb: 5 }}>
      <Alert severity="error" sx={{ width: '100%' }}>
      Are you sure you want to aggregate cases? It will delete selected cases to create a new one.
      </Alert>
        Please select cases to aggregate:
        <br/>
        <TextField 
                        onChange={(e)=> setNewPackSize(e.target.value)}
                        className={classes.field} 
                        type="number"
                        id="packSize-name" 
                        label="New case pack size" 
                        fullWidth required
                        value={newPackSize}
                        InputLabelProps={{ shrink: true }}
                    />                   
        <FormControlLabel
          control={<Checkbox checked={newPackFormat} onChange={handleChangeCaseSplitCheckBox} />}
          label="Repack in Non Original Wooden case (NOWC)"
          /> 
              <DataGridPro
                pagination
                autoHeight 
                rows={casesAggregation} // {rows} //
                columns= {columnsCaseAggregateSelected} //  {data.columns} //
                       
              />

        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            <Grid item xs={12} md={6}>    
                <TextField fullWidth label="Search cases in database"
                            inputRef={searchRef} 
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                handleSearch()
                              }
                            }}                            
                />
            </Grid>           
            <Grid item xs={12}  md={6} sx={{ pb: 5 }}>                
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} onChange={handleChange} />}
                    label="Include removed cases"
                  />             
                  <LoadingButton loading={isLoadingSearch} variant="contained" onClick={handleSearch}>
                  Search
                </LoadingButton>                
            </Grid>
            <Grid item xs={12}>
              <DataGridPro
                pagination
                autoHeight 
                rows={data.data} // {rows} //
                columns= {columnsCaseAggregate} //  {data.columns} //
                
                loading={isLoadingSearch}     
              />
            </Grid>  
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateCaseSplit()} color="primary">
          Yes
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenCaseSplitClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
  const handleAddCase = (caseId) => {
    const alreadyPresent = casesAggregation.filter((el) =>  el.id === caseId)
    // console.log(alreadyPresent);
    if ( alreadyPresent.length>0 ) {
      return 0;
    }
    const filteredData =  data.data.filter((el) =>  el.id === caseId); 
    setCasesAggregation([...casesAggregation,...filteredData ]); 
    // console.log([...casesAggregation,...filteredData ]);
    return 1;
  }
  const handleDeleteCase = (caseId) => {
    const filteredData =  casesAggregation.filter((el) =>  el.id !== caseId); 
    setCasesAggregation([...filteredData ]); 
  }  
  const handleChangeCaseSplitCheckBox = (event) => {
    setNewPackFormat(event.target.checked);
  };  
  const handleDialogOpenCaseSplitClose = () => {
    setOpenCaseSplit(false); 
  }  
  const handleGenerateCaseSplit = async () => {
    setIsLoadingButton(true);      
    const url = `/cases/repack`;
    const listCasesAggregation = casesAggregation.map((obj) => obj.id);
    const res = await fetchDataAwait(url, "post", {cases: listCasesAggregation, new_format:[newPackSize], repack: newPackFormat });
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setOpenCaseSplit(false);
      setIsLoading(true);
      setConfirmationMessage(<span>{res.data.message}</span>);
      if (res.data.newCases.length===1) {
        navigate(`/stock/case/${res.data.newCases[0][0]}`);
      } else {
        // Reload page
        setIsLoadingSearch(true);
        const resReload = await fetchDataAwait("/cases", "put", {search: searchRef.current.value, searchAll: isChecked});
        if (resReload.error) {
          setErrorMessage(resReload.error.message);
        } else {
          setData(resReload.data)
          console.log(resReload.data)
          setConfirmationMessage(<span>{res.data.message}</span>);
        }
      }
      setIsLoading(false);
    }
    setIsLoadingButton(false);      
    return 0;
  }



  const handleClickPicture = (img, row) => {
    setDialogPicture(img); 
    setDialogCaseName(`${row.name} - ${row.certificationReference}` ); 
    setDialogOpenPicture(true);  
  }  
  function displayDialogPicture() {
     return (
        <Dialog
        open={dialogOpenPicture}
        onClose={() => setDialogOpenPicture(false)}
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle >Case Picture</DialogTitle> 
          <DialogContent>
            <DialogContentText >
              {dialogCaseName}
            </DialogContentText>
              <InnerImageZoom src={dialogPicture}   zoomSrc={dialogPicture} />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => setDialogOpenPicture(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>              
      )
  }    
  
const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

  const handleSearch = async () => {
    setIsLoadingSearch(true);
    console.log(searchRef.current.value);
    const res = await fetchDataAwait("/cases", "put", {search: searchRef.current.value, searchAll: isChecked});
    if (res.error) {
      setErrorMessage(res.error.message);
    }
    else {
      setData(res.data);
      setConfirmationMessage(res.data.message);

    }
    setIsLoadingSearch(false);
  }
  
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };  

  if (isLoading) {
    return (
      <Page title="Cases">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Cases</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Cases">
        <Container maxWidth="false">
          <Stack sx={{ pb: 5 }} direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between" >       
              <Typography variant="h4">Cases</Typography>
              <Button
                variant="contained"
                onClick={() => setOpenCaseSplit(true)}
              >
                AGGREGATE CASES
              </Button>  
              {dialogCaseSplit()}             
          </Stack> 
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            <Grid item xs={12} md={6}>    
                  <TextField fullWidth label="Search cases in database"
                              inputRef={searchRef} 
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  handleSearch()
                                }
                              }}                                   
                  />
              </Grid>
            <Grid item xs={12}  md={6} sx={{ pb: 5 }}>                
                  <FormControlLabel
                    control={<Checkbox checked={isChecked} onChange={handleChange} />}
                    label="Include removed cases"
                  />             
                  <LoadingButton loading={isLoadingSearch} variant="contained" onClick={handleSearch}>
                  Search
                </LoadingButton>                
            </Grid>
            <Grid item xs={12}>
              Get full list of results in link here : <Link href={data.url}>csv file</Link> <br/><br/>
            </Grid>             
            <Grid item xs={12}>
              <DataGridPro
                pagination
                autoHeight 
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                
                loading={isLoadingSearch}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}    
        {displayDialogPicture()}      
      </Page>
    );
  }
  return (
    <Page title="Cases">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Cases</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



