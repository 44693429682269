import { makeStyles } from '@mui/styles';
import { InputLabel,  Button, Snackbar, Alert, Select, FormControl, Grid, Typography, Checkbox, TextField, FormControlLabel, MenuItem } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { useState } from 'react';
import axios from "axios";
import { fetchDataAwait } from '../../utils/api';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function ButtonDialogUploadTransactionDocument(props) {
  const classes = useStyles();   
  const { transactionId, callBackFunction, setErrorMessage, setConfirmationMessage } = props;
  ButtonDialogUploadTransactionDocument.propTypes = {
    transactionId: PropTypes.number,
    callBackFunction: PropTypes.func,
    setErrorMessage: PropTypes.func,
    setConfirmationMessage: PropTypes.func
  };  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [documentData,setDocumentData] = useState({
    name: "",
    invoiceNumber: null,
    description: "",
    visibility: false, 
    type: 1
  });
  const [dataFile,setDataFile] = useState({name:"no file selected"});
  const [documentType, setDocumentType] = useState([]);


  const updateDocList = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait("/document-type", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDocumentType(res.data.documentType);
    setIsLoading(false);  
    return true    
  }
  

  const handleAddDocumentData = (field,value) => {
    documentData[field] = value
    setDocumentData({...documentData});
  }


  const handleChangeCheckBox = (event) => {
    handleAddDocumentData("visibility",event.target.checked)
  }

  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
  };


  const handleSelectedDocType = (event) => {
    handleAddDocumentData("type",event.target.value);
  };

  const uploadDocument = async () => {
    setIsLoading(true);
    const signedUrl = await fetchDataAwait('/get-signed-url/', "put",  {file: dataFile.name, type: documentData.type});
    console.log(signedUrl);
    if (signedUrl.error) {
      setErrorMessage("Error with S3 presigned url");
      setIsLoading(false); 
      return 0;
    }     
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    Object.entries(signedUrl.data.presignedURL.fields).forEach(([k, v]) => {
      postData.append(k, v);
    });

    postData.append('file', dataFile); // The file has be the last element  

    await axios.post(signedUrl.data.presignedURL.url, postData, {
      headers: {'Content-Type': 'multipart/form-data'},
    })          
    .then(async () => {
      console.log("UPLOADING DATA");
      const res = await fetchDataAwait(`/document/transaction/${transactionId}`, "post",  {file: signedUrl.data.fileName, type: dataFile.type, ...documentData});
      if (res.error) {
        setErrorMessage(res.error.message);
      } else {
        // setData(res.data);
        setConfirmationMessage("Succesfully saved.");
        setDialogOpen(false);
        callBackFunction(); 
        // navigate(`/client/transaction/${res.data.id}`)
      }          
    })
    .catch(error => {
      setErrorMessage("Error S3 upload");
      console.log(error);                
    });
    setIsLoading(false);
    return 1;
  }
  

  const handleOpenDialog = () => {
    setDialogOpen(true);
    updateDocList();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      


  function displayContent() {

    return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
      <Grid item xs={12} md={3} >
        <Typography variant="h6">Document Type</Typography>
      </Grid>  
      <Grid item xs={12} md={9} >
        <FormControl fullWidth>
          <InputLabel>Document type</InputLabel>                      
          <Select style={{width: 300}}
                autoWidth	
                label="Document Type" 
                labelId="select-type"
                id="select-type"
                value={documentData.type}
                onChange={handleSelectedDocType}
              >
                {documentType.map((item,i) => (
                    <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                ))}
            </Select>  
        </FormControl>                    
      </Grid>   
      <Grid item xs={12} md={3} >
        <Typography variant="h6">File Upload</Typography>
      </Grid> 
      <Grid item xs={12} md={8}>
            <Button
              variant="contained"
              component="label"
            >
              Upload a document
              <input
                type="file"
                hidden
                onChange={handleCapture}
              />
          </Button>  <br/>     
          {dataFile.name}
      </Grid>    

      <Grid item xs={12} md={3} >
        <Typography variant="h6">Invoice Number (only for invoice)</Typography>
      </Grid>  
      <Grid item xs={12} md={9} >
        <TextField 
            onChange={(e)=> handleAddDocumentData("invoiceNumber",e.target.value)}
            className={classes.field} 
            id="file-invoiceNumber" 
            fullWidth required
            value={documentData.invoiceNumber}
        />                      
      </Grid>  


      <Grid item xs={12} md={3} >
        <Typography variant="h6">File Name</Typography>
      </Grid>  
      <Grid item xs={12} md={9} >
        <TextField 
            onChange={(e)=> handleAddDocumentData("name",e.target.value)}
            className={classes.field} 
            id="file-name" 
            fullWidth required
            value={documentData.name}
        />                      
      </Grid>  

      <Grid item xs={12} md={3} >
        <Typography variant="h6">Description</Typography>
      </Grid>  
      <Grid item xs={12} md={9} >
        <TextField 
            onChange={(e)=> handleAddDocumentData("description",e.target.value)}
            className={classes.field} 
            multiline
            rows={2}   
            id="client-name" 
            fullWidth required
            value={documentData.description}
        />                      
      </Grid>  
      <Grid item xs={12} md={3} >
        <Typography variant="h6">Visible by Client?</Typography>
      </Grid>  
      <Grid item xs={12} md={9} >
          <FormControlLabel
          control={<Checkbox checked={documentData.visibility} onChange={handleChangeCheckBox} />}
          label="Make it visible"
          />                         
      </Grid>                                                                                                                                                                                                  
      </Grid>        
    )
    
  }

  return (
    <span>
      <LoadingButton  variant="contained"  color="primary"  onClick={() => handleOpenDialog()}><InsertDriveFileIcon/>Upload Doc</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">Upload a document</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {displayContent()}                    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" onClick={() => uploadDocument()} color="primary">
              ADD document
            </LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
    </span>
    )
}