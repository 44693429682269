// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Alert, Container, Typography, Skeleton, IconButton, Stack, Button, Snackbar  } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { LoadingButton } from '@mui/lab';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';

// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';


// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function GenerateReporting() {
  const navigate = useNavigate();
  const classes = useStyles();     
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const [dialogOpen, setDialogOpen] = useState(false);

  const [period, setPeriod] = useState(''); 
  const [endDate, setEndDate] = useState(''); 
  const [startDate, setStartDate] = useState(''); 

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/reporting", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
      { field: 'id', headerName: 'id', width:20},
      { field: 'period', headerName: 'Period', width:200  },
      { field: 'startDate', headerName: 'startDate', width:200  },
      { field: 'endDate', headerName: 'endDate', width:200  }
  ];


  const handleOpenDialog = () => { 
    setDialogOpen(true);    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }     

  const createReportingData = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/generate-reporting`, "post", {period,dateStart:startDate.toISOString(), dateEnd:endDate.toISOString() });
    console.log(res);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setData(res.data);    
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }


  if (isLoading) {
    return (
      <Page title="Vintages">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Reporting Data Generation</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    console.log("data")
    console.log(data)
    return (
      <Page title="Reporting Data Generation">
        <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Reporting Data Generation
          </Typography>

          <LoadingButton   variant="contained"  color="primary"  onClick={() => handleOpenDialog()}>Generate New Period</LoadingButton>
          <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth='xl'
          >
            <DialogTitle id="alert-dialog-title">Generate Data</DialogTitle> 
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Select the period name (displayed on final report) and range for which to produce the data:
              </DialogContentText>
              <TextField 
                            label="Period name (displayed on report"
                            onChange={(e)=> setPeriod(e.target.value)}
                            className={classes.field} 
                            id="period-name" 
                            fullWidth required
                            value={period}
                        />              
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Start date"
                    inputFormat="dd/MM/yyyy"
                    value={startDate}
                    onChange={(e)=> setStartDate(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />        
              </LocalizationProvider>    
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="End date"
                      inputFormat="dd/MM/yyyy"
                      value={endDate}
                      onChange={(e)=> setEndDate(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />        
              </LocalizationProvider>            
            </DialogContent>
            <DialogActions>
              <LoadingButton    variant="contained"  color="primary"  onClick={() => createReportingData()}>Generate Reporting data</LoadingButton>
              <Button variant="text" onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
            {snackbarError()}      
          {snackbarSuccess()}           
          </Dialog>        

        </Stack> 
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              
              <DataGridPro
                pagination
                autoHeight 
                rows={data.list} // {rows} //
                columns= {columns} //  {data.columns} //
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Reporting Data Generation">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Reporting Data Generation</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



