import { Button, Grid, Select, MenuItem, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { fetchDataAwait } from '../../utils/api';




export default function DialogRowUpdateCategoryRegion(props) {


  const {dialogOpen, setDialogOpen, setErrorMessage,setConfirmationMessage, rowId, vintageId, callBackFunction} = props;
  DialogRowUpdateCategoryRegion.propTypes = {
    rowId: PropTypes.number.isRequired,
    vintageId: PropTypes.number.isRequired,
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    setConfirmationMessage: PropTypes.func.isRequired,
    callBackFunction: PropTypes.func.isRequired
  }; 

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
 
  useEffect(async () => {
    console.log("USE EFFECT")
    if (dialogOpen) {
      setIsLoading(true);
      const res = await fetchDataAwait(`/vintage-category/${vintageId}`, "get", null);
      console.log(res)
      if (res.error) {
          setErrorMessage(res.error.message);
          setIsLoading(false);  
          return false
      }
      setData(res.data);
      setIsLoading(false);  
    }
    return true
 }, [dialogOpen]);


  const handleSaveDialog = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/vintage-category/${vintageId}`, "put", {categoryId: data.category.id, category2Id: data.category2.id, regionId: data.region.id});
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setConfirmationMessage(res.data.message);
    setIsLoading(false); 
    callBackFunction(rowId, data)
    setDialogOpen(false);
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  } 

  const handleSelectedCategoryId = (event) => {
    data.category.id = event.target.value;
    data.category.name =  data.listCategory.find(obj => obj.id === event.target.value).name;
    setData({...data});
  };
  const handleSelectedCategory2Id = (event) => {
    data.category2.id = event.target.value;
    data.category2.name =  data.listCategory2.find(obj => obj.id === event.target.value).name;
    setData({...data});
  };  
  const handleSelectedRegionId = (event) => {
    data.region.id = event.target.value;  
    data.region.name =  data.listRegion.find(obj => obj.id === event.target.value).name;
    setData({...data});
  };

  function displayContent() {
    console.log(data)
    if (data ) {
      return ( 
        <DialogContentText id="alert-dialog-description">
        <Grid  item xs={12} sm={6} md={3}>
        Category
        <Select style={{width: 300}}
          autoWidth	
          labelId="select-category"
          id="select-category"
          value={data.category.id}
          onChange={handleSelectedCategoryId}
        >
          {data.listCategory.map((item,i) => (
              <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
          ))}
        </Select>  
      </Grid>
      <Grid  item xs={12} sm={6} md={3}>
        Category 2
        <Select style={{width: 300}}
          autoWidth	
          labelId="select-category2"
          id="select-category2"
          value={data.category2.id}
          onChange={handleSelectedCategory2Id}
        >
          {data.listCategory2.map((item,i) => (
              <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
          ))}
        </Select>  
      </Grid>      
      <Grid  item xs={12} sm={6} md={3}>
          Region
        <Select style={{width: 300}}
            autoWidth	
            labelId="select-region"
            id="select-region"
            value={data.region.id}
            onChange={handleSelectedRegionId}
          >
            {data.listRegion.map((item,i) => (
                <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
            ))}
        </Select>  
      </Grid>      
      </DialogContentText>
        )
    }

    return ( 
      <DialogContentText id="alert-dialog-description">
        <Skeleton variant="rectangular" height={300} />
      </DialogContentText>
      )
  }
  


  return (
      <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">Select a Category and region:</DialogTitle> 
        <DialogContent>
          {displayContent()}
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" onClick={handleSaveDialog} color="primary">
            SAVE
          </LoadingButton>
          <Button variant="text" onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>           
    )
}