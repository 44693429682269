// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Stack, Box, Grid, Container, Typography, Skeleton, Snackbar, Alert} from '@mui/material';
// components
import { useState, useEffect } from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';


// ----------------------------------------------------------------------


export default function Customs() {
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null); 


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/customs", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  // T.externalId, T.type1275 type, DATE_FORMAT(T.createdAt, "%Y-%m-%d") transactionCreatedAt, T.externalIdClient client, T.typeClient, purchaseId, PO
  // , sum(T.cashMoved1275) cashMoved1275, ifnull(sum(T.bookValue),0) Inventory1275BookValue, ifnull(sum(case when T.type1275 = "Wine purchase" THEN T.bookValue ELSE -1 * T.bookValue END),0) Inventory1275Move, sum(T.cashMovedClient) cashMovedClient, count(*) nbTransactions

  const columns =  [   
    { field: 'Designation', headerName: 'Designation', width:150   }, 
    { field: 'GUID', headerName: 'GUID', width:200 }, 
    { field: 'Color', headerName: 'Color', width:200   }, 
    { field: 'Mill', headerName: 'Mill', width:150   },   
      { field: 'Pack', headerName: 'Pack', width:200   },      
      { field: '# Cs', headerName: '# Cs', width:150   },
      { field: 'Format (l)', headerName: 'Format (l)', width:300   },
      { field: '# Bts', headerName: '# Bts', width:200   },  
      { field: 'TOT Bts', headerName: 'TOT Bts', width:200   },  
      { field: 'Poids unitaire', headerName: 'Poids unitaired', width:200  },        
      { field: 'Poids total', headerName: 'Poids total', width:200   },  
      { field: 'Valeur Unitaire EUR', headerName: 'Valeur Unitaire EUR', width:200  },
      { field: 'Valeur Totale EUR', headerName: 'Valeur Totale EUR', width:200  },

      { field: 'Date Entrée', headerName: 'Date Entrée', width:200  },
      { field: 'Document Douanier', headerName: 'Document Douanier', width:200  },
      { field: 'Date Emission Doc. Douanier', headerName: 'Date Emission Doc. Douanier', width:200  },
      { field: 'Bureau de Douane Emetteur', headerName: 'Bureau de Douane Emetteur', width:200  },
      { field: 'N° du 11.95', headerName: 'N° du 11.95', width:200  },
      { field: 'Date de Sortie', headerName: 'Date de Sortie', width:200  },
      { field: 'N° de MRN(Sortie Douanes)', headerName: 'N° de MRN(Sortie Douanes)', width:200  },                                          

      { field: 'orderReference', headerName: 'orderReference', width:200  },                                          
  ];



const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  



  if (isLoading) {
    return (
      <Page title="Customs">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Customs View</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Customs">
        <Container maxWidth="false">
          <Stack sx={{ pb: 5 }} direction={{ xs: 'column', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between" >       
              <Typography variant="h4">Customs View</Typography>        
          </Stack> 
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
            <Grid item xs={12}>
              <DataGridPro
                pagination
                autoHeight 
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}     
      </Page>
    );
  }
  return (
    <Page title="Customs">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Customs View</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



