// material
import { makeStyles } from '@mui/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Link, InputLabel, FormControl, CardHeader, Alert, Snackbar, TextField, Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Card, CardContent, Button, MenuItem, Select  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
// components
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';

// ----------------------------------------------------------------------


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);




export default function DocumentItem() {
  const classes = useStyles(); 
  const { id } = useParams();    
  const navigate = useNavigate();
  // const [note, setNote] = useState("");

  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const [isLoadingButton,setIsLoadingButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   

  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/document/${id}`, "get", null);
    };
    fetchDataReactScreen();
  }, [id]);  
  
  // useEffect(() => {
  //   if (data) {
  //     setNote(data.data.note);
  //   }
  // }, [data]);  



  const columnsTransactions =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/client/transaction/${params.value}`)}
        >
          <ZoomInIcon />
        </IconButton >        
      </strong>
    )
  },
  { field: 'client', headerName: 'Client' ,width:200},   
  { field: 'methodLabel', headerName: 'Type' ,width:200},
  { field: 'note', headerName: 'Note',width:300},      
  { field: 'invoice', headerName: 'Invoice#',width:200},        
  { field: 'groupName', headerName: 'Transac Group', width:200},      
  { field: 'cashTransfer', headerName: 'cashTransfer', width:200 },
  { field: 'admin', headerName: 'Admin',width:200},      
  { field: 'createdAt', headerName: 'createdAt', width:200  },
  { field: 'updatedAt', headerName: 'updatedAt', width:200  }
];


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  

  

  const handleCancelClick = () => { 
    setIsDisabled(true);  
  }


  const uploadItem = async () => {
    console.log({...data.data});
    setIsLoadingButton(true);   
    let url = '/document/';
    url += id;
    const res = await fetchDataAwait(url, "put", {...data.data});
    
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      // setData(res);
      setConfirmationMessage("Succesfully saved.");
      setIsDisabled(true);  
    }   
    setIsLoadingButton(false);      
  }
  

  const removeItem = async () => {
    setIsLoadingButton(true);   
    let url = '/cancel-document/';
    url += id;
    const res = await fetchDataAwait(url, "get", null);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      // setData(res);
      setConfirmationMessage("Succesfully saved.");  
      // Reload page
      setIsLoading(true); 
      const res = await fetchDataAwait(`/document/${id}`, "get", null);
      if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false); 
      } else {
        setData(res.data);
        setIsDisabled(true);  
        setIsLoading(false); 
      }        
    }   
    setIsDisabled(true);    
    setIsLoadingButton(false);      
  }


  const handleSaveClick = () => {
    uploadItem(); 
  }

  const handleRemoveClick = () => {
    removeItem(); 
  }

  const handleOpenDocVisibility = async (visibility) => {
    setIsLoadingButton(true);      
    const url = `/document/visibility/${id}/${visibility}`;
    const res = await fetchDataAwait(url, "get", null);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      // Update Data     
      data.data.isClientVisible = visibility;
      setData({...data});
    }   
    setIsLoadingButton(false);      
    return 0;    
  } 
    
  const handleAddDocumentData = (field,value) => {
    data.data[field] = value
    setData({...data});
  }

  const displayVisibilityButton = () => {
    if (data.data.isClientVisible ===1) {
      return (
        <span>
        <VisibilityIcon/>
        <LoadingButton loading={isLoadingButton}  variant="contained" onClick={() => handleOpenDocVisibility(0)}>
        Hide it
      </LoadingButton>               
     </span>
      )
    }
    return (
      <span>
        <VisibilityOffIcon/>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={() => handleOpenDocVisibility(1)}>
        Show it
      </LoadingButton>
     </span>
    )
  }

  const handleSelectedDocType = (event) => {
    handleAddDocumentData("documentTypeId",event.target.value);
  };  

  function displayActionButtons() {
    if (isDisabled) {
      return (
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" mb={5}>  
            <Button
              variant="contained"
              onClick={() => setIsDisabled(false)}
            >
              <EditIcon/>
              Edit
            </Button>        
            <Button
              variant="contained"
              onClick={() => handleRemoveClick()}
            >
            <CancelIcon/>
            Cancel document
          </Button>  
      </Stack> 
      )
    }
    return ( 
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" mb={5}>  
        <LoadingButton
          variant="contained"
          loading={isLoadingButton}
          onClick={() => handleSaveClick()}
        >
          <SaveIcon/>
          Save
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => handleCancelClick(true)}
        >
          <CancelIcon/>
          Cancel
        </Button>        
      </Stack>
  )
}



  if (isLoading) {
    return (
      <Page title="Transaction">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Document - db id:{id}</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Document">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Document - db id {id}</Typography>    
                {displayActionButtons()}                          
                                     
          </Stack>          
            
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Document</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> handleAddDocumentData("name",e.target.value)}
                          id="name" 
                          fullWidth required
                          value={data.data.name}
                          disabled={isDisabled}
                      />
                            
                    </Grid>
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">File Link</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >

                      <Link
                        target="_blank"
                        href={data.data.url}
                      >
                        <DescriptionIcon />
                      </Link>                         
                    </Grid>                    
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Client</Typography>
                    </Grid>                     
                    <Grid item xs={12} md={9} >
                      <IconButton 
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => navigate(`/client/list/${data.data.clientId}`)}
                      >
                        <ZoomInIcon /> 
                      </IconButton >                       
                      {data.data.externalId}
                    </Grid>                     
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Document type</Typography>
                    </Grid>                     
                    <Grid item xs={12} md={9} >
                      <FormControl fullWidth>
                        <InputLabel>Document type</InputLabel>                      
                        <Select style={{width: 300}}
                              autoWidth	
                              label="Document Type" 
                              labelId="select-type"
                              id="select-type"
                              value={data.data.documentTypeId}
                              disabled={isDisabled}
                              onChange={handleSelectedDocType}
                            >
                              {data.documentType.map((item,i) => (
                                  <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                              ))}
                          </Select>  
                      </FormControl>                    
                    </Grid>   
                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Doc visible in client´s document section?</Typography>
                    </Grid> 
                    <Grid item xs={12} md={8}>
                         {displayVisibilityButton()}
                    </Grid>                  

                    <Grid item xs={12} md={3} >
                      <Typography variant="h6">Description</Typography>
                    </Grid>  
                    <Grid item xs={12} md={9} >
                      <TextField 
                          onChange={(e)=> handleAddDocumentData("description",e.target.value)}
                          className={classes.field} 
                          multiline
                          rows={2}   
                          id="client-name"
                          fullWidth required
                          disabled={isDisabled}
                          value={data.data.description}
                      />                      
                    </Grid>  
                    <Grid item xs={12} >
                      <small> created at {data.data.createdAt} | updated at {data.data.updatedAt} | Admin {data.data.creator} </small> 
                    </Grid>
                  </Grid>  
                </CardContent>
              </Card >            
              
            </Grid>                                        
            <Grid item xs={12}>
            <Card >
                <CardHeader title="Transactions linked to this document" />
                <CardContentNoPadding >            
                <DataGridPro
                pagination
                autoHeight 
                rows={data.transactions} 
                columns= {columnsTransactions}
                
                loading={isLoading}

              />
  
            </CardContentNoPadding >
              </Card >                  
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}  
      </Page>
    );
  }
  return (
    <Page title="Document">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Document - db id {id}</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}

