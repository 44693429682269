// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton,  Stack, Button  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from '@mui/icons-material/Add';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';


// ----------------------------------------------------------------------


export default function Tracker() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/trackers", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (

        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/tracker/list/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
    )
  },
    { field: 'name', headerName: 'Tracker', width:300 },
    { field: 'externalReference', headerName: 'externalReference', width:300  },
    { field: 'tempmateAPISerialNumber', headerName: 'Tempmate Id', width:160  },
    { field: 'tempmateAPIStatus', headerName: 'Tempmate Status', width:160  },
    { field: 'createdAt', headerName: 'createdAt', width:150  },
    { field: 'updatedAt', headerName: 'updatedAt', width:150  }
];


  if (isLoading) {
    return (
      <Page title="Trackers">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Trackers</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    console.log("data")
    console.log(data)
    return (
      <Page title="Trackers">
        <Container maxWidth="false">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
              Trackers
              </Typography>
              <Button
                variant="contained"
                onClick={() => navigate(`/tracker/list/new`)}
              >
                <AddIcon/>
                New
              </Button>
          </Stack>  
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
       
              <DataGridPro
                autoHeight 
                pagination
                rows={data.data} 
                columns= {columns} 
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Trackers">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Trackers</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



