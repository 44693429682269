import { makeStyles } from '@mui/styles';
import { Skeleton, MenuItem, Select, RadioGroup, Radio, Button,  FormControl, Grid, Typography, TextField, FormControlLabel } from '@mui/material';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from "axios";
import { fetchDataAwait } from '../../utils/api';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function ButtonDialogUploadDocumentShipping(props) {
  const classes = useStyles();   
  const { documentId, shippingId, callBackFunction, setErrorMessage, setConfirmationMessage, dialogOpen, setDialogOpen } = props;
  ButtonDialogUploadDocumentShipping.propTypes = {
    documentId: PropTypes.number,
    shippingId: PropTypes.string,
    callBackFunction: PropTypes.func,
    setErrorMessage: PropTypes.func,
    setConfirmationMessage: PropTypes.func,
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
  };  
  const [isLoading, setIsLoading] = useState(true);
  
    
  const [documentData,setDocumentData] = useState(null);
  const [dataFile,setDataFile] = useState({name:"no file selected"});


  useEffect(() => {
    refreshDocumentData(); 
  }, [documentId]);


  const handleAddDocumentData = (field,value) => {
    documentData[field] = value
    setDocumentData({...documentData});
  }

  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
    console.log(event.target.files[0])
  };


  const uploadDocument = async () => {
    setIsLoading(true);
    if (dataFile.type) {
      // NEW FILE TO UPLOAD
      const signedUrl = await fetchDataAwait('/get-signed-url/', "put",  {file: dataFile.name, type: "shipping"});
      console.log(signedUrl);
      if (signedUrl.error) {
        setErrorMessage(`Error with S3 presigned url ${signedUrl.error.message}` );
        setIsLoading(false); 
        return 0;
      }     
      // UPLOAD FILE - Upload file
      const postData = new FormData();
      Object.entries(signedUrl.data.presignedURL.fields).forEach(([k, v]) => {
        postData.append(k, v);
      });

      postData.append('file', dataFile); // The file has be the last element  

      await axios.post(signedUrl.data.presignedURL.url, postData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })          
      .then(async () => {
        console.log("UPLOADING DATA 1");
        let res;
        console.log(documentData)

        if (documentId) {
          res = await fetchDataAwait(`/shipping/document/${documentId}`, "put",{file: signedUrl.data.fileName, type: dataFile.type, ...documentData, shippingId}  );
        } else {
          res = await fetchDataAwait(`/shipping/document`, "post",  {file: signedUrl.data.fileName, type: dataFile.type, ...documentData,shippingId});
        }
        if (res.error) {
          setErrorMessage(res.error.message);
        } else {
          // setData(res.data);
          setConfirmationMessage("Succesfully saved.");
          setDialogOpen(false);
          callBackFunction(); 
          // navigate(`/client/transaction/${res.data.id}`)
        }          
      })
      .catch(error => {
        setErrorMessage("Error S3 upload");
        console.log(error);                
      });
    } else {
      console.log("UPLOADING DATA");
      let res;
      console.log(shippingId)
      if (documentId) {
        res = await fetchDataAwait(`/shipping/document/${documentId}`, "put",  {...documentData, shippingId});
      } else {
        res = await fetchDataAwait(`/shipping/document`, "post",  { ...documentData, shippingId});
      }
      if (res.error) {
        setErrorMessage(res.error.message);
      } else {
        // setData(res.data);
        setConfirmationMessage("Succesfully saved.");
        setDialogOpen(false);
        callBackFunction(); 
        // navigate(`/client/transaction/${res.data.id}`)
      } 

    }
    setIsLoading(false);
    return 1;
  }
  

  const refreshDocumentData = async() => {
    setIsLoading(true);
    let res;
    if (documentId) {
      res = await fetchDataAwait(`/shipping/document/${documentId}`, "get", null);
    } else {
      res = await fetchDataAwait(`/shipping/document`, "get", null);
    }    
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }     
    setDocumentData(res.data.document);  
    setIsLoading(false);  
    return true       
  }        



  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }


  function displayContent() {
    if (documentData) {
      return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
        <Grid item xs={12} md={3} >
          <Typography variant="h6">File Upload</Typography>
        </Grid> 
        <Grid item xs={12} md={8}>
              <Button
                variant="contained"
                component="label"
              >
                Upload a document
                <input
                  type="file"
                  hidden
                  onChange={handleCapture}
                />
            </Button>  <br/>     
            {dataFile.name}
        </Grid>                  
        <Grid item xs={12} md={3} >
          <Typography variant="h6">File Name</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <TextField 
              onChange={(e)=> handleAddDocumentData("name",e.target.value)}
              className={classes.field} 
              id="file-name" 
              fullWidth required
              value={documentData.name}
          />                      
        </Grid>  

        <Grid item xs={12} md={3} >
          <Typography variant="h6">Description</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <TextField 
              onChange={(e)=> handleAddDocumentData("description",e.target.value)}
              className={classes.field} 
              multiline
              rows={2}   
              id="client-name" 
              fullWidth required
              value={documentData.description}
          />                      
        </Grid>  

        <Grid item xs={12} md={3} >
          <Typography variant="h6">Date File</Typography>
        </Grid>  
        <Grid item xs={12} md={9} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Date File"
                    inputFormat="dd/MM/yyyy"
                    value={documentData.fileDate}
                    onChange={(e)=> handleAddDocumentData("fileDate",e)}
                    renderInput={(params) => <TextField {...params} />}
                  />        
            </LocalizationProvider>                 
        </Grid>  
                                                                                                                                                                    
        </Grid>        
      )
    }

    return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
         <Skeleton variant="rectangular" height={300} />                                                                                                                                                                                   
      </Grid>        
      )
    
  }

  return (
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">Upload a document {shippingId}</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {displayContent()}                    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" onClick={() => uploadDocument()} color="primary">
              Update document
            </LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
    )
}