import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
//
import Warehouse from './pages/warehouse/Warehouse';
import Task from './pages/warehouse/Task';
import Transit from './pages/warehouse/Transit';
import Sensor from './pages/warehouse/Sensor';
import SensorItem from './pages/warehouse/SensorItem';
import PalletItem from './pages/warehouse/PalletItem';
import Pallet from './pages/warehouse/Pallet';
import Location from './pages/warehouse/Location';
import PrepareOrder from './pages/warehouse/PrepareOrder';
import PrepareOrderItem from './pages/warehouse/PrepareOrderItem';

import Purchases from './pages/purchases/Purchases';
import Suppliers from './pages/purchases/Suppliers';
import SupplierItem from './pages/purchases/SupplierItem';
import PurchasesList from './pages/purchases/PurchasesList';
import PurchaseItem from './pages/purchases/PurchaseItem';
import AddNewCases from './pages/purchases/AddNewCases';

import ShippingList from './pages/shipping/ShippingList';
import ShippingItem from './pages/shipping/ShippingItem';
import Tracker from './pages/shipping/Tracker';
import TrackerItem from './pages/shipping/TrackerItem';

import Client from './pages/clients/Client';
import ClientList from './pages/clients/ClientList';
import ClientItem from './pages/clients/ClientItem';
import ManagementFee from './pages/clients/ManagementFee';
import ManagementFeeItem from './pages/clients/ManagementFeeItem';
import Transaction from './pages/clients/Transaction';
import TransactionItem from './pages/clients/TransactionItem';
import Documents from './pages/clients/DocumentList';
import DocumentItem from './pages/clients/DocumentItem';
import GenerateReporting from './pages/clients/GenerateReporting';
import ReportingDoc from './pages/clients/ReportingDoc';

import Stock from './pages/stock/Stock';
import Estate from './pages/stock/Estate';
import Wine from './pages/stock/Wine';
import Vintage from './pages/stock/Vintage';
import Case from './pages/stock/Case';
import CaseItem from './pages/stock/CaseItem';
import StockView from './pages/stock/View';
import Inventory from './pages/stock/Inventory';
import Customs from './pages/stock/Customs';
import EstateItem from './pages/stock/EstateItem';
import WineItem from './pages/stock/WineItem';
import VintageItem from './pages/stock/VintageItem';

import CaseTraceabilityItem from './pages/traceability/CaseTraceabilityItem';
import CaseTraceabilityList from './pages/traceability/CaseTraceabilityList';

import Allocation from './pages/allocate/Allocation';
import TargetAllocation from './pages/allocate/TargetAllocation';
import Allocate from './pages/allocate/Allocate';
import AllocateRound from './pages/allocate/AllocateRound';
import AddTransaction from './pages/allocate/AddTransaction';
import AddDelivery from './pages/allocate/AddDelivery';
import AddTransactionCase from './pages/allocate/AddTransactionCase';
import AddCashTransaction from './pages/allocate/AddCashTransaction';
import TransactionSelect  from './pages/allocate/TransactionSelect';

import Performance from './pages/analytics/Performance';
import News from './pages/analytics/News';

import WinePortfolio from './pages/clientView/WinePortfolio';
import ClientTransactions from './pages/clientView/ClientTransactions';
import Marketplace from './pages/clientView/Marketplace';
import Document from './pages/clientView/Document';

import QRCam from './pages/QRCam';



// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <DashboardApp /> }
      ]
    },
    {
      path: '/warehouse',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Warehouse /> },
        { path: 'dashboard', element: <Warehouse /> },
        { path: 'task', element: <Task /> },
        { path: 'transit', element: <Transit /> },
        { path: 'sensor', element: <Sensor /> },
        { path: 'sensor/:id', element: <SensorItem /> },
        { path: 'pallet', element: <Pallet /> },
        { path: 'pallet/:id', element: <PalletItem /> },
        { path: 'location', element: <Location /> },
        { path: 'prepare', element: <PrepareOrder /> },
        { path: 'prepare/:id', element: <PrepareOrderItem /> }
      ]
    },
    {
      path: '/purchase',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Purchases /> },
        { path: 'dashboard', element: <Purchases /> },
        { path: 'suppliers', element: <Suppliers /> },
        { path: 'suppliers/:id', element: <SupplierItem /> },
        { path: 'list', element: <PurchasesList /> },
        { path: 'list/:id', element: <PurchaseItem /> },
        { path: 'add-cases/purchase/:id', element: <AddNewCases /> },
        { path: 'add-cases', element: <AddNewCases /> }
      ]
    },
    {
      path: '/shipping',
      element: <DashboardLayout />,
      children: [
        { path: 'list', element: <ShippingList /> },
        { path: 'list/:id', element: <ShippingItem /> },
      ]
    },    
    {
      path: '/tracker',
      element: <DashboardLayout />,
      children: [
        { path: 'list', element: <Tracker /> },
        { path: 'list/:id', element: <TrackerItem /> },
      ]
    },       
    {
      path: '/client',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Client /> },
        { path: 'dashboard', element: <Client /> },
        { path: 'list', element: <ClientList /> },
        { path: 'list/:id', element: <ClientItem /> },
        { path: 'management-fee/list', element: <ManagementFee /> },
        { path: 'management-fee/list/:id', element: <ManagementFeeItem /> },

        { path: 'reporting/data', element: <GenerateReporting /> },
        { path: 'reporting/doc', element: <ReportingDoc /> },

        { path: 'transaction', element: <Transaction /> },
        { path: 'documents', element: <Documents /> },
        { path: 'transaction/:id', element: <TransactionItem /> },
        { path: 'document/:id', element: <DocumentItem /> }
      ]
    },
    {
      path: '/stock',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Stock /> },
        { path: 'overview', element: <Stock /> },
        { path: 'estate', element: <Estate /> },
        { path: 'estate/:id', element: <EstateItem /> },
        { path: 'wine', element: <Wine /> },
        { path: 'wine/:id', element: <WineItem /> },
        { path: 'vintage', element: <Vintage /> },
        { path: 'vintage/:id', element: <VintageItem /> },
        { path: 'case', element: <Case /> },
        { path: 'view', element: <StockView /> },        
        { path: 'inventory', element: <Inventory /> },   
        { path: 'customs', element: <Customs /> },           
        { path: 'case/:id', element: <CaseItem /> },
      ]
    },
    {
      path: '/traceability',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Stock /> },
        { path: 'case', element: <CaseTraceabilityList /> },       
        { path: 'case/:id', element: <CaseTraceabilityItem /> },
      ]
    },    
    {
      path: '/allocation',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Allocation /> },
        { path: 'dashboard', element: <Allocation /> },
        { path: 'define', element: <TargetAllocation /> },
        { path: 'allocate', element: <Allocate />},
        { path: 'allocate/:id', element: <AllocateRound /> },
        { path: 'transaction', element: <AddTransaction /> },
        { path: 'delivery', element: <AddDelivery /> },
        { path: 'transaction-case', element: <AddTransactionCase /> },
        { path: 'cash-transaction', element: <AddCashTransaction /> },
        { path: 'cash-transaction/:id', element: <AddCashTransaction /> },
        { path: 'select', element: <TransactionSelect /> }
      ]
    },
    {
      path: '/analytics',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Performance /> },
        { path: 'performance', element: <Performance /> },
        { path: 'news', element: <News /> }
      ]
    },
    {
      path: '/client-view',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <WinePortfolio /> },
        { path: 'transactions', element: <ClientTransactions /> },
        { path: 'documents', element: <Document /> },
        { path: 'marketplace', element: <Marketplace /> }
      ]
    },
    {
      path: '/qr',
      element: <DashboardLayout />,      
      children: [
        { path: '/', element: <QRCam /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <DashboardLayout />   , children: [
          { path: '/', element: <NotFound /> }
        ]},
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <DashboardLayout />   , children: [
          { path: '*', element: <NotFound /> }
        ]},
      ]
    },
    { path: '*', element: <Navigate to="/404" /> }
  ]);
}
