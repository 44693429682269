
import {  Button, Snackbar, Alert, IconButton  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {useNavigate} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';


export default function ButtonDialogCasePalletHistory(props) {
  const navigate = useNavigate();
  const {caseId} = props;
  ButtonDialogCasePalletHistory.propTypes = {
    caseId: PropTypes.number.isRequired
  };  
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataGrid, setDataGrid] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const columns =  [
    { field: 'caseId', headerName: 'caseId', width:200 },
    { field: 'palletId', headerName: 'palletId', width:200
    , renderCell: (params) =>  (
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/warehouse/pallet/${params.value}`)}
      >
        <ZoomInIcon /> 
        {params.value}
      </IconButton >
      )
    },     
    { field: 'status', headerName: 'status', width:200 },
    { field: 'pallet', headerName: 'pallet Name', width:200 },
    { field: 'dateAddition', headerName: 'dateAddition', width:300  },
    { field: 'dateRemoval', headerName: 'dateRemoval', width:200  },
    { field: 'createdAt', headerName: 'createdAt', width:200  },
    { field: 'updatedAt', headerName: 'updatedAt', width:200  }
];


  const updatePalletList = async () => {
    setIsLoading(true); 
    const res = await fetchDataAwait(`/pallets-history/${caseId}`, "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDataGrid(res.data.data)  
    setIsLoading(false);  
    return true    
  }
  



  const handleOpenDialog = () => {
    setDialogOpen(true);
    updatePalletList();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

 
  return (
    <span>
      <LoadingButton   variant="text"  color="primary"  onClick={() => handleOpenDialog()}>see case moves</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">History Case / Pallets </DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={dataGrid}
                columns= {columns}
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
        {snackbarError()}      
        {snackbarSuccess()}
    </span>
    )
}