
import { TextField,CardContent, Grid, Typography, Button, Snackbar, Alert  } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

export default function DialogShippingTrackerUpdate(props) {
  const { shippingId, trackerId, shippingName, trackerName, callBackFunction, dialogOpen, setDialogOpen, endDate, setEndDate, startDate, setStartDate, description, setDescription } = props;
  DialogShippingTrackerUpdate.propTypes = {
    shippingId: PropTypes.number.isRequired,
    trackerId: PropTypes.number.isRequired,
    shippingName: PropTypes.string.isRequired,
    trackerName: PropTypes.string.isRequired,
    callBackFunction: PropTypes.func.isRequired,
    dialogOpen: PropTypes.object.isRequired, 
    setDialogOpen: PropTypes.func.isRequired, 
    endDate: PropTypes.object.isRequired, 
    setEndDate: PropTypes.func.isRequired, 
    startDate: PropTypes.object.isRequired, 
    setStartDate: PropTypes.func.isRequired, 
    description: PropTypes.object.isRequired, 
    setDescription: PropTypes.func.isRequired, 

  };
  const classes = useStyles(); 
 
  // const [endDate, setEndDate] = useState(null); 
  // const [startDate, setStartDate] = useState(null); 
  // const [description, setDescription] = useState(null); 

  // const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataGrid, setDataGrid] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    

  const updateTrackerList = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait("/trackers", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setDataGrid(res.data.data)  
    setIsLoading(false);  
    return true    
  }
  
  const updateShippingTracker = async () => {
    setIsLoading(true);
    const res = await fetchDataAwait(`/shipping-tracker/${shippingId}/${trackerId}`, "post", {dateAssigned: startDate,dateRemoved: endDate, description} );
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    callBackFunction(res.data.trackers)
    setConfirmationMessage(res.data.message);
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }


  const handleOpenDialog = () => {
    setDialogOpen(true);
    updateTrackerList();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      

  return (
    <span>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Update Tracker [{trackerName}] link to shipping [{shippingName}]</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

            Fill out Tracker placing date and any relevant infos
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">    
            <Grid item xs={12} md={3} >
              <Typography variant="h6">Placed on shipping on:</Typography>
            </Grid> 
            <Grid item xs={12} md={3} >                          
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={startDate}
                      onChange={(e)=> setStartDate(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />        
              </LocalizationProvider>  
              </Grid> 
              <Grid item xs={12} md={3} >
              <Typography variant="h6">Removed on:</Typography>
            </Grid> 
            <Grid item xs={12} md={3} >                 
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={endDate}
                      onChange={(e)=> setEndDate(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />        
              </LocalizationProvider> 
              </Grid> 
              <Grid item xs={12} md={3} >
              <Typography variant="h6">Description/Details</Typography>
            </Grid> 
            <Grid item xs={12} md={9} >
              <TextField 
                  onChange={(e)=> setDescription(e.target.value)}
                  className={classes.field} 
                  value={description}
                  id="DESCRIPTION" 
                  fullWidth required
              />                       
            </Grid>                                  
              </Grid> 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={()=> updateShippingTracker()} color="primary">
              SAVE
            </Button>            
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
        {snackbarError()}      
        {snackbarSuccess()}
    </span>
    )
}