import { Skeleton,Button,  Grid, Typography, TextField} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchDataAwait } from '../../utils/api';



export default function ButtonDialogShippingEnd(props) {
  const { endDate, shippingId, shippingName, callBackFunction, setErrorMessage, setConfirmationMessage, dialogOpen, setDialogOpen } = props;
  ButtonDialogShippingEnd.propTypes = {
    shippingId: PropTypes.string,
    shippingName: PropTypes.string,
    endDate: PropTypes.object,
    callBackFunction: PropTypes.func,
    setErrorMessage: PropTypes.func,
    setConfirmationMessage: PropTypes.func,
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
  };  
  const [isLoading, setIsLoading] = useState(false);    
  const [shippingDate,setShippingData] = useState(null);


  useEffect(() => {
    setShippingData(endDate);
  }, [endDate]);


  const updateShippingData = async (e) => {
    setShippingData(e)
  }



  const updateEndingDate = async () => {
    setIsLoading(true);
    if (Object.prototype.toString.call(shippingDate) !== "[object Date]") {
      setErrorMessage("Date is incorrect");
      setIsLoading(false);      
      return 0;      
    }    
    const res = await fetchDataAwait(`/shipping/end/${shippingId}`, "put",  {endShipping:moment(shippingDate).utcOffset(0, true).format()});

    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      // setData(res.data);
      setConfirmationMessage(`Shipping Succesfully ends on: ${shippingDate.toISOString()}`);
      setDialogOpen(false);
      callBackFunction(shippingDate); 
      // navigate(`/client/transaction/${res.data.id}`)
    } 

    setIsLoading(false);
    return 1;
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }


  function displayContent() {
      return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
        <Grid item xs={12} md={12} >
          This action will end the shipment and move the shipped cases position to the one defined on the shipping page.
        </Grid> 
        <Grid item xs={12} md={3} >
          <Typography variant="h6">End Date</Typography>
        </Grid>
        <Grid item xs={12} md={9} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Date End"
                    format="dd/MM/yyyy HH:mm"
                    inputFormat="dd/MM/yyyy HH:mm"
                    value={shippingDate}
                    onChange={(e)=> updateShippingData(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />        
            </LocalizationProvider>                 
        </Grid>  
                                                                                                                                                                    
        </Grid>        
      )
    

    // return (
    //   <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                        
    //      <Skeleton variant="rectangular" height={300} />                                                                                                                                                                                   
    //   </Grid>        
    //   )
    
  }

  return (
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">End Shipping {shippingName}</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {displayContent()}                    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" onClick={() => updateEndingDate()} color="primary">
              STOP SHIPPING
            </LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
    )
}