import { Button, IconButton, Grid, TextField  } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import DialogBottlePickVintage from './DialogBottlePickVintage';
import { fCurrency } from '../../utils/formatNumber';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },  
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));





export default function DialogBottleToNewCase(props) {
  const classes = useStyles();

  const {dialogOpen, setDialogOpen, setErrorMessage, rowId, callBackFunction, dataBottles, setDataBottles} = props;
  DialogBottleToNewCase.propTypes = {
    rowId: PropTypes.number,
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    callBackFunction: PropTypes.func.isRequired,
    dataBottles: PropTypes.array.isRequired,
    setDataBottles: PropTypes.func.isRequired,
  }; 

  const [isLoading, setIsLoading] = useState(false);
  const [dialogBottleOpen, setDialogBottleOpen] = useState(false);
  const [rowVintageId, setRowVintageId] = useState(null); 
  
  const newBottle = {
    id:1,
    bottleName: "",
    vintage : "",
    lwin : "",
    qty: 0,
    bookValue: null,
  };  


  const columnsGrid =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.row.id);}}
        >
          <ClearIcon />
        </IconButton >        
      </span>
      )
    }, 
    { field: 'bottleName', headerName: 'Vintage', width:300 , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handlePickVintage(params.row.id);}}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton >
        {params.value}        
      </span>
      )
    }, 
    { field: 'qty', headerName: '#Bottles', width:100  ,cellClassName: 'editable-theme--cell' , editable: true, type: 'number'},
    { field: 'bookValue', headerName: 'book Price (if multicase)', width:200  , renderCell: (params) =>  fCurrency(params.value)   ,cellClassName: 'editable-theme--cell' , editable: true },
];


const handleClickAddRow = () => {
  const row = {...newBottle};
  row.id = Math.max(...dataBottles.map(o => o.id), 0)+1;
  setDataBottles([...dataBottles,row]);
};


const handlePickVintage = (rowTableId) => {
  setRowVintageId(rowTableId);
  setDialogBottleOpen(true);
}; 


function CustomAddToolbar() {
  return (
    <GridToolbarContainer>            
            <LoadingButton
                color="primary" loading={isLoading}
                onClick={handleClickAddRow}
                variant="contained"
            >
              <AddCircleOutlineOutlinedIcon/>Add Row
            </LoadingButton>
    </GridToolbarContainer>
  );
}

const updateBottleData= (rowCaseId,rowData) => {
  // console.log(rowCaseId)
  const updatedRows = dataBottles.map((row) => {
    if (row.id === rowCaseId) {
      row.vintage = rowData.id;
      row.lwin = rowData.lwin;
      row.bottleName = `${rowData.name} ${rowData.year}` ;
      return row;
    }
    return row;
  });
  // console.log(updatedRows)
  setDataBottles( [...updatedRows]);
};

  const handleRemoveRow = (rowId) => {
    const updatedRows = dataBottles.filter((el) =>  el.id !== rowId);
    setDataBottles( [...updatedRows]);
  };  

  const handleSaveDialog = () => {
    callBackFunction(rowId,dataBottles)
    setDialogOpen(false);
  }

  const handleCloseDialog = () => {
    console.log("close")
      setDialogOpen(false);    
  } 
    
  const handleCellEditCommit = ({ id, field, value }) => {
    const updatedRows = dataBottles.map((row) => {
      if (row.id === id) {
        row[field] = value;
        return row;
      }
      return row;
    });
    setDataBottles(updatedRows);
  };  


  return (
    <span>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Add Bottle to Case</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
              <Grid item xs={12} md={12}>    
                Add bottles to case
              </Grid>              
              <Grid item xs={12}  className={classes.root}>
                <DataGridPro overflow="auto"
                  pagination
                  autoHeight 
                  rows={dataBottles} 
                  columns= {columnsGrid} 
                  pageSize={25}
                  loading={isLoading}
                  onCellEditCommit={handleCellEditCommit}
                  components={{
                    Toolbar: CustomAddToolbar,
                  }}                             
                  />
              </Grid>
            </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button variant="contained" onClick={handleSaveDialog} color="primary">
              OK
            </Button>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>           
        <DialogBottlePickVintage dialogOpen={dialogBottleOpen} setDialogOpen={setDialogBottleOpen} setErrorMessage={setErrorMessage} rowId={rowVintageId} callBackFunction={updateBottleData}/>   
      </span>
    )
}