// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { IconButton, Link, Box, Grid, Container, Typography, Skeleton, Stack, TextField, Snackbar, Alert, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect , useRef} from 'react';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';



// ----------------------------------------------------------------------


export default function DocumentList() {
  const searchRef = useRef();
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingButton,setIsLoadingButton] = useState(false);   
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/documents", "put", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
    { field: 'id', headerName: ' ', width:80
    , renderCell: (params) => (
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/client/document/${params.value}`)}
      >
        <ZoomInIcon />
        {params.value}
      </IconButton >
    )
  },
  { field: 'url', headerName: 'Doc', width:80, renderCell: (params) => {
    if (params.value) {
      return (
        <Link
          target="_blank"
          href={params.value}
        >
          <DescriptionIcon />
        </Link>                 
      )
    }
    return "-"
  }}, 
  { field: 'updatedAt', headerName: 'date', width:200  },
  { field: 'documentType', headerName: 'Type' ,width:200},  
  { field: 'transactionId', headerName: 'Link to Transaction?',width:200, renderCell: (params) => {
    if (params.value) {
      return (
        <span>
          <Button loading={isLoading}  variant="text" onClick={() => navigate(`/client/transaction/${params.value}`)}>
          id {params.value}
          </Button>               
        </span>
      )
    }
    return (
      <span>
        -
     </span>
    )
}}, 
  { field: 'externalId', headerName: 'Client' ,width:300},
  { field: 'name', headerName: 'Document' ,width:300},
  { field: 'description', headerName: 'Description',width:300},       
  { field: 'creator', headerName: 'Admin', width:200},      
  { field: 'isClientVisible', headerName: 'Visible by Client?', width:200 , renderCell: (params) => {
      if (params.value ===1) {
        return (
          <span>
          <VisibilityIcon/>
          <LoadingButton loading={isLoadingButton}  variant="text" onClick={() => handleOpenDocVisibility(params.row.id,0)}>
          Hide it
        </LoadingButton>               
       </span>
        )
      }
      return (
        <span>
          <VisibilityOffIcon/>
          <LoadingButton loading={isLoadingButton} variant="text" onClick={() => handleOpenDocVisibility(params.row.id,1)}>
          Show it
        </LoadingButton>
       </span>
      )
  }},
  { field: 'isEmailSentToClient', headerName: 'isEmailSentToClient',width:200},      
  { field: 'createdAt', headerName: 'createdAt', width:200  },
  { field: 'updatedAt', headerName: 'updatedAt', width:200  },
  ];


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  

  const handleSearch = async () => {
    setIsLoadingSearch(true);
    const res = await fetchDataAwait("/documents", "put", {search: searchRef.current.value});
    if (res.error) {
      setErrorMessage(res.error.message);
    }
    else {
      setData(res.data);
      setConfirmationMessage(res.data.message);

    }
    setIsLoadingSearch(false);
  }


  const handleOpenDocVisibility = async (docId, visibility) => {
    setIsLoadingButton(true);      
    const url = `/document/visibility/${docId}/${visibility}`;
    const res = await fetchDataAwait(url, "get", null);
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      // Update table
      const updatedRows = data.documents.map((row) => {
        if (row.id === docId) {
          row.isClientVisible = visibility;
          return row;
        }
        return row;
      });      
      setData({...data, documents: updatedRows});
    }   
    setIsLoadingButton(false);      
    return 0;    
    
  } 
  

  if (isLoading) {
    return (
      <Page title="Documents">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Documents</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Documents">
        <Container maxWidth="false">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
              Documents
              </Typography>
            </Stack> 
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12} md={6}>    
                  <TextField fullWidth label="Search Documents in database" id="fullWidth"
                              inputRef={searchRef} 
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  handleSearch()
                                }
                              }}                                 
                  />
              </Grid>
            <Grid item xs={12} md={6} >                                        
                  <LoadingButton loading={isLoadingSearch} variant="contained" onClick={handleSearch}>
                  Search
                </LoadingButton>
            </Grid>              
            <Grid item xs={12}>
              <DataGridPro
                pagination
                overflow="auto"
                autoHeight 
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}
        {snackbarSuccess()}
      </Page>
    );
  }
  return (
    <Page title="Documents">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Documents</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}


