
import {  Button, Snackbar, Alert, FormControl  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function ButtonDialogRemoveBlackTag(props) {
  const classes = useStyles();   
  const {caseId, setErrorMessage, setConfirmationMessage} = props;
  ButtonDialogRemoveBlackTag.propTypes = {
    caseId: PropTypes.number.isRequired,
    setErrorMessage: PropTypes.func,
    setConfirmationMessage: PropTypes.func,    
  };  
  const [reason, setReason] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const getTag = async () => {
    setIsLoading(true); 
    const res = await fetchDataAwait(`/blacktag/${caseId}`, "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    
    setIsLoading(false);  
    return true    
  }
  

  const removeTag = async () => {
    setIsLoading(true); 
    const res = await fetchDataAwait(`/blacktag/${caseId}`, "put", {reason});
    if (res.error) {
        console.log(res.error.message);
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    setConfirmationMessage(res.data.message)
    setIsLoading(false);  
    setDialogOpen(false);
    return true    
  }


  const handleOpenDialog = () => {
    setDialogOpen(true);
    getTag();    
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

       
  return (
    <span>
      <LoadingButton   variant="text"  color="primary"  onClick={() => handleOpenDialog()}>remove label</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Remove Black Tags</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert severity="error" sx={{ width: '100%' }}>
                WARNING - This is a dangerous action for traceability
              </Alert>
              <br/>
                Black tags will be dissociated from the case and bottles. Photo/Weight measures will be kept for the case black tag.
                <br/><br/><br/>
                Reason for deleting black tags (mandatory)
                <br/>
                <TextField 
                                onChange={(e)=> setReason(e.target.value)}
                                className={classes.field} 
                                id="packSize-name" 
                                label="Reason" 
                                fullWidth required
                                multiline
                                rows={4}                                 
                                value={reason}
                                InputLabelProps={{ shrink: true }}
                            /> 

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton variant="contained"  color="primary"  loading={isLoading} onClick={removeTag} >
              REMOVE
            </LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
 
    </span>
    )
}