import { makeStyles } from '@mui/styles';
import { Skeleton, MenuItem, Select, RadioGroup, Radio, Button,  FormControl, Grid, Typography, TextField, FormControlLabel } from '@mui/material';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from "axios";
import { fetchDataAwait } from '../../utils/api';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));


export default function ButtonBackUpdatePOCase(props) {
  const classes = useStyles();   
  const { purchaseId,  setErrorMessage, setConfirmationMessage, dialogOpen, setDialogOpen } = props;
  ButtonBackUpdatePOCase.propTypes = {
    purchaseId: PropTypes.string.isRequired,
    setErrorMessage: PropTypes.func,
    setConfirmationMessage: PropTypes.func,
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
  };  
  const [isLoading, setIsLoading] = useState(true);
  
  const [valueField, setValueField] = useState(null);
  const [field, setField] = useState("orderReference");
    
  const [listFields,setListFields] = useState(["orderReference","location","qualityStandard"]);


  const uploadField = async () => {
    setIsLoading(true);
    
    const res = await fetchDataAwait(`/purchase-field/${purchaseId}`, "put",  {field, valueField});

    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage("Succesfully Updated.");
      setDialogOpen(false);
    } 

    setIsLoading(false);
    return 1;
  }
  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

  const handleFieldSelected = (event) => {
    setField(event.target.value);
  };  
  


  function displayContent() {

    return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                                           

      <Grid item xs={12} md={3} >
        <Typography variant="h6">Field to update</Typography>
      </Grid>    
      <Grid item xs={12} md={9} >
        <FormControl> 
          <Select 
            fullWidth	
            label="Field" 
            labelId="select-Field"
            id="select-Field"
            value={field}
            onChange={handleFieldSelected}
          >
            {listFields.map((item,i) => (
                <MenuItem value={item} key={i}>{item}</MenuItem>
            ))}
          </Select> 
        </FormControl>                   
      </Grid>                   
      <Grid item xs={12} md={3} >
        <Typography variant="h6">Value of field:</Typography>
      </Grid>  
      <Grid item xs={12} md={9} >
        <TextField 
            onChange={(e)=> setValueField(e.target.value)}
            className={classes.field} 
            label="Field Value"
            fullWidth 
            value={valueField}

        />                      
      </Grid>                                                                                                                                                    
    </Grid>        
    )
  }

  return (
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">Mass update of a case/bottle field</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {displayContent()}                    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton variant="contained" onClick={() => uploadField()} color="primary">
              UPDATE ALL CASES
            </LoadingButton>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>        
    )
}