
import {  Button  } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

import { useState } from 'react';
import { fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';



export default function ButtonDialogAddClient(props) {

  const {updateClientSelection, setErrorMessage, client, setClient } = props;
  ButtonDialogAddClient.propTypes = {
    updateClientSelection: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired, 
    setClient: PropTypes.func.isRequired,
  };   
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let clientSelection=[];

  const columnsClient = [
    { field: 'name', headerName: 'Name', width:200
      , renderCell: (params) =>  (
      <div>
        <strong>{params.value}</strong> - <small>{params.row.externalId}</small>
      </div>
      )
    },      
    { field: 'cashBalance', headerName: 'Cash', width:200  , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'casesValue', headerName: 'Wine Value', width:200 , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'bookValue', headerName: 'Book Value', width:200 , renderCell: (params) =>  fCurrency(params.value) },  
    { field: 'valueDate', headerName: 'Value Date', width:200  },  
  ];

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

  const handleCloseAddDialogConfirmedClient = () => {
    if (clientSelection.length >0) {
      // Add new column to allocation table
      updateClientSelection(clientSelection);
    }
      setDialogOpen(false);
    return true;
  }      

  const handleButtonClick = () => {
    if (client.length===0) {
      const res = refreshClients()
      setDialogOpen(res);        
    } 
    else {
      setDialogOpen(true);  
    }
  }      
    
  const refreshClients = async() => {
    setIsLoading(true);
    const res = await fetchDataAwait("/allocation/get-clients", "get", null);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    
    setClient(res.data.client);  
    setIsLoading(false);  
    return true       
  }      

  return (
    <span>
      <LoadingButton  variant="contained"  color="primary" loading={isLoading}  onClick={handleButtonClick}><PersonAddIcon/>Add Client(s)</LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Select buyer<LoadingButton loading={isLoading} size="small" onClick={() => refreshClients()} >< RefreshIcon/></LoadingButton></DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <DataGridPro overflow="auto"
                pagination
                autoHeight 
                rows={client} 
                columns= {columnsClient} 
                pageSize={25}
                loading={isLoading}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  clientSelection=newSelection;
                }}
                
                />
                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseAddDialogConfirmedClient} color="primary" autoFocus>
              ADD
            </Button>
          </DialogActions>
        </Dialog>              
    </span>
    )
}