// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Link, FormControl, InputLabel, Select, MenuItem, Checkbox,  FormControlLabel, Box, Grid, Container, Typography, Skeleton, IconButton, Button,  ButtonGroup, Snackbar, Alert, Stack, Card, CardContent, AppBar, Chip  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
// components

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {useNavigate, useParams} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { useState, useEffect } from 'react';
import ButtonDialogCaseSensor from '../../components/stock/ButtonDialogCaseSensor';
import ButtonDialogCasePallet from '../../components/stock/ButtonDialogCasePallet';
import ButtonDialogCasePalletHistory from  '../../components/stock/ButtonDialogCasePalletHistory';

import ButtonDialogRemoveBlackTag from  '../../components/stock/ButtonDialogRemoveBlackTag';

import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}



export default function CaseItem() {
  const classes = useStyles();   
  const { id } = useParams();    
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);  
  const [dialogOpenPicture, setDialogOpenPicture] = useState(false);  
  const [openCaseSplit, setOpenCaseSplit] = useState(false);  
  const [newPackSize, setNewPackSize] = useState(0);  
  const [newPackFormat, setNewPackFormat] = useState(false);  
  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [formError, setFormError] = useState({});
  const [isLoadingButton,setIsLoadingButton] = useState(false); 


  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const handleChangeCheckBox = (event) => {
    setCaseData("saleForbidden",event.target.checked);
  };  

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/case/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/case-param`, "get", null);
    };      
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
    }
    setFormError({
        "name": false,
        "packSize": false,
        "bottleSize": false,
        "packType": false,
        "lwin": false,
        "location": false,
        "orderReference": false,
        "saleForbidden": false    
    });
    return 0    
  }, [id]);

  const columnsTransactions =  [
    { field: 'id', headerName: ' ', width:20
      , renderCell: (params) => (
        <strong>
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/client/transaction/${params.value}`)}
          >
            <ZoomInIcon />
          </IconButton >        
        </strong>
      )
    }, 
    { field: 'user', headerName: 'Client' ,width:100},
    { field: 'methodLabel', headerName: 'Type' ,width:100},
    { field: 'note', headerName: 'Note',width:300},      
    { field: 'invoice', headerName: 'Invoice#',width:100},        
    { field: 'groupName', headerName: 'Transac Group', width:200},      
    { field: 'cashTransfer', headerName: 'cashTransfer', width:100 , renderCell: (params) =>  fCurrency(params.value)  },
    { field: 'admin', headerName: 'Admin',width:200},      
    { field: 'createdAt', headerName: 'createdAt', width:200  },
    { field: 'updatedAt', headerName: 'updatedAt', width:200  }
];


// https://dev.to/jessicabetts/how-to-use-google-maps-api-and-react-js-26c2
const columnsPosition =  [
  { field: 'datePosition', headerName: 'datePosition' ,width:150},
  { field: 'type', headerName: 'Type' ,width:150},
  { field: 'position', headerName: 'Position' ,width: 300},
  { field: 'inboundShippingId', headerName: 'Shipping' ,width:200 , renderCell: (params) => {
    if (params.value) {
      return (
        <strong>
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/shipping/list/${params.value}`)}
          >
            
            <ZoomInIcon />
          </IconButton >        
          {params.row.shipping}
        </strong>
      )
    }
    return (
      <span>
        No shipping data
      </span>
    )
  }
}, 
  // { field: 'label', headerName: 'label' ,width:100},
  // { field: 'logs', headerName: 'logs' ,width:100},
  // { field: 'type', headerName: 'type' ,width:100},
  { field: 'address', headerName: 'address' ,width:300},
  // { field: 'latitude', headerName: 'latitude' ,width:70},
  // { field: 'longitude', headerName: 'longitude' ,width:70},
  { field: 'number1195', headerName: 'number1195' ,width:150},
  { field: 'customsDocument', headerName: 'customsDocument' ,width:150},
];


  const columnsOwnership =  [ 
    { field: 'userName', headerName: 'Owner' ,width:200},
    { field: 'ownershipStatus', headerName: 'Ownership Status',width:300},      
    { field: 'dateAllocation', headerName: 'dateAllocation',width:200},        
    { field: 'bookValue', headerName: 'bookValue', width:200},      
    { field: 'exitValue', headerName: 'exitValue', width:200 },
    { field: 'dateExit', headerName: 'dateExit',width:200},      
    { field: 'clientInstruction', headerName: 'clientInstruction', width:300  },
    { field: 'isSaleForbidden', headerName: 'isSaleForbidden', width:200  },
    { field: 'createdAt', headerName: 'updatedAt', width:200  }
];


  const columnsBottles =  [
    { field: 'name', headerName: 'Bottle', width:300}, 
    { field: 'bottleSize', headerName: 'Size' ,width:200},  
    { field: 'vintageId', headerName: 'Vintage' ,width:200
    , renderCell: (params) => (
      <div>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/vintage/${params.value}`)}
        >
          <ZoomInIcon /> 
        </IconButton > 
        {params.row.year} {params.row.vintage}       
      </div>
    )
  },    
    { field: 'lwin', headerName: 'Lwin' ,width:200},  
    { field: 'certificationReference', headerName: '9-digit' ,width:200},  
    { field: 'qualityStandard', headerName: 'Quality Standard' ,width:200},  
    { field: 'inCaseStatus', headerName: 'Status' ,width:200},  
  ];


  const columnsHistory =  [
    { field: 'date', headerName: 'date',width:200},    
    { field: 'action', headerName: 'action' ,width:200},      
    { field: 'caseData', headerName: 'caseData', width:300},  
    { field: 'actionData', headerName: 'actionData' ,width:500},
  ];


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  
  
  function displayLoading() {
    return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item xs={12}>
        <Skeleton variant="rect"  height={500}  />
      </Grid>
    </Grid>
    )
  }
  
  
  function displayError() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
        <Grid item >
          <Alert  severity="error" sx={{ width: '100%' }}>
          {error.message}
          </Alert>          
        </Grid>
      </Grid>
    );  
  }
  
  const uploadItem = async () => {
    setIsLoadingButton(true);   
    let url = '/case/';
    url += id;
       
    const res = await fetchDataAwait(url, "put", data.case);
    
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setData(res.data);
      setConfirmationMessage("Succesfully saved.");
      setIsDisabled(true);  
    }   
    setIsLoadingButton(false);      
  }
  
  
  const handleSaveClick = () => {
    uploadItem(); 
  }
  
  
  const setCaseData = (field,value) => {
    data.case[field] = value;
    setData({...data});
  }
  
  
  const handleCancelClick = () => { 
    setData({...data});
    setIsDisabled(true);  
  }

  const handleSelectedBottleSize = (event) => {
    setCaseData("bottleSize",event.target.value);
  };
  
  const handleSelectedPackType = (event) => {
    setCaseData("packType",event.target.value);
  };

  const handleSelectedStatus = (event) => {
    setCaseData("status",event.target.value);
  };


  const dialogCaseSplit = () =>  (
    <Dialog
    open={openCaseSplit}
    onClose={() => handleDialogOpenCaseSplitClose()}
    aria-labelledby="alert-dialog-CaseSplit"
    aria-describedby="alert-dialog-CaseSplit-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Split Case</DialogTitle> 
      <DialogContent sx={{ pb: 5 }}>
      <Alert severity="error" sx={{ width: '100%' }}>
      Are you sure you want to split this case? It will delete this case and create new ones.
      </Alert>
        Please select the pack size of the new cases:
        <br/>
        <TextField 
                        onChange={(e)=> setNewPackSize(e.target.value)}
                        className={classes.field} 
                        type="number"
                        id="packSize-name" 
                        label="New Cases Pack Size" 
                        fullWidth required
                        value={newPackSize}
                        InputLabelProps={{ shrink: true }}
                    /> 
        <FormControlLabel
          control={<Checkbox checked={newPackFormat} onChange={handleChangeCaseSplitCheckBox} />}
          label="Repack in Non Original Wooden case (NOWC)"
          /> 
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateCaseSplit()} color="primary">
          Yes
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenCaseSplitClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleChangeCaseSplitCheckBox = (event) => {
    setNewPackFormat(event.target.checked);
  };  
  const handleDialogOpenCaseSplitClose = () => {
    setOpenCaseSplit(false); 
  }  
  const handleGenerateCaseSplit = async () => {
    setIsLoadingButton(true);      
    const url = `/cases/repack`;
    const res = await fetchDataAwait(url, "post", {cases:[id], new_format:[newPackSize], repack: newPackFormat });
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setOpenCaseSplit(false);
      setIsLoading(true);
      setConfirmationMessage(<span>{res.data.message}</span>);
      if (res.data.newCases.length===1) {
        navigate(`/stock/case/${res.data.newCases[0][0]}`);
      } else {
        if (!id) {
          return 0;
        }
        // Reload page
        const resReload = await fetchDataAwait(`/case/${id}`, "get", null);
        if (resReload.error) {
          setErrorMessage(resReload.error.message);
        } else {
          setData(resReload.data)
          setConfirmationMessage(<span>{res.data.message}</span>);
        }
      }
      setIsLoading(false);
    }
    setIsLoadingButton(false);      
    return 0;
  }

  function displayPurchase(){
    if (!data.case.purchaseId) {
      return (
        <Grid item xs={12} >
        <small>Case not linked to a purchase</small> 
      </Grid>    
      )
    }

    let jsxLink= ""
    if (data.case.purchaseUrl) {
      jsxLink= (<Link
        target="_blank"
        href={data.case.purchaseUrl} >                                                                                
      Link to Invoice
      </Link>  
      )
    }

    return (
      <Grid item xs={12} >
      <small>Inital Purchase: 
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/purchase/list/${data.case.purchaseId}`)}
      >
        <ZoomInIcon /> 
        {data.case.purchaseName} - {data.case.purchaseFileDate}
      </IconButton >  
                                                                                 
      {jsxLink}
        
        </small> 
    </Grid>    
    )
  }

  function displayDialogPicture() {
    if (data.case.urlThumbnail) {
      return (
        <div>
        <Button onClick={() => setDialogOpenPicture(true)}>
        <img alt="Case Thumbnail" src={data.case.urlThumbnail} />
        <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setDialogOpenPicture(true)}
            >
              <ZoomInIcon />
            </IconButton > 
            {data.case.certificationReference}     
        </Button>
        

        <Dialog
          open={dialogOpenPicture}
          onClose={() => setDialogOpenPicture(false)}
          fullWidth
          maxWidth='xl'
          >
            <DialogTitle >Case Picture</DialogTitle> 
            <DialogContent>
              <DialogContentText >
                {data.case.name}
              </DialogContentText>
                <InnerImageZoom src={data.case.url}   zoomSrc={data.case.url} />
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={() => setDialogOpenPicture(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>               
      </div>
      )
    }
    return ( <div> {data.case.certificationReference} </div>)      
  }   


  function displayItem() {
    let jsxPallet = (      
          <div>
            No Pallet
          </div>)
    let jsxSensor = (      
      <div>
        No Sensor
      </div>)
    if (data.case.palletId) {
      jsxPallet = (<div>
      On pallet 
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/warehouse/pallet/${data.case.palletId}`)}
      >
        <ZoomInIcon /> 
        {data.case.palletCode}
      </IconButton > 
    </div>)
    }
    if (data.case.sensorGroupId) {
      jsxSensor = (<div>
      Sensor
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/warehouse/sensor/${data.case.sensorGroupId}`)}
      >
        <ZoomInIcon /> 
        {data.case.sensorGroupName}
      </IconButton > 
    </div>)
    }    

    return (
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                      
            <Grid item xs={12} >
              <Card >
                <CardContent>
                <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">  
                  <Grid item xs={12} md={8}>
                    <TextField 
                        onChange={(e)=> setCaseData("name",e.target.value)}
                        className={classes.field} 
                        id="wine-name" 
                        label="Wine Name" 
                        fullWidth required
                        value={data.case.name}
                        error={formError.name}
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}  md={4}>
                    {displayDialogPicture()}
                    <ButtonDialogRemoveBlackTag                 setConfirmationMessage={setConfirmationMessage} 
                setErrorMessage={setErrorMessage}  caseId={data.case.id} />
                  </Grid>

    
                  <Grid item xs={12} md={4} >
                    <TextField 
                        onChange={(e)=> setCaseData("packSize",e.target.value)}
                        className={classes.field} 
                        id="packSize-name" 
                        label="Pack Size" 
                        fullWidth required
                        value={data.case.packSize}
                        error={formError.packSize}
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: true }}
                    />                  
                  </Grid> 
                  <Grid item xs={12} md={4} >
                    <FormControl fullWidth>
                      <InputLabel>Bottle Size</InputLabel>                      
                      <Select style={{width: 300}}
                            autoWidth	
                            label="bottle Size" 
                            disabled={isDisabled}
                            labelId="select-bottleSize"
                            id="select-bottleSize"
                            value={data.case.bottleSize}
                            onChange={handleSelectedBottleSize}
                          >
                            {data.listBottleSize.map((item,i) => (
                                <MenuItem value={item} key={i}>{item}</MenuItem>
                            ))}
                        </Select>  
                    </FormControl>                                              
                  </Grid>                     
                  <Grid item xs={12} md={4} >
                    <FormControl fullWidth>
                      <InputLabel>Pack Type</InputLabel>                    
                        <Select style={{width: 300}}
                            autoWidth	
                            label="Pack Type" 
                            disabled={isDisabled}
                            labelId="select-packType"
                            id="select-packTypw"
                            value={data.case.packType}
                            onChange={handleSelectedPackType}
                          >
                            {data.listPackType.map((item,i) => (
                                <MenuItem value={item} key={i}>{item}</MenuItem>
                            ))}
                      </Select>   
                    </FormControl>                        
                  </Grid>                               
                  <Grid item xs={12} md={4} >
                    Allocated on {data.case.dateAllocation}
                    <FormControl> 
                    <InputLabel>Case Status</InputLabel>  
                    <Select style={{width: 300}}
                            autoWidth	
                            label="status Size" 
                            disabled
                            labelId="select-status"
                            id="select-status"
                            value={data.case.status}
                            onChange={handleSelectedStatus}
                          >
                            {data.status.map((item,i) => (
                                <MenuItem value={item} key={i}>{item}</MenuItem>
                            ))}
                        </Select>  
                    </FormControl>                     
                  </Grid>       
                  <Grid item xs={12} md={4} >
                    {jsxPallet} <ButtonDialogCasePallet isDisabled={isDisabled} caseId={data.case.id} palletCode={data.case.palletCode}  caseName={data.case.name} callbackCase={setCaseData} />
                    <ButtonDialogCasePalletHistory  caseId={data.case.id} />
                  </Grid> 
                  <Grid item xs={12} md={4} >
                    {jsxSensor} <ButtonDialogCaseSensor isDisabled={isDisabled} caseId={data.case.id} sensorGroupName={data.case.sensorGroupName}  caseName={data.case.name} callbackCase={setCaseData} />
                  </Grid>                                                     
                  <Grid item xs={12}  md={4} >
                  <TextField 
                        onChange={(e)=> setCaseData("lwin",e.target.value)}
                        className={classes.field} 
                        id="lwin" 
                        label="lwin" 
                        fullWidth required
                        value={data.case.lwin}
                        error={formError.lwin}
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: true }}
                    />
                  </Grid>    
                  <Grid item xs={12}  md={4} >
                    <TextField 
                        onChange={(e)=> setCaseData("location",e.target.value)}
                        className={classes.field} 
                        id="location" 
                        label="Location" 
                        fullWidth
                        value={data.case.location}
                        error={formError.location}
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: true }}
                    />
                  </Grid> 
                  <Grid item xs={12}  md={4} >
                    <TextField 
                        onChange={(e)=> setCaseData("orderReference",e.target.value)}
                        className={classes.field} 
                        id="orderReference" 
                        label="orderRef" 
                        fullWidth
                        value={data.case.orderReference}
                        error={formError.orderReference}
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}  md={4} >
                    Current Owner
                    <IconButton 
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => navigate(`/client/list/${data.case.userId}`)}
                    >
                      <ZoomInIcon /> 
                      {data.case.user}
                    </IconButton >  
                  </Grid>                   
                  <Grid item xs={12}  md={4} >
                    <FormControlLabel
                    control={<Checkbox disabled={isDisabled} checked={data.case.saleForbidden} onChange={handleChangeCheckBox} />}
                    label="Sale Forbidden?"
                    /> 
                  </Grid> 
                  <Grid item xs={12}  md={4} >
                    <TextField 
                        onChange={(e)=> setCaseData("clientInstruction",e.target.value)}
                        className={classes.field} 
                        id="clientInstruction" 
                        label="clientInstruction" 
                        fullWidth
                        value={data.case.clientInstruction}
                        error={formError.clientInstruction}
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: true }}
                    />
                  </Grid>                                                     
                  {displayPurchase()}
                  <Grid item xs={12} >
                    <small>Book Value</small> <b><Chip label={fCurrency(data.case.bookValue)} variant="outlined" /></b> | <small>Last Value</small> <b><Chip label={fCurrency(data.case.currentValue)} variant="outlined" /></b> <small>as of {data.case.currentValueUpdatedAt}</small>
                  </Grid>                                                           
                  <Grid item xs={12} >
                    <small> created at {data.case.createdAt} | updated at {data.case.updatedAt} </small> 
                  </Grid>
                </Grid>              
                </CardContent>
              </Card >            
              
            </Grid>                                        
            <Grid item xs={12}>
            <Card >
              <CardContentNoPadding >            
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Bottles" {...a11yProps(0)} />
                <Tab label="Valuation" {...a11yProps(1)} />
                <Tab label="Ownership" {...a11yProps(2)} />
                <Tab label="History" {...a11yProps(3)} />
                <Tab label="Transactions" {...a11yProps(4)} />
                <Tab label="Traceability" {...a11yProps(5)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <DataGridPro
                pagination
                autoHeight 
                rows={data.bottles} 
                columns= {columnsBottles}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HighchartsReact
                constructorType='stockChart'
                highcharts={Highcharts}
                options={{
                  credits: {
                    enabled: false
                  },  
                  title: {
                    text: `${data.case.name} ${data.case.packSize}x${data.case.bottleSize}`
                  },
                  series: [{
                    name: `Cases Value`,
                    data: data.performance,
                    tooltip: {
                      valueDecimals: 0
                    }
                  }]
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DataGridPro
              pagination
                autoHeight 
                rows={data.ownership} 
                columns= {columnsOwnership}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
               <DataGridPro
               pagination
                autoHeight 
                rows={data.history} 
                columns= {columnsHistory}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </TabPanel> 
            <TabPanel value={value} index={4}>
              <DataGridPro
              pagination
                autoHeight 
                rows={data.transactions} 
                columns= {columnsTransactions}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </TabPanel>     
            <TabPanel value={value} index={5}>
              <DataGridPro
              pagination
                autoHeight 
                rows={data.position} 
                columns= {columnsPosition}
                
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </TabPanel>                          
            </CardContentNoPadding >
              </Card >                  
            </Grid>
          </Grid>
    );
  }



  function displayActionButtons() {

    if (isLoading || !data) {
      return ""
    }
  
      if (isDisabled) {
        return (
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" >
          <Button
            variant="contained"
            onClick={() => setIsDisabled(false)}
          >
            <EditIcon/>
            Edit
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenCaseSplit(true)}
          >
            SPLIT CASE
          </Button>
          {dialogCaseSplit()}          
        </Stack>        
        )
      }
  
      return (    
      <ButtonGroup>
        <LoadingButton
          variant="contained"
          loading={isLoadingButton}
          onClick={() => handleSaveClick()}
        >
          <SaveIcon/>
          Save
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => handleCancelClick(true)}
        >
          <CancelIcon/>
          Cancel
        </Button>        
    </ButtonGroup>
    )
  }



  function displayPage(func) {
    return (
      <Page title="Case">
        <Container maxWidth="false">
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Case - {id}
            </Typography>
            {displayActionButtons()}
          </Stack>        
          {func()}
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}    
      </Page>
    );
  }
  
  // PAGE LOGIC
  if (isLoading) {
    return displayPage(displayLoading);
  }
  if (!error) {   
    return displayPage(displayItem);
  }
  return displayPage(displayError);

}

