// Amplify
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator } from '@aws-amplify/ui-react'
import { LicenseInfo } from '@mui/x-data-grid-pro';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

const awsconfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_ID_POOL,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT,
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_S3_REGION
    }
  }
};

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

// MUI-X
LicenseInfo.setLicenseKey(
  '4168b74cbfd7030ac2ea8bba5e3e82e6T1JERVI6MzU5MDUsRVhQSVJZPTE2NzM3MDc4MDkwMDAsS0VZVkVSU0lPTj0x',
);


// ----------------------------------------------------------------------

function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
    </ThemeConfig>
  );
}

export default withAuthenticator(App);