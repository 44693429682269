// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Button,  ButtonGroup, Snackbar, Alert, Stack, Card, CardContent, MenuItem, Select  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

// components
import {useNavigate, useParams} from 'react-router-dom';
import { useState, useEffect } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import ButtonDialogModifyVintagePicture from '../../components/stock/ButtonDialogModifyVintagePicture';
import ButtonDialogPickWine from '../../components/stock/ButtonDialogPickWine';
import ButtonAddNewVintage from '../../components/stock/ButtonAddNewVintage';
import DialogRowUpdateCategoryRegion from '../../components/allocate/DialogRowUpdateCategoryRegion';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:0,
    marginBottom: 0,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

// ----------------------------------------------------------------------


export default function VintageItem() {
  const classes = useStyles();  
  const { id } = useParams();      
  const [itemId, setItemId] = useState(id);
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   
  // Form
  const [isDisabled, setIsDisabled] = useState(true);
  const [formError, setFormError] = useState({});
  const [isLoadingButton,setIsLoadingButton] = useState(false); 
  // Category
  const [dialogOpenCategory,setDialogOpenCategory] = useState(false);

  // Grapes
  const [addDialogOpen,setAddDialogOpen] = useState(false);
  const [selectedGrapeId,setSelectedGrapeId] = useState(null);
  const columnsVintageGrapes = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.value);}}
        >
          <DeleteIcon />
        </IconButton >        
      </strong>
    )
  },    
  { field: 'name', headerName: 'Name', width:200   },  
  { field: 'blendPercent', headerName: 'Blend Percent (%)', cellClassName: 'editable-theme--cell', width:200, type: 'number' , editable: true  },
  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/vintage/${id}`, "get", null);
    };
    const fetchDataReactScreenNew = async () => {
      fetchData(setIsLoading,setData,setError,`/vintage-param`, "get", null);
    };       
    if (id!=='new') {
      fetchDataReactScreen();
    } else {
      fetchDataReactScreenNew();
      setIsDisabled(false);
    //   setData({
    //     "vintage": {
    //       "name": null,
    //       "lwin": null,
    //       "wine": null,
    //       "wineId": null,
    //       "drinkingWindowStart": null,
    //       "drinkingWindowEnd": null,
    //       "year": null,
    //       "organicStatus": null,
    //       "productionBottles": null,       
    //       "vintagePictureId": null,
    //       "url": null,      
    //       "urlThumbnail": null,                 
    //     },
    //     "vintages":[],
    //     "vintageGrapes":[],
    //     "grapes":[]      
    //   });
    // }
    }    
    setFormError({
      "name": false,
      "lwin": false,
      "drinkingWindowStart": false,
      "drinkingWindowEnd": false,
      "year": false,
      "organicStatus": false,
      "productionBottles": false,       
      "vintagePictureId": false,    
    });
    return 0    
  }, [id]);


  const columns =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/vintage/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        

    )
  },
  { field: 'name', headerName: 'Vintage', width:300 },
  { field: 'year', headerName: 'Year', width:200   },
  { field: 'lwin', headerName: 'lwin', width:200   }
];


const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

function displayLoading() {
  return (
  <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
    <Grid item xs={12}>
      <Skeleton variant="rect"  height={500}  />
    </Grid>
  </Grid>
  )
}


function displayError() {
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item >
        <Alert  severity="error" sx={{ width: '100%' }}>
        {error.message}
        </Alert>          
      </Grid>
    </Grid>
  );  
}

const uploadItem = async () => {
  setIsLoadingButton(true);      
  let url = '/vintage/';
  if (itemId !=="new") {
    url += itemId;
  } 
  let res = null;
  if (itemId ==="new") {
    res = await fetchDataAwait(url, "post", data);
  }
  else {
    res = await fetchDataAwait(url, "put", data);
  }
  if (res.error) {
    setErrorMessage(res.error.message);
  } else {
    setData(res.data);
    if (itemId ==="new") {
      setItemId(res.data.vintage.id);
    }
    setConfirmationMessage("Succesfully saved.");
    setIsDisabled(true);  
  }   
  setIsLoadingButton(false);      
}


const handleSaveClick = () => {
  uploadItem(); 
}


const setVintageData = (field,value) => {
  data.vintage[field] = value
  setData({...data});
}



const handleCancelClick = () => { 
  setData({...data});
  setIsDisabled(true);  
}


function displayActionButtons() {

  if (isLoading || !data) {
    return ""
  }

    if (isDisabled) {
      let buttonJsx ="";
      if ( data.vintage.wineId ) {
        buttonJsx = <ButtonAddNewVintage wineId={data.vintage.wineId} />  
      }      
      return (
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" >
        <Button
          variant="contained"
          onClick={() => setIsDisabled(false)}
        >
          <EditIcon/>
          Edit
        </Button>
        {buttonJsx}
      </Stack>        
      )
    }

    return (    
    <ButtonGroup>
      <LoadingButton
        variant="contained"
        loading={isLoadingButton}
        onClick={() => handleSaveClick()}
      >
        <SaveIcon/>
        Save
      </LoadingButton>
      <Button
        variant="contained"
        onClick={() => handleCancelClick(true)}
      >
        <CancelIcon/>
        Cancel
      </Button>        
  </ButtonGroup>
  )
}


function displayPage(func) {
  return (
    <Page title="Vintage">
      <Container maxWidth="false">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Vintage
          </Typography>
          {displayActionButtons()}
        </Stack>        
        {func()}
      </Container>
      {snackbarError()}   
      {snackbarSuccess()}    
    </Page>
  );
}

const handleRemoveRow = (rowId) => {
  const filteredVintageGrapes = data.vintageGrapes.filter((el) =>  el.id !== rowId); 
  setData({...data, vintageGrapes: filteredVintageGrapes});
}
const handleCellEditCommit = ({ id, field, value }) => {
  const updatedRows = data.vintageGrapes.map((row) => {
    if (row.id === id) {
      row[field] = value;
      return row;
    }
    return row;
  });
  setData({...data, vintageGrapes: updatedRows});
};
const handleSelectedGrapeId = (event) => {
  setSelectedGrapeId(event.target.value);
};
const handleCloseAddDialog = () => {
  setAddDialogOpen(false);
  }
const handleCloseAddDialogConfirmed = () => {
  if ( selectedGrapeId === '' ) {
    setErrorMessage("Select a grape");
    return false;
  }  
  // Check if row already exists
 if (data.vintageGrapes.some((el) => 
    el.id === selectedGrapeId
  )) {
    setErrorMessage("This grape is already defined.");
    return false;
  }
  // Add to existing Composition
  const newRow = {
    id: selectedGrapeId,
    name: data.grapes.find(obj => obj.id === selectedGrapeId).name,
    blendPercent:0,
  }
  setData({...data, vintageGrapes: [...data.vintageGrapes,newRow]});
  setAddDialogOpen(false);

  return true;
}

const updateAllocationCategory = (rowId, categoryData) => {
  data.vintage.regionName = categoryData.region.name
  data.vintage.categoryName = categoryData.category.name
  data.vintage.category2Name = categoryData.category2.name
  data.vintage.regionId = categoryData.region.id
  data.vintage.categoryId =  categoryData.category.id
  data.vintage.category2Id =  categoryData.category2.id
  setData({...data, vintage: data.vintage});
}


function defineGrapeList() {
  return (

    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="left">
      <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left">
        <Grid container item xs={12} >
            <ButtonGroup variant="contained"  color="primary" aria-label="contained primary button group">
              <Button  onClick={()=> setAddDialogOpen(true)} disabled={isDisabled}><AddIcon/>Add Grape</Button>
              <Dialog
                open={addDialogOpen}
                onClose={handleCloseAddDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Add a Grape</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Grid  item xs={12} sm={6} md={3}>
                          Grape
                        <Select style={{width: 300}}
                            autoWidth	
                            labelId="select-grape"
                            id="select-grape"
                            onChange={handleSelectedGrapeId}
                          >
                            {data.grapes.map((item,i) => (
                                <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                            ))}
                        </Select>  
                      </Grid>                      
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="text" onClick={handleCloseAddDialog} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleCloseAddDialogConfirmed} color="primary" autoFocus>
                      ADD
                    </Button>
                  </DialogActions>
                </Dialog>              
          </ButtonGroup>         
        </Grid>               
      </Grid>
        <Grid item container spacing={3} direction="row" justifyContent="left" alignItems="left"  className={classes.root} md={6}>  
          <DataGridPro 
          overflow="auto"
          disableExtendRowFullWidth
          autoHeight 
          rows={data.vintageGrapes} 
          columns= {columnsVintageGrapes} 
          onCellEditCommit={handleCellEditCommit}
        />           
            
      </Grid>              
    </Grid>

  )
}


function displayItem() {
  let ImgJsx =   (  <Button disabled  variant="contained"  color="primary"> <ImageIcon /> Select Image</Button>);
  if (itemId!=="new") {
    ImgJsx =   (  <ButtonDialogModifyVintagePicture vintageId={data.vintage.id} vintageName={data.vintage.name} url={data.vintage.url} callbackCase={setVintageData} />);
  }
  if (data.vintage.url) {
    ImgJsx = ( <span>
          <InnerImageZoom src={data.vintage.urlThumbnail}   zoomSrc={data.vintage.url} />
          <ButtonDialogModifyVintagePicture vintageId={data.vintage.id} vintageName={data.vintage.name} url={data.vintage.url} callbackCase={setVintageData} />
          </span>
    );        
  }  
  return (
    <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
      <Grid item xs={12} >
        <Card >
        <CardContent>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">  
            <Grid item xs={12}  md={5} >
              <TextField 
                  onChange={(e)=> setVintageData("name",e.target.value)}
                  className={classes.field} 
                  id="wine-name" 
                  label="Vintage Name" 
                  fullWidth required
                  value={data.vintage.name}
                  error={formError.name}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}  md={3} >
              <TextField 
                  onChange={(e)=> setVintageData("year",e.target.value)}
                  className={classes.field} 
                  id="year-name" 
                  label="Year" 
                  fullWidth required
                  value={data.vintage.year}
                  error={formError.year}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>            
            <Grid item xs={12}  md={4}>
              {ImgJsx}
            </Grid>            
            <Grid item xs={12} md={4}>
            <TextField 
                  onChange={(e)=> setVintageData("lwin",e.target.value)}
                  className={classes.field} 
                  id="lwin" 
                  label="lwin 11" 
                  fullWidth required
                  value={data.vintage.lwin}
                  error={formError.lwin}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>   
            <Grid item xs={12} md={4}>
            <TextField 
                  onChange={(e)=> setVintageData("wine",e.target.value)}
                  className={classes.field} 
                  id="wine" 
                  label="wine" 
                  fullWidth required
                  value={data.vintage.wine}
                  error={formError.wine}
                  disabled
                  InputLabelProps={{ shrink: true }}
              />
              <ButtonDialogPickWine itemName={`${data.vintage.name} ${data.vintage.year}`}  wineName={data.vintage.wine} callbackItem={setVintageData} isDisabled={isDisabled} />
            </Grid>                                        
            <Grid item xs={12} md={6}>
              <TextField 
                  onChange={(e)=> setVintageData("drinkingWindowStart",e.target.value)}
                  className={classes.field} 
                  type="number"
                  id="drinkingWindowStart" 
                  label="drinking Window Start" 
                  fullWidth
                  value={data.vintage.drinkingWindowStart}
                  error={formError.drinkingWindowStart}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid item xs={12} md={6}>
              <TextField 
                  onChange={(e)=> setVintageData("drinkingWindowEnd",e.target.value)}
                  className={classes.field} 
                  type="number"
                  id="drinkingWindowEnd" 
                  label="drinking Window End" 
                  fullWidth
                  value={data.vintage.drinkingWindowEnd}
                  error={formError.drinkingWindowEnd}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>   
            <Grid item xs={12} md={6}>
              <TextField 
                  onChange={(e)=> setVintageData("organicStatus",e.target.value)}
                  className={classes.field} 
                  id="organicStatus" 
                  label="organic Status" 
                  fullWidth
                  value={data.vintage.organicStatus}
                  error={formError.organicStatus}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>    
            <Grid item xs={12} md={6}>
              <TextField 
                  onChange={(e)=> setVintageData("productionBottlesAnnual",e.target.value)}
                  className={classes.field} 
                  id="productionBottlesAnnual" 
                  label="production Bottles Annual" 
                  fullWidth
                  value={data.vintage.productionBottlesAnnual}
                  error={formError.productionBottlesAnnual}
                  disabled={isDisabled}
                  InputLabelProps={{ shrink: true }}
              />
            </Grid>      
            <Grid container item xs={12}>
              List Grapes 
              {defineGrapeList()}
            </Grid> 
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                onClick={() => setDialogOpenCategory(true)}
                disabled={itemId==='new'}
              >
                <EditIcon/>
                Update Allocation Categories
              </Button> Alloc Region: <b>{data.vintage.regionName}</b> - Alloc Category: <b>{data.vintage.categoryName}</b> - Alloc Category2: <b>{data.vintage.category2Name}</b>
              <DialogRowUpdateCategoryRegion vintageId={itemId} rowId="1" callBackFunction={updateAllocationCategory} setConfirmationMessage={setConfirmationMessage} setErrorMessage={setErrorMessage} dialogOpen={dialogOpenCategory} setDialogOpen={setDialogOpenCategory} />
            </Grid>                       
            <Grid item xs={12} >
              <small> created at {data.vintage.createdAt} | updated at {data.vintage.updatedAt} </small> 
            </Grid>
          </Grid>              
        </CardContent>
        </Card>                    
      </Grid>
      <Grid item xs={12}>
      <Box sx={{ pb: 5 }}>
        <Typography variant="h6">Vintages for this wine</Typography>
      </Box>        
      
      <DataGridPro
        autoHeight 
        pagination
        rows={data.vintages} 
        columns= {columns} 
        
        loading={isLoading}
        components={{
          Toolbar: CustomToolbar,
        }}         
      />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ pb: 5 }}>
        <Typography variant="h6">Vintage Performance (12x75cl)</Typography>
      </Box>        
      <HighchartsReact
                constructorType='stockChart'
                highcharts={Highcharts}
                options={{
                  credits: {
                    enabled: false
                  },  
                  title: {
                    text: `${data.vintage.name}`
                  },
                  series: [{
                    name: `Liv-ex Avg Listed Value`,
                    data: data.avgValue,
                    tooltip: {
                      valueDecimals: 0
                    }
                  },
                  {
                    name: `Liv-ex Market Price`,
                    data: data.marketPrice,
                    tooltip: {
                      valueDecimals: 0
                    }
                  }                ]
                }}
              />
    </Grid>    
  </Grid>    
  )

}

  // PAGE LOGIC
  if (isLoading) {
    return displayPage(displayLoading);
  }
  if (!error) {   
    return displayPage(displayItem);
  }
  return displayPage(displayError);



}

