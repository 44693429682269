import { Button, Grid, Skeleton, Typography , AppBar, Box} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { fetchData } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },  
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}



export default function DialogClientHeaderBreakdown(props) {
  const classes = useStyles();
//   roundComposition={dataRoundInProgress} clientSelection={clientSelection}
  const {dialogOpen, setDialogOpen, setErrorMessage, roundComposition, clientSelection} = props;
  DialogClientHeaderBreakdown.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    roundComposition: PropTypes.object.isRequired,
    clientSelection: PropTypes.number.isRequired,
  }; 

  const [data, setData] = useState(null);
  // let prices = null;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [value, setValue] = useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    const fetchDataClient = async () => {
      if (clientSelection) {
        fetchData(setIsLoading,setData,setError,`/allocation/client-breakdown`, "put", {roundComposition,clientSelection: [clientSelection] });
      }
    }; 
    setIsLoading(true);
    fetchDataClient();
  }, [clientSelection]); 


  const handleCloseDialog = () => {
    setDialogOpen(false);    
  } 

  const displayBreakdown = () => {
    if (isLoading) {
      return (
      <Grid item xs={12} >
        <Skeleton variant="rect"  height={200}  />
      </Grid>        
      )
    }
    if (data) {
      console.log({
        chart: {
          type: 'bar'
        },
        title: {
          text: 'ALLOCATION BY REGION'
        },
        xAxis: {
          categories: data.region.xAxis,
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          labels: {
            overflow: 'justify'
          }
        },
        tooltip: {
          valueSuffix: ' €'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          shadow: true
        },
        credits: {
          enabled: false
        },
        series: [{
          name: data.region.series[0].name,
          data: data.region.series[0].value,
        }]
      })
      return (
        <span>
          <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Value" {...a11yProps(0)} />
            <Tab label="Percentage" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
        Value
            <Grid item xs={12} md={4}>    
            <HighchartsReact
                    
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'bar'
                      },
                      title: {
                        text: 'ALLOCATION BY CATEGORY'
                      },
                      xAxis: {
                        categories: data.category.xAxis,
                        title: {
                          text: null
                        }
                      },
                      yAxis: {
                        min: 0,
                        labels: {
                          overflow: 'justify'
                        }
                      },
                      tooltip: {
                        valueSuffix: ' €'
                      },
                      plotOptions: {
                        bar: {
                          dataLabels: {
                            enabled: true
                          }
                        }
                      },
                      legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        x: -40,
                        y: 80,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor:
                          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                        shadow: true
                      },
                      credits: {
                        enabled: false
                      },
                      series: [{
                        name: data.category.series[0].name,
                        data: data.category.series[0].value,
                      },
                      {
                        name: data.category.series[1].name,
                        data: data.category.series[1].value,
                      },
                      {
                        name: data.category.series[2].name,
                        data: data.category.series[2].value,
                      }]
                    }}
                  />        
            </Grid>
            <Grid item xs={12} md={4}>    
        <HighchartsReact
                
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'bar'
                  },
                  title: {
                    text: 'ALLOCATION BY CATEGORY 2'
                  },
                  xAxis: {
                    categories: data.category2.xAxis,
                    title: {
                      text: null
                    }
                  },
                  yAxis: {
                    min: 0,
                    labels: {
                      overflow: 'justify'
                    }
                  },
                  tooltip: {
                    valueSuffix: ' €'
                  },
                  plotOptions: {
                    bar: {
                      dataLabels: {
                        enabled: true
                      }
                    }
                  },
                  legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                    x: -40,
                    y: 80,
                    floating: true,
                    borderWidth: 1,
                    backgroundColor:
                      Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                    shadow: true
                  },
                  credits: {
                    enabled: false
                  },
                  series: [{
                    name: data.category2.series[0].name,
                    data: data.category2.series[0].value,
                  },
                  {
                    name: data.category2.series[1].name,
                    data: data.category2.series[1].value,
                  },
                  {
                    name: data.category2.series[2].name,
                    data: data.category2.series[2].value,
                  }]
                }}
              />        
            </Grid>
            <Grid item xs={12} md={4}>    
        <HighchartsReact
                
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'bar'
                  },
                  title: {
                    text: 'ALLOCATION BY REGION'
                  },
                  xAxis: {
                    categories: data.region.xAxis,
                    title: {
                      text: null
                    }
                  },
                  yAxis: {
                    min: 0,
                    labels: {
                      overflow: 'justify'
                    }
                  },
                  tooltip: {
                    valueSuffix: ' €'
                  },
                  plotOptions: {
                    bar: {
                      dataLabels: {
                        enabled: true
                      }
                    }
                  },
                  legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                    x: -40,
                    y: 80,
                    floating: true,
                    borderWidth: 1,
                    backgroundColor:
                      Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                    shadow: true
                  },
                  credits: {
                    enabled: false
                  },
                  series: [{
                    name: data.region.series[0].name,
                    data: data.region.series[0].value,
                  },
                  {
                    name: data.region.series[1].name,
                    data: data.region.series[1].value,
                  },
                  {
                    name: data.region.series[2].name,
                    data: data.region.series[2].value,
                  }]
                }}
              />        
            </Grid>   
            </Grid>    
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
           
           <Grid item xs={12} md={4}>    
           <HighchartsReact
                   
                   highcharts={Highcharts}
                   options={{
                     chart: {
                       type: 'bar'
                     },
                     title: {
                       text: 'ALLOCATION BY CATEGORY %'
                     },
                     xAxis: {
                       categories: data.categoryPercent.xAxis,
                       title: {
                         text: null
                       }
                     },
                     yAxis: {
                       min: 0,
                       labels: {
                         overflow: 'justify'
                       }
                     },
                     tooltip: {
                       valueSuffix: ' %'
                     },
                     plotOptions: {
                       bar: {
                         dataLabels: {
                           enabled: true
                         }
                       }
                     },
                     legend: {
                       layout: 'vertical',
                       align: 'right',
                       verticalAlign: 'top',
                       x: -40,
                       y: 80,
                       floating: true,
                       borderWidth: 1,
                       backgroundColor:
                         Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                       shadow: true
                     },
                     credits: {
                       enabled: false
                     },
                     series: [{
                       name: data.categoryPercent.series[0].name,
                       data: data.categoryPercent.series[0].value,
                     },
                     {
                      name: data.categoryPercent.series[1].name,
                      data: data.categoryPercent.series[1].value,
                    },
                    {
                      name: data.categoryPercent.series[2].name,
                      data: data.categoryPercent.series[2].value,
                    }]
                   }}
                 />        
           </Grid>
           <Grid item xs={12} md={4}>    
       <HighchartsReact
               
               highcharts={Highcharts}
               options={{
                 chart: {
                   type: 'bar'
                 },
                 title: {
                   text: 'ALLOCATION BY CATEGORY2 %'
                 },
                 xAxis: {
                   categories: data.category2Percent.xAxis,
                   title: {
                     text: null
                   }
                 },
                 yAxis: {
                   min: 0,
                   labels: {
                     overflow: 'justify'
                   }
                 },
                 tooltip: {
                   valueSuffix: ' %'
                 },
                 plotOptions: {
                   bar: {
                     dataLabels: {
                       enabled: true
                     }
                   }
                 },
                 legend: {
                   layout: 'vertical',
                   align: 'right',
                   verticalAlign: 'top',
                   x: -40,
                   y: 80,
                   floating: true,
                   borderWidth: 1,
                   backgroundColor:
                     Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                   shadow: true
                 },
                 credits: {
                   enabled: false
                 },
                 series: [{
                   name: data.category2Percent.series[0].name,
                   data: data.category2Percent.series[0].value,
                 },
                 {
                  name: data.category2Percent.series[1].name,
                  data: data.category2Percent.series[1].value,
                },
                {
                  name: data.category2Percent.series[2].name,
                  data: data.category2Percent.series[2].value,
                }]
               }}
             />        
           </Grid>
           <Grid item xs={12} md={4}>    
       <HighchartsReact
               
               highcharts={Highcharts}
               options={{
                 chart: {
                   type: 'bar'
                 },
                 title: {
                   text: 'ALLOCATION BY REGION %'
                 },
                 xAxis: {
                   categories: data.regionPercent.xAxis,
                   title: {
                     text: null
                   }
                 },
                 yAxis: {
                   min: 0,
                   labels: {
                     overflow: 'justify'
                   }
                 },
                 tooltip: {
                   valueSuffix: ' %'
                 },
                 plotOptions: {
                   bar: {
                     dataLabels: {
                       enabled: true
                     }
                   }
                 },
                 legend: {
                   layout: 'vertical',
                   align: 'right',
                   verticalAlign: 'top',
                   x: -40,
                   y: 80,
                   floating: true,
                   borderWidth: 1,
                   backgroundColor:
                     Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                   shadow: true
                 },
                 credits: {
                   enabled: false
                 },
                 series: [{
                   name: data.regionPercent.series[0].name,
                   data: data.regionPercent.series[0].value,
                 },
                 {
                  name: data.regionPercent.series[1].name,
                  data: data.regionPercent.series[1].value,
                },
                {
                  name: data.regionPercent.series[2].name,
                  data: data.regionPercent.series[2].value,
                }]
               }}
             />        
           </Grid>   
           </Grid>                
              </TabPanel>                             
      </span>
      )
    }
    return "No data found"
  }

  return (
      <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='xl'
      >
        <DialogTitle id="alert-dialog-title">Client Breakdown</DialogTitle> 
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

              {displayBreakdown()}
              {/* <FormControl> 
                <Select style={{width: 300}}
                  autoWidth	
                  label="status Size" 
                  labelId="select-status"
                  id="select-status"
                  value={value}
                  onChange={handleSelected}
                >
                  {listValues.map((item,i) => (
                      <MenuItem value={item} key={i}>{item}</MenuItem>
                  ))}
                </Select> 
              </FormControl> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>           
    )
}