// material
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Snackbar, Alert, Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Card, CardContent, Button, ButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';

// components
import {useNavigate,useParams} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import ButtonDialogPalletSensor from '../../components/warehouse/ButtonDialogPalletSensor';
import ButtonDialogPalletLocation from '../../components/warehouse/ButtonDialogPalletLocation';
import { fetchData, fetchDataAwait } from '../../utils/api';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));
// ----------------------------------------------------------------------


export default function PalletItem() {
  const { id } = useParams();     
  const navigate = useNavigate();
  const classes = useStyles();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [noUpdateYet, setNoUpdateYet] = useState(true);

  const [isLoadingButton,setIsLoadingButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);   


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,`/pallet/${id}`, "get", null);
    };
    fetchDataReactScreen();
  }, [id]);  


  const columns =  [
    { field: 'id', headerName: ' ', width:150
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.row.id);}}
        >
          <ClearIcon />
        </IconButton >          
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={() => navigate(`/stock/case/${params.value}`)}
        >
          <ZoomInIcon />
          {params.value}
        </IconButton >        
        </span>
    )
  },
    { field: 'name', headerName: 'Case', width:200 },
    { field: 'packSize', headerName: 'packSize', width:200  },
    { field: 'packType', headerName: 'packType', width:200  },
    { field: 'bottleSize', headerName: 'bottleSize', width:200  },
    { field: 'lwin', headerName: 'lwin', width:200  },      
    { field: 'location', headerName: 'location', width:200  },      
    { field: 'orderReference', headerName: 'orderReference', width:200  },
    { field: 'status', headerName: 'status', width:200  },
    { field: 'pallet', headerName: 'current Pallet', width:200  },    
];


const handleRemoveRow = (rowId) => {
  const filteredTargetComposition = data.cases.filter((el) =>  el.id !== rowId); 
  data.cases = filteredTargetComposition
  setData({...data});
  setNoUpdateYet(false);
}

const setPalletData = (field,value) => {
  data.data[field] = value
  setData({...data});
}


const [uploadDialog, setUploadDialog] = useState(false);
const [dataFile,setDataFile] = useState({name:"no file selected"});
const handleDialogUploadClose = () => {
  setUploadDialog(false); 
}  
const handleDialogUploadOpen = () => {
  setUploadDialog(true); 
}    
const uploadDocument = async () => {
  setIsLoadingButton(true);
  // UPLOAD FILE - Upload file
  const postData = new FormData();
  postData.append("refOrder", id)
  postData.append('file', dataFile); // The file has be the last element  


  const res = await fetchDataAwait(`/compo-pallet/${id}`, "put", postData, {'Content-Type': 'multipart/form-data'});
  if (res.error) {
    setErrorMessage(res.error.message);
  }   
  else {
    setUploadDialog(false); 
    setTableLoading(true);
    // setDataList(res.data);
    setConfirmationMessage("List saved.");
    data.cases = res.data.cases
    setData({...data});
    setNoUpdateYet(true);    
    setTableLoading(false);
  }

  setIsLoadingButton(false);
  return 1;
}
const handleCapture = (event) => {
  setDataFile(event.target.files[0]);
};   
const dialogUpload = () =>  (
  <Dialog
  open={uploadDialog}
  onClose={() => handleDialogUploadClose()}
  aria-labelledby="alert-dialog-upload"
  maxWidth='lg'
  >
    <DialogTitle  sx={{ pb: 5 }}>Upload a list of cases</DialogTitle> 
    <DialogContent>
    <DialogContentText >
            <Grid container spacing={0} justifyContent="left" alignItems="left">        
              <Grid sx={{ pb: 2 }} item xs={12}>
                Upload of list of cases to <strong>REPLACE the existing list</strong>
              </Grid>                                              
              <Grid sx={{ pb: 2 }} item xs={12}  >
                File must be a <strong>"csv"</strong> and contain a column "caseId" with the list of ids.
              </Grid>  
              <Grid item xs={12} >
              <Button
                variant="contained"
                component="label"
              >
                Upload a CSV
                <input
                  type="file"
                  hidden
                  onChange={handleCapture}
                />
              </Button>  <br/>     
              {dataFile.name}   
              </Grid>                                                                                                                                                                                                                          
            </Grid>             
                  
          </DialogContentText>
        </DialogContent>
    <DialogActions>
      <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> uploadDocument()} color="primary">
      Upload CSV
      </LoadingButton>        
      <Button variant="text" onClick={()=> handleDialogUploadClose()} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog> 
);



const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

  const handleCancelClick = () => { 
    setData({...data});
    setIsDisabled(true);  
  }

  const uploadItem = async () => {
    setIsLoadingButton(true);   
    let url = '/pallet/';
    url += id;
    console.log(data.data)
    const res = await fetchDataAwait(url, "put", data.data);
    
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      // setData(res);
      setConfirmationMessage("Succesfully saved.");
      setIsDisabled(true);  
    }   
    setIsLoadingButton(false);      
  }
  
  
  const handleSaveClick = () => {
    uploadItem(); 
  }
    

  function displayActionButtons() {
      if (isDisabled) {
        return (
        <ButtonGroup>
          <IconButton
            variant="contained"
            onClick={() => setIsDisabled(false)}
          >
            <EditIcon/>
            
          </IconButton>
        </ButtonGroup>        
        )
      }

      return (    
      <ButtonGroup>
        <LoadingButton
          variant="contained"
          loading={isLoadingButton}
          onClick={() => handleSaveClick()}
        >
          <SaveIcon/>
          Save
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => handleCancelClick(true)}
        >
          <CancelIcon/>
          Cancel
        </Button>        
    </ButtonGroup>
    )
  }

  const handleSaveCompoClick = async () => { 
    setTableLoading(true);      
    const res = await fetchDataAwait(`/save-pallet/${id}`, "put", {cases: data.cases.map(el => el.id)});
    if (res.error) {
      setErrorMessage(res.error.message);
    }   
    else {
      setConfirmationMessage("List saved.");
      data.cases = res.data.cases
      setData({...data});
      setNoUpdateYet(true);
    }
    setTableLoading(false);      
  }


  if (isLoading) {
    return (
      <Page title="Pallet">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Pallet</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Pallet">
        <Container maxWidth="false">
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Pallet {data.data.code}
          </Typography>
          <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" >
            <ButtonDialogPalletSensor palletId={id} code={data.data.code} />                  
          </Stack>  
        </Stack>          
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
          <Grid item xs={12} >
            <Card >
              <CardContent>
                <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">                           
                  <Grid  item xs={6} md={2} >
                    Wgt: <Typography variant="h6">{data.data.weight}kg</Typography>
                  </Grid>
                  <Grid  item xs={6} md={2} >
                    # Cases: <Typography variant="h6">{data.data.countCase}</Typography>
                  </Grid> 
                  <Grid  item xs={12} md={6} >
                    Location: <Typography variant="h6">{data.data.locationName} <ButtonDialogPalletLocation locationId={data.data.locationId} palletCode={data.data.code} palletId={data.data.id} locationName={data.data.locationName} callbackLocation={setPalletData} isDisabled={false} />   </Typography> 
                  </Grid>                  
                  <Grid  item xs={12} >
                    Observation   
                    {displayActionButtons()}
                    </Grid> 
                  <Grid  item xs={12} >
                      <TextField 
                      onChange={(e)=> setPalletData("observation",e.target.value)}
                      id="Observation" 
                      rows={4}
                      fullWidth required
                      value={data.data.observation}
                      disabled={isDisabled}
                    />                    
                  </Grid>                            
                </Grid>
              </CardContent>
              </Card>    
              </Grid>                            
              <Grid item xs={12}>
              <Box sx={{ pb: 5 }}>
                <Typography variant="h6">Cases on pallet {data.data.code}</Typography>
              </Box>  


              <Grid sx={{ pb: 2 }} item xs={12} >
          <Card >
            <CardContent>
            <Grid xs={12} sx={{ pb: 2 }} item justifyContent="left" alignItems="center" > 
            <LoadingButton
                color="primary" loading={isLoading}
                onClick={() => handleDialogUploadOpen()}
                variant="contained"
            >
              <InsertDriveFileIcon/>Upload from list
            </LoadingButton>    
            {dialogUpload()}
            </Grid>
            <Grid xs={12} item justifyContent="left" alignItems="center" > 
            <Stack sx={{ pb:3 }} direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="space-between">   
              <Stack sx={{ pb:3 }} direction="row" spacing={2} justifyContent="left">   
                  <LoadingButton
                      color="primary" loading={isLoading}
                      onClick={handleSaveCompoClick}
                      variant="contained"
                      disabled={noUpdateYet}
                  >
                    <SaveIcon/>Save New List
                  </LoadingButton>     
                          
              </Stack>              
            </Stack>  
            </Grid>                        
            <Grid xs={12} item container spacing={3} direction="row" justifyContent="left" alignItems="center"  className={classes.root}>  
              <DataGridPro
                  autoHeight 
                  pagination
                  rows={data.cases} 
                  columns= {columns}
                  loading={tableLoading}
                  components={{
                    Toolbar: CustomToolbar,
                  }}         
                />    
            </Grid> 
          </CardContent>
        </Card>  
        </Grid>  






              </Grid>                   
          </Grid>   
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}           
      </Page>
    );
  }
  return (
    <Page title="Pallet">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Pallet</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



