import axios from "axios";
import { Auth } from "aws-amplify";
// import React from 'react';


const baseUrl = process.env.REACT_APP_BASE_URL;
// const defaultHeaders = { "Content-Type": "application/vnd.api+json" };
// const UnknownErrorMessage = "An Unknown Error occurred";


export function fetchData(setIsLoading,setData,setError,route, method, payload) {

    if (!baseUrl) {
        throw new Error("No BASE_URL set in environment");
    }

    Auth.currentSession().then(authState => {
        if (!authState) {
          console.log("User is not authenticated");
          setError({message:"User is not authenticated"});
          setData(null);
          setIsLoading(false);          
        }

        const axiosConfig = {
          method,
          url: `${baseUrl}${route}`,
          headers: {
            'Content-Type':'application/json',
            Authorization: `${authState.getIdToken().getJwtToken()}`
          }
        }

        if (payload) {
          axiosConfig.data = payload
        }
        console.log(axiosConfig)
        axios(axiosConfig)
          .then(response => {
            console.log(response);
            setData(response.data);
            setIsLoading(false);      
            setError(null);                  
          })
          .catch(error => {
            console.log(error);
            setError(error.response.data);
            // setData(null);     
            setIsLoading(false);                   
          });

      }).catch(err => { 
        console.log("error aws auth");
        setError(err);
        setData(null);  
        setIsLoading(false);        
      });

  }


  export async function fetchDataAwait(route, method, payload, paramHeader = {} ) {

    if (!baseUrl) {
        throw new Error("No BASE_URL set in environment");
    }

    return Auth.currentSession().then(async authState => { 
        if (!authState) {
          console.log("User is not authenticated");        
          return {
            data: null,
            error: { message: "User is not authenticated" }
          }
        }

        const axiosConfig = {
          method,
          url: `${baseUrl}${route}`,
          headers: {
            'Content-Type':'application/json',            
            ...paramHeader,
            Authorization: `${authState.getIdToken().getJwtToken()}`
          }
        }

        if (payload) {
          axiosConfig.data = payload
        }
        console.log(axiosConfig)
        return axios(axiosConfig) 
          .then(response =>  {
            // console.log("succes")
            console.log(response)
            return {
              data: response.data,
              error: null
            }
          })
          .catch(error => {
            // console.log("error")
            console.log(error)
            return {           
              data: null,
              error: error.response.data 
            }
          });

      }).catch(err => { 
        // console.log("err other")
        console.log(err)
        return {
          data:  null,
          error: err
        }
      });

  }
