// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, Stack, Button  } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Page from '../../components/Page';
import { fetchData } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';


// ----------------------------------------------------------------------


export default function ClientList() {
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/users", "get", null);
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
      { field: 'id', headerName: ' ', width:20
      , renderCell: (params) => (
          <IconButton 
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/client/list/${params.value}`)}
          >
            <ZoomInIcon />
            {params.value}
          </IconButton >        
      )
    },
    { field: 'externalId', headerName: 'Client Id', width:200 },    
    { field: 'name', headerName: 'Client', width:200 },
    { field: 'phone', headerName: 'Phone', width:200   },
    { field: 'cashBalance', headerName: 'Cash', width:200 , renderCell: (params) =>  fCurrency(params.value)  },
    { field: 'casesValue', headerName: 'Portfolio', width:200 , renderCell: (params) =>  fCurrency(params.value)  },
    { field: 'bookValue', headerName: 'Book Value', width:200 , renderCell: (params) =>  fCurrency(params.value)  },
    { field: 'valueDate', headerName: 'as of', width:200   },
    { field: 'role', headerName: 'Role(s)', width:200   }
  ];


  if (isLoading) {
    return (
      <Page title="Clients">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Clients</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   

    return (
      <Page title="Clients">
        <Container maxWidth="false">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Clients
              </Typography>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => navigate(`/client/list/new`)}
              >
                New
              </Button>
            </Stack> 
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
       
              <DataGridPro
                pagination
                 overflow="auto"
                autoHeight 
                rows={data.data} // {rows} //
                columns= {columns} //  {data.columns} //
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                }}         
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Clients">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Clients</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}


