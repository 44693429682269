import { makeStyles } from '@mui/styles';
import { Box, Grid, Container, Typography, Button, Card, CardContent, CardActions, } from '@mui/material';

// components
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));

export default function TransactionSelect() {
  const classes = useStyles();
  const navigate = useNavigate();




  function selectTransaction() {
    return (
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={5} md={5} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Wine Transaction
              </Typography>    
              Generate wine transactions such allocation to clients or trading between clients.
            </CardContent>
            <CardActions>
              <Button  variant="contained"  onClick={()=> navigate(`/allocation/transaction`)} color="primary">CREATE</Button>
            </CardActions>
        </Grid>  
        <Grid item xs={12} sm={5} md={5} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Cash Transaction
              </Typography>    
              Generate cash transaction such as fees, cash inflow/outflow.
            </CardContent>
            <CardActions>
              <Button  variant="contained"  onClick={()=> navigate(`/allocation/cash-transaction`)} color="primary">CREATE</Button>
            </CardActions>
        </Grid> 
        <Grid item xs={12} sm={5} md={5} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Delivery
              </Typography>       
              Generate transaction for wine delivery. Cases will be removed from a client's collection at €0.       
            </CardContent>
            <CardActions>
              <Button  variant="contained"  onClick={()=> navigate(`/allocation/delivery`)} color="primary">CREATE</Button>
            </CardActions>
        </Grid> 
        <Grid item xs={12} sm={5} md={5} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Sell a specific case
              </Typography>       
              Generate transaction for sale of specific cases. 
            </CardContent>
            <CardActions>
              <Button  variant="contained"  onClick={()=> navigate(`/allocation/transaction-case`)} color="primary">CREATE</Button>
            </CardActions>
        </Grid>         
        <Grid item xs={12} sm={5} md={5} component={Card} className={classes.card}>
            <CardContent>
              <Typography component="h5" variant="h5" >
                Create New Cases
              </Typography>       
              Generate new cases from recently purchased wines or for collection brought by clients.
            </CardContent>
            <CardActions>
              <Button  variant="contained"  onClick={()=> navigate(`/purchase/add-cases`)} color="primary">CREATE</Button>
            </CardActions>
        </Grid>                     
      </Grid>
    )
  }



  function displayPage(func) {
    return (
      <Page title="Transaction">
        <Container maxWidth="true">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Transaction</Typography>
          </Box>
          {func()}
        </Container>   
      </Page>
    );
  }


  return displayPage(selectTransaction);

}

