
import {  Button, Snackbar, Alert  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';

import { useState } from 'react';

export default function ButtonDialogPickRole(props) {
  const {dataGrid, callbackItem, isDisabled} = props;
  ButtonDialogPickRole.propTypes = {
    dataGrid: PropTypes.array.isRequired,
    callbackItem: PropTypes.func.isRequired,    
    isDisabled: PropTypes.bool.isRequired,
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);    
  const columns = [
    { field: 'id', headerName: 'id', width:200
      , renderCell: (params) =>  (
      <Button  variant="contained" onClick={() => updateRole(params.value)} color="primary" autoFocus>
        Select
      </Button>
      )
    },      
    { field: 'name', headerName: 'Role', width:150}
  ];


  const updateRole = async (roleId) => {
    callbackItem(dataGrid.find(obj => obj.id === roleId));    
    setIsLoading(false);  
    setDialogOpen(false); 
    return true    
  }
  

  const handleOpenDialog = () => {
    setDialogOpen(true);  
  }  

  const handleCloseDialog = () => {
    setDialogOpen(false);    
  }

      
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }      
 

  return (
    <span>
      <LoadingButton  disabled={isDisabled}  variant="text"  color="primary"  onClick={() => handleOpenDialog()}><AddIcon/>Add role to user </LoadingButton>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='xl'
        >
          <DialogTitle id="alert-dialog-title">Assign Role to Client</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <DataGridPro 
                overflow="auto"
                autoHeight 
                rows={dataGrid}
                columns= {columns}
                pageSize={25}
                loading={isLoading}
                />                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
          {snackbarError()}      
        {snackbarSuccess()}          
        </Dialog>        
    </span>
    )
}