import { Button, IconButton, Grid, TextField  } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { fetchDataAwait } from '../../utils/api';



export default function DialogBottlePickVintage(props) {
  const searchRef = useRef();
  const {dialogOpen, setDialogOpen, setErrorMessage, rowId, callBackFunction} = props;
  DialogBottlePickVintage.propTypes = {
    rowId: PropTypes.number,
    dialogOpen: PropTypes.bool.isRequired,
    setDialogOpen: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    callBackFunction: PropTypes.func.isRequired,
  }; 

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]); 
  

  const columnsGrid =  [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <strong>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleAddBottles(params.row);}}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton >        
      </strong>
      )
    }, 
    { field: 'name', headerName: 'Vintage', width:300  },
    { field: 'year', headerName: 'Year', width:200  },
    { field: 'estate', headerName: 'Estate', width:200  },
    { field: 'lwin', headerName: 'lwin', width:200  },
    { field: 'country', headerName: 'Country', width:200  },
    { field: 'region', headerName: 'Region', width:200  },
    { field: 'subRegion', headerName: 'subRegion',width:200  }
];



  const handleAddBottles = (row) => {
    callBackFunction(rowId,row)
    setDialogOpen(false);
  }  


  const handleCloseDialog = () => {
    console.log("close")
      setDialogOpen(false);    
  } 
    
  const refreshData = async() => {
    setIsLoading(true);
    const dataPost = {
      search: searchRef.current.value,
      limit: 10,
    }
    const res = await fetchDataAwait("/vintages", "put", dataPost);
    console.log(res);
    if (res.error) {
        setErrorMessage(res.error.message);
        setIsLoading(false);  
        return false
    }
    console.log(res.data)
    setData(res.data.data);  
    setIsLoading(false);  
    return true       
  }      

  return (
    <span>
        <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">Pick vintage of bottle</DialogTitle> 
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="left">
              <Grid item xs={12} md={12}>    
                Select the bottle vintage
              </Grid>              
              <Grid item xs={12} md={6}>    
                <TextField fullWidth id="fullWidth"
                            inputRef={searchRef} 
                            onKeyPress={(event) => {
                              if (event.key === 'Enter')
                              refreshData()
                            }}                            

                />
              </Grid>
              <Grid item xs={12}  md={6} sx={{ pb: 5 }}>                   
                  <LoadingButton loading={isLoading} variant="contained" onClick={() => refreshData()}>
                  Search
                </LoadingButton>                
              </Grid>
              <Grid item xs={12}>
                <DataGridPro overflow="auto"
                  pagination
                  autoHeight 
                  rows={data} 
                  columns= {columnsGrid} 
                  pageSize={25}
                  loading={isLoading}
                  />
              </Grid>
            </Grid>


                      
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>              
      </span>
    )
}