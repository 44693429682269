// material
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { Box, Grid, Container, Typography, Skeleton, IconButton, TextField, Snackbar, Alert, Stack} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AddIcon from '@mui/icons-material/Add';
// components
import {useNavigate} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';


// ----------------------------------------------------------------------

export default function Transaction() {
  const searchRef = useRef();
  const navigate = useNavigate();
  // API
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null); 


  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }
  
  
  useEffect(() => {
    const fetchDataReactScreen = async () => {
      fetchData(setIsLoading,setData,setError,"/transactions", "put", {});
    };
    fetchDataReactScreen();
  }, []);  


  const columns =  [
      { field: 'id', headerName: ' ', width:20
        , renderCell: (params) => (
            <IconButton 
              variant="contained"
              color="primary"
              size="small"
              onClick={() => navigate(`/client/transaction/${params.value}`)}
            >
              <ZoomInIcon />
              {params.value}
            </IconButton >        
        )
      }, 
      { field: 'user', headerName: 'Client' ,width:100},
      { field: 'methodLabel', headerName: 'Type' ,width:100},
      { field: 'note', headerName: 'Note',width:300},      
      { field: 'invoice', headerName: 'Invoice#',width:100},        
      { field: 'groupName', headerName: 'Transac Group', width:200},      
      { field: 'cashTransfer', headerName: 'cashTransfer', width:100 , renderCell: (params) =>  fCurrency(params.value)  },
      { field: 'admin', headerName: 'Admin',width:200},      
      { field: 'createdAt', headerName: 'createdAt', width:200  },
      { field: 'updatedAt', headerName: 'updatedAt', width:200  }
  ];

  
const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorMessage(null);
};
const handleCloseSnackbarSuccess = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setConfirmationMessage(null);
};
function snackbarError() {
  if (errorMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}
function snackbarSuccess() {
  if (confirmationMessage) {
    return (    
      <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
          {confirmationMessage}
        </Alert>
      </Snackbar> 
    )
  }
  return ""
}  

  const handleSearch = async () => {
    setIsLoadingSearch(true);
    const res = await fetchDataAwait("/transactions", "put", {search: searchRef.current.value});
    if (res.error) {
      setErrorMessage(res.error.message);
    }
    else {
      setData(res.data);
      setConfirmationMessage(res.data.message);

    }
    setIsLoadingSearch(false);
  }

  
  if (isLoading) {
    return (
      <Page title="Transactions">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Transactions</Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={500}  />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );  
  } 
  if (!error) {   
    return (
      <Page title="Transactions">
        <Container maxWidth="false">
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">Transactions</Typography>     
                <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" >                       
                  <LoadingButton  variant="contained" onClick={() => navigate(`/allocation/select`)}>
                    <AddIcon/>New Transactions
                  </LoadingButton>     
                </Stack>                                 
          </Stack>           
          <Grid  sx={{ pb: 5 }}  spacing={3}  container direction="row" justifyContent="left" alignItems="left">          
            <Grid item xs={12} md={6}>    
                  <TextField fullWidth label="Search transactions in database" id="fullWidth"
                              inputRef={searchRef} 
                              onKeyPress={(event) => {
                                if (event.key === 'Enter')
                                  handleSearch()
                              }}
                  />
              </Grid>
            <Grid item xs={12} md={6} >       
              <Stack  spacing={3}  direction="row" alignItems="center" justifyContent="space-between" mb={5}>                                 
                  <LoadingButton loading={isLoadingSearch} variant="contained" onClick={handleSearch}>
                  Search
                </LoadingButton>
              </Stack>
            </Grid>
            <Grid item xs={12}>
            <DataGridPro
                pagination
              autoHeight 
              rows={data.data} 
              columns= {columns}
              pageSize="50"
              loading={isLoadingSearch}
              components={{
                Toolbar: CustomToolbar,
              }}         
            />
            </Grid>
          </Grid>
        </Container>
        {snackbarError()}   
        {snackbarSuccess()}        
      </Page>
    );
  }
  return (
    <Page title="Transactions">
      <Container maxWidth="false">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Transactions</Typography>
        </Box>
        <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center">
          <Grid item >
            Error {error.message}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );  
}



