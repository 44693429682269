// material
import { makeStyles } from '@mui/styles';
import { Link,CardActions, Card, CardContent, Box, Grid, Container, Typography, Skeleton, Button, IconButton, Snackbar, Alert, Stack, Menu } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from  '@mui/lab/AdapterDateFns';
import LocalizationProvider from  '@mui/lab/LocalizationProvider';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ListIcon from '@mui/icons-material/List';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// components
import { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';

import DialogAddUniqueCaseFromSearch from '../../components/allocate/DialogAddUniqueCaseFromSearch';
import Page from '../../components/Page';
import { fetchData, fetchDataAwait } from '../../utils/api';
import { fCurrency } from '../../utils/formatNumber';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .editable-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    }
  },
  field: {
    marginTop:20,
    marginBottom: 20,
    display: 'block'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  card: {
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }  
}));

// ----------------------------------------------------------------------

export default function PrepareOrderItem() {
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [dialogOpenCase,setDialogOpenCase] = useState(false);

  const columnsTransactions = [
    { field: 'id', headerName: ' ', width:20
    , renderCell: (params) => (
      <span>
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleRemoveRow(params.row.id);}}
        >
          <ClearIcon />
        </IconButton >        
      </span>
      )
    },   
    { field: 'caseName', headerName: 'Case', width:300     , renderCell: (params) => (
      <span>
          
        <IconButton 
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> { handleAddCase(params.row.id);}}
        >
          <AddCircleOutlineOutlinedIcon />
          
        </IconButton >    
        {params.value}    
      </span>
      )
    },              
    { field: 'caseId', headerName: 'caseId', width:100 , renderCell: (params) => (
      <IconButton 
        variant="contained"
        color="primary"
        size="small"
        onClick={() => navigate(`/stock/case/${params.value}`)}
      >
        <ZoomInIcon />
        {params.value}
      </IconButton >        
      )
    },       
    { field: 'caseOwner', headerName: 'Client', width:100 },    
    { field: 'colour', headerName: 'Color', width:100 },  
    { field: 'status', headerName: 'Status', width:100 },   
    { field: 'certificationReference', headerName: '9-digit', width:150 },
    { field: 'palletCode', headerName: 'Pallet', width:100 },

    { field: 'comment', headerName: 'Comment/Tags', width:300  ,cellClassName: 'editable-theme--cell', editable: true  },
    { field: 'checked', headerName: 'Checked?', width:100   , renderCell: (params) =>  {
      if (!params.value) {
        return (<span>"No Check"</span>)
      }
      return (
        <strong>
        Transaction {params.value}
        </strong>
      )
    } 
},
    { field: 'transactionId', headerName: 'Delivery Transaction', width:300  , renderCell: (params) =>  {
        if (!params.value) {
          return  (<span>"Not delivered"</span>)
        }
        return (
          <strong>
          Transaction {params.value}
          </strong>
        )
      } 
  },
    { field: 'priceForDocuments', headerName: 'Value for documents', width:200 ,cellClassName: 'editable-theme--cell', type: 'number' , editable: true , renderCell: (params) =>  fCurrency(params.value) },

    { field: 'bookValue', headerName: 'book', width:70 , renderCell: (params) =>  fCurrency(params.value) },
    { field: 'error', headerName: 'error', width:300  , 
      renderCell: (params) => {
        if (params.value!=='' && params.value) {
          return (<Alert severity="error">{params.value}</Alert>)
        }
        return ''
      }
    }
    ];

  // Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };    

  // Modify 
  const [isDisabled, setIsDisabled] = useState(true);
  // API NEW
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  // SNACKBAR
  const [errorMessage, setErrorMessage] = useState(null);  
  const [confirmationMessage, setConfirmationMessage] = useState(null);   
  // TRANSACTION DATA
  const newTransaction = {
    id:1,
    caseName: "",
    caseId: null,    
    caseOwner:"",
    status:"",

    certificationReference:"",
    palletCode:"",
    comment:"",
    checked:"",
    transactionId: null,
    priceForDocuments: null,


    bookValue:null,
    currentValue:null,
  };
  const [targetComposition, setTargetComposition] = useState([newTransaction]);  
  const [rowId, setRowId] = useState(null);
  // Confirmation
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogFunction, setConfirmationDialogFunction] = useState(()=>true);
  const [confirmationDialogParam, setConfirmationDialogParam] = useState([]);  
  function handleCloseConfirmationDialog() {
    setConfirmationDialogOpen(false);
  }
  function handleCloseConfirmationDialogConfirmed() {
    setConfirmationDialogOpen(false);
    confirmationDialogFunction(...confirmationDialogParam);
    
  }
  const handleClickAddRow = () => {
    const row = {...newTransaction};
    row.id = Math.max(...targetComposition.map(o => o.id), 0)+1;
    setTargetComposition([...targetComposition,row]);
  };  
  const handleAddCase = (rowTableId) => {
    setRowId(rowTableId);
    setDialogOpenCase(true);
  };      

  // API LIST
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [errorList, setErrorList] = useState(null);
  const fetchDataList = async () => {
    fetchData(setIsLoadingList,setDataList,setErrorList,`/warehouse/get-order-composition/${id}`, "get", null);
  };
  useEffect(() => {
    fetchDataList();
  }, []);  
  useEffect(() => {
    if (errorList) {
      setErrorMessage(errorList.message);
    }
  }, [errorList]);
  useEffect(() => {
    setTargetComposition(dataList.composition);
  }, [dataList]);  

  const updateOrderDescription= (field,value) => {
    dataList.description[field] = value;
    setDataList({...dataList});
}; 

  const updateCaseData = (rowId,rowClient) => {

    if (targetComposition.find(obj => obj.caseId === rowClient.id)) {
      setErrorMessage(`Case id ${rowClient.id} already added to list`);
      return 0
    }

    const updatedRows = targetComposition.map((row) => {
      if (row.id === rowId) {
      
        row.caseId = rowClient.id;
        row.caseName = rowClient.name;
        row.caseOwner = rowClient.user;
        row.status = rowClient.status;
        row.certificationReference = rowClient.certificationReference;
        row.palletCode = rowClient.palletCode;
        row.bookValue = rowClient.bookValue;
        row.currentValue = rowClient.currentValue;        

        return row;
      }
      return row;
    });
    setTargetComposition([...updatedRows]);
    return 1
  };   



  function CustomToolbar() {
    return (
      <GridToolbarContainer>            
        <GridToolbarExport   csvOptions={{utf8WithBom: true}} />
      </GridToolbarContainer>
    );
  }  


  function loadingSelect() {
    return (
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12} className={classes.card}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>      
      </Grid>
    )
  }


  const handleRemoveRow = (rowId) => {
    const filteredTargetComposition = targetComposition.filter((el) =>  el.id !== rowId); 
    setTargetComposition(filteredTargetComposition);
  }


  const saveNewTargetAllocation = async () => {
    setIsLoading(true);      
    const res = await fetchDataAwait(`/warehouse/update-order-composition/${id}`, "put", targetComposition);
    if (res.error) {
      setErrorMessage(res.error.message);
    }   
    else {
      setConfirmationMessage("List saved.");
    }
    setIsLoading(false);      
  }


  const handleSaveClick = () => { 
      saveNewTargetAllocation(); 
  }

  const handleSaveDescriptionClick = async () => { 
    setIsLoadingNew(true);     
    setIsLoadingNew(true);
    const res = await fetchDataAwait(`/warehouse/update-order/${id}`, "put", {name: dataList.description.name, description:  dataList.description.description, expectedDate: dataList.description.expectedDate, status: dataList.description.status});
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage("Succesfully saved.");
      setIsDisabled(true); 
      fetchDataList();
    }
    setIsLoadingNew(false);
     
    setIsLoadingNew(false);  
  }  

  const handleEditDescriptionClick = () => {
    if (isDisabled) {
      setIsDisabled(false);  
    } else {
      setIsDisabled(true);        
    }
  }  


  function dialogConfirmation() {
    return (            
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmationTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseConfirmationDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseConfirmationDialogConfirmed} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>  
      )
  }  


  const [openPalletLabel, setOpenPalletLabel] = useState(false);
  const [palletLabelData, setPalletLabelData] = useState({
    destination: "",
    destinationAddress: "",
    name1275: "",
    name1275Address: "",    
    weightTotal: 0,    
    description: "", 
    nbRows: 0,
    url: null
  });
  const handleAddPalletLabelData = (field,value) => {
    palletLabelData[field] = value
    setPalletLabelData({...palletLabelData});
  }  
  const dialogPalletLabel = () =>  (
    <Dialog
    open={openPalletLabel}
    onClose={() => handleDialogOpenPalletLabelClose()}
    aria-labelledby="alert-dialog-palelt"
    aria-describedby="alert-dialog-palelt-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate Pallet Label</DialogTitle> 
      <DialogContent>
            <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">                                        
                <Grid item xs={12} md={3} >
                  <Typography variant="h6">To</Typography>
                </Grid>  
                <Grid item xs={12} md={9} >
                  <TextField 
                      onChange={(e)=> handleAddPalletLabelData("destination",e.target.value)}
                      className={classes.field} 
                      id="destination" 
                      label="Recipient"
                      fullWidth required
                      value={palletLabelData.destinationName}
                  />    
                  <TextField 
                      onChange={(e)=> handleAddPalletLabelData("destinationAddress",e.target.value)}
                      className={classes.field} 
                      multiline
                      label="Recipient Address"
                      rows={2}   
                      id="destinationAddress" 
                      fullWidth required
                      value={palletLabelData.destinationAddress}
                  />                                     
                </Grid>
                <Grid item xs={12} md={3} >
                  <Typography variant="h6">From</Typography>
                </Grid>  
                <Grid item xs={12} md={9} >
                  <TextField 
                      onChange={(e)=> handleAddPalletLabelData("name1275",e.target.value)}
                      className={classes.field} 
                      id="name1275" 
                      label="Sender"
                      fullWidth required
                      value={palletLabelData.name1275}
                  />    
                  <TextField 
                      onChange={(e)=> handleAddPalletLabelData("name1275Address",e.target.value)}
                      className={classes.field} 
                      multiline
                      rows={2}   
                      id="name1275Address" 
                      label="Sender Address"
                      fullWidth required
                      value={palletLabelData.name1275Address}
                  />                                     
                </Grid> 
                <Grid item xs={12} md={3} >
                  <Typography variant="h6">Pallet Information</Typography>
                </Grid>  
                <Grid item xs={12} md={9} >
                <TextField 
                      onChange={(e)=> handleAddPalletLabelData("nbRows",e.target.value)}
                      className={classes.field} 
                      id="nbRows" 
                      fullWidth
                      label="Number Cases"
                      type="number"                      
                      value={palletLabelData.nbRows}
                  />    
                  <TextField 
                      onChange={(e)=> handleAddPalletLabelData("weightTotal",e.target.value)}
                      className={classes.field} 
                      id="weightTotal" 
                      label="Weight Pallet"
                      type="number"                      
                      fullWidth required
                      value={palletLabelData.weightTotal}
                  />                                        
                  <TextField 
                      onChange={(e)=> handleAddPalletLabelData("palletDescription",e.target.value)}
                      className={classes.field} 
                      multiline
                      rows={2}   
                      id="palletDescription" 
                      label="Description"
                      fullWidth required
                      value={palletLabelData.palletDescription}
                  />                      
                </Grid> 
                <Grid item xs={12} >
                  {snackbarPalletLabel()}      
                </Grid>                                                                                                                                                                                                                  
              </Grid>             
                    
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGeneratePalletLabel()} color="primary">
          Generate PDF
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenPalletLabelClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenPalletLabelClose = () => {
    setOpenPalletLabel(false); 
  }  
  const handleGeneratePalletLabel = async () => {
    setIsLoadingButton(true);      
    const url = `/generate/pallet-label`;
    const res = await fetchDataAwait(url, "post", { ...palletLabelData, refOrder: id });
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      handleAddPalletLabelData("url",res.data.url)
      // setOpenPalletLabel(false);
    }   
    setIsLoadingButton(false);      
    return 0;
  }  
  function snackbarPalletLabel() {
    if (palletLabelData.url) {
      return (    
          <Alert severity="success" sx={{ width: '100%' }}>
            PDF Generated: <Link href={palletLabelData.url}>Link</Link>
          </Alert>
      )
    }
    return (    
        <Alert severity="info" sx={{ width: '100%' }}>
          PDF not generated yet
        </Alert>
    )
  }   





  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    destinationName: "",
    destinationAddress: "",
    name1275: "",
    name1275Address: "",  
    url: null,  
  });
  const handleAddInvoiceData = (field,value) => {
    invoiceData[field] = value
    setInvoiceData({...invoiceData});
  }  
  const dialogInvoice = () =>  (
    <Dialog
    open={openInvoice}
    onClose={() => handleDialogOpenInvoiceClose()}
    aria-labelledby="alert-dialog-invoiceData"
    aria-describedby="alert-dialog-invoiceData-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate Invoice</DialogTitle> 
      <DialogContent>
      <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">        
                <Grid item xs={12}>
                  Generate an invoice from saved rows and "value for documents" field.
                </Grid>                                              
                <Grid item xs={12} md={3} >
                  <Typography variant="h6">To</Typography>
                </Grid>  
                <Grid item xs={12} md={9} >
                  <TextField 
                      onChange={(e)=> handleAddInvoiceData("destination",e.target.value)}
                      className={classes.field} 
                      id="destination" 
                      label="Recipient"
                      fullWidth required
                      value={invoiceData.destination}
                  />    
                  <TextField 
                      onChange={(e)=> handleAddInvoiceData("destinationAddress",e.target.value)}
                      className={classes.field} 
                      multiline
                      label="Recipient Address"
                      rows={2}   
                      id="destinationAddress" 
                      fullWidth required
                      value={invoiceData.destinationAddress}
                  />                                     
                </Grid>
                <Grid item xs={12} md={3} >
                  <Typography variant="h6">From</Typography>
                </Grid>  
                <Grid item xs={12} md={9} >
                  <TextField 
                      onChange={(e)=> handleAddInvoiceData("name1275",e.target.value)}
                      className={classes.field} 
                      id="name1275" 
                      label="Sender"
                      fullWidth required
                      value={invoiceData.name1275}
                  />    
                  <TextField 
                      onChange={(e)=> handleAddInvoiceData("name1275Address",e.target.value)}
                      className={classes.field} 
                      multiline
                      rows={2}   
                      id="name1275Address" 
                      label="Sender Address"
                      fullWidth required
                      value={invoiceData.name1275Address}
                  />                                     
                </Grid>  
                <Grid item xs={12} >
                  {snackbarInvoice()}      
                </Grid>                                                                                                                                                                                                                          
              </Grid>             
                    
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateInvoice()} color="primary">
          Generate PDF
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenInvoiceClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenInvoiceClose = () => {
    setOpenInvoice(false); 
  }  
  const handleGenerateInvoice = async () => {
    setIsLoadingButton(true);      
    const url = `/generate/order-invoice`;
    const res = await fetchDataAwait(url, "post", { ...invoiceData, refOrder: id });
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      handleAddInvoiceData("url",res.data.url)
    }   
    setIsLoadingButton(false);      
    return 0;
  }  
  function snackbarInvoice() {
    if (invoiceData.url) {
      return (    
          <Alert severity="success" sx={{ width: '100%' }}>
            PDF Generated: <Link href={invoiceData.url}>Link</Link>
          </Alert>
      )
    }
    return (    
        <Alert severity="info" sx={{ width: '100%' }}>
          PDF not generated yet
        </Alert>
    )
  }



  const [openPackingList, setOpenPackingList] = useState(false); 
  const [packingList, setPackingList] = useState({
    url: null
  });  
  const handleAddPackingList = (field,value) => {
    packingList[field] = value
    setPackingList({...packingList});
  }   
  const dialogPackingList = () =>  (
    <Dialog
    open={openPackingList}
    onClose={() => handleDialogOpenPackingListClose()}
    aria-labelledby="alert-dialog-palelt"
    aria-describedby="alert-dialog-palelt-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate Packing List</DialogTitle> 
      <DialogContent>
            <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">                                        
                <Grid item xs={12} >
                  Create Packing List                              
                </Grid>           
                <Grid item xs={12} >
                  {snackbarPackingList()}      
                </Grid>                                                                                                                                                                                                                  
              </Grid>                         
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGeneratePackingList()} color="primary">
          Generate PDF
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenPackingListClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenPackingListClose = () => {
    setOpenPackingList(false); 
  }  
  const handleGeneratePackingList = async () => {
    setIsLoadingButton(true);      
    const url = `/generate/packing-list`;
    const res = await fetchDataAwait(url, "post", { refOrder: id} );
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      handleAddPackingList("url",res.data.url)
    }   
    setIsLoadingButton(false);      
    return 0;
  }  
  function snackbarPackingList() {
    if (packingList.url) {
      return (    
          <Alert severity="success" sx={{ width: '100%' }}>
            PDF Generated: <Link href={packingList.url}>Link</Link>
          </Alert>
      )
    }
    return (    
        <Alert severity="info" sx={{ width: '100%' }}>
          PDF not generated yet
        </Alert>
    )
  }


  const [openWHPicking, setOpenWHPicking] = useState(false);
  const [whPickingData, setWHPickingData] = useState({
    destinationName: "",
    destinationAddress: "",
    name1275: "",
    name1275Address: "",    
    weightTotal: 0,    
    description: "", 
    nbRows: 0,
    url: null
  });
  const handleAddWHPickingData = (field,value) => {
    whPickingData[field] = value
    setWHPickingData({...whPickingData});
  }  
  const dialogWHPicking = () =>  (
    <Dialog
    open={openWHPicking}
    onClose={() => handleDialogOpenWHPickingClose()}
    aria-labelledby="alert-dialog-palelt"
    aria-describedby="alert-dialog-palelt-description"
    maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title" sx={{ pb: 5 }}>Generate Warehouse picking</DialogTitle> 
      <DialogContent>
            <DialogContentText>
              Generate a document to organize the pickup of cases in the warehouse - Only saved rows are used.                 
              {snackbarPickingList()}      
            </DialogContentText>
            
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> handleGenerateWHPicking()} color="primary">
          Generate PDF
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogOpenWHPickingClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  const handleDialogOpenWHPickingClose = () => {
    setOpenWHPicking(false); 
  }  
  const handleGenerateWHPicking = async () => {
    setIsLoadingButton(true);      
    const url = `/generate/warehouse-picking`;
    const res = await fetchDataAwait(url, "post", {refOrder: id });
    if (res.error) {
      setErrorMessage(res.error.message);
    } else {
      setConfirmationMessage(res.data.message);
      handleAddWHPickingData("url",res.data.url)
    }   
    setIsLoadingButton(false);      
    return 0;
  }  
  function snackbarPickingList() {
    if (whPickingData.url) {
      return (    
          <Alert severity="success" sx={{ width: '100%' }}>
            PDF Generated: <Link href={whPickingData.url}>Link</Link>
          </Alert>
      )
    }
    return (    
        <Alert severity="info" sx={{ width: '100%' }}>
          PDF not generated yet
        </Alert>
    )
  }


  const [uploadDialog, setUploadDialog] = useState(false);
  const [dataFile,setDataFile] = useState({name:"no file selected"});
  const handleDialogUploadClose = () => {
    setUploadDialog(false); 
  }  
  const handleDialogUploadOpen = () => {
    setUploadDialog(true); 
  }    
  const uploadDocument = async () => {
    setIsLoadingButton(true);
    // UPLOAD FILE - Upload file
    const postData = new FormData();
    postData.append("refOrder", id)
    postData.append('file', dataFile); // The file has be the last element  


    const res = await fetchDataAwait(`/warehouse/upload-order-composition`, "post", postData, {'Content-Type': 'multipart/form-data'});
    if (res.error) {
      setErrorMessage(res.error.message);
    }   
    else {
      setUploadDialog(false); 
      setIsLoading(true);
      setDataList(res.data);
      setConfirmationMessage("List saved.");
      setIsLoading(false);
    }

    setIsLoadingButton(false);
    return 1;
  }
  const handleCapture = (event) => {
    setDataFile(event.target.files[0]);
  };   
  const dialogUpload = () =>  (
    <Dialog
    open={uploadDialog}
    onClose={() => handleDialogUploadClose()}
    aria-labelledby="alert-dialog-upload"
    maxWidth='lg'
    >
      <DialogTitle  sx={{ pb: 5 }}>Upload a list of cases</DialogTitle> 
      <DialogContent>
      <DialogContentText >
              <Grid container spacing={0} justifyContent="left" alignItems="left">        
                <Grid sx={{ pb: 2 }} item xs={12}>
                  Upload of list of cases to replace the existing list-
                </Grid>                                              
                <Grid sx={{ pb: 2 }} item xs={12}  >
                  File must be a <strong>"csv"</strong> and contain a column "caseId" with the list of ids, and (optional) a column "price" (numerical value only) and "comment".
                </Grid>  
                <Grid item xs={12} >
                <Button
                  variant="contained"
                  component="label"
                >
                  Upload a CSV
                  <input
                    type="file"
                    hidden
                    onChange={handleCapture}
                  />
                </Button>  <br/>     
                {dataFile.name}   
                </Grid>                                                                                                                                                                                                                          
              </Grid>             
                    
            </DialogContentText>
          </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoadingButton} variant="contained" onClick={()=> uploadDocument()} color="primary">
        Upload CSV
        </LoadingButton>        
        <Button variant="text" onClick={()=> handleDialogUploadClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog> 
  );
  
  function pushToDelivery() {
    navigate('/allocation/delivery', { state: targetComposition }); 
  }

  function pushToSell() {
    navigate('/allocation/transaction-case', { state: targetComposition }); 
  }  

  function defineTransactions() {
    return (
      <Grid container  spacing={3} direction="row" justifyContent="left" alignItems="center" >
        <Grid sx={{ pb: 2 }} item xs={12} >
        <Card >
          <CardContent>
            <Grid xs={12} item container spacing={3} direction="row" justifyContent="left" alignItems="left" >
              <Grid container item xs={12} sm={6} >
                <TextField 
                  onChange={(e)=> updateOrderDescription("name",e.target.value)}
                  className={classes.field} 
                  id="target-name" 
                  label="Order title" 
                  fullWidth required
                  value={dataList.description.name}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid container item xs={12} sm={6}>            
                <TextField
                  onChange={(e)=> updateOrderDescription("description",e.target.value)}
                  className={classes.field}
                  id="target-description"
                  label="Description"
                  value={dataList.description.description}
                  multiline
                  rows={4}
                  fullWidth required
                  disabled={isDisabled}
                />
              </Grid>
              <Grid container item xs={12} sm={6}>            
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Expected Order Date"
                  inputFormat="dd/MM/yyyy"
                  disabled={isDisabled}
                  value={dataList.description.expectedDate}
                  onChange={(e)=> updateOrderDescription("expectedDate",e)}
                  renderInput={(params) => <TextField {...params} />}
                />        
          </LocalizationProvider> 
              </Grid>                  
              <Grid container item xs={12} sm={6}>            
                <TextField
                  onChange={(e)=> updateOrderDescription("status",e.target.value)}
                  className={classes.field}
                  id="target-status"
                  label="status"
                  value={dataList.description.status}
                  fullWidth required
                  disabled={isDisabled}
                />
              </Grid>              
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" disabled={!isDisabled} onClick={handleEditDescriptionClick}><EditIcon/>Edit</Button> 
            <LoadingButton variant="contained" loading={isLoadingNew} disabled={isDisabled} onClick={handleSaveDescriptionClick}><SaveIcon/>Save</LoadingButton> 
            <Button variant="link" disabled={isDisabled} onClick={handleEditDescriptionClick}><CancelIcon/></Button>             
          </CardActions>
        </Card >   
        </Grid>
        <Grid sx={{ pb: 2 }} item xs={12} >
          <Card >
            <CardContent>
            <Grid xs={12} sx={{ pb: 2 }} item justifyContent="left" alignItems="center" > 
            <LoadingButton
                color="primary" loading={isLoading}
                onClick={() => handleDialogUploadOpen()}
                variant="contained"
            >
              <InsertDriveFileIcon/>Upload from list
            </LoadingButton>    
            {dialogUpload()}
            </Grid>
            <Grid xs={12} item justifyContent="left" alignItems="center" > 
            <Stack sx={{ pb:3 }} direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="space-between">   
              <Stack sx={{ pb:3 }} direction="row" spacing={2} justifyContent="left">   
                  <LoadingButton
                      color="primary" loading={isLoading}
                      onClick={handleClickAddRow}
                      variant="contained"
                  >
                    <AddCircleOutlineOutlinedIcon/>Add Row
                  </LoadingButton>
                  <LoadingButton
                      color="primary" loading={isLoading}
                      onClick={handleSaveClick}
                      variant="contained"
                  >
                    <SaveIcon/>Save List
                  </LoadingButton>     
                          
              </Stack>  
              <Stack sx={{ pb:3 }} direction="row" spacing={2} justifyContent="right">   
                <LoadingButton
                  variant="contained"  color="primary" 
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClickMenu}
                >
                  <ListIcon/>Actions
                </LoadingButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                    <Stack direction="column" spacing={2}>
                    <LoadingButton  variant="contained" onClick={() =>  setOpenInvoice(true)}>
                      Generate Invoice
                    </LoadingButton>
                    <LoadingButton  variant="contained" onClick={() =>  setOpenPackingList(true)}>
                    Generate Packing List
                    </LoadingButton>
                    <LoadingButton  variant="contained" onClick={() => setOpenPalletLabel(true)}>
                    Generate Pallet Label
                    </LoadingButton>
                    <LoadingButton  variant="contained" onClick={() => setOpenWHPicking(true)}>
                    Generate Warehouse Picking
                    </LoadingButton>


                    <Button  variant="contained" onClick={() => pushToDelivery()}>
                    Create Delivery Transactions
                    </Button>
                    <Button  variant="contained" onClick={() => pushToSell()}>
                    Create Sell Transactions
                    </Button>                  

                    {dialogInvoice()}
                    {dialogPackingList()}
                    {dialogWHPicking()}

                    {dialogPalletLabel()}

                    </Stack>
                </Menu>                  
              </Stack>                
            </Stack>  
            </Grid>                        
            <Grid xs={12} item container spacing={3} direction="row" justifyContent="left" alignItems="center"  className={classes.root}>  
                <DataGridPro 
                pagination
                overflow="auto"
                autoHeight 
                rows={targetComposition} 
                columns= {columnsTransactions} 
                loading={isLoading}
                onCellEditCommit={handleCellEditCommit}
                components={{
                  Toolbar: CustomToolbar,
                }}                  
              />      
            </Grid> 
          </CardContent>
        </Card>  
        </Grid>                
        {dialogConfirmation()}          
        <DialogAddUniqueCaseFromSearch dialogOpen={dialogOpenCase} setDialogOpen={setDialogOpenCase} setErrorMessage={setErrorMessage} rowId={rowId} setCase={updateCaseData}/>
      </Grid>
    ) // [dialogOpenClient,setDialogOpenClient]
  }


  const handleCellEditCommit = ({ id, field, value }) => {
        const updatedRows = targetComposition.map((row) => {
          if (row.id === id) {
            row[field] = value;
            return row;
          }
          return row;
        });
        setTargetComposition(updatedRows);
    };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };
  function snackbarError() {
    if (errorMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setConfirmationMessage(null);
  };  
  function snackbarSuccess() {
    if (confirmationMessage) {
      return (    
        <Snackbar open  onClose={handleCloseSnackbarSuccess} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
          <Alert onClose={handleCloseSnackbarSuccess} severity="success" sx={{ width: '100%' }}>
            {confirmationMessage}
          </Alert>
        </Snackbar> 
      )
    }
    return ""
  }  


  function displayPage(func) {
    return (
      <Page title="Order Prep">
        <Container maxWidth="false">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Prepare Order</Typography>
          </Box>
          {func()}
        </Container>
        {snackbarError()}       
        {snackbarSuccess()} 
      </Page>
    );
  }

  // PAGE LOGIC
  if (!isLoadingList) {
    return displayPage(defineTransactions);
  }

    return displayPage(loadingSelect);
}
